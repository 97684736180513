import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function WantStartBusiness() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        section.querySelector(".left-div").style.animation =
          "slideInLeft 1s ease forwards";
        section.querySelector(".right-div").style.animation =
          "slideInRight 1s ease forwards";
      } else {
        // If the section is out of view, revert the animation
        section.querySelector(".left-div").style.animation = "none";
        section.querySelector(".right-div").style.animation = "none";
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Bedrijf starten
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Wil ik ondernemen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Ben je nieuwsgierig of ondernemerschap iets voor jou is? Het idee
          lijkt interessant, maar wil je ook echt ondernemer worden? Wij staan
          klaar om je te helpen alles in kaart te brengen, zodat je een
          weloverwogen beslissing kunt nemen.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Ondernemen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Je eigen bedrijf starten, wat kost dat?
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Branche- en marktonderzoek
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Parttime ondernemen
        </Button>
      </div>
      <div style={{ textAlign: "center" }} className="section-1">
        <Divider textAlign="left" className="divider">
          {"Ondernemen".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Wat omvat ondernemerschap precies en is het iets voor jou?
                Terwijl sommigen er nooit aan zouden denken, zijn er anderen die
                niets liever doen. In dit artikel ontdek je of ondernemen bij
                jou past en wat iemand tot een echte ondernemer maakt.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/IdeaImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-2">
        <Divider textAlign="right" className="divider">
          {"Je eigen bedrijf starten, wat kost dat?".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/MountainImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Wat zijn eigenlijk de kosten om je eigen bedrijf te starten? In
                dit artikel laten we zien welke uitgaven gepaard gaan met de
                eerste stappen van het ondernemerschap!
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-3">
        <Divider textAlign="left" className="divider">
          {"Branche- en marktonderzoek".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Voordat je begint met je eigen onderneming, is het raadzaam om
                eerst een branche- en marktonderzoek uit te voeren. Ben je
                bekend met de branche waarin je gaat opereren? En ben je op de
                hoogte van de concurrentie? In ons artikel delen we meer
                inzichten over het uitvoeren van branche- en marktonderzoek.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div
              className="right-div imageCard"
              style={{ display: "inline-block" }}
            >
              <img
                src={"./Professional/ThinkingImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-4">
        <Divider textAlign="right" className="divider">
          {"Parttime ondernemen".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/ResearchImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Ondernemen naast je baan, studie of gezin? Dat is heel goed
                mogelijk! Het biedt je de kans om te ontdekken of ondernemen
                iets voor jou is. Er zijn tal van mogelijkheden voor parttime
                ondernemen, en in dit artikel vertellen we waar je rekening mee
                moet houden.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default WantStartBusiness;
