import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

const ExportToExcel = ({ Data, fileName, headerName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (excelData, fileName) => {
    function modifyData(inputData, headerName) {
      if (!Array.isArray(inputData)) {
        return [];
      }

      const modifiedData = inputData.map((object) => {
        const modifiedObject = {};

        for (const oldField in headerName) {
          if (object.hasOwnProperty(oldField)) {
            const newField = headerName[oldField];
            modifiedObject[newField] = object[oldField];
          }
        }

        return modifiedObject;
      });

      const filteredData = modifiedData.filter(
        (object) => Object.keys(object).length > 0
      );

      return filteredData;
    }

    const modifiedData = modifyData(excelData, headerName);
    const ws = XLSX.utils.json_to_sheet(modifiedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant="outlined"
      color="success"
      startIcon={<NoteAddOutlinedIcon />}
      onClick={(e) => exportToCSV(Data, fileName)}
    >
      Excel export
    </Button>
  );
};
export default ExportToExcel;
