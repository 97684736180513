import { useAuth } from '../hooks/auth'
import { Navigate, useLocation } from 'react-router-dom';

export const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const publicRoutes = ["/login", "/forgot-password", "/reset-password", "/register"];

  if(!auth?.user && !localStorage.getItem("dashbook_user")) {
    return <Navigate to="/login" state={{ path: location.pathname }}/>
  }

  if (publicRoutes.includes(location.pathname)) {
    return <Navigate to="/" />
  }

  return children;
}
