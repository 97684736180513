import React, { useEffect } from "react";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import SideBarItem from "./SideBarItem";
import SideBarNestedItem from "./SideBarNestedItem";
import "./SideBar.css";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AirplayIcon from "@mui/icons-material/Airplay";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
// import NoAccountsIcon from "@mui/icons-material/NoAccounts";
// import CategoryIcon from "@mui/icons-material/Category";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MoneyIcon from "@mui/icons-material/Money";
import { Typography } from "@mui/material";
import AssistantIcon from "@mui/icons-material/Assistant";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ShutterSpeedIcon from "@mui/icons-material/ShutterSpeed";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CampaignIcon from "@mui/icons-material/Campaign";
import AcUnitIcon from "@mui/icons-material/AcUnit";

const SideBarContent = ({ isDarkTheme, showFullDrawer }) => {
  const { t } = useTranslation();

  /**
   * label: String
   * route: String - route to redirect
   * activeOn: Array[String] - array of routes in which module will be displayed as activated
   */
  const moduleList = [
    {
      label: `${t("dashboard.title")}`,
      route: "/dashboard",
      activeOn: ["/dashboard"],
      children: [],
      icon: <DashboardIcon sx={{ color: "white" }} />,
    },
    {
      label: `${t("customers.title")}`,
      route: "/customers",
      activeOn: ["/customers", "/customers/add", "/customers/edit/.*"],
      children: [],
      icon: <SupervisedUserCircleIcon sx={{ color: "white" }} />,
    },
    {
      label: t("offers.title"),
      route: "/offers",
      activeOn: ["/offers", "/offers/add", "/offers/edit/.*"],
      children: [],
      icon: <AssistantIcon sx={{ color: "white" }} />,
    },
    {
      label: t("projects.title"),
      route: "/projects",
      activeOn: ["/projects", "/projects/add", "/projects/edit/.*"],
      children: [],
      icon: <AirplayIcon sx={{ color: "white" }} />,
    },
    {
      label: t("hours.side_title"),
      route: "/hours/overview",
      activeOn: [],
      children: [
        {
          label: t("team.title"),
          route: "/team",
          icon: <Diversity1Icon sx={{ color: "white" }} />,
          activeOn: ["/team"],
        },
        {
          label: t("hourInvoice.title"),
          route: "/invoice",
          icon: <QueryBuilderIcon sx={{ color: "white" }} />,
          activeOn: ["/invoice"],
        },
        {
          label: t("report.title"),
          route: "/hours/reports",
          icon: <AssessmentIcon sx={{ color: "white" }} />,
          activeOn: ["/report"],
        },
      ],
      icon: <ShutterSpeedIcon sx={{ color: "white" }} />,
    },
    {
      label: t("rides.title"),
      route: "/rides",
      activeOn: ["/rides", "/rides/add", "/rides/edit/.*"],
      children: [],
      icon: <AirportShuttleIcon sx={{ color: "white" }} />,
    },
    {
      label: t("invoices.title"),
      route: "/normal_invoice",
      activeOn: [
        "/normal_invoice",
        "/normal_invoice/add",
        "/normal_invoice/edit/.*",
      ],
      icon: <ReceiptIcon sx={{ color: "white" }} />,

      children: [
        {
          label: t("invoices.periodic_invoices.title"),
          route: "/periodic_invoice",
          icon: <DescriptionIcon sx={{ color: "white" }} />,
          activeOn: [
            "/periodic_invoice",
            "/periodic_invoice/add",
            "/periodic_invoice/edit/.*",
          ],
        },
      ],
    },
    {
      label: t("costs.title"),
      route: "/purchase_invoice",
      activeOn: [
        "/purchase_invoice",
        "/purchase_invoice/add",
        "/purchase_invoice/edit/.*",
      ],
      children: [],
      icon: <MoneyIcon sx={{ color: "white" }} />,
    },
    // {
    //   label: t("role.title"),
    //   route: "/roles",
    //   activeOn: ["/roles", "/roles/add", "/roles/edit/.*"],
    //   children: [],
    //   icon: <NoAccountsIcon sx={{ color: "white" }} />,
    // },
    {
      label: t("transactions.title"),
      route: "/transactions",
      activeOn: ["/transactions", "/transactions/add", "/transactions/edit/.*"],
      children: [],
      icon: <AccountBalanceIcon sx={{ color: "white" }} />,
    },
    // {
    //   label: t("subcategories.title"),
    //   route: "/subcategories",
    //   activeOn: [
    //     "/subcategories",
    //     "/subcategories/add",
    //     "/subcategories/edit/.*",
    //   ],
    //   children: [],
    //   icon: <CategoryIcon sx={{ color: "white" }} />,
    // },
    // {
    //   label: t("users.title"),
    //   route: "/users",
    //   activeOn: ["/users", "/users/add", "/users/edit/.*"],
    //   children: [],
    //   icon: <GroupAddIcon sx={{ color: "white" }} />,
    // },

    {
      label: t("inbox.side_title"),
      activeOn: [],
      children: [
        {
          label: t("inbox.inkoop.side_title"),
          route: "/inbox/inkoop",
          icon: <Diversity1Icon sx={{ color: "white" }} />,
          activeOn: ["/inbox/inkoop"],
        },
        {
          label: t("inbox.verkoop.side_title"),
          route: "/inbox/verkoop",
          icon: <Diversity1Icon sx={{ color: "white" }} />,
          activeOn: ["/inbox/verkoop"],
        },
      ],
      icon: <ShutterSpeedIcon sx={{ color: "white" }} />,
    },
    {
      label: t("reports.title"),
      route: "/overviews/profitandloss",
      activeOn: [
        "/overviews/profitandloss",
        "/overviews/balance",
        "/overviews/trialbalance",
        "/overviews/costs_and_revenue",
        "/overviews/mutaties",
      ],
      children: [],
      icon: <AssessmentIcon sx={{ color: "white" }} />,
    },
    {
      label: t("otherBooking.title"),
      route: "/otherBookings",
      activeOn: ["/otherBookings"],
      children: [],
      icon: <AcUnitIcon sx={{ color: "white" }} />,
    },
    {
      label: t("apps_and_deals.title"),
      route: "/apps_and_deals/featured",
      activeOn: [
        "/apps_and_deals/featured",
        "/apps_and_deals/apps",
        "/apps_and_deals/deals",
        "/apps_and_deals/my_apps",
        "/apps_and_deals/my_deals",
      ],
      children: [],
      icon: <CampaignIcon sx={{ color: "white" }} />,
    },
    {
      label: "Professional",
      route: "/professionalList/featured",
      activeOn: [
        "/professionalList/featured",
        "/professionalList/quotesSubmitted",
        "/professionalList/expertiseFields",
        "/professionalList/credits",
      ],
      children: [],
      icon: <CampaignIcon sx={{ color: "white" }} />,
    },
  ];

  return (
    <Paper
      sx={{ minHeight: "100vh", bgcolor: isDarkTheme ? "#161616" : "#0d0d0d" }}
      square
    >
      <Toolbar>
        <Box
          sx={{
            mx: "auto",
            my: 2,
          }}
        >
          {!showFullDrawer ? (
            <Typography variant="h4" color="white">
              D
            </Typography>
          ) : (
            <img src={"/logo_light.svg"} alt="Dashbook Logo" width={"100px"} />
          )}
        </Box>
      </Toolbar>

      <Divider sx={{ borderColor: "#1d1d1d !important" }} />
      <List
        component="nav"
        sx={{ mx: showFullDrawer ? 1 : 0 }}
        className={`sidebar ${isDarkTheme ? "dark" : "light"}`}
      >
        {showFullDrawer
          ? moduleList.map((item, index) => {
              if (item.children.length) {
                return (
                  <SideBarNestedItem
                    item={item}
                    key={index.toString()}
                    showFullDrawer={showFullDrawer}
                  />
                );
              }

              return (
                <SideBarItem
                  item={item}
                  key={index.toString()}
                  showFullDrawer={showFullDrawer}
                />
              );
            })
          : moduleList.map((item, outerIndex) => {
              if (item.children?.length) {
                return (
                  <>
                    <SideBarItem
                      item={item}
                      key={outerIndex.toString()}
                      showFullDrawer={showFullDrawer}
                    />
                    {item.children.map((children, innerIndex) => (
                      <SideBarItem
                        item={children}
                        key={innerIndex}
                        showFullDrawer={showFullDrawer}
                      />
                    ))}
                  </>
                );
              }
              return (
                <SideBarItem
                  item={item}
                  key={outerIndex.toString()}
                  showFullDrawer={showFullDrawer}
                />
              );
            })}
      </List>
    </Paper>
  );
};

export default function SideBar({
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
  isDarkTheme,
  window,
  showFullDrawer,
}) {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { i18n } = useTranslation();

  useEffect(() => {
    let language = localStorage.getItem("selectedLanguage")
      ? localStorage.getItem("selectedLanguage")
      : "nl";

    i18n.changeLanguage(language);
    // setSelectedLanguage(language);
    // eslint-disable-next-line
  }, [i18n]);

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        height: "100vh",
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            height: "100vh",
            borderRight: "none",
          },
        }}
      >
        <SideBarContent
          isDarkTheme={isDarkTheme}
          showFullDrawer={showFullDrawer}
        />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            height: "100%",
            borderRight: "none",
            bgcolor: isDarkTheme ? "#161616" : "#0d0d0d",
          },
        }}
        open
      >
        <SideBarContent
          isDarkTheme={isDarkTheme}
          showFullDrawer={showFullDrawer}
        />
      </Drawer>
    </Box>
  );
}
