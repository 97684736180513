import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../Functies/TryFourteenDays";
import { useNavigate } from "react-router-dom";

function WhyWe() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div style={{ padding: "2rem" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Waarom ?
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Dashbook: dé voordeligste en meest gebruiksvriendelijke
          boekhoudsoftware voor starters, zzp'ers en kleine ondernemers.
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Speciaal ontworpen voor ondernemers, biedt Dashbook alle essentiële
          functies zonder extra kosten. Het is eenvoudig te gebruiken, zodat je
          zelfstandig kunt werken zonder de hulp van een accountant.
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Uniek in Nederland, Dashbook bevat een module voor projecten met ORT
          uren. Maar Dashbook is meer dan alleen boekhoudsoftware. Je kunt je
          eigen producten of diensten promoten en reageren op aanvragen van
          professionals, waardoor al je ondernemingsbehoeften op één plek worden
          vervuld.
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor relatiebeheer met Dashbook
          </Typography>
        </div>
        <div
          className="buttonBox"
          style={{
            padding: "1rem 3rem",
            paddingTop: "0rem !important",
            overflowX: "scroll",
          }}
        >
          <Button
            variant="contained"
            className={`buttonDesign`}
            onClick={() => scrollToTarget(1)}
          >
            Doen mee met de beste van beste
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(2)}
          >
            Speciaal voor starters en zzp'ers
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(3)}
          >
            Super simpel
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(4)}
          >
            Al vanaf € 5, -
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(5)}
          >
            Geen verborgen kosten
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(6)}
          >
            Veilig- secure cloud
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(7)}
          >
            Gratis bankkoppeling
          </Button>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-1"
        >
          <Divider textAlign="left" className="divider">
            {"Doen mee met de beste van beste".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="left-div card">
                <Typography variant="h4" gutterBottom>
                  Doen mee met de beste van beste
                </Typography>

                <Typography variant="subtitle1" gutterBottom>
                  Dashbook: Jouw persoonlijke boekhouding, op jouw manier
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Boekhouden wordt vaak als saai en tijdrovend ervaren, vooral
                  voor starters, zzp'ers, en kleine ondernemers. Het lijkt soms
                  een noodzakelijk kwaad.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Traditionele boekhoudsoftware is vaak ingewikkeld en bevat
                  functies die niet relevant zijn voor jou. Spreadsheets zijn
                  foutgevoelig en nemen veel tijd in beslag.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook is anders. We begrijpen dat elke ondernemer, of je nu
                  een starter, zzp'er, of kleine ondernemer bent, uniek is in
                  denken en werken. Met Dashbook kun je jouw boekhouding op jouw
                  manier bijhouden.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook is gebruiksvriendelijk, overzichtelijk en bevat alle
                  functies die jij als starter, zzp'er, of kleine ondernemer
                  nodig hebt.
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="right-div imageCard">
                <img
                  src={"./Professional/join_the_best.png"}
                  alt="Dashbook Logo"
                  height={"350px"}
                  style={{
                    margin: "auto 0px",
                    display: "inline-block",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-2"
        >
          <Divider textAlign="right" className="divider">
            {"Speciaal voor starters en zzp'ers".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="up-div imageCard"></div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="down-div card">
                {" "}
                <Typography variant="h4" gutterBottom>
                  Speciaal voor starters en zzp'ers
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook: eenvoudig en overzichtelijk boekhouden voor zzp'ers
                  en kleine ondernemingen
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook is speciaal ontwikkeld met het oog op zzp'ers en
                  kleine ondernemingen. Het programma is ontworpen om eenvoudig
                  te gebruiken te zijn en bevat alle essentiële functies die je
                  nodig hebt.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  In tegenstelling tot traditionele boekhoudsoftware is Dashbook
                  niet overladen met overbodige functies. Hierdoor blijft het
                  programma overzichtelijk en gemakkelijk te begrijpen.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Ontdek het gemak van boekhouden met Dashbook.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-3"
        >
          <Divider textAlign="left" className="divider">
            {"Super simpel".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="left-div card">
                <Typography variant="h4" gutterBottom>
                  Super simpel
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook: moeiteloos boekhouden voor zzp'ers, helder en
                  gestroomlijnd
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook is specifiek ontworpen met zzp'ers in gedachten.
                  Hierdoor is het programma gemakkelijk in gebruik en voorzien
                  van alle benodigde functies.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Met Dashbook kun je jouw boekhouding eenvoudig bijhouden
                  dankzij duidelijke taal en begrijpelijke processen. Je hebt
                  geen uitgebreide boekhoudkundige kennis nodig.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  En als je toch vragen hebt, staan we voor je klaar. Onze
                  uitstekende ondersteuning is ook beschikbaar via telefoon.
                  Ervaar het gemak van boekhouden met Dashbook.
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="right-div imageCard"></div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-4"
        >
          <Divider textAlign="right" className="divider">
            {"Al vanaf € 5, -".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="up-div imageCard"></div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="down-div card">
                <Typography variant="h4" gutterBottom>
                  Al vanaf € 5, -
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook: betaalbaar boekhouden zonder concessies aan
                  kwaliteit
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook is het meest betaalbare boekhoudprogramma in
                  Nederland.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Wij voorzien in alle benodigde functies zonder onnodige
                  kosten.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Met Dashbook betaal je slechts vanaf € 5,- per maand, omdat we
                  begrijpen dat je niet gedwongen wilt worden om het volle pond
                  te betalen voor functies die je niet gebruikt. Bij ons betaal
                  je gewoon voor wat je nodig hebt.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Bovendien kun je extra korting verdienen door vrienden en
                  kennissen uit te nodigen. Ontdek de voordelen van betaalbaar
                  boekhouden met Dashbook.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-5"
        >
          <Divider textAlign="left" className="divider">
            {"Geen verborgen kosten".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="left-div card">
                <Typography variant="h4" gutterBottom>
                  Geen verborgen kosten
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook: boekhouden voor zzp'ers, helder en transparant
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Veel boekhoudprogramma's lijken in eerste instantie voordelig,
                  maar in de praktijk blijken er vaak addertjes onder het gras
                  te zitten.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Ze lokken met een lage prijs voor de eerste paar maanden,
                  waarna de kosten stijgen.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Belangrijke functies, zoals facturatie, zijn soms niet
                  inbegrepen in de basisprijs.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Daarnaast zijn er vaak beperkingen op het aantal boekingen,
                  facturen en banktransacties.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Bij Dashbook gaan we een stap verder. Wij hanteren een vaste
                  prijs zonder verrassingen. Ons systeem is overzichtelijk met
                  drie pakketten, waarbij duidelijk wordt aangegeven welke
                  module bij elk pakket is inbegrepen.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Wat Dashbook uniek maakt, is dat onze laatste module nog
                  steeds de meest voordelige in Nederland is. Kies voor
                  transparantie en betaalbaarheid met Dashbook.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Onbeperkt factureren, boeken en banktransacties uitvoeren is
                  bij ons vanzelfsprekend.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Zo weet je altijd waar je aan toe bent. Geen addertjes, alleen
                  helderheid en transparantie.
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="right-div imageCard"></div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-6"
        >
          <Divider textAlign="right" className="divider">
            {"Veilig- secure cloud".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="up-div imageCard"></div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="down-div card">
                <Typography variant="h4" gutterBottom>
                  Veilig- secure cloud
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook: veilig en betrouwbaar boekhouden
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Bij Dashbook staat de veiligheid van jouw gegevens voorop.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Wij bewaren jouw gegevens in beveiligde datacenters en nemen
                  dagelijks back-ups, waardoor je altijd en overal toegang hebt
                  tot je gegevens zonder het risico deze te verliezen.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Voor meer informatie over onze beveiligingsmaatregelen
                  verwijzen wij je graag naar onze website.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-7"
        >
          <Divider textAlign="left" className="divider">
            {"Gratis bankkoppeling".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="left-div card">
                <Typography variant="h4" gutterBottom>
                  Gratis bankkoppeling
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook: Gratis bankkoppeling, geen extra kosten
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Bij Dashbook onderscheiden we ons door onze gratis
                  bankkoppeling. Terwijl andere boekhoudprogramma's extra kosten
                  in rekening brengen voor deze service, nemen wij die kosten
                  voor onze rekening.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dit betekent dat je bij Dashbook profiteert van een
                  automatische bankkoppeling zonder extra kosten, waardoor we
                  niet alleen concurrerend zijn maar zelfs nog voordeliger dan
                  andere boekhoudpakketten. Kies voor gemak, bespaar kosten,
                  kies Dashbook.
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="right-div imageCard"></div>
            </Grid>
          </Grid>
        </div>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default WhyWe;
