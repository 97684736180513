import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SellDigitalInboxSales() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Digitale inbox (verkoop)
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Als je geen gebruik maakt van de facturenmodule in Dashbook, heb je de
          mogelijkheid om de functionaliteit "Mailbox facturen" in te schakelen.
          Hiermee kun je je facturen mailen en verwerken in het verkoopmodule
          van Dashbook.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden Digitale inbox (verkoop)met Dashbook
          </Typography>
        </div>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Digitale inbox (verkoop)
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Digitale inbox (verkoop)".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Digitale inbox (verkoop)
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Als derden verantwoordelijk zijn voor het opstellen van je
                facturen, kun je al deze documenten eenvoudig naar het
                geactiveerde e-mailadres van Dashbook sturen. Dit is bijzonder
                handig omdat je ze vervolgens moeiteloos kunt doorsturen naar de
                Mailbox verkoop. Van daaruit kun je de facturen efficiënt
                verwerken in je boekhouding.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Voordelen: </strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiëntie: </strong>
                Derden kunnen facturen rechtstreeks naar het geactiveerde
                e-mailadres van Dashbook sturen, waardoor het proces
                gestroomlijnd wordt.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemakkelijke Verwerking:</strong>
                Door de ontvangen facturen naar de Mailbox verkoop te sturen,
                kun je ze op een centrale locatie beheren en efficiënt in je
                boekhouding verwerken.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Geïntegreerde Workflow:</strong>
                Het gebruik van deze functionaliteit binnen Dashbook zorgt voor
                een naadloze integratie in je boekhoudproces, waardoor
                handmatige stappen worden geminimaliseerd.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Tijdsbesparing:</strong>
                Het eenvoudig doorsturen van facturen en het centraal beheren
                ervan bespaart tijd en bevordert een gestroomlijnde
                boekhoudkundige workflow.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellDigitalInboxSales;
