import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

export default function SideBarItem({ item, classes = "", showFullDrawer }) {
  let navigate = useNavigate();
  const location = useLocation();

  let isActive = false;

  for (let i = 0; i < item.activeOn?.length; i++) {
    const route = item.activeOn[i];
    const regex = new RegExp(route);

    if (location.pathname.match(regex)) {
      isActive = true;
    }
  }

  const handleNavigation = (selectedModule) => {
    navigate(selectedModule.route);
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffc367",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 800,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <CustomWidthTooltip
      title={!showFullDrawer ? <Typography>{item.label}</Typography> : ""}
      arrow
      placement="right"
    >
      <ListItemButton
        key={item.route}
        sx={{ mr: !showFullDrawer ? 1 : 0 }}
        onClick={() => handleNavigation(item)}
        className={`side-nav-link ${isActive && "active"} ${classes}`}
      >
        <ListItemText
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <Stack direction="row" spacing={2}>
            {item.icon}
            {showFullDrawer && (
              <Typography
                variant="body1"
                noWrap
                sx={{ fontSize: "14px" }}
                color="primaryText.dark"
              >
                {item.label}
              </Typography>
            )}
          </Stack>
        </ListItemText>
      </ListItemButton>
    </CustomWidthTooltip>
  );
}
