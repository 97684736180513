import { Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function ProfessionalFooter() {
  return (
    <>
      <div className="p-1 footer">
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <Link
              className="footerLink"
              to={"/"}
              style={{ marginBottom: "0px" }}
            >
              <img
                src={"/logo_light.svg"}
                alt="Dashbook Logo"
                width={"100px"}
                height={"15px"}
                style={{ margin: "auto 0px" }}
              />
              <h3 className="footerMenuTitle">Dashbook</h3>
            </Link>
            <p
              style={{
                color: "white",
                marginTop: "0px",
                textAlign: "left",
              }}
            >
              Vind de perfecte match
            </p>
            <Link
              className="footerLink"
              to="mailto:info@dashbookofferte"
              style={{ justifyContent: "flex-start" }}
            >
              <img
                src={"./Professional/mailIcon.png"}
                alt="mail Icon"
                height={"16px"}
                style={{ margin: "auto 0px" }}
              />
              <span style={{ margin: "auto 0px" }}>info@dashbookofferte</span>
            </Link>
            <Link
              className="footerLink"
              to={"tel:+0880000000"}
              style={{ justifyContent: "flex-start" }}
            >
              <img
                src={"./Professional/phoneIcon.png"}
                alt="phone Icon"
                height={"16px"}
                style={{ margin: "auto 0px" }}
              />
              <span style={{ margin: "auto 0px" }}>088 - 000 00 00</span>
            </Link>

            <h3 className="footerMenuTitle">Locaties</h3>
            <p className="footerAdd">Nederland</p>
            <p className="footerAdd">Keersopstraat 15</p>
            <p className="footerAdd">3044EX, Rotterdam</p>
            <p className="footerAdd">KVK: 89981154</p>
          </Grid>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <h3 className="footerMenuTitle">Over Dashbook</h3>
            <Link className="footerLink" to={"/contact"}>
              Contact
            </Link>
            <Link className="footerLink" to={"/onze_missie"}>
              Onze missie
            </Link>
            <Link className="footerLink" to={"/vacatures"}>
              Vacatures
            </Link>
            <Link className="footerLink" to={"/blog"}>
              Bloq
            </Link>
            <Link
              className="footerLink"
              to={"/veel_gestelde_vragen"}
              
            >
              Veelgestelde vragen
            </Link>
            <Link
              className="footerLink"
              to={"/voorwaarden"}
              
            >
              Algemene voorwaarden
            </Link>
            <Link
              className="footerLink"
              to={"/privacyverklaring"}
              
            >
              Privacyverklaring
            </Link>
            <Link
              className="footerLink"
              to={"/cookieverklaring"}
              
            >
              Cookieverklaring
            </Link>
            <Link
              className="footerLink"
              to={"/sitemap"}
              
            >
              Sitemap
            </Link>
          </Grid>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <h3 className="footerMenuTitle">Support</h3>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              to={"/kennisbank_faq"}
              
            >
              Kennisbank / FAQ
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              to={"/kennisbank_faq"}
              
            >
              Hoe boek ik
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              to={"/kennisbank_faq"}
              
            >
              Handleiding
            </Link>
            <Link
              className="footerLink"
              style={{ justifyContent: "flex-start" }}
              to={"/support"}
              
            >
              Support
            </Link>
          </Grid>
          <Grid item xs={12} md={2.5} sm={5} className="borderRadius">
            <h3 className="footerMenuTitle">Voor bedrijven</h3>
            <Link
              className="footerLink"
              onClick={() => {
                window.location.href = "https://dashbook.nl/login";
              }}
            >
              Aanmelden als professional
            </Link>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        sx={{
          backgroundColor: "black",
          color: "white",
          padding: "0px 2rem",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ order: { xs: 2, md: 1 }, alignSelf: "center" }}
        >
          <Typography
            variant="body1"
            sx={{
              margin: "0rem",
              fontSize: "0.9rem",
              fontFamily: "'Poppins'",
            }}
            gutterBottom
          >
            © Copyright 2024 ProfitB B.V.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
          <div className="flex footerIconGroup">
            <div className="footerSocialIcon">
              <img src={"./Professional/FaceBookIcon.png"} alt="phone Icon" />
            </div>
            <div className="footerSocialIcon">
              <img src={"./Professional/LinkedInIcon.png"} alt="phone Icon" />
            </div>
            <div className="footerSocialIcon">
              <img src={"./Professional/TwitterIcon.png"} alt="phone Icon" />
            </div>
            <div className="footerSocialIcon">
              <img src={"./Professional/YoutubeIcon.png"} alt="phone Icon" />
            </div>
            <div className="footerSocialIcon">
              <img src={"./Professional/InstagramIcon.png"} alt="phone Icon" />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfessionalFooter;
