import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import API from "../config/axios";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useForm } from "react-hook-form";
import CheckBox from "./form/CheckBox";
// import AutocompleteInput from "./form/AutocompleteInput";
// import TextInput from "./form/TextInput";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const formatDateToReadable = (dateString) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const formattedDate = `${day} ${months[monthIndex]} ${year}`;
  return formattedDate;
};

const ActiveInfo = ({ setComponent }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const abortController = useRef(new AbortController());
  const [purchasedSubscriptions, setPurchasedSubscriptions] = useState([]);
  const [userMemberships, setUserMemberships] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const expireWarningDays = 2;
  const getPurchasedSubscription = () => {
    API.get("/users/subscription/user", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setPurchasedSubscriptions(
            resData.data.sort(
              (a, b) => new Date(b.endDate) - new Date(a.endDate)
            )
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const getUsersMemberships = () => {
    API.get("/users/membership/user/list", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const activeMemberships = resData.data.filter(
            (item) => item.status === "active"
          );
          setUserMemberships(
            activeMemberships.sort(
              (a, b) => new Date(b.endDate) - new Date(a.endDate)
            )
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const isExpireSoon = (endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    const endDateObj = new Date(endDate);

    const difference = endDateObj.getTime() - Date.now();

    const differenceInDays = Math.ceil(difference / millisecondsPerDay);
    return differenceInDays <= expireWarningDays;
  };
  const getMemberships = () => {
    API.get("/users/membership/list", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setMemberships(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getPurchasedSubscription();
    getUsersMemberships();
    getMemberships();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {/* <Button onClick={checkStatus}>Check</Button> */}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="LIDMAATSCHAP" />
          <Tab label="ABONNEMENT" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {userMemberships.length > 0 ? (
          <div>
            <Alert
              severity="info"
              icon={false}
              color="info"
              sx={{ marginBottom: "1rem" }}
              classes={{ message: "fullWidthMessage" }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ textAlign: "center", margin: "0px" }}
              >
                Hier zijn uw momenteel actieve lidmaatschappen:
              </Typography>
            </Alert>
            <Grid container justifyContent={"center"} sx={{ padding: "1rem" }}>
              {userMemberships.map((membership, membershipIndex) => (
                <Grid
                  item
                  xs={12}
                  md={3.75}
                  sm={12}
                  sx={{
                    marginRight: "2rem",
                    marginBottom: "2rem",
                    position: "relative",
                  }}
                  key={membershipIndex}
                >
                  <Card className={`subCard`}>
                    <CardContent style={{ paddingBottom: "0px" }}>
                      <Typography
                        variant="overline"
                        display="block"
                        align="center"
                        sx={{
                          backgroundColor: "#D0AF88",
                          color: "white",
                          marginBottom: "1rem",
                        }}
                      >
                        {
                          memberships.find(
                            (item) =>
                              item._id === membership.membershipId ||
                              item.id === membership.membershipId
                          )?.name
                        }
                      </Typography>

                      <Typography variant="h3" sx={{ textAlign: "center" }}>
                        {
                          memberships.find(
                            (item) =>
                              item._id === membership.membershipId ||
                              item.id === membership.membershipId
                          )?.price
                        }
                        *
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        sx={{ textAlign: "center" }}
                      >
                        per jaar
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Begint om:
                        <strong>
                          {formatDateToReadable(membership.startDate)}
                        </strong>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Eindigt bij:
                        <strong>
                          {formatDateToReadable(membership.endDate)}
                        </strong>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <>
            <Alert
              severity="warning"
              icon={false}
              color="warning"
              sx={{ marginBottom: "1rem" }}
              classes={{ message: "fullWidthMessage" }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ textAlign: "center", margin: "0px" }}
              >
                Geen actieve lidmaatschappen gevonden.
              </Typography>
            </Alert>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setComponent("Buy")}
              >
                Kopen
              </Button>
            </div>
          </>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {purchasedSubscriptions.length > 0 ? (
          <Alert
            severity="info"
            icon={false}
            color="info"
            sx={{ marginBottom: "1rem" }}
            classes={{ message: "fullWidthMessage" }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ textAlign: "center", margin: "0px" }}
            >
              Hier zijn uw momenteel actieve abonnementen:
            </Typography>
          </Alert>
        ) : (
          <Alert
            severity="warning"
            icon={false}
            color="warning"
            sx={{ marginBottom: "1rem" }}
            classes={{ message: "fullWidthMessage" }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ textAlign: "center", margin: "0px" }}
            >
              Geen actieve abonnementen gevonden.
            </Typography>
          </Alert>
        )}

        {purchasedSubscriptions.length > 0 ? (
          <div>
            <Grid container justifyContent={"center"} sx={{ padding: "1rem" }}>
              {purchasedSubscriptions.map((subscription, subscriptionIndex) => (
                <Grid
                  item
                  xs={12}
                  md={3.75}
                  sm={12}
                  sx={{
                    margin: "0px 1rem",
                    marginBottom: "2rem",
                    position: "relative",
                  }}
                  key={subscriptionIndex}
                >
                  <Card className={`subCard `}>
                    <CardContent style={{ paddingBottom: "0px" }}>
                      {isExpireSoon(subscription.endDate) ? (
                        <Typography
                          variant="overline"
                          display="block"
                          align="center"
                          sx={{
                            backgroundColor: "red",
                            color: "white",
                            margin: "1rem 0px",
                          }}
                        >
                          Verloopt binnenkort
                        </Typography>
                      ) : (
                        ""
                      )}
                      {/* {subscription.isTrialPlan ? (
                    <Typography
                      variant="overline"
                      display="block"
                      align="center"
                      sx={{
                        backgroundColor: "green",
                        color: "white",
                        margin: "1rem 0px",
                      }}
                    >
                      This Is Trial Plan
                    </Typography>
                  ) : (
                    ""
                  )} */}
                      <Typography
                        variant="overline"
                        display="block"
                        align="center"
                        sx={{
                          backgroundColor: "#D0AF88",
                          color: "white",
                          marginBottom: "1rem",
                        }}
                      >
                        {subscription?.name}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {subscription?.description}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        Alle functies die u nodig heeft:
                      </Typography>
                      {subscription.modules.map((module, moduleIndex) => (
                        <div
                          style={{ display: "flex", gap: "5px" }}
                          key={moduleIndex}
                        >
                          <DoneIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "#d0af88",
                            }}
                          />
                          <Typography
                            variant="overline"
                            display="block"
                            style={{ color: "#d0af88" }}
                          >
                            {module?.name}
                          </Typography>
                        </div>
                      ))}
                      <Typography variant="body1" gutterBottom>
                        Begint om:
                        <strong>
                          {formatDateToReadable(subscription.stateDate)}
                        </strong>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Eindigt bij:
                        <strong>
                          {formatDateToReadable(subscription.endDate)}
                        </strong>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setComponent("Buy")}
            >
              Kopen
            </Button>
          </div>
        )}
      </CustomTabPanel>
    </div>
  );
};

// const AutoPayment = ({ control, watch }) => {
//   const methods = [
//     // { name: "creditcard", value: "creditcard" },
//     { name: "directdebit", value: "directdebit" },
//     { name: "paypal", value: "paypal" },
//   ];
//   return (
//     <div style={{ margin: "1rem" }}>
//       <Grid container spacing={2}>
//         <Grid item sm={6} xs={12} md={4}>
//           <AutocompleteInput
//             name="method"
//             labelTheme={true}
//             type="text"
//             label="methods"
//             control={control}
//             options={methods}
//             getOptionLabel={(option) => option?.name}
//             isOptionEqualToValue={(option, value) => {
//               return option.value === value.value;
//             }}
//           />
//         </Grid>
//         {watch("method")?.value === "paypal" ? (
//           <Grid item sm={6} xs={12} md={4}>
//             <TextInput
//               placeholder="For example: B-12A34567B8901234CD"
//               name="paypalBillingAgreementId"
//               type="text"
//               labelTheme={true}
//               label={"paypalBillingAgreementId"}
//               autoFocus={true}
//               control={control}
//               // customError={inputErrors.companyOwnerName || null}
//             />
//           </Grid>
//         ) : (
//           ""
//         )}
//         {watch("method")?.value === "directdebit" ? (
//           <>
//             <Grid item sm={6} xs={12} md={4}>
//               <TextInput
//                 name="IbanNumber"
//                 placeholder="For Example : NL91ABNA0417164300"
//                 type="text"
//                 labelTheme={true}
//                 label={"IbanNumber"}
//                 autoFocus={true}
//                 control={control}
//                 // customError={inputErrors.companyOwnerName || null}
//               />
//             </Grid>
//             <Grid item sm={6} xs={12} md={4}>
//               <TextInput
//                 name="bicCode"
//                 placeholder="For Example : ABNANL2A"
//                 type="text"
//                 labelTheme={true}
//                 label={"bicCode"}
//                 autoFocus={true}
//                 control={control}
//                 // customError={inputErrors.companyOwnerName || null}
//               />
//             </Grid>
//           </>
//         ) : (
//           ""
//         )}
//       </Grid>
//     </div>
//   );
// };

const BuySubscription = ({ setComponent }) => {
  //   const [isLoading, setIsLoading] = useState(false);
  // const [isMember, setIsMember] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [rows, setRows] = useState([]);
  const [purchasedSubscriptions, setPurchasedSubscriptions] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [userMemberships, setUserMemberships] = useState([]);
  const [modules, setModules] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const abortController = useRef(new AbortController());

  const defaultValues = {
    membershipAgreement: false,
    membershipIsAutopay: false,
    agreement: false,
    isAutopay: false,
    isMember: false,
  };

  const { control, watch } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const getPurchasedSubscription = () => {
    API.get("/users/subscription/user", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setPurchasedSubscriptions(
            resData.data.sort(
              (a, b) => new Date(b.endDate) - new Date(a.endDate)
            )
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const getUsersMemberships = () => {
    API.get("/users/membership/user/list", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const activeMemberships = resData.data.filter(
            (item) => item.status === "active"
          );
          setUserMemberships(
            activeMemberships.sort(
              (a, b) => new Date(b.endDate) - new Date(a.endDate)
            )
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const getMemberships = () => {
    API.get("/users/membership/list", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setMemberships(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const BuySubscription = () => {
    const isAgreed = watch("agreement");
    const data = {
      subscriptionId: selectedPlan,
      buyMembershipAlso: selectedMembership ? true : false,
      membershipId: selectedMembership,
      isAutopay: false,
      redirectUrl: window.location.href,
      webhookBaseUrl: API.getUri() || API.defaults.baseURL,
    };
    console.log(data, "data");
    if (isAgreed && selectedPlan) {
      API.post("/users/subscription/buysubscription", data, {
        signal: abortController.current.signal,
      })
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            window.open(resData.data, "_self");
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (!error.code || error.code !== "ERR_CANCELED") {
            toast.error("Something went wrong");
          }
        })
        .finally(() => {
          // setIsLoading(false);
        });
    } else {
      setShowAlert(true);
    }
  };

  const getSubScriptionList = () => {
    // setIsLoading(true);
    API.get("/public/subscriptions", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setRows(resData.data.filter((item) => item.isTrialPlan !== true));
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const getModulesList = () => {
    // setIsLoading(true);
    API.get("/public/modules", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setModules(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getSubScriptionList();
    getModulesList();
    getPurchasedSubscription();
    getMemberships();
    getUsersMemberships();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {purchasedSubscriptions.length > 0 ? (
        <Alert
          severity="info"
          icon={false}
          color="info"
          sx={{ marginBottom: "1rem" }}
          classes={{ message: "fullWidthMessage" }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            Kies hieronder voor het type maandabonnement of jaarabonnement.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            Als je nu activeert, betaal je pas vanaf{" "}
            {formatDateToReadable(purchasedSubscriptions[0]?.endDate)}
          </Typography>
        </Alert>
      ) : (
        ""
      )}

      {userMemberships.length > 0 ? (
        <div>
          <Typography
            variant="h3"
            gutterBottom
            textAlign={"center"}
            sx={{ color: "#D0AF88" }}
          >
            actieve lidmaatschappen
          </Typography>
          <Grid container justifyContent={"center"} sx={{ padding: "1rem" }}>
            {userMemberships.map((membership, membershipIndex) => (
              <Grid
                item
                xs={12}
                md={3.75}
                sm={12}
                sx={{
                  marginRight: "2rem",
                  marginBottom: "2rem",
                  position: "relative",
                }}
                key={membershipIndex}
              >
                <Card className={`subCard`}>
                  <CardContent style={{ paddingBottom: "0px" }}>
                    <Typography
                      variant="overline"
                      display="block"
                      align="center"
                      sx={{
                        backgroundColor: "#D0AF88",
                        color: "white",
                        marginBottom: "1rem",
                      }}
                    >
                      {
                        memberships.find(
                          (item) =>
                            item._id === membership.membershipId ||
                            item.id === membership.membershipId
                        )?.name
                      }
                    </Typography>

                    <Typography variant="h3" sx={{ textAlign: "center" }}>
                      {
                        memberships.find(
                          (item) =>
                            item._id === membership.membershipId ||
                            item.id === membership.membershipId
                        )?.price
                      }
                      *
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ textAlign: "center" }}
                    >
                      per jaar
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Begint om:
                      <strong>
                        {formatDateToReadable(membership?.startDate)}
                      </strong>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Eindigt bij:
                      <strong>
                        {formatDateToReadable(membership?.endDate)}
                      </strong>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <div>
          <Typography
            variant="h3"
            gutterBottom
            textAlign={"center"}
            sx={{ color: "#D0AF88" }}
          >
            Selecteer Lidmaatschap
          </Typography>
          <Grid container justifyContent={"center"} sx={{ padding: "1rem" }}>
            {memberships.map((membership, membershipIndex) => (
              <Grid
                item
                xs={12}
                md={3.75}
                sm={12}
                sx={{
                  marginRight: "2rem",
                  marginBottom: "2rem",
                  position: "relative",
                }}
                key={membershipIndex}
              >
                <Card
                  className={`subCard ${
                    membership._id === selectedMembership ||
                    membership.id === selectedMembership
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    setSelectedMembership(membership._id || membership.id)
                  }
                >
                  <CardContent style={{ paddingBottom: "0px" }}>
                    <Typography
                      variant="overline"
                      display="block"
                      align="center"
                      sx={{
                        backgroundColor: "#D0AF88",
                        color: "white",
                        marginBottom: "1rem",
                      }}
                    >
                      {membership?.name}
                    </Typography>

                    <Typography variant="h3" sx={{ textAlign: "center" }}>
                      {membership?.price}*
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ textAlign: "center" }}
                    >
                      per jaar
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      <Typography
        variant="h3"
        gutterBottom
        textAlign={"center"}
        sx={{ color: "#D0AF88" }}
      >
        Selecteer een abonnementstype
      </Typography>
      {showAlert ? (
        selectedPlan ? (
          ""
        ) : (
          <p
            style={{
              color: "red",
              paddingLeft: "2rem",
              margin: "0px",
            }}
          >
            abonnement selecteren
          </p>
        )
      ) : (
        ""
      )}

      <Grid container justifyContent={"space-between"} sx={{ padding: "1rem" }}>
        {rows.map((subscription, subscriptionIndex) => (
          <Grid
            item
            xs={12}
            md={3.75}
            sm={12}
            sx={{ marginBottom: "2rem", position: "relative" }}
            key={subscriptionIndex}
          >
            <Card
              className={`subCard ${
                subscription._id === selectedPlan ||
                subscription.id === selectedPlan
                  ? "selected"
                  : ""
              }`}
              onClick={() =>
                setSelectedPlan(subscription._id || subscription.id)
              }
            >
              <CardContent style={{ paddingBottom: "0px" }}>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                  }}
                >
                  {subscription?.name}
                </Typography>

                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  {userMemberships.length > 0
                    ? subscription.priceWithMemberShip
                    : subscription.priceWithOutMemberShip}
                  *
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Alle functies die u nodig heeft:
                </Typography>
                {subscription.modules.map((module, moduleIndex) => (
                  <div
                    style={{ display: "flex", gap: "5px" }}
                    key={moduleIndex}
                  >
                    <DoneIcon
                      style={{
                        height: "15px",
                        margin: "6px 0px",
                        color: "#d0af88",
                      }}
                    />
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "#d0af88" }}
                    >
                      {
                        modules.find(
                          (item) => item._id === module || item.id === module
                        )?.name
                      }
                    </Typography>
                  </div>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div>
        <CheckBox
          label={
            "Ik ga akkoord met het omzetten van mijn proefabonnement naar een betaald abonnement."
          }
          control={control}
          name="agreement"
          color="info"
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>

      {showAlert ? (
        watch("agreement") ? (
          ""
        ) : (
          <p
            style={{
              color: "red",
              paddingLeft: "2rem",
              margin: "0px",
            }}
          >
            Ga akkoord met de algemene voorwaarden voordat u doorgaat
          </p>
        )
      ) : (
        ""
      )}

      <div style={{ textAlign: "center" }}>
        <Button variant="outlined" color="secondary" onClick={BuySubscription}>
          Bevestigen
        </Button>
      </div>
    </div>
  );
};

function SubscriptionInfo() {
  const [child, setChild] = useState("Info");

  const updateStatus = () => {
    const path = "/users/update_status";
    API.put(path)
      .then((res) => {
        const resData = res.data;

        if (!resData.status) {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    updateStatus();
  }, []);

  return (
    <div>
      <div
        style={{
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack
          flexDirection="row"
          sx={{
            width: 150,
            borderRadius: 50,
            backgroundColor: "rgba(208,175,136,0.3)",
          }}
        >
          <Typography
            sx={{
              backgroundColor:
                child === "Buy" ? "rgb(208, 175, 136)" : "transparent",
              color: child === "Buy" ? "#FFF" : "#000",
              width: "50%",
              p: 1,
              borderRadius: 50,
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setChild("Buy");
            }}
          >
            Buy
          </Typography>
          <Typography
            sx={{
              backgroundColor:
                child === "Info" ? "rgb(208, 175, 136)" : "transparent",
              color: child === "Info" ? "#FFF" : "#000",
              width: "50%",
              p: 1,
              borderRadius: 50,
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setChild("Info");
            }}
          >
            Info
          </Typography>
        </Stack>
      </div>

      {child && child === "Buy" ? (
        <BuySubscription setComponent={setChild} />
      ) : (
        <ActiveInfo setComponent={setChild} />
      )}
    </div>
  );
}

export default SubscriptionInfo;
