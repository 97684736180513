import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function StartingBusiness() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        section.querySelector(".left-div").style.animation =
          "slideInLeft 1s ease forwards";
        section.querySelector(".right-div").style.animation =
          "slideInRight 1s ease forwards";
      } else {
        // If the section is out of view, revert the animation
        section.querySelector(".left-div").style.animation = "none";
        section.querySelector(".right-div").style.animation = "none";
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Onderneming starten
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Ik ga ondernemen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Gefeliciteerd met het starten van je eigen onderneming! Je hebt al een
          aantal belangrijke stappen gezet, zoals je inschrijven bij de KVK en
          het kiezen van een bedrijfsnaam. Nu is het tijd om de volgende stap te
          zetten en je onderneming verder vorm te geven.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Open een zakelijke rekening
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Algemene voorwaarden
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Uurtarief
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Andere zaken om aan te denken
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(5)}
        >
          Je eerste klanten
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(6)}
        >
          Het maken van een factuur
        </Button>
      </div>
      <div style={{ textAlign: "center" }} className="section-1">
        <Divider textAlign="left" className="divider">
          {"Open een zakelijke rekening".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Een zakelijke rekening is een must voor elke ondernemer. Met een
                zakelijke rekening kun je je privé- en zakelijke financiën
                gescheiden houden. Dit is belangrijk voor de overzichtelijkheid
                en voor de belastingaangifte.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/MonitorImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-2">
        <Divider textAlign="right" className="divider">
          {"Algemene voorwaarden".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/VoorLogoImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Algemene voorwaarden zijn een set regels die van toepassing zijn
                op alle overeenkomsten die je sluit met je klanten. Ze bevatten
                onder andere informatie over de prijs, de levertijd, de garantie
                en de aansprakelijkheid. Algemene voorwaarden zijn niet
                verplicht, maar ze kunnen je wel veel tijd en moeite besparen
                bij het afhandelen van geschillen.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-3">
        <Divider textAlign="left" className="divider">
          {"Uurtarief".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Als je diensten aanbiedt, moet je een uurtarief bepalen. Dit
                tarief is gebaseerd op een aantal factoren, zoals je ervaring,
                je expertise en de marktwaarde van je diensten. Het is
                belangrijk om een uurtarief te bepalen dat voor jou en voor je
                klanten acceptabel is.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div
              className="right-div imageCard"
              style={{ display: "inline-block" }}
            >
              <img
                src={"./Professional/WriteImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-4">
        <Divider textAlign="right" className="divider">
          {"Andere zaken om aan te denken".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/DashbookImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Er zijn nog veel andere zaken om aan te denken als je een eigen
                onderneming start. Denk bijvoorbeeld aan:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Marketing en sales: Hoe ga je je bedrijf promoten en klanten
                vinden?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Financiën: Hoe ga je je financiën bijhouden?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Administratie: Hoe ga je je administratie bijhouden?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Wet- en regelgeving: Welke wetten en regels zijn van toepassing
                op je bedrijf?
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-5">
        <Divider textAlign="left" className="divider">
          {"Je eerste klanten".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Er zijn talloze manieren om je eerste klanten te werven. Veel
                startende ondernemers beginnen met het benaderen van hun
                bestaande netwerk. Informeer mensen over je nieuwe onderneming
                en wat je voor hen kunt betekenen. Daarnaast kunnen sociale
                media, online forums, mond-tot-mondreclame en een professionele
                website ook effectieve middelen zijn om je eerste klanten aan te
                trekken.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/FriendAdviseImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-6">
        <Divider textAlign="right" className="divider">
          {"Het maken van een factuur".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/StartInvoice.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Als ondernemer vind je dit waarschijnlijk vanzelfsprekend. Toch
                kan het geen kwaad om kritisch te kijken naar de opbouw van je
                facturen. Zo ben je er zeker van dat je factuur aan de eisen van
                de Belastingdienst voldoet, en voorkom je achteraf veel werk als
                je het moet corrigeren.
              </Typography>

              <div className="flex" style={{ marginBottom: "1rem" }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#4A88EA",
                    marginTop: "1rem",
                  }}
                >
                  Lees meer
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8", marginTop: "1rem" }}
                >
                  Gratis proefperiode
                </Button>
              </div>
              <Typography
                variant="subtitle1"
                sx={{ color: "blue" }}
                gutterBottom
              >
                Lees meer over facturen
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "blue" }}
                gutterBottom
              >
                Wat is verplicht op een factuur?
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "blue" }}
                gutterBottom
              >
                Meer over facturen in jouw huisstijl
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "blue" }}
                gutterBottom
              >
                Meer Over BTW Tarief
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "blue" }}
                gutterBottom
              >
                Lees meer over orrecte factuurnummering
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default StartingBusiness;
