import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function SupportOption() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Heb je een vraag?
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          In onze kennisbank staan de antwoorden op de meest gestelde vragen.
          Krijg je liever support via e-mail? Dan zit ons supportteam voor je
          klaar. Stuur een mail naar: support@Dashbook.nl en wij helpen je
          verder.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="flex"
        style={{ justifyContent: "space-around", gap: "1rem" }}
      >
        <Typography variant="h4" className="titleUponDivider" gutterBottom>
          Direct antwoord op jouw vraag!
        </Typography>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Kennisbank
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Leren boekhouden
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Contact met onze supportafdeling
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Kennisbank".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Kennisbank
              </Typography>
              <Typography variant="h6" gutterBottom>
                <strong>Kennisbank met antwoorden op al je vragen!</strong>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Vind snel en eenvoudig de oplossing voor jouw probleem in onze
                uitgebreide kennisbank. Met meer dan 12 categorieën en
                duidelijke screenshots is er altijd een antwoord op jouw vraag.
              </Typography>
              <Typography variant="h6" gutterBottom>
                <strong>Ontdek de voordelen:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Snel en eenvoudig zoeken: Vind direct de informatie die je zoekt
                met onze gebruiksvriendelijke zoekfunctie.
                <strong>Volledige boekhouding:</strong> Beheer moeiteloos je
                complete boekhouding, van facturen tot bonnetjes en
                btw-aangiftes, alles binnen handbereik met de Dashbook app.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Uitgebreide antwoorden:</strong>
                Alle antwoorden zijn voorzien van heldere tekst en screenshots,
                zodat je stap voor stap kunt zien hoe je jouw probleem oplost.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>12 verschillende categorieën:</strong>
                Van algemene vragen tot technische problemen, we hebben alles
                voor je in huis.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Altijd up-to-date:</strong>
                De kennisbank wordt continu bijgewerkt met nieuwe informatie en
                antwoorden.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Bezoek nu onze kennisbank en vind het antwoord op jouw vraag!
              </Typography>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                >
                  Naar de Kennisbank
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/BooksSupport.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="left" className="divider">
          {"Leren boekhouden".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div imageCard">
              <img
                src={"./Professional/BookSupport.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            lg={5.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="up-div card">
              <Typography variant="h4" gutterBottom>
                Leren boekhouden
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Wil je meer te weten komen over de basisprincipes van het
                boekhouden?
              </Typography>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                >
                  Naar de Kennisbank
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="right" className="divider">
          {"Contact met onze supportafdeling".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/ContactSupport.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Contact met onze supportafdeling
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Vragen of opmerkingen? Laat het ons weten Stuur ons gerust een
                bericht met je vraag of opmerkingen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Wij zijn altijd benieuwd naar je ervaringen en beantwoorden
                graag je vragen.
              </Typography>

              <div className="flex" style={{ justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                >
                  Contactformulier
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SupportOption;
