import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SellFutureInvoicing() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Verzend je facturen op tijd, zelfs als je er niet aan denkt
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Wil je alvast een factuur maken maar deze nog niet meteen naar de
          klant sturen? Dat is mogelijk in Dashbook. Je kunt de factuur op de
          normale manier maken en als conceptfactuur laten staan. Op een later
          moment kun je de factuur openen en verzenden. Maar als je al weet
          wanneer je de factuur wilt verzenden, kun je het verzenden ook
          inplannen, zodat je later zelf niets meer hoeft te doen.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Facturen in toekomst versturen met Dashbook
          </Typography>
        </div>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Facturen in toekomst versturen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="right" className="divider">
          {"Facturen in toekomst versturen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Facturen in toekomst versturen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>
                  Automatiseer het verzendproces van je facturen in Dashbook:
                </strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemak:</strong> Klanten Plan het verzenden van je
                facturen in op een door jou gekozen datum en verzend ze
                automatisch.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiëntie:</strong>
                Bespaar tijd en moeite door het verzenden van facturen te
                automatiseren.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Betrouwbaarheid:</strong>
                Zorg ervoor dat je facturen altijd op tijd worden verzonden, wat
                de betrouwbaarheid van je facturatieproces bevordert.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellFutureInvoicing;
