import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function Vacancies() {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "2rem" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Vacatures
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Kom werken bij het leukste IT-bedrijf van Rotterdam !!!
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem" }}>
        <Typography
          variant="h4"
          sx={{
            textAlign: "left",
            color: "#D0AF88",
            fontWeight: "300 !important",
            textDecoration: "underline",
            fontStyle: "italic",
          }}
          gutterBottom
        >
          Wie zijn wij
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Dashbook is een dynamisch en snelgroeiend IT-bedrijf. Met ons unieke
          boekhoudprogramma en service streven wij ernaar om samen met jou de
          zzp-markt in Nederland te veroveren
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Opgericht in 2023 en speciaal ontworpen om de boekhouding van zzp'ers
          te vereenvoudigen, ondersteunt ons programma al enkele jaren diverse
          zzp'ers in heel Nederland.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Ons kantoor is gevestigd aan de Keesopstraat te Rotterdam, een
          toplocatie op steenworp Zee schmids en centrum van Rotterdam is ook
          binnen een kwartier te bereiken.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Werken bij Dashbook betekent deel uitmaken van een hecht team dat
          elkaar eerder als vrienden dan als collega's beschouwt. Het komt
          regelmatig voor dat we werkdagen afsluiten op het dakterras of in een
          van de gezellige gelegenheden in de buurt. De afgelopen jaren hebben
          we aanzienlijke groei doorgemaakt en zijn we druk bezig geweest met de
          verdere ontwikkeling van ons bedrijf. Dit betekent dat we op zoek zijn
          naar mensen die ons hechte team willen komen versterken. Bekijk
          hieronder onze beschikbare vacatures!
        </Typography>

        <div style={{ padding: "1rem" }}>
          <div container justifyContent={"space-between"}>
            <Grid
              item
              xs={12}
              md={4.5}
              sm={12}
              sx={{
                backgroundColor: "white",
                padding: "2rem",
                borderRadius: "10px",
              }}
            >
              {/* <div
                className="flex"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              > */}
              {/* <div className="flex" style={{ gap: "1rem" }}> */}
              {/* <Button
                    variant="contained"
                    style={{ backgroundColor: "green" }}
                  >
                    Contact ons
                  </Button> */}
              <Button
                variant="contained"
                style={{ backgroundColor: "#6ED1D8" }}
              >
                Beschikbare vacatures
              </Button>
              {/* </div> */}
              {/* </div> */}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vacancies;
