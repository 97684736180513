import React, { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import CustomDialogBox from "../components/CustomDialogBox";
import TextInput from "../components/form/TextInput";
import { useForm } from "react-hook-form";
import { highlightField } from "../utils/Commons";
import API from "../config/axios";
import { toast } from "react-hot-toast";
import { useAuth } from "../hooks/auth";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ChangePasswordDialogBox = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputErrors, setInputErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();

  const validationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(t("changePasswordPage.currentPasswordRequired")),
    newPassword: yup
      .string()
      .required(t("changePasswordPage.newPasswordRequired"))
      .min(8, t("changePasswordPage.passwordMust8CharLong"))
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z]).*$/,
        t("changePasswordPage.passwordContain1Char1Num"),
      ),
    confirmPassword: yup
      .string()
      .required(t("changePasswordPage.confirmPasswordRequired"))
      .oneOf(
        [yup.ref("newPassword"), null],
        t("changePasswordPage.passwordsMustMatch"),
      ),
  });

  const { handleSubmit, control } = useForm({
    // defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const requestStartTime = Date.now();
    API.put("users/change-password", data)
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status) {
          toast.success(t("changePasswordPage.passwordChangeSuccessfully"));
          auth.logout();
          navigate("/login");
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
            try {
              highlightField(Object.keys(resData.error)[0]);
            } catch (error) {
              console.warn("Field cannot be highlighted");
            }
          } else {
            toast.error(resData?.error?.message || "Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  return (
    <CustomDialogBox
      title={t("changePassword")}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Stack
          mr={1}
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              onClose();
            }}
          >
            {t("common_btn.cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          >
            {t("common_btn.submit")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextInput
              name="currentPassword"
              type="password"
              label={t("changePasswordPage.currentPassword")}
              control={control}
              customError={inputErrors.currentPassword || null}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              name="newPassword"
              type="password"
              label={t("changePasswordPage.newPassword")}
              control={control}
              customError={inputErrors.newPassword || null}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              name="confirmPassword"
              type="password"
              label={t("changePasswordPage.confirmPassword")}
              control={control}
              customError={inputErrors.confirmPassword || null}
            />
          </Grid>
        </Grid>
      </form>
    </CustomDialogBox>
  );
};

export default ChangePasswordDialogBox;
