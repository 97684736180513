import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function GoingIntoBusiness() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        section.querySelector(".left-div").style.animation =
          "slideInLeft 1s ease forwards";
        section.querySelector(".right-div").style.animation =
          "slideInRight 1s ease forwards";
      } else {
        // If the section is out of view, revert the animation
        section.querySelector(".left-div").style.animation = "none";
        section.querySelector(".right-div").style.animation = "none";
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Bedrijf starten
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Ik ga ondernemen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Gefeliciteerd, je gaat ondernemen! Je hebt besloten om voor jezelf te
          beginnen. Maar hoe ga je van start? We helpen je graag verder met
          informatie die handig is aan het begin van je onderneming. Je leest
          onder andere hoe je een ondernemingsplan schrijft, hoe je een
          bedrijfsnaam kiest en hoe je omgaat met gemaakte kosten voordat je je
          hebt ingeschreven bij de Kamer van Koophandel (KVK).
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Ondernemingsplan
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Bedrijfsnaam kiezen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Je bedrijf inschrijven bij de KVK
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Aan huis beginnen
        </Button>
      </div>
      <div style={{ textAlign: "center" }} className="section-1">
        <Divider textAlign="left" className="divider">
          {"Ondernemingsplan".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Met een ondernemingsplan leg je de basis voor jouw onderneming.
                Je beschrijft welke werkzaamheden je gaat verrichten en hoe je
                je bedrijfsdoelen gaat bereiken. Daarnaast breng je de
                concurrentie, potentiële klanten, en de kansen en beperkingen
                van je bedrijf in kaart. Het ondernemingsplan is als het ware de
                blauwdruk van je onderneming.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/TipsImage.png"}
                alt="MarioImage"
                width="50%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-2">
        <Divider textAlign="right" className="divider">
          {"Bedrijfsnaam kiezen".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/VisionImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                De keuze van een bedrijfsnaam is een belangrijke beslissing. De
                naam moet bij je bedrijf passen en je merkidentiteit
                weerspiegelen. Er zijn verschillende factoren waarmee je
                rekening moet houden bij het kiezen van een bedrijfsnaam,
                waaronder:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *De aard van je bedrijf: Wat voor soort bedrijf heb je? Wat zijn
                je producten of diensten?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Je doelgroep: Wie zijn je klanten? Wat spreekt hen aan?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Je concurrentie: Wat zijn de namen van je concurrenten? Hoe wil
                je *je onderscheiden?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Hier zijn enkele tips voor het kiezen van een bedrijfsnaam:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Maak het makkelijk te onthouden: Mensen moeten je naam
                gemakkelijk kunnen onthouden en uitspreken.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Maak het onderscheidend: Je naam moet je bedrijf onderscheiden
                van je concurrenten.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Maak het relevant: Je naam moet relevant zijn voor je bedrijf
                en je producten of diensten.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-3">
        <Divider textAlign="left" className="divider">
          {"Je bedrijf inschrijven bij de KVK".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Met de officiële inschrijving bij de Kamer van Koophandel (KVK)
                start officieel jouw onderneming. Van harte gefeliciteerd!
                Mogelijk heb je al zakelijke kosten gemaakt voordat je je hebt
                ingeschreven. Het is daarom belangrijk om de bonnetjes en
                facturen van deze kosten zorgvuldig te bewaren.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div
              className="right-div imageCard"
              style={{ display: "inline-block" }}
            >
              <img
                src={"./Professional/WebSearchImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-4">
        <Divider textAlign="right" className="divider">
          {"Aan huis beginnen".toUpperCase()}
        </Divider>
        <Grid container sx={{ padding: "0px 3rem" }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/LaptopImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Het starten van een bedrijf aan huis kan handig zijn, maar
                inderdaad zijn er vaak regels waaraan je moet voldoen. Niet
                iedereen mag zomaar een bedrijf aan huis beginnen en er zijn
                bepaalde voorwaarden waar rekening mee moet worden gehouden. Het
                is essentieel om te onderzoeken welke regels en voorschriften
                van toepassing zijn op jouw specifieke situatie voordat je je
                bedrijf aan huis start.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default GoingIntoBusiness;
