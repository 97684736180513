import React from "react";

import { Button, CircularProgress } from "@mui/material";

export default function CustomBtn({ children, loading, disabled, ...props }) {
  return (
    <Button
      {...props}
      disabled={!!loading || disabled}
      startIcon={
        loading ? (
          <CircularProgress color="grey" size={20} sx={{ mr: 1 }} />
        ) : (
          ""
        )
      }
    >
      {children}
    </Button>
  );
}
