import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";
function SellBrandingLayoutEditor() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Facturen maken die passen bij jouw huisstijl
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Breng je professionele uitstraling tot uiting op je facturen! Met de
          prachtige huisstijl die je hebt laten ontwikkelen, kun je nu dezelfde
          herkenbaarheid en professionaliteit toevoegen aan je facturen. Zo
          blijven ze in lijn met je website, briefpapier en e-mails, en maken ze
          een sterke visuele indruk op je klanten.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden Huisstijl lay-out editor met Dashbook
          </Typography>
        </div>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Huisstijl lay-out editor
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Huisstijl lay-out editor".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                E-mails en facturen opmaken in je huisstijl
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                In Dachbookkun je jouw facturen en e-mails een persoonlijke
                touch geven met je eigen huisstijl. Hier zijn de aanpassingen
                die je kunt doen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong> Bedrijfslogo:</strong> Voeg je bedrijfslogo toe voor
                een professionele uitstraling.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Lettertype en Grootte:</strong> Kies het lettertype en
                de grootte van de letters die bij jouw stijl passen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Indeling Facturen: </strong>Personaliseer de indeling
                van je facturen zodat ze aansluiten bij jouw voorkeuren.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Achtergrondafbeelding (Briefpapier):</strong> Upload
                eenvoudig je briefpapier voor een herkenbare achtergrond op je
                facturen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Automatische Gegevens:</strong> Vul je bedrijfsgegevens
                en upload je logo, zodat deze automatisch op je facturen
                verschijnen, zelfs zonder briefpapier.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Header en Footer voor E-mails:</strong> Voeg een
                aangepaste header en footer toe aan je e-mails voor een
                consistente uitstraling.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Met deze mogelijkheden maak je jouw facturen en e-mails niet
                alleen professioneel, maar ook volledig in lijn met de
                identiteit van jouw bedrijf.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellBrandingLayoutEditor;
