import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { useTranslation } from "react-i18next";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { useNavigate, useParams } from "react-router-dom";
import NextPlanIcon from "@mui/icons-material/NextPlan";

import StopCircleIcon from "@mui/icons-material/StopCircle";
import style from "./purchase.module.css";
import CustomBtn from "../CustomBtn";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HexagonIcon from '@mui/icons-material/Hexagon';

const EditInvoiceDrawer = (props) => {
  const {
    isTablet,
    isDrawerOpen,
    toggleDrawer,
    handleSubmit,
    onSubmit,
    onError,
    openDialog,
    deleteInvoice,
    downloadInvoicePdf,
    documents = [],
    backPath,
    status,
    uploadDocuments = [],
    showDownloadInvoicePdfBtn = false,
    isDownloadingPdf = false,
    sendInvoiceReminder,
    isSendingInvoiceReminder,
    isDeletingInvoice,
    type = "periodic",
    type1,
    urlState = {},
    difference = 0,
    invoiceData,
    nextTransaction,
    emiId,
    stopEmiDate,
  } = props;

  const [showButtons, setShowButtons] = useState(false);
  const [showSendButtons, setShowSendButtons] = useState(null);
  const [showOptionButtons, setShowOptionButtons] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { t } = useTranslation();
  const { invoiceId } = useParams();

  const navigate = useNavigate();

  const handleDropdownClick = (event) => {
    setShowButtons(!showButtons);
  };
  const handleDropdownClickSendButton = (event) => {
    setShowSendButtons(!showSendButtons);
  };

  const showDeleteConfirmationDialog = () => {
    setShowDeleteDialog(true);
  };

  const closeDeleteConfirmationDialog = () => {
    setShowDeleteDialog(false);
  };

  return (
    <>
      <Stack>
        <Drawer
          variant={!isTablet ? "persistent" : "temporary"}
          anchor="right"
          open={!isTablet ? true : isDrawerOpen}
          onClose={toggleDrawer}
          sx={{ zIndex: 1 }}
        >
          <Divider />
          {isDrawerOpen ? (
            <Stack mt={8} p={4} width="290px">
              <Stack spacing={2}>
                <ButtonGroup variant="contained" color="success" size="large">
                  <Button
                    color="success"
                    disabled={difference === 0 ? false : true}
                    sx={{
                      color: "white",
                      height: 50,
                    }}
                    startIcon={<SaveIcon style={{ fontSize: 25 }} />}
                    className={style.buttonText}
                    onClick={handleSubmit(
                      (data) => onSubmit(data, "save"),
                      onError
                    )}
                  >
                    {t("common_btn.save")}
                  </Button>
                  <Button
                    sx={{ fontSize: "18px", height: 50, color: "white" }}
                    color="success"
                    disabled={difference === 0 ? false : true}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleDropdownClick}
                  >
                    {showButtons ? (
                      <KeyboardArrowUpIcon style={{ fontSize: 25 }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: 25 }} />
                    )}
                  </Button>
                </ButtonGroup>
                <Collapse in={showButtons}>
                  <Stack mt={2} spacing={2}>
                    <Button
                      startIcon={
                        <AddBoxOutlinedIcon style={{ fontSize: 25 }} />
                      }
                      variant="contained"
                      color="info"
                      disabled={difference === 0 ? false : true}
                      className={style.buttonText}
                      onClick={handleSubmit(
                        (data) => onSubmit(data, "save&new"),
                        onError
                      )}
                    >
                      <Typography noWrap p={0.5}>
                        {t("common_btn.save+new")}
                      </Typography>
                    </Button>
                    {status !== "creditInvoice" && (
                      <Button
                        sx={{ fontSize: "20px", color: "white" }}
                        className={style.buttonText}
                        color="purple"
                        disabled={difference === 0 ? false : true}
                        variant="contained"
                        startIcon={
                          <ContentCopyOutlinedIcon style={{ fontSize: 25 }} />
                        }
                        onClick={handleSubmit(
                          (data) => onSubmit(data, "copy"),
                          onError
                        )}
                      >
                        <Typography noWrap>{t("common_btn.copy")}</Typography>
                      </Button>
                    )}
                  </Stack>
                </Collapse>

                {type === "normal" && (
                  <Button
                    sx={{ fontSize: "20px", height: 70 }}
                    variant="contained"
                    disabled
                  >
                    <Typography color="black" fontSize="20px">
                      {t(`invoices.status.${status}`)}
                    </Typography>
                  </Button>
                )}
                <Button
                  className={style.buttonText}
                  variant="contained"
                  color="inherit"
                  startIcon={<ArrowBackOutlinedIcon style={{ fontSize: 25 }} />}
                  onClick={() => navigate(backPath)}
                >
                  {t("common_btn.back")}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  className={style.buttonText}
                  startIcon={
                    <DeleteOutlineOutlinedIcon style={{ fontSize: 25 }} />
                  }
                  onClick={showDeleteConfirmationDialog}
                >
                  {t("common_btn.delete")}
                </Button>
              </Stack>
              {type1 === "transactions" && (
                <Stack mt={2}>
                  <CustomBtn
                    variant="outlined"
                    className={style.buttonText}
                    endIcon={<NextPlanIcon style={{ fontSize: 25 }} />}
                    onClick={nextTransaction}
                  >
                    {t("common_btn.next_transactions")}
                  </CustomBtn>
                </Stack>
              )}
              <Stack spacing={2} mt={6}>
                {showDownloadInvoicePdfBtn && (
                  <CustomBtn
                    variant="outlined"
                    color="error"
                    className={style.buttonText}
                    startIcon={
                      <PictureAsPdfOutlinedIcon style={{ fontSize: 25 }} />
                    }
                    onClick={downloadInvoicePdf}
                    loading={isDownloadingPdf}
                  >
                    {t("common_btn.pdf_download")}
                  </CustomBtn>
                )}
                {type === "normal" && status !== "paid" && (
                  <Stack>
                    <ButtonGroup variant="outlined" color="info" size="large">
                      <Button
                        color="info"
                        className={style.buttonText}
                        startIcon={
                          <TextSnippetOutlinedIcon style={{ fontSize: 25 }} />
                        }
                      >
                        {t("common_btn.send")}
                      </Button>
                      <Button
                        sx={{ fontSize: "18px", height: 50 }}
                        color="info"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleDropdownClickSendButton}
                      >
                        {showSendButtons ? (
                          <KeyboardArrowUpIcon style={{ fontSize: 25 }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: 25 }} />
                        )}
                      </Button>
                    </ButtonGroup>
                    <Collapse in={showSendButtons}>
                      <Stack mt={2} spacing={2}>
                        {status === "expired" && (
                          <CustomBtn
                            className={style.buttonText}
                            color="purple"
                            startIcon={
                              <AddAlertOutlinedIcon style={{ fontSize: 25 }} />
                            }
                            variant="outlined"
                            onClick={sendInvoiceReminder}
                            loading={isSendingInvoiceReminder}
                          >
                            <Typography noWrap>
                              {t("common_btn.reminder")}
                            </Typography>
                          </CustomBtn>
                        )}

                        <Button
                          className={style.buttonText}
                          startIcon={
                            <TextSnippetOutlinedIcon style={{ fontSize: 25 }} />
                          }
                          variant="outlined"
                          color="success"
                          onClick={() =>
                            navigate(
                              `/normal_invoice/send_invoice/${invoiceId}`,
                              {
                                state: { invoiceNoFormatted: invoiceData },
                              }
                            )
                          }
                        >
                          <Typography noWrap p={0.5}>
                            {t("common_btn.send_invoice")}
                          </Typography>
                        </Button>

                        {status === "concept" && (
                          <Button
                            className={style.buttonText}
                            color="info"
                            variant="outlined"
                            onClick={() => openDialog("sendInvoiceInFuture")}
                            startIcon={
                              <SendOutlinedIcon style={{ fontSize: 25 }} />
                            }
                          >
                            <Typography noWrap>
                              {t("common_btn.sent_to_sent")}
                            </Typography>
                          </Button>
                        )}
                      </Stack>
                    </Collapse>
                  </Stack>
                )}
                {type === "offers" && ["not_sent", "sent"].includes(status) && (
                  <Button
                    className={style.buttonText}
                    color="info"
                    variant="outlined"
                    onClick={() => openDialog("sendByEmail")}
                    startIcon={<SendOutlinedIcon style={{ fontSize: 25 }} />}
                  >
                    <Typography noWrap p={0.5}>
                      {t("common_btn.send_by_email")}
                    </Typography>
                  </Button>
                )}
              </Stack>
              <Stack mt={6}>
                {type === "normal" && (
                  <>
                    <ButtonGroup variant="contained" color="info" size="large">
                      <Button
                        color="info"
                        sx={{ width: "100%", fontSize: "18px" }}
                        startIcon={
                          <MoreVertOutlinedIcon style={{ fontSize: 25 }} />
                        }
                        style={{
                          justifyContent: "flex-start",
                          fontWeight: 400,
                        }}
                      >
                        {t("common_btn.options")}
                      </Button>
                      <Button
                        sx={{ fontSize: "20px", height: 50 }}
                        color="info"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={() => setShowOptionButtons(!showOptionButtons)}
                      >
                        {showOptionButtons ? (
                          <KeyboardArrowUpIcon style={{ fontSize: 25 }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: 25 }} />
                        )}
                      </Button>
                    </ButtonGroup>
                    <Collapse in={showOptionButtons}>
                      <Stack p={1} spacing={1}>
                        {!["concept", "planned", "paid"].includes(status) && (
                          <Button
                            className={style.buttonText}
                            color="purple"
                            variant="contained"
                            startIcon={
                              <CheckOutlinedIcon style={{ fontSize: 25 }} />
                            }
                            style={{
                              color: "white",
                            }}
                            onClick={() => openDialog("statusPaid")}
                            disabled={status === "paid" ? true : false}
                          >
                            <Typography noWrap p={0.5}>
                              {t("common_btn.paid")}
                            </Typography>
                          </Button>
                        )}
                        {!["credited", "creditInvoice"].includes(status) && (
                          <Button
                            className={style.buttonText}
                            color="secondary"
                            variant="contained"
                            startIcon={
                              <RestartAltIcon style={{ fontSize: 25 }} />
                            }
                            style={{
                              color: "white",
                            }}
                            onClick={() => openDialog("statusCredit")}
                          >
                            <Typography noWrap p={0.5}>
                              {t("common_btn.credit_invoice")}
                            </Typography>
                          </Button>
                        )}
                      </Stack>
                    </Collapse>
                  </>
                )}
                {type === "purchase" && (
                  <>
                    <ButtonGroup variant="contained" color="info" size="large">
                      <Button
                        color="info"
                        sx={{ width: "100%", fontSize: "18px" }}
                        startIcon={
                          <MoreVertOutlinedIcon style={{ fontSize: 25 }} />
                        }
                        style={{
                          justifyContent: "flex-start",
                          fontWeight: 400,
                        }}
                      >
                        {t("common_btn.options")}
                      </Button>
                      <Button
                        sx={{ fontSize: "20px", height: 50 }}
                        color="info"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={() => setShowOptionButtons(!showOptionButtons)}
                      >
                        {showOptionButtons ? (
                          <KeyboardArrowUpIcon style={{ fontSize: 25 }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: 25 }} />
                        )}
                      </Button>
                    </ButtonGroup>

                    <Collapse in={showOptionButtons}>
                      {status !== "paid" && (
                        <Stack p={1} spacing={1}>
                          <Button
                            className={style.buttonText}
                            color="purple"
                            variant="contained"
                            startIcon={
                              <CheckOutlinedIcon style={{ fontSize: 25 }} />
                            }
                            style={{
                              color: "white",
                            }}
                            onClick={() => openDialog("statusPaid")}
                            disabled={status === "paid" ? true : false}
                          >
                            <Typography noWrap p={0.5}>
                              {t("common_btn.paid")}
                            </Typography>
                          </Button>
                        </Stack>
                      )}
                      <Stack p={1} spacing={1}>
                        <Button
                          className={style.buttonText}
                          color="success"
                          variant="contained"
                          startIcon={
                            <FactCheckIcon style={{ fontSize: 25 }} />
                          }
                          style={{
                            color: "white",
                          }}
                          onClick={() => openDialog("purchaseEmi")}
                        >
                          <Typography noWrap p={0.5}>
                            {t("common_btn.emi")}
                          </Typography>
                        </Button>
                      </Stack>
                      {emiId && (
                        <>
                          {stopEmiDate === null && (
                            <Stack p={1} spacing={1}>
                              <Button
                                className={style.buttonText}
                                color="info"
                                variant="contained"
                                startIcon={
                                  <StopCircleIcon style={{ fontSize: 25 }} />
                                }
                                style={{
                                  color: "white",
                                }}
                                onClick={() => openDialog("stopEmi")}
                              >
                                <Typography noWrap p={0.5}>
                                  {t("common_btn.stop")}
                                </Typography>
                              </Button>
                            </Stack>
                          )}
                          {stopEmiDate !== null && (
                            <Stack p={1} spacing={1}>
                              <Button
                                className={style.buttonText}
                                color="purple"
                                variant="contained"
                                startIcon={
                                  <HexagonIcon style={{ fontSize: 25 }} />
                                }
                                style={{
                                  color: "white",
                                }}
                                onClick={() => openDialog("stopEmi2")}
                              >
                                <Typography noWrap p={0.5}>
                                  {t("costs.stop2.handleReminderBtn")}
                                </Typography>
                              </Button>
                            </Stack>
                          )}
                        </>
                      )}
                    </Collapse>
                  </>
                )}
              </Stack>
              {["offers"].includes(type) && status === "offer_accepted" && (
                <Stack mt={6}>
                  <Button
                    className={style.buttonText}
                    variant="contained"
                    sx={{ color: "white" }}
                    onClick={() =>
                      navigate("/normal_invoice/add", {
                        state: { urlState: urlState },
                      })
                    }
                    startIcon={
                      <TextSnippetOutlinedIcon style={{ fontSize: 25 }} />
                    }
                    color="purple"
                  >
                    {t("common_btn.make_invoice")}
                  </Button>
                </Stack>
              )}
              {["normal", "periodic", "offers"].includes(type) && (
                <Stack mt={6}>
                  <Button
                    className={style.buttonText}
                    variant="outlined"
                    onClick={() => openDialog("uploadFileEdit")}
                    startIcon={<UploadOutlinedIcon style={{ fontSize: 25 }} />}
                    color="error"
                    endIcon={
                      <Chip
                        size="small"
                        label={documents?.length || uploadDocuments.length}
                        color="error"
                      />
                    }
                  >
                    {t("common_btn.upload_pdf")}
                  </Button>
                </Stack>
              )}
            </Stack>
          ) : (
            !isTablet && (
              <Stack width="60px" mt={8} p={1}>
                <Stack spacing={3}>
                  <Tooltip placement="left" arrow title={t("common_btn.save")}>
                    <IconButton
                      color="success"
                      disabled={difference === 0 ? false : true}
                      className={style.iconButton}
                      onClick={handleSubmit(
                        (data) => onSubmit(data, "save"),
                        onError
                      )}
                    >
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    arrow
                    title={t("common_btn.save+new")}
                  >
                    <IconButton
                      className={style.iconButton}
                      color="info"
                      disabled={difference === 0 ? false : true}
                      onClick={handleSubmit(
                        (data) => onSubmit(data, "save&new"),
                        onError
                      )}
                    >
                      <AddBoxOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  {status !== "creditInvoice" && (
                    <Tooltip
                      placement="left"
                      arrow
                      title={t("common_btn.copy")}
                    >
                      <IconButton
                        className={style.iconButton}
                        color="purple"
                        disabled={difference === 0 ? false : true}
                        onClick={handleSubmit(
                          (data) => onSubmit(data, "copy"),
                          onError
                        )}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {type === "normal" && (
                    <Tooltip placement="left" arrow title={status}>
                      <IconButton
                        className={style.iconButton}
                        color="inherit"
                        onClick={() => navigate(backPath)}
                      >
                        S
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip placement="left" arrow title={t("common_btn.back")}>
                    <IconButton
                      className={style.iconButton}
                      color="inherit"
                      onClick={() => navigate(backPath)}
                    >
                      <ArrowBackOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  {showDownloadInvoicePdfBtn &&
                    !["not_sent"].includes(status) && (
                      <Tooltip
                        placement="left"
                        arrow
                        title={t("common_btn.pdf_download")}
                      >
                        <IconButton
                          className={style.iconButton}
                          variant="outlined"
                          color="error"
                          onClick={downloadInvoicePdf}
                          loading={isDownloadingPdf}
                        >
                          <PictureAsPdfOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  <Tooltip
                    placement="left"
                    arrow
                    title={`${t("common_btn.delete")} `}
                  >
                    <IconButton
                      onClick={showDeleteConfirmationDialog}
                      color="error"
                      className={style.iconButton}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  {type1 === "transactions" && (
                    <Stack mt={2}>
                      <Tooltip
                        placement="left"
                        arrow
                        title={t("common_btn.next_transactions")}
                      >
                        <IconButton
                          onClick={nextTransaction}
                          className={style.iconButton}
                        >
                          <NextPlanIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                  {type === "offers" &&
                    ["not_sent", "sent"].includes(status) && (
                      <Tooltip
                        placement="left"
                        arrow
                        title={t("common_btn.send_by_email")}
                      >
                        <IconButton
                          className={style.iconButton}
                          color="info"
                          variant="outlined"
                          onClick={() => openDialog("sendByEmail")}
                        >
                          <SendOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  {type === "normal" && (
                    <>
                      {/* {status === "expired" && ( */}
                        <Tooltip
                          placement="left"
                          arrow
                          title={t("common_btn.reminder")}
                        >
                          <IconButton
                            className={style.iconButton}
                            color="purple"
                            variant="outlined"
                            onClick={sendInvoiceReminder}
                            loading={isSendingInvoiceReminder}
                          >
                            <AddAlertOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      {/* )} */}
                      {status !== "paid" && (
                        <Tooltip
                          placement="left"
                          arrow
                          title={t("common_btn.send_invoice")}
                        >
                          <IconButton
                            className={style.iconButton}
                            variant="outlined"
                            color="success"
                            onClick={() =>
                              navigate(
                                `/normal_invoice/send_invoice/${invoiceId}`,
                                {
                                  state: { invoiceNoFormatted: invoiceData },
                                }
                              )
                            }
                          >
                            <AddAlertOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {status === "concept" && (
                        <Tooltip
                          placement="left"
                          arrow
                          title={t("common_btn.sent_to_sent")}
                        >
                          <IconButton
                            className={style.iconButton}
                            color="info"
                            variant="outlined"
                            onClick={() => openDialog("sendInvoiceInFuture")}
                          >
                            <SendOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!["concept", "planned", "paid"].includes(status) && (
                        <>
                          <Tooltip
                            placement="left"
                            arrow
                            title={t("common_btn.paid")}
                          >
                            <IconButton
                              className={style.iconButton}
                              color="purple"
                              variant="contained"
                              onClick={() => openDialog("statusPaid")}
                              disabled={status === "paid" ? true : false}
                            >
                              <SendOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {!["credited", "creditInvoice"].includes(status) && (
                        <>
                          <Tooltip
                            placement="left"
                            arrow
                            title={t("common_btn.credit_invoice")}
                          >
                            <IconButton
                              className={style.iconButton}
                              color="secondary"
                              variant="contained"
                              onClick={() => openDialog("statusCredit")}
                            >
                              <RestartAltIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </>
                  )}
                  {type === "purchase" && status !== "paid" && (
                    <Tooltip
                      placement="left"
                      arrow
                      title={t("common_btn.paid")}
                    >
                      <IconButton
                        className={style.iconButton}
                        color="purple"
                        variant="contained"
                        onClick={() => openDialog("statusPaid")}
                        disabled={status === "paid" ? true : false}
                      >
                        <SendOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {["offers"].includes(type) && status === "offer_accepted" && (
                    <Tooltip
                      placement="left"
                      arrow
                      title={t("common_btn.make_invoice")}
                    >
                      <IconButton
                        className={style.iconButton}
                        color="purple"
                        variant="contained"
                        onClick={() =>
                          navigate("/normal_invoice/add", {
                            state: { urlState: urlState },
                          })
                        }
                      >
                        <TextSnippetOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {["normal", "periodic", "offers"].includes(type) && (
                    <Tooltip
                      placement="left"
                      arrow
                      title={`${t("common_btn.upload_pdf")} ${
                        documents?.length || uploadDocuments?.length
                      }`}
                    >
                      <IconButton
                        onClick={() => openDialog("uploadFileEdit")}
                        color="error"
                        className={style.iconButton}
                      >
                        <UploadOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {type === "purchase" && (
                    <>
                      <Tooltip
                        placement="left"
                        arrow
                        title={t("common_btn.emi")}
                      >
                        <IconButton
                          className={style.iconButton}
                          color="success"
                          variant="contained"
                          onClick={() => openDialog("purchaseEmi")}
                        >
                          <FactCheckIcon />
                        </IconButton>
                      </Tooltip>
                      {emiId && (
                        <>
                          {stopEmiDate === null && (
                            <Tooltip
                              placement="left"
                              arrow
                              title={t("common_btn.stop")}
                            >
                              <IconButton
                                className={style.iconButton}
                                color="info"
                                variant="contained"
                                onClick={() => openDialog("stopEmi")}
                              >
                                <StopCircleIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {stopEmiDate !== null && (
                            <Tooltip
                              placement="left"
                              arrow
                              title={t("costs.stop2.handleReminderBtn")}
                            >
                              <IconButton
                                className={style.iconButton}
                                color="purple"
                                variant="contained"
                                onClick={() => openDialog("stopEmi2")}
                              >
                                <HexagonIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
            )
          )}
        </Drawer>
        <IconButton
          onClick={toggleDrawer}
          size="small"
          style={{
            borderRadius: "4px",
            width: "25px",
            backgroundColor: "#c28948",
            transition: "right 0.3s ease",
            position: "fixed",
            bottom: "20px",
            right: isDrawerOpen
              ? type === "normal"
                ? "274px"
                : "260px"
              : isTablet
              ? "3px"
              : "35px",
            zIndex: "1000",
          }}
        >
          {isDrawerOpen ? (
            <KeyboardDoubleArrowRightIcon
              sx={{ fontSize: 25, color: "white" }}
            />
          ) : (
            <KeyboardDoubleArrowLeftIcon
              color="secondary"
              sx={{ fontSize: 25, color: "white" }}
            />
          )}
        </IconButton>
      </Stack>
      <DeleteConfirmationDialog
        open={showDeleteDialog}
        title={t("invoices.dialogBox.title.delete_invoices")}
        description={t("invoices.dialogBox.delete_selected_invoice")}
        onClose={closeDeleteConfirmationDialog}
        onDelete={deleteInvoice}
        isDeletingData={isDeletingInvoice}
      />
    </>
  );
};

export default EditInvoiceDrawer;
