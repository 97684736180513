import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";
function RelationshipManagement() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Inrichting
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Relatiebeheer in boekhoudsoftware
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          De boekhoudsoftware biedt uitgebreide functies voor relatiebeheer. Dit
          helpt bedrijven om hun relaties met klanten, leveranciers en andere
          relaties te beheren.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor relatiebeheer met Dashbook
          </Typography>
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            Artikelen
          </Typography>
        </div>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Eén overzichtspagina
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Informatie per relaties
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Debiteuren
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Crediteuren
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Eén overzichtspagina".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Eén overzichtspagina voor al je zakelijke relaties
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Met de relatiepagina in Dashbook heb je al je klanten en
                leveranciers op één overzichtelijke plek. Via de zoekfunctie
                vind je snel en gemakkelijk de contactgegevens die je zoekt.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/RelatiesImage.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Informatie per relaties".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard">
              <img
                src={"./Professional/InformatieImage.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Informatie per relaties
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Met Dashbook sla je per contact alle relevante informatie op.
                Dit kan vanuit een factuur of door een nieuw contact aan te
                maken. Je kunt de gegevens zelf invullen, maar je kunt ook
                gebruikmaken van de automatische invulfunctie op basis van het
                KVK-nummer
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Debiteuren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                DEBITEUREN
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Met Dashbook sla je per contact alle relevante informatie op.
                Dit kan vanuit een factuur of door een nieuw contact aan te
                maken. Je kunt de gegevens zelf invullen, maar je kunt ook
                gebruikmaken van de automatische invulfunctie op basis van het
                KVK-nummer
              </Typography>
              <Typography
                variant="overline"
                display="block"
                align="left"
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "0.3rem 0.6rem",
                  marginBottom: "1rem",
                }}
              >
                Leer meer over debiteuren
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/DebImage.png"}
                alt="Dashbook Logo"
                height={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Crediteuren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard">
              <img
                src={"./Professional/CredImage.png"}
                alt="Dashbook Logo"
                height={"100%"}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                CREDITEUREN
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Is een leverancier die je nog geld schuldig bent. Dit kan een
                factuur voor een product of dienst zijn, of een andere
                vergoeding. Zolang je de factuur nog niet hebt betaald, is dit
                een crediteur.
              </Typography>
              <Typography
                variant="overline"
                display="block"
                align="left"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "0.3rem 0.6rem",
                  marginBottom: "1rem",
                }}
              >
                Leer meer over crediteuren
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default RelationshipManagement;
