import React from "react";
import Button from "@mui/material/Button";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import { Dialog, Paper } from "@mui/material";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function CustomDialogBox({
  isOpen,
  onClose,
  title,
  children,
  actions,
  maxWidth = "sm",
  showDialogAction = true,
  ...otherProps
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      {...otherProps}
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: "move", fontSize: "1.3rem", fontWeight: 500 }}
        id="draggable-dialog-title"
        sx={{ ml: 1, p: 2 }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{children}</DialogContent>
      {showDialogAction ? (
        <DialogActions
          sx={{ ml: 1, p: 2 }}
          style={{ justifyContent: "flex-start" }}
        >
          {actions || (
            <Button onClick={onClose}>{t("common_btn.cancel")}</Button>
          )}
        </DialogActions>
      ) : (
        ""
      )}
    </Dialog>
  );
}
