import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function StartPage() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Bedrijf starten
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          De stappen die je moet nemen om je bedrijf te starten
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Het starten van een eigen bedrijf is een opwindende stap. Met een idee
          en enthousiasme ben je klaar om aan de slag te gaan. Maar waar begin
          je? In dit hoofdstuk leggen we alles uit over het opzetten van een
          onderneming.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Wil ik ondernemen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Ik ga ondernemen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Onderneming starten
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Dashbook en ik
        </Button>
      </div>
      <div style={{ textAlign: "center" }} className="section-1">
        <Divider textAlign="left" className="divider">
          {"Wil ik ondernemen".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Misschien speelt al geruime tijd het idee in je hoofd: "Is
                ondernemerschap iets voor mij?" We begrijpen heel goed dat dit
                een lastige keuze kan zijn. Wat zijn de risico's en uitdagingen
                van het ondernemerschap, en ben je straks wel verzekerd van
                voldoende inkomen?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Laten we alles eens op een rij zetten.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Alles op een rij
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/MarioImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-2">
        <Divider textAlign="right" className="divider">
          {"Ik ga ondernemen".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/PhotographerImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Voordat je jouw bedrijf officieel inschrijft, is het zinvol om
                alvast enkele stappen te zetten. Denk hierbij aan het opstellen
                van je ondernemersplan, het kiezen van een geschikte bedrijfs-
                en domeinnaam, en het inlezen over de mogelijkheden en
                beperkingen van het starten van een bedrijf aan huis. Dit zijn
                waardevolle voorbereidingen om een solide basis te leggen voor
                de start van je onderneming.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Vliegende start
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-3">
        <Divider textAlign="left" className="divider">
          {"Onderneming starten".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Het inschrijven bij de Kamer van Koophandel (KVK) is inderdaad
                een belangrijke stap. Hier zijn enkele stappen die je kunt
                overwegen:
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Wij geven je vleugels
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div
              className="right-div imageCard"
              style={{ display: "inline-block" }}
            >
              <img
                src={"./Professional/EmployeeImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-4">
        <Divider textAlign="right" className="divider">
          {"Dashbook en ik".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/DashbookImage.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Of wij bij elkaar passen dat bepaal jij. Uiteraard helpen we je
                graag op weg. Al onze functionaliteiten lees je hier.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Zijn we een Match ?
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default StartPage;
