import React, { useEffect, useRef } from "react";
import ProfessionalHeader from "./components/ProfessionalHeader";
import OurPackages from "./components/Prices/OurPackages";
import FrequentlyAskedQuestions from "./components/Prices/FrequentlyAskedQuestions";
import RatesOverview from "./components/Prices/RatesOverview";
import BackofficeService from "./components/Prices/BackofficeService";
import DesignPage from "./components/Functies/DesignPage";
import AdministrationPage from "./components/Functies/AdministrationPage";
import LinksPage from "./components/Functies/LinksPage";
import AccountingReporting from "./components/Functies/AccountingReporting";
import Declarations from "./components/Functies/Declarations";
import ToSell from "./components/Functies/ToSell";
import AdminSetup from "./components/Functies/Designs/AdminSetup";
import ChartAccounts from "./components/Functies/Designs/ChartAccounts";
import RelationshipManagement from "./components/Functies/Designs/RelationshipManagement";
import PermissionsRoles from "./components/Functies/Designs/PermissionsRoles";
import UnlimitedUsers from "./components/Functies/Designs/UnlimitedUsers";
import SimpleScreens from "./components/Functies/Designs/SimpleScreens";
import AdminProjects from "./components/Functies/Administration/AdminProjects";
import AdminTimeKeeper from "./components/Functies/Administration/AdminTimeKeeper";
import AdminRides from "./components/Functies/Administration/AdminRides";
import AdminProductService from "./components/Functies/Administration/AdminProductService";
import AdminPurchaseCost from "./components/Functies/Administration/AdminPurchaseCost";
import AdminReceipts from "./components/Functies/Administration/AdminReceipts";
import OurPrices from "./components/Prices/OurPrices";
import SellBrandingLayoutEditor from "./components/Functies/ToSells/SellBrandingLayoutEditor";
import SellDigitalInboxSales from "./components/Functies/ToSells/SellDigitalInboxSales";
import SellFutureInvoicing from "./components/Functies/ToSells/SellFutureInvoicing";
import SellIdealInvoicing from "./components/Functies/ToSells/SellIdealInvoicing";
import SellInvoicing from "./components/Functies/ToSells/SellInvoicing";
import SellQuotes from "./components/Functies/ToSells/SellQuotes";
import SellRecurringInvoices from "./components/Functies/ToSells/SellRecurringInvoices";
import SellReminders from "./components/Functies/ToSells/SellReminders";
import SellUblInvoices from "./components/Functies/ToSells/SellUblInvoices";
import LinkApp from "./components/Functies/Links/LinkApp";
import LinkBank from "./components/Functies/Links/LinkBank";
import MollieIdeal from "./components/Functies/Links/MollieIdeal";
import Depreciations from "./components/Functies/AccountingAndReporting/Depreciations";
import Documents from "./components/Functies/AccountingAndReporting/Documents";
import OtherBookings from "./components/Functies/AccountingAndReporting/OtherBookings";
import Overviews from "./components/Functies/AccountingAndReporting/Overviews";
import Reports from "./components/Functies/AccountingAndReporting/Reports";
import DeclarationBtw from "./components/Functies/DeclarationsComponents/DeclarationBtw";
import DeclarationIcp from "./components/Functies/DeclarationsComponents/DeclarationIcp";
import DeclarationIncomeTax from "./components/Functies/DeclarationsComponents/DeclarationIncomeTax";
import DeclarationSupplement from "./components/Functies/DeclarationsComponents/DeclarationSupplement";
import WhyWe from "./components/Dashbook/WhyWe";
import FromWhome from "./components/Dashbook/FromWhome";
import Security from "./components/Dashbook/Security";
import Blog from "./components/Dashbook/Blog";
import Change from "./components/Dashbook/Change";
import Vacancies from "./components/Dashbook/Vacancies";
import Contact from "./components/Dashbook/Contact";
import FunctionCommon from "./components/Functies/FunctionCommon";
import WantStartBusiness from "./components/Starten/WantStartBusiness";
import GoingIntoBusiness from "./components/Starten/GoingIntoBusiness";
import StartingBusiness from "./components/Starten/StartingBusiness";
import DashbookAndMe from "./components/Starten/DashbookAndMe";
import StartPage from "./components/Starten/StartPage";
import SupportHome from "./components/Support/SupportHome";
import KnowledgeBaseFaq from "./components/Support/KnowledgeBaseFaq";
// import DashbookUpdate from "./components/Support/DashbookUpdate";
import SupportOption from "./components/Support/SupportOption";
import FindYourExpert from "./components/Support/FindYourExpert";
// import DashbookHome from "./components/Dashbook/DashbookHome";
import ProfessionalFooter from "./components/ProfessionalFooter";
import CookieDeclaration from "./components/Footer/CookieDeclaration";
import PrivacyDeclaration from "./components/Footer/PrivacyDeclaration";
import OurMission from "./components/Footer/OurMission";
import Sitemap from "./components/Footer/Sitemap";
import TermsAndConditions from "./components/Footer/TermsAndConditions";
import StorePage from "./components/Starten/StorePage";
import HomePage from "./components/Starten/HomePage";
import { useNavigate } from "react-router-dom";

function ProfessionalApp({ name }) {
  const navigate = useNavigate();
  const setPage = (pageName) => {
    navigate(`/${pageName}`);
  };

  const targetRef = useRef(null);
  const scrollToTarget = () => {
    // if (page) navigate(page);
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    scrollToTarget();
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => scrollToTarget, [name]);
  return (
    <div ref={targetRef} className="clientApp">
      <ProfessionalHeader setPage={setPage} page={name} />

      {/* {name === "store" ? <StorePage /> : ""}
      {!name || name === "home" ? <HomePage setPage={setPage} /> : ""}
      {name === "start" ? <StartPage /> : ""}
      {name === "want_to_do_business" ? <WantStartBusiness /> : ""}
      {name === "going_into_business" ? <GoingIntoBusiness /> : ""}
      {name === "starting_business" ? <StartingBusiness /> : ""}
      {name === "dashbook_and_me" ? <DashbookAndMe setPage={setPage} /> : ""}

      {name === "our_prices" ? <OurPrices /> : ""}
      {name === "our_packages" ? <OurPackages /> : ""}
      {name === "back_office_service" ? <BackofficeService /> : ""}
      {name === "rates_overview" ? <RatesOverview /> : ""}
      {name === "frequently_asked_questions" ? (
        <FrequentlyAskedQuestions />
      ) : (
        ""
      )}

      {name === "functions" ? <FunctionCommon /> : ""}
      {name === "design" ? <DesignPage /> : ""}
      {name === "simple_screens" ? <SimpleScreens /> : ""}
      {name === "unlimited_users" ? <UnlimitedUsers /> : ""}
      {name === "permissions_and_roles" ? <PermissionsRoles /> : ""}
      {name === "relationship_management" ? <RelationshipManagement /> : ""}
      {name === "chart_of_accounts" ? <ChartAccounts /> : ""}
      {name === "admin_setup" ? <AdminSetup /> : ""}

      {name === "administration" ? <AdministrationPage /> : ""}
      {name === "admin_projects" ? <AdminProjects /> : ""}
      {name === "admin_time_keeper" ? <AdminTimeKeeper /> : ""}
      {name === "admin_rides" ? <AdminRides /> : ""}
      {name === "admin_product_service" ? <AdminProductService /> : ""}
      {name === "admin_purchase_cost" ? <AdminPurchaseCost /> : ""}
      {name === "admin_receipts" ? <AdminReceipts /> : ""}

      {name === "to_sell" ? <ToSell /> : ""}
      {name === "quotes" ? <SellQuotes /> : ""}
      {name === "invoicing" ? <SellInvoicing /> : ""}
      {name === "recurring_invoices" ? <SellRecurringInvoices /> : ""}
      {name === "branding_layout_editor" ? <SellBrandingLayoutEditor /> : ""}
      {name === "ubl_invoices" ? <SellUblInvoices /> : ""}
      {name === "ideal_invoicing" ? <SellIdealInvoicing /> : ""}
      {name === "future_invoicing" ? <SellFutureInvoicing /> : ""}
      {name === "reminders" ? <SellReminders /> : ""}
      {name === "digital_inbox_sales" ? <SellDigitalInboxSales /> : ""}

      {name === "links" ? <LinksPage /> : ""}
      {name === "app" ? <LinkApp /> : ""}
      {name === "bank" ? <LinkBank /> : ""}
      {name === "mollie_ideal" ? <MollieIdeal /> : ""}

      {name === "accounting_and_reporting" ? <AccountingReporting /> : ""}
      {name === "depreciations" ? <Depreciations /> : ""}
      {name === "overviews" ? <Overviews /> : ""}
      {name === "other_bookings" ? <OtherBookings /> : ""}
      {name === "reports" ? <Reports /> : ""}
      {name === "documents" ? <Documents /> : ""}

      {name === "declarations" ? <Declarations /> : ""}
      {name === "declaration_btw" ? <DeclarationBtw /> : ""}
      {name === "declaration_icp" ? <DeclarationIcp /> : ""}
      {name === "declaration_supplement" ? <DeclarationSupplement /> : ""}
      {name === "declaration_income_tax" ? <DeclarationIncomeTax /> : ""}

      {name === "dashbook_home" ? <DashbookHome /> : ""}
      {name === "why_we" ? <WhyWe /> : ""}
      {name === "from_whome" ? <FromWhome /> : ""}
      {name === "security" ? <Security /> : ""}
      {name === "blogs" ? <Blog /> : ""}
      {name === "changes" ? <Change setPage={setPage} /> : ""}
      {name === "vacancies" ? <Vacancies /> : ""}
      {name === "contact_us" ? <Contact /> : ""}

      {name === "support_home" ? <SupportHome /> : ""}
      {name === "knowledge_base_faq" ? <KnowledgeBaseFaq /> : ""}
      {name === "faq_dashbook_update" ? <DashbookUpdate /> : ""}
      {name === "faq_support" ? <SupportOption /> : ""}
      {name === "find_your_expert" ? <FindYourExpert /> : ""}

      {name === "cookie_declaration" ? <CookieDeclaration /> : ""}
      {name === "privacy_declaration" ? <PrivacyDeclaration /> : ""}
      {name === "our_mission" ? <OurMission /> : ""}
      {name === "sitemap" ? <Sitemap /> : ""}
      {name === "terms_and_condition" ? <TermsAndConditions /> : ""} */}

      {name === "store" ? <StorePage /> : ""}
      {!name || name === "home" || name === "dashbook" ? (
        <HomePage setPage={setPage} />
      ) : (
        ""
      )}
      {name === "starten" ? <StartPage /> : ""}
      {name === "wil_ik_ondernemen" ? <WantStartBusiness /> : ""}
      {name === "ik_ga_ondernemen" ? <GoingIntoBusiness /> : ""}
      {name === "onderneming_starten" ? <StartingBusiness /> : ""}
      {name === "dashbook_en_ik" ? <DashbookAndMe setPage={setPage} /> : ""}

      {name === "onze_prijzen" ? <OurPrices /> : ""}
      {name === "onze_pakketten" ? <OurPackages /> : ""}
      {name === "backoffice_service" ? <BackofficeService /> : ""}
      {name === "tarieven_overzicht" ? <RatesOverview /> : ""}
      {name === "veel_gestelde_vragen" ? <FrequentlyAskedQuestions /> : ""}

      {name === "functies" ? <FunctionCommon /> : ""}
      {name === "inrichting" ? <DesignPage /> : ""}
      {name === "eenvoudige_invoerschermen" ? <SimpleScreens /> : ""}
      {name === "onbeperkt_gebruikers_aanmaken" ? <UnlimitedUsers /> : ""}
      {name === "rechten_en_rollen_instellen" ? <PermissionsRoles /> : ""}
      {name === "relatiebeheer" ? <RelationshipManagement /> : ""}
      {name === "subcategorien_rekeningschema" ? <ChartAccounts /> : ""}
      {name === "inrichting_administratie" ? <AdminSetup /> : ""}

      {name === "administratie" ? <AdministrationPage /> : ""}
      {name === "projecten" ? <AdminProjects /> : ""}
      {name === "uren_time_keeper" ? <AdminTimeKeeper /> : ""}
      {name === "ritten" ? <AdminRides /> : ""}
      {name === "producten_en_diensten" ? <AdminProductService /> : ""}
      {name === "inkopen_kosten" ? <AdminPurchaseCost /> : ""}
      {name === "bonnen_inbox" ? <AdminReceipts /> : ""}

      {name === "verkopen" ? <ToSell /> : ""}
      {name === "offertes" ? <SellQuotes /> : ""}
      {name === "factureren" ? <SellInvoicing /> : ""}
      {name === "periodieke_facturen" ? <SellRecurringInvoices /> : ""}
      {name === "huisstijl_layout_editor" ? <SellBrandingLayoutEditor /> : ""}
      {name === "ubl_facturen" ? <SellUblInvoices /> : ""}
      {name === "facturatie_met_ideal_mollie" ? <SellIdealInvoicing /> : ""}
      {name === "facturen_in_toekomst_versturen" ? <SellFutureInvoicing /> : ""}
      {name === "herinneringen_en_aanmaningen" ? <SellReminders /> : ""}
      {name === "digitale_inbox_verkoop" ? <SellDigitalInboxSales /> : ""}

      {name === "koppelingen" ? <LinksPage /> : ""}
      {name === "app" ? <LinkApp /> : ""}
      {name === "bank" ? <LinkBank /> : ""}
      {name === "mollie_ideal" ? <MollieIdeal /> : ""}

      {name === "boekhouding_en_rapportage" ? <AccountingReporting /> : ""}
      {name === "afschrijvingen" ? <Depreciations /> : ""}
      {name === "overzichten" ? <Overviews /> : ""}
      {name === "overige_boekingen" ? <OtherBookings /> : ""}
      {name === "rapporten" ? <Reports /> : ""}
      {name === "documenten" ? <Documents /> : ""}

      {name === "aangiftes" ? <Declarations /> : ""}
      {name === "btw_aangiftes" ? <DeclarationBtw /> : ""}
      {name === "icp_aangiftes" ? <DeclarationIcp /> : ""}
      {name === "suppletieaangifte" ? <DeclarationSupplement /> : ""}
      {name === "inkomstenbelasting" ? <DeclarationIncomeTax /> : ""}

      {/* {name === "dashbook" ? <DashbookHome /> : ""} */}
      {name === "waarom" ? <WhyWe /> : ""}
      {name === "voor_wie" ? <FromWhome /> : ""}
      {name === "security" ? <Security /> : ""}
      {name === "blog" ? <Blog /> : ""}
      {name === "overstappen" ? <Change setPage={setPage} /> : ""}
      {name === "vacatures" ? <Vacancies /> : ""}
      {name === "contact" ? <Contact /> : ""}

      {name === "ondersteuning" ? <SupportHome /> : ""}
      {name === "kennisbank_faq" ? <KnowledgeBaseFaq /> : ""}
      {/* {name === "Dashbook Update" ? <DashbookUpdate /> : ""} */}
      {name === "support" ? <SupportOption /> : ""}
      {name === "vind_je_expert" ? <FindYourExpert /> : ""}

      {name === "cookieverklaring" ? <CookieDeclaration /> : ""}
      {name === "privacyverklaring" ? <PrivacyDeclaration /> : ""}
      {name === "onze_missie" ? <OurMission /> : ""}
      {name === "sitemap" ? <Sitemap /> : ""}
      {name === "voorwaarden" ? <TermsAndConditions /> : ""}

      <ProfessionalFooter />
    </div>
  );
}

export default ProfessionalApp;
