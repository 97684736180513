import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Button } from "@mui/material";
import { useAuth } from "../../../hooks/auth";
import { useNavigate } from "react-router-dom";
// import DropdownMenu from "./Dropdowns/DropdownMenu";
import MobileDropDown from "./Dropdowns/MobileDropDown";
import HorizontalMenu from "./Dropdowns/HorizontalMenu";

function ProfessionalHeader({ setPage, page }) {
  const auth = useAuth();
  const userId = auth?.user?.id;
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  // const { t } = useTranslation();
  const [parent, setParent] = useState();
  const closeMenubar = () => {
    setParent(null);
  };
  // const headerLinks = [
  //   {
  //     name: "Starten",
  //     value: "start",
  //     submenu: true,
  //     submenuOptions: [
  //       {
  //         name: "Wil ik ondernemen",
  //         value: "want_to_do_business",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Ik ga ondernemen",
  //         value: "going_into_business",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Onderneming starten",
  //         value: "starting_business",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Dashbook en ik",
  //         value: "dashbook_and_me",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Functies",
  //     value: "functions",
  //     submenu: true,
  //     submenuOptions: [
  //       {
  //         name: "Inrichting",
  //         value: "design",
  //         submenu: true,
  //         submenuOptions: [
  //           {
  //             name: "Eenvoudige invoerschermen",
  //             value: "simple_screens",
  //             submenu: false,
  //             submenuOptions: [],
  //           },
  //           {
  //             name: "Onbeperkt gebruikers aanmaken",
  //             value: "unlimited_users",
  //             submenu: false,
  //             submenuOptions: [],
  //           },
  //           {
  //             name: "Rechten en rollen instellen",
  //             value: "permissions_and_roles",
  //             submenu: false,
  //             submenuOptions: [],
  //           },
  //           {
  //             name: "Relatiebeheer",
  //             value: "relationship_management",
  //             submenu: false,
  //             submenuOptions: [],
  //           },
  //           {
  //             name: "Subcategorieën (rekeningschema)",
  //             value: "chart_of_accounts",
  //             submenu: false,
  //             submenuOptions: [],
  //           },
  //           {
  //             name: "Inrichting administratie",
  //             value: "admin_setup",
  //             submenu: false,
  //             submenuOptions: [],
  //           },
  //         ],
  //       },
  //       {
  //         name: "Administratie",
  //         value: "administration",
  //         submenu: true,
  //         submenuOptions: [
  //           { name: "Projecten", value: "admin_projects" },
  //           {
  //             name: "Uren + Time Keeper",
  //             value: "admin_time_keeper",
  //           },
  //           {
  //             name: "Ritten",
  //             value: "admin_rides",
  //           },
  //           {
  //             name: "Producten en diensten",
  //             value: "admin_product_service",
  //           },
  //           {
  //             name: "Inkopen (Kosten)",
  //             value: "admin_purchase_cost",
  //           },
  //           { name: "Bonnen inbox", value: "admin_receipts" },
  //         ],
  //       },
  //       {
  //         name: "Verkopen",
  //         value: "to_sell",
  //         submenu: true,
  //         submenuOptions: [
  //           { name: "Offertes", value: "quotes" },
  //           { name: "Factureren", value: "invoicing" },
  //           { name: "Periodieke facturen", value: "recurring_invoices" },
  //           {
  //             name: "Huisstijl lay-out editor",
  //             value: "branding_layout_editor",
  //           },
  //           { name: "UBL-facturen", value: "ubl_invoices" },
  //           {
  //             name: "Facturatie met IDEAL (Mollie)",
  //             value: "ideal_invoicing",
  //           },
  //           {
  //             name: "Facturen in toekomst versturen",
  //             value: "future_invoicing",
  //           },
  //           { name: "Herinneringen en aanmaningen", value: "reminders" },
  //           {
  //             name: "Digitale inbox (verkoop)",
  //             value: "digital_inbox_sales",
  //           },
  //         ],
  //       },
  //       {
  //         name: "Koppelingen",
  //         value: "links",
  //         submenu: true,
  //         submenuOptions: [
  //           { name: "App", value: "app" },
  //           { name: "Bank", value: "bank" },
  //           { name: "Mollie Ideal", value: "mollie_ideal" },
  //         ],
  //       },
  //       {
  //         name: "Boekhouding en rapportage",
  //         value: "accounting_and_reporting",
  //         submenu: true,
  //         submenuOptions: [
  //           { name: "Afschrijvingen", value: "depreciations" },
  //           { name: "Overzichten", value: "overviews" },
  //           { name: "Overige boekingen", value: "other_bookings" },
  //           { name: "Rapporten", value: "reports" },
  //           { name: "Documenten", value: "documents" },
  //         ],
  //       },
  //       {
  //         name: "Aangiftes",
  //         value: "declarations",
  //         submenu: true,
  //         submenuOptions: [
  //           { name: "BTW- aangiftes", value: "declaration_btw" },
  //           {
  //             name: "ICP-aangiftes",
  //             value: "declaration_icp",
  //           },
  //           {
  //             name: "Suppletieaangifte",
  //             value: "declaration_supplement",
  //           },
  //           {
  //             name: "Inkomstenbelasting ",
  //             value: "declaration_income_tax",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Prijzen",
  //     value: "our_prices",
  //     submenu: true,
  //     submenuOptions: [
  //       {
  //         name: "Onze Prijzen",
  //         value: "our_prices",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Onze pakketten",
  //         value: "our_packages",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Backoffice service",
  //         value: "back_office_service",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Tarieven overzicht",
  //         value: "rates_overview",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Veel gestelde vragen",
  //         value: "frequently_asked_questions",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Overstappen",
  //     value: "changes",
  //     submenu: false,
  //     submenuOptions: [],
  //   },
  //   {
  //     name: "Ondersteuning",
  //     value: "support_home",
  //     submenu: true,
  //     submenuOptions: [
  //       {
  //         name: "Kennisbank / FAQ",
  //         value: "knowledge_base_faq",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Dashbook Update",
  //         value: "faq_dashbook_update",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Support",
  //         value: "faq_support",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Vind je expert",
  //         value: "find_your_expert",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Dashbook",
  //     value: "dashbook_home",
  //     submenu: true,
  //     submenuOptions: [
  //       {
  //         name: "Waarom ?",
  //         value: "why_we",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Voor wie ?",
  //         value: "from_whome",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Security",
  //         value: "security",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Blog",
  //         value: "blogs",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Overstappen",
  //         value: "changes",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Vacatures",
  //         value: "vacancies",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //       {
  //         name: "Contact",
  //         value: "contact_us",
  //         submenu: false,
  //         submenuOptions: [],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Store",
  //     value: "store",
  //     submenu: false,
  //     submenuOptions: [],
  //   },
  // ];

  const headerLinks = [
    {
      name: "Starten",
      value: "starten",
      submenu: true,
      submenuOptions: [
        {
          name: "Wil ik ondernemen",
          value: "wil_ik_ondernemen",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Ik ga ondernemen",
          value: "ik_ga_ondernemen",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Onderneming starten",
          value: "onderneming_starten",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Dashbook en ik",
          value: "dashbook_en_ik",
          submenu: false,
          submenuOptions: [],
        },
      ],
    },
    {
      name: "Functies",
      value: "functies",
      submenu: true,
      submenuOptions: [
        {
          name: "Inrichting",
          value: "inrichting",
          submenu: true,
          submenuOptions: [
            {
              name: "Eenvoudige invoerschermen",
              value: "eenvoudige_invoerschermen",
              submenu: false,
              submenuOptions: [],
            },
            {
              name: "Onbeperkt gebruikers aanmaken",
              value: "onbeperkt_gebruikers_aanmaken",
              submenu: false,
              submenuOptions: [],
            },
            {
              name: "Rechten en rollen instellen",
              value: "rechten_en_rollen_instellen",
              submenu: false,
              submenuOptions: [],
            },
            {
              name: "Relatiebeheer",
              value: "relatiebeheer",
              submenu: false,
              submenuOptions: [],
            },
            {
              name: "Subcategorieën (rekeningschema)",
              value: "subcategorien_rekeningschema",
              submenu: false,
              submenuOptions: [],
            },
            {
              name: "Inrichting administratie",
              value: "inrichting_administratie",
              submenu: false,
              submenuOptions: [],
            },
          ],
        },
        {
          name: "Administratie",
          value: "administratie",
          submenu: true,
          submenuOptions: [
            { name: "Projecten", value: "projecten" },
            {
              name: "Uren + Time Keeper",
              value: "uren_time_keeper",
            },
            {
              name: "Ritten",
              value: "ritten",
            },
            {
              name: "Producten en diensten",
              value: "producten_en_diensten",
            },
            {
              name: "Inkopen (Kosten)",
              value: "inkopen_kosten",
            },
            { name: "Bonnen inbox", value: "bonnen_inbox" },
          ],
        },
        {
          name: "Verkopen",
          value: "verkopen",
          submenu: true,
          submenuOptions: [
            { name: "Offertes", value: "offertes" },
            { name: "Factureren", value: "factureren" },
            { name: "Periodieke facturen", value: "periodieke_facturen" },
            {
              name: "Huisstijl lay-out editor",
              value: "huisstijl_layout_editor",
            },
            { name: "UBL-facturen", value: "ubl_facturen" },
            {
              name: "Facturatie met IDEAL (Mollie)",
              value: "facturatie_met_ideal_mollie",
            },
            {
              name: "Facturen in toekomst versturen",
              value: "facturen_in_toekomst_versturen",
            },
            { name: "Herinneringen en aanmaningen", value: "herinneringen_en_aanmaningen" },
            {
              name: "Digitale inbox (verkoop)",
              value: "digitale_inbox_verkoop",
            },
          ],
        },
        {
          name: "Koppelingen",
          value: "koppelingen",
          submenu: true,
          submenuOptions: [
            { name: "App", value: "app" },
            { name: "Bank", value: "bank" },
            { name: "Mollie Ideal", value: "mollie_ideal" },
          ],
        },
        {
          name: "Boekhouding en rapportage",
          value: "boekhouding_en_rapportage",
          submenu: true,
          submenuOptions: [
            { name: "Afschrijvingen", value: "afschrijvingen" },
            { name: "Overzichten", value: "overzichten" },
            { name: "Overige boekingen", value: "overige_boekingen" },
            { name: "Rapporten", value: "rapporten" },
            { name: "Documenten", value: "documenten" },
          ],
        },
        {
          name: "Aangiftes",
          value: "aangiftes",
          submenu: true,
          submenuOptions: [
            { name: "BTW- aangiftes", value: "btw_aangiftes" },
            {
              name: "ICP-aangiftes",
              value: "icp_aangiftes",
            },
            {
              name: "Suppletieaangifte",
              value: "suppletieaangifte",
            },
            {
              name: "Inkomstenbelasting",
              value: "inkomstenbelasting",
            },
          ],
        },
      ],
    },
    {
      name: "Prijzen",
      value: "onze_prijzen",
      submenu: true,
      submenuOptions: [
        {
          name: "Onze Prijzen",
          value: "onze_prijzen",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Onze pakketten",
          value: "onze_pakketten",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Backoffice service",
          value: "backoffice_service",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Tarieven overzicht",
          value: "tarieven_overzicht",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Veel gestelde vragen",
          value: "veel_gestelde_vragen",
          submenu: false,
          submenuOptions: [],
        },
      ],
    },
    {
      name: "Overstappen",
      value: "overstappen",
      submenu: false,
      submenuOptions: [],
    },
    {
      name: "Ondersteuning",
      value: "ondersteuning",
      submenu: true,
      submenuOptions: [
        {
          name: "Kennisbank / FAQ",
          value: "kennisbank_faq",
          submenu: false,
          submenuOptions: [],
        },
        // {
        //   name: "Dashbook Update",
        //   value: "faq_dashbook_update",
        //   submenu: false,
        //   submenuOptions: [],
        // },
        {
          name: "Support",
          value: "support",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Vind je expert",
          value: "vind_je_expert",
          submenu: false,
          submenuOptions: [],
        },
      ],
    },
    {
      name: "Dashbook",
      value: "dashbook",
      submenu: true,
      submenuOptions: [
        {
          name: "Waarom ?",
          value: "waarom",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Voor wie ?",
          value: "voor_wie",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Security",
          value: "security",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Blog",
          value: "blog",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Overstappen",
          value: "overstappen",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Vacatures",
          value: "vacatures",
          submenu: false,
          submenuOptions: [],
        },
        {
          name: "Contact",
          value: "contact",
          submenu: false,
          submenuOptions: [],
        },
      ],
    },
    {
      name: "Store",
      value: "store",
      submenu: false,
      submenuOptions: [],
    },
  ];
  
  const setValue = (value) => {
    setMenuOpen(false);
    setPage(value);
  };

  return (
    <div className="sticky">
      <div className="mainHead desktop">
        <div className="headerStyle">
          <div style={{ width: "max-content" }}>
            <button
              className="headerButtonLogo"
              onClick={() => {
                setPage("home");
                closeMenubar();
              }}
            >
              <img
                src={"/logo.svg"}
                alt="DashBook Logo"
                width={"100px"}
                height={"auto"}
              />
            </button>
          </div>
          {/* <div className="menuLinks" onMouseLeave={closeMenubar}>
            {headerLinks.map((headerLink, headerLinkIndex) => (
              <DropdownMenu
                menuItem={headerLink}
                parent={parent}
                setParent={setParent}
                closeMenubar={closeMenubar}
                setPage={setValue}
                key={headerLinkIndex}
                activePage={page}
              />
            ))}
          </div> */}
          <div className="menuLinks">
            {headerLinks.map((headerLink, headerLinkIndex) => (
              <HorizontalMenu
                menuItem={headerLink}
                parent={parent}
                setParent={setParent}
                closeMenubar={closeMenubar}
                setPage={setValue}
                key={headerLinkIndex}
                activePage={page}
              />
            ))}
          </div>
          {userId ? (
            <div className="buttonContainerStyle">
              <Button
                variant="outlined"
                onClick={() => navigate("/dashboard")}
                className="loginBtn"
              >
                Dashboard
              </Button>
            </div>
          ) : (
            <div className="buttonContainerStyle">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#d0af88 !important",
                  boxShadow: "none !important",
                }}
                onClick={() => navigate("/login")}
              >
                inloggen
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="mobile" style={{ width: "100%" }}>
        <div className="flex">
          <div style={{ padding: "0px 1rem", alignSelf: "center" }}>
            <button className="headerButtonLogo">
              <img
                src={"/logo.svg"}
                alt="DashBook Logo"
                width={"100px"}
                height={"auto"}
              />
            </button>
          </div>
          <div className="flex">
            {userId ? (
              <div className="buttonContainerStyle">
                <Button
                  variant="outlined"
                  onClick={() => navigate("/dashboard")}
                  className="loginBtn"
                >
                  Dashboard
                </Button>
              </div>
            ) : (
              <div className="buttonContainerStyle">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#d0af88 !important",
                    boxShadow: "none !important",
                  }}
                  onClick={() => navigate("/login")}
                >
                  inloggen
                </Button>
              </div>
            )}
            <button
              style={{
                marginRight: "0px",
                color: "black !important",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "18px",
                margin: "1rem",
              }}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <CloseOutlinedIcon /> : <MenuTwoToneIcon />}
            </button>
          </div>
        </div>

        {menuOpen ? (
          <div
            style={{
              padding: "1rem",
              maxHeight: "calc(100vh-70px)",
              overflow: "scroll",
            }}
          >
            {headerLinks.map((headerLink, headerLinkIndex) => (
              <MobileDropDown
                menuItem={headerLink}
                parent={parent}
                setParent={setParent}
                closeMenubar={closeMenubar}
                setPage={setValue}
                key={headerLinkIndex}
                activePage={page}
              />
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ProfessionalHeader;
