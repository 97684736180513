import React, { useEffect, useRef } from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Button, Chip, Grid, Stack, Typography } from "@mui/material";
import TextInput from "../../components/form/TextInput";

import { useTranslation } from "react-i18next";

import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import api from "../../config/axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { highlightField } from "../../utils/Commons";

const SendByEmailDialogBox = ({
  isOpen,
  onClose,
  control,
  setValue,
  inputErrors,
  openDialog,
  getValues,
  uploadDocuments,
  documents,
  setInputErrors,
}) => {
  const { t } = useTranslation();
  const { offerId } = useParams();
  const abortController = useRef(new AbortController());

  const handleFormatChangeForOffersEmail = (newFormat) => {
    setValue("offerEmailText", newFormat);
  };

  const handleTagClickForOffersEmail = (tag) => {
    const { offerEmailText } = getValues();
    if (offerEmailText) {
      setValue("offerEmailText", offerEmailText + `{${tag}}`);
    } else {
      setValue("offerEmailText", `{${tag}}`);
    }
  };

  const sendOffers = async () => {
    const { emails, emailSubject, offerEmailText, uploadDocuments } =
      getValues();

    const emailAttachments = uploadDocuments.map((doc) => doc._id);

    const data = {
      emails: emails,
      emailSubject: emailSubject,
      emailText: offerEmailText,
    };
    if (emailAttachments?.length) {
      data.emailAttachments = emailAttachments;
    }
    try {
      const res = await api.put(`users/offers/${offerId}/send`, data);
      const resData = res.data;

      if (resData.status) {
        onClose();
        toast.success(t("offers.offers_send_successfully"));
      } else {
        if (resData && resData.type === "VALIDATION") {
          setInputErrors(resData.error);

          try {
            highlightField(Object.keys(resData.error)[0]);
          } catch (error) {
            console.warn("Field cannot be highlighted");
          }
        } else {
          toast.error(resData?.error?.message || "Something went wrong");
        }
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  const getInvoiceSettings = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    try {
      const res = await api.get(`users/settings/numbering_and_text`, {
        signal: abortController.current.signal,
      });
      const resData = res.data;

      if (resData.status) {
        setValue("offerEmailText", resData?.data?.offerEmailText);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };
  useEffect(() => {
    getInvoiceSettings();
    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <CustomDialogBox
      title={t("offers.dialogBox.title.send_by_email")}
      isOpen={isOpen}
      maxWidth="md"
      onClose={onClose}
      actions={
        <Stack
          flex={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="success"
            size="large"
            sx={{ color: "white" }}
            onClick={sendOffers}
          >
            {t("common_btn.send")}
          </Button>
        </Stack>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack mb={2}>
            <Typography variant="body1" color="secondary">
              {t("offers.dialogBox.sender")}
            </Typography>
          </Stack>
          <Stack mb={2}>
            <TextInput
              disabled
              name="senderName"
              label={t("offers.fields.name")}
              control={control}
              customError={inputErrors?.name || null}
            />
          </Stack>
          <TextInput
            disabled
            name="senderEmail"
            label={t("offers.fields.email")}
            control={control}
            customError={inputErrors?.senderEmail || null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack mb={2}>
            <Typography variant="body1" color="secondary">
              {t("offers.dialogBox.Recipient")}
            </Typography>
          </Stack>
          <Stack mb={2}>
            <TextInput
              disabled
              name="receiverName"
              label={t("offers.fields.name")}
              control={control}
              customError={inputErrors?.receiverName || null}
            />
          </Stack>
          <TextInput
            name="emails"
            label={t("offers.fields.email")}
            control={control}
            customError={inputErrors?.emails || null}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name="emailSubject"
            label={t("offers.fields.subject")}
            control={control}
            customError={inputErrors?.emailSubject || null}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            multiline={true}
            rows={10}
            label={t("invoices.setting.offer_email_text")}
            type="text"
            name="offerEmailText"
            control={control}
            customError={inputErrors?.offerEmailText || null}
            onChangeCallback={(value) =>
              handleFormatChangeForOffersEmail(value)
            }
            placeholder="Enter custom format"
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            flexWrap="wrap"
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography>{t("invoices.setting.tags")} :</Typography>
            <Typography
              style={{ cursor: "pointer" }}
              color="blue"
              onClick={() => handleTagClickForOffersEmail("customer_name")}
            >
              {t("invoices.setting.customer")}
            </Typography>
            <Typography
              style={{ cursor: "pointer" }}
              color="blue"
              onClick={() => handleTagClickForOffersEmail("offer_id")}
            >
              {t("invoices.setting.offer_id")}
            </Typography>
            <Typography
              style={{ cursor: "pointer" }}
              color="blue"
              onClick={() => handleTagClickForOffersEmail("name")}
            >
              {t("invoices.setting.name")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => openDialog("uploadFileEdit")}
            startIcon={<UploadOutlinedIcon style={{ fontSize: 25 }} />}
            color="error"
            endIcon={
              <Chip
                size="small"
                label={documents?.length || uploadDocuments.length}
                color="error"
              />
            }
          >
            {t("common_btn.upload_pdf")}
          </Button>
        </Grid>
      </Grid>
    </CustomDialogBox>
  );
};

export default SendByEmailDialogBox;
