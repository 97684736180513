import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SellInvoicing() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Facturen maken en versturen in een handomdraai
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          " Genereer met gemak professionele inkoop- en verkoopfacturen en
          verstuur ze direct naar je klanten. Onze facturen voldoen aan alle
          regelgeving en worden automatisch verwerkt in je boekhouding. Houd
          overzicht over openstaande facturen en maak gebruik van automatische
          betalingsherinneringen om snellere betalingen te bevorderen."
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Factureren met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Professionele uitstraling
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Factureer uren
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Factureer producten of diensten
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Documenten toevoegen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(5)}
        >
          Facturatie met iDEAL (Mollie)
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(6)}
        >
          Goedgekeurde offerte naar factuur
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(7)}
        >
          Openstaande facturen herinneren
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(8)}
        >
          UBL e-facturen (XML)
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(9)}
        >
          Creditfacturen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(10)}
        >
          Factuur dupliceren
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Professionele uitstraling".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Professionele uitstraling
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Een sterke huisstijl is van essentieel belang voor de
                uitstraling van je bedrijf. Het draagt bij aan herkenbaarheid,
                professionaliteit en maakt een blijvende indruk.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <strong> Voordelen:</strong>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <strong>Herkenbaarheid: </strong>Facturen in je eigen huisstijl
                zijn direct herkenbaar voor je klanten, wat bijdraagt aan
                vertrouwen en professionaliteit.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Professioneel:</strong> Facturen die aansluiten bij je
                huisstijl ogen professioneel en dragen bij aan een positieve
                eerste indruk.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Indruk: </strong>Facturen in je eigen huisstijl maken
                een sterke indruk op je klanten, wat potentieel kan leiden tot
                een verhoogde omzet.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Factureer uren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Factureer uren
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Uren factureren wordt moeiteloos met Dashbook.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dit is niet alleen handig voor je eigen administratie en het
                voldoen aan de urennorm, maar ook voor het snel en eenvoudig
                factureren. Leg simpelweg je uren vast en factureer ze met een
                simpele druk op de knop.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Factureer producten of diensten".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Factureer producten of diensten
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Naast het factureren van uren, is het ook mogelijk om alle
                andere producten en diensten te factureren met Dashbook.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Indien je regelmatig dezelfde producten verkoopt, biedt Dashbook
                de optie om deze op te slaan. Hierbij worden gegevens zoals de
                omschrijving, het bedrag, de btw en de categorie automatisch
                voor je ingevuld, wat het proces vereenvoudigt.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Documenten toevoegen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Documenten toevoegen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Voeg moeiteloos de algemene voorwaarden of andere specifieke
                documenten toe als bijlage. Op deze manier heeft je klant alle
                benodigde informatie direct beschikbaar.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-5"
      >
        <Divider textAlign="left" className="divider">
          {"Facturatie met iDEAL (Mollie)".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Facturatie met iDEAL (Mollie)
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Versnel de betalingsprocessen en stimuleer snellere betalingen
                met iDEAL.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Automatiseer het toevoegen van een betaallink aan je facturen,
                waardoor je klanten moeiteloos en veilig kunnen betalen met
                iDEAL en creditcard. Verbeter de klantervaring en bevorder een
                efficiëntere financiële afhandeling.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-6"
      >
        <Divider textAlign="right" className="divider">
          {"Goedgekeurde offerte naar factuur".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Goedgekeurde offerte naar factuur
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Nadat je klant de offerte online heeft geaccepteerd, kun je deze
                moeiteloos omzetten naar een factuur wanneer dat nodig is.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Deze handige functie bespaart niet alleen tijd, maar vermindert
                ook de kans op fouten. Wel zo makkelijk en efficiënt.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-7"
      >
        <Divider textAlign="left" className="divider">
          {"Openstaande facturen herinneren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Openstaande facturen herinneren
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Optimaal beheer van je openstaande facturen is van essentieel
                belang voor een gezonde financiële situatie. Met onze functies
                wordt het verzenden van herinneringen heel eenvoudig, en zelfs
                volledig geautomatiseerd!
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Kies uit onze handige standaardteksten voor het versturen van
                herinneringen, of personaliseer en breid ze gemakkelijk uit
                volgens jouw wensen. Beheer je communicatie met klanten soepel
                en professioneel, terwijl je betalingen efficiënt stimuleert.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-8"
      >
        <Divider textAlign="right" className="divider">
          {"UBL e-facturen (XML)".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                UBL e-facturen (XML)
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Elektronisch factureren wordt moeiteloos met Dashbook.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Facturen die je per e-mail verstuurt, bevatten automatisch de
                elektronische factuur, ook bekend als de UBL-factuur. Hierdoor
                kunnen opdrachtgevers deze vaak automatisch inlezen, wat het
                proces vereenvoudigt en versnelt.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Kies uit onze handige standaardteksten voor het versturen van
                herinneringen, of personaliseer en breid ze gemakkelijk uit
                volgens jouw wensen. Beheer je communicatie met klanten soepel
                en professioneel, terwijl je betalingen efficiënt stimuleert.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-9"
      >
        <Divider textAlign="left" className="divider">
          {"Creditfacturen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Creditfacturen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Crediteer facturen moeiteloos met slechts één druk op de knop.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Wanneer je een factuur crediteert, wordt deze automatisch
                geïntegreerd in de boekhouding. De resulterende creditnota kan
                vervolgens direct worden verstuurd. Vereenvoudig je
                crediteringsproces en bespaar kostbare tijd met deze handige
                functie.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-10"
      >
        <Divider textAlign="right" className="divider">
          {"Factuur dupliceren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Factuur dupliceren
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Vereenvoudig het opstellen van facturen voor dezelfde klant met
                de functie "Factuur dupliceren". Hiermee worden automatisch
                nieuwe facturen aangemaakt, waardoor je snel en gemakkelijk
                duplicaten kunt versturen naar de klant. Bespaar tijd en
                minimaliseer inspanningen bij het factureren met deze handige
                optie.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellInvoicing;
