import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FrequentlyAskedQuestions() {
  // const { t } = useTranslation();

  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  useEffect(() => {
    setExpandedPanel("faq-header-1");
    // eslint-disable-next-line
  }, []);
  return (
    <div style={{ padding: "2rem 6rem", backgroundColor: "#F3F3F3" }}>
      <Typography
        variant="h2"
        gutterBottom
        textAlign={"center"}
        sx={{ color: "#D0AF88" }}
      >
        Veelgestelde vragen over onze prijzen
      </Typography>

      <div style={{ padding: "2rem 0px" }}>
        {/* Question 1 */}
        <Accordion
          sx={{ border: "1px solid #D0AF88" }}
          expanded={expandedPanel === "faq-header-1"}
          onChange={handleChange("faq-header-1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content-1"
            id="faq-header-1"
          >
            <Typography variant="subtitle1">
              Onze pakketten gratis uitproberen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginBottom: "1rem" }}>
              Dashbook biedt een gratis proefperiode van 14 dagen aan voor alle
              pakketten. Zo kunt u onze software onbeperkt en vrijblijvend
              uitproberen.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              U hoeft niets te doen om de proefperiode te starten. Na 14 dagen
              stopt de proefperiode automatisch. U kunt ook op elk moment zelf
              opzeggen.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Om het abonnement definitief te maken, activeert u het via mijn
              “accountbeheer” van uw Dashbook-account.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* Question 2 */}
        <Accordion
          sx={{ border: "1px solid #D0AF88" }}
          expanded={expandedPanel === "faq-header-2"}
          onChange={handleChange("faq-header-2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content-2"
            id="faq-header-2"
          >
            <Typography variant="subtitle1">
              Waarom zijn onze prijzen lager dan andere boekhoudprogramma's?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginBottom: "1rem" }}>
              Onze boekhoudsoftware is gericht op ondernemers zonder financiële
              achtergrond.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Veel boekhoudprogramma's zijn te complex en onbegrijpelijk voor
              deze doelgroep. Ze zitten vol met functies die je nooit gebruikt
              en die het moeilijk maken om de software te leren.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Wij hebben onze software daarom zo ontwikkeld dat hij simpel en
              gebruiksvriendelijk is. We hebben alleen de essentiële functies
              behouden, zodat je je boekhouding snel en gemakkelijk kunt doen.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Hierdoor kunnen we onze prijzen lager houden dan andere
              boekhoudprogramma's.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Dus ben je een ondernemer zonder financiële achtergrond? Dan is
              onze boekhoudsoftware de perfecte oplossing voor jou!
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Question 3 */}
        <Accordion
          sx={{ border: "1px solid #D0AF88" }}
          expanded={expandedPanel === "faq-header-3"}
          onChange={handleChange("faq-header-3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content-3"
            id="faq-header-3"
          >
            <Typography variant="subtitle1">
              Upgraden naar een betaald Dashbook-pakket?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginBottom: "1rem" }}>
              Wilt u meer uit Dashbook halen? Upgrade dan naar een betaald
              pakket.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              In het accountbeheer van uw Dashbook-account kunt u eenvoudig uw
              gratis pakket upgraden naar een betaald pakket.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Question 4 */}
        <Accordion
          sx={{ border: "1px solid #D0AF88" }}
          expanded={expandedPanel === "faq-header-4"}
          onChange={handleChange("faq-header-4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content-4"
            id="faq-header-4"
          >
            <Typography variant="subtitle1">
              Welke beperkingen zijn er in mijn Dashbook-account?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginBottom: "1rem" }}>
              Dashbook is ontworpen voor gebruik door iedereen. Om iedereen een
              goede ervaring te bieden, zijn er een aantal beperkingen ingebouwd
              in onze pakketten.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Zo kun je in elk pakket een onbeperkt aantal contacten toevoegen.
              Voor extra gebruikers moet je extra betalen of upgraden naar het
              Unlimited-pakket.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Sommige pakketten hebben een beperkt aantal banktransacties. Voor
              onbeperkte banktransacties kun je upgraden naar het
              Unlimited-pakket.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Question 5 */}
        <Accordion
          sx={{ border: "1px solid #D0AF88" }}
          expanded={expandedPanel === "faq-header-5"}
          onChange={handleChange("faq-header-5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content-5"
            id="faq-header-5"
          >
            <Typography variant="subtitle1">
              Hoe wordt het verwerken van banktransacties berekend?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginBottom: "1rem" }}>
              Het aantal banktransacties dat je kunt verwerken in een
              abonnementsmaand, is afhankelijk van je pakket.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Een banktransactie is een betaling die je ontvangt of doet. Per
              factuur, inkoopfactuur of bon heb je dus meestal één
              banktransactie.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Het moment dat je de koppeling maakt tussen een banktransactie en
              een factuur of categorie, is bepalend voor het aantal transacties
              dat je in een abonnementsmaand hebt gebruikt. De datum van de
              transactie zelf is niet belangrijk.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Ongebruikte transacties in een abonnementsmaand vervallen op het
              moment dat de volgende abonnementsmaand ingaat. Het is dus niet
              mogelijk om overgebleven transacties mee te nemen of op te sparen.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Question 6 */}
        <Accordion
          sx={{ border: "1px solid #D0AF88" }}
          expanded={expandedPanel === "faq-header-6"}
          onChange={handleChange("faq-header-6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content-6"
            id="faq-header-6"
          >
            <Typography variant="subtitle1">
              Is jouw administratie veilig?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginBottom: "1rem" }}>
              Online administraties zijn veilig opgeslagen in de cloud.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              De cloud is een netwerk van beveiligde servers over de hele
              wereld. Je gegevens worden opgeslagen op meerdere servers, zodat
              je ze niet kunt verliezen door brand of diefstal.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Dashbook gebruikt het cloudplatform Azure van Microsoft ? .
              Microsoft is een betrouwbare partij met een sterke reputatie op
              het gebied van beveiliging.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Azure-servers worden 24 uur per dag en 7 dagen in de week bewaakt
              en gecontroleerd.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Dashbook maakt elke dag back-ups van jouw gegevens. Zo ben je
              altijd verzekerd van een veilige administratie.
            </Typography>
            <Typography sx={{ marginBottom: "1rem" }}>
              Hier zijn enkele specifieke beveiligingsmaatregelen die Dashbook
              neemt:
            </Typography>
            <ul>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Gebruik van sterke encryptie
                </Typography>
              </li>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Toegangscontrole op basis van gebruikersrollen
                </Typography>
              </li>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Periodieke audit van de beveiliging
                </Typography>
              </li>
            </ul>
            <Typography sx={{ marginBottom: "1rem" }}>
              Dashbook is een betrouwbare partner voor jouw online
              administratie.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Question 7 */}
        <Accordion
          sx={{ border: "1px solid #D0AF88" }}
          expanded={expandedPanel === "faq-header-7"}
          onChange={handleChange("faq-header-7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="faq-content-7"
            id="faq-header-7"
          >
            <Typography variant="subtitle1">
              Betaling en voorwaarden voor Dashbook
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginBottom: "1rem", color: "#D0AF88" }}>
              Betalen
            </Typography>
            <ul>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Voor betaalde pakketten kun je betalen via eenmalige incasso
                  of automatische incasso. Wij houden de wettelijke termijn van
                  14 dagen aan.
                </Typography>
              </li>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  De abonnementskosten worden maandelijks in rekening gebracht.
                </Typography>
              </li>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Alle genoemde prijzen zijn exclusief btw.
                </Typography>
              </li>
            </ul>
            <Typography sx={{ marginBottom: "1rem", color: "#D0AF88" }}>
              Voorwaarden
            </Typography>
            <ul>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Er zijn geen ingewikkelde contracten.
                </Typography>
              </li>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Je kunt op elk moment opzeggen, zonder opzegtermijn.
                </Typography>
              </li>
            </ul>
            <Typography sx={{ marginBottom: "1rem", color: "#D0AF88" }}>
              Meer informatie
            </Typography>
            <ul>
              <li>
                <Typography sx={{ marginBottom: "1rem" }}>
                  Bekijk onze website voor meer informatie over betaling en
                  voorwaarden.
                </Typography>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default FrequentlyAskedQuestions;
