import { Button, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextInput from "../../../../components/form/TextInput";
import { useForm } from "react-hook-form";
import CheckIcon from "@mui/icons-material/Check";
import AppsAndDeals from "../../../dashboard/AppsAndDeals";
import { useNavigate } from "react-router-dom";

// import { yupResolver } from "@hookform/resolvers/yup";

function StorePage() {
  const navigate = useNavigate();
  const { control } = useForm({
    // defaultValues: defaultValues,
    mode: "onBlur",
    // resolver: yupResolver(validationSchema),
  });

  return (
    <div style={{ padding: "2rem" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Partners van Dashbook
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Zelfstandig ondernemer? Houd alle ballen in de lucht met de Dashbook
          Store! Als zelfstandig ondernemer weet je als geen ander: er komt veel
          kijken bij het runnen van je eigen bedrijf. Van marketing tot
          administratie, en van sales tot productontwikkeling: je bent
          verantwoordelijk voor het hele plaatje.
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={()=>navigate('/dashboard')}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h3" className="titleUponDivider" gutterBottom>
            Hulp is nabij!
          </Typography>
        </div>
      </div>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingBottom: "2rem" }}
      >
        <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
          <div className="left-div card">
            <Typography variant="subtitle1" gutterBottom>
              In de Dashbook Store vind je alles wat je nodig hebt om je taken
              efficiénter en effectiever te realiseren. We bieden een breed
              scala aan producten en diensten van onze partners, die net als Wij
              alteen het atlerbeste voor jou als zelfstandig ondernemer willen.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>Ons assortiment:</strong>
            </Typography>
            <ul>
              <li>
                <strong>Marketingtoots: </strong>Vergroot je online
                zichtbaarheid, trek nieuwe klanten aan en boost je conversie met
                professionele marketingtools.
              </li>
              <li>
                <strong>Verzekeringen: </strong>In de store vind je diverse
                verzekeringen die speciaal zijn afgestemd op de behoeften van
                zelfstandig ondememers
              </li>
              <li>
                <strong>Tidsbesparende diensten: </strong>Besteed je kostbare
                tijd aan waar je écht goed in bent en outsource taken zoals
                boekhouding Of webdesign.
              </li>
              <li>
                <strong>Trainingen en workshops: </strong>Ontwikket je
                vaardigheden en blijf op de hoogte van de laatste trends met
                inspirerende trainingen en workshops.
              </li>
            </ul>
            <Typography variant="h6" gutterBottom>
              <strong>En nog veel meer!</strong>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              We voegen voortdurend nieuwe producten en diensten toe aan ons
              assortiment, dus houd de in de gaten voor de laatste updates.
            </Typography>
            <Typography variant="h6" gutterBottom>
              <strong>Waarom kiezen voor de Dashbook Store?</strong>
            </Typography>
            <ul>
              <li>
                <strong>Aileen het beste: </strong>We werken samen met
                betrouwbare partners die bewezen kwaliteit leve ren.
              </li>
              <li>
                <strong>Eenvoudig te gebruiken: </strong>Alle producten en
                diensten Zijn gebruiksvriendelijk en gericht op de behoeften van
                zelfstandig ondernemers.
              </li>
              <li>
                <strong>Flexibel: </strong>Kies de producten en diensten die je
                nodig hebt, wanneer je ze nodig hebt.
              </li>
              <li>
                <strong>Voordelig: </strong>Profiteer van scherpe prijzen en
                speciate aanbiedingen.
              </li>
            </ul>
            <Typography variant="h6" gutterBottom>
              <strong>
                Bezoek de Store vandaag nog en ontdek hoe we je kunnen helpen om
                je bedrijf naar een hoger niveau te tillen!
              </strong>
            </Typography>
            <div className="flex">
              <Button
                variant="contained"
                style={{ backgroundColor: "#4A88EA" }}
                onClick={() => navigate("/login")}
              >
                Bekijk onze Store <ArrowForwardIcon />
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "#6ED1D8" }}
                onClick={() => navigate("/login")}
              >
                meld je direct aan <ArrowForwardIcon />
              </Button>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4.5}
          lg={4.5}
          sm={12}
          sx={{ alignSelf: "center" }}
        >
          <div className="right-div imageCard">
            <img
              src={"./Professional/flexable.png"}
              alt="Dashbook Logo"
              width={"80%"}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid container xs={12} md={12} lg={12} sm={12}>
          <div
            className="left-div card"
            xs={12}
            md={12}
            lg={12}
            sm={12}
            style={{ width: "100%" }}
          >
            <div className="flex">
              <Grid container>
                <Grid container xs={12} md={6} lg={6} sm={12}>
                  <Typography variant="h5" gutterBottom>
                    Blijf op de hoogte van onze ontwikkelingen
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Schrijf je direct in en ontvang een maandelijkse update, vol
                    inspiratie, innovatie en uitdagende opdrachten.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={6} lg={6} sm={12}>
                      <TextInput
                        name="firstname"
                        placeholder="Voornaam"
                        control={control}
                      ></TextInput>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInput
                        name="infix"
                        placeholder="Tussenvoegsel"
                        control={control}
                      ></TextInput>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInput
                        name="lastname"
                        placeholder="Achternaam"
                        control={control}
                      ></TextInput>
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        name="email"
                        placeholder="E-mailadres"
                        control={control}
                      ></TextInput>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        // width: "100%",
                        marginTop: "6px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="info"
                        style={{ width: "80%" }}
                      >
                        Aanmelden &nbsp; <ArrowForwardIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sm={12}>
                  <ul style={{ listStyle: "none" }}>
                    <li style={{ display: "flex" }}>
                      <CheckIcon /> &nbsp;&nbsp;
                      <Typography variant="h6">
                        Ontvang 1x per maand onze Dashbook update
                      </Typography>
                    </li>
                    <li style={{ display: "flex" }}>
                      <CheckIcon /> &nbsp;&nbsp;
                      <Typography variant="h6">
                        Direct op de hoogte van de allernieuwste opdrachten{" "}
                      </Typography>
                    </li>
                    <li style={{ display: "flex" }}>
                      <CheckIcon /> &nbsp;&nbsp;
                      <Typography variant="h6">
                        Boordevol inspiratie, innovatie, tips & tricks{" "}
                      </Typography>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h3" gutterBottom>
            De partners van Dashbook
          </Typography>
        </div>
        <Typography variant="h5" gutterBottom>
          Alvast een aantal partners uit onze greep
        </Typography>
        <Grid item xs={12} sx={12} md={12} lg={8} xl={10} container spacing={2}>
          <AppsAndDeals />
        </Grid>
      </div>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Interesse gewekt?
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button variant="contained" sx={{ color: "white" }}>
            Bekijk onze store
          </Button>
        </div>
      </div>
    </div>
  );
}

export default StorePage;
