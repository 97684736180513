import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import TextareaAutoSize from "../../../../components/form/TextareaAutoSize";
import TextInput from "../../../../components/form/TextInput";
import { highlightField } from "../../../../utils/Commons";
import API from "../../../../config/axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();
  const [inputErrors, setInputErrors] = useState({});
  const { t } = useTranslation();
  const mailController = useRef(new AbortController());

  const contactUsValidationSchema = yup.object().shape({
    name: yup.string().required(t("customers.name_required")),
    message: yup.string(),
    email: yup
      .string()
      .email(t("loginPage.emailInvalid"))
      .required(t("loginPage.emailrequired")),
    subject: yup.string().required(t("customers.customerRequired")),
  });

  const { handleSubmit, control, reset } = useForm({
    // defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(contactUsValidationSchema),
  });

  const onError = (errors) => {
    try {
      if ("message" in errors) {
        console.log({ message: errors.message.message });
        setInputErrors({ message: errors.message.message });
      }
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.log(error, "check");
      console.warn("Field cannot be highlighted");
    }
  };

  const contactUs = (formData) => {
    API.post(`admin/blogs/addContact`, formData, {
      signal: mailController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          toast.success("Contact Us Form Submitted");
        } else {
          toast.error("Something Went Wrong At Sending contactUs Form Process");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something Went Wrong At Sending contactUs Form Process");
        }
      })
      .finally(() => {
        reset();
        // setIsLoading(false);
      });
  };

  return (
    <div style={{ padding: "2rem" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Contact
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Heb je vragen of opmerkingen? Laat het ons weten!
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Stuur ons gerust een bericht via onze website
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button variant="contained" sx={{ color: "white" }} onClick={()=>navigate('/dashboard')}>
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "1.5rem" }}>
        <form autoComplete="off" onSubmit={handleSubmit(contactUs, onError)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <img
                alt=""
                style={{ display: "block" }}
                src="./Professional/ContactImage.png"
                width={"60%"}
                height={"auto"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12}>
                <TextInput
                  style={{ borderRadius: "5px" }}
                  name="name"
                  type="text"
                  label={"Naam:"}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.name || null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  style={{ borderRadius: "5px" }}
                  name="email"
                  type="email"
                  label={"E-mailadres:"}
                  control={control}
                  customError={inputErrors.email || null}
                  autoFocus={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  style={{ borderRadius: "5px" }}
                  name="subject"
                  type="text"
                  label={"Onderwerp:"}
                  control={control}
                  customError={inputErrors.subject || null}
                  autoFocus={true}
                />
              </Grid>

              <Grid item xs={12}>
                <TextareaAutoSize
                  label={"Je bericht:"}
                  type="text"
                  name="message"
                  rows={10}
                  control={control}
                  customError={inputErrors.message || null}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginTop: "1rem" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "green", color: "white" }}
                    sx={{ bgcolor: "green", color: "white" }}
                  >
                    Bericht versturen
                    <img
                      alt=""
                      src={"./nextWhite.png"}
                      style={{ marginLeft: "10px" }}
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default Contact;
