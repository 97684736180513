import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SellReminders() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Herinneringen en aanmaningen: zorg dat je facturen betaald worden
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Wijs je klanten vriendelijk op openstaande betalingen en maak het hen
          gemakkelijk om direct te betalen met Dashbook.
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Met de standaardteksten beschikbaar in de tool kun je eenvoudig
          betalingsherinneringen versturen. Dit stelt je in staat om op een
          respectvolle wijze je klanten te attenderen op eventuele
          betalingsachterstanden, terwijl de betaallink direct toegankelijk is
          voor een snelle afwikkeling van openstaande bedragen.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Herinneringen en aanmaningen met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Herinneringen en aanmaningen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Herinneringen en aanmaningen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Herinneringen en aanmaningen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiënt herinneringen sturen met Dashbook:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Snel en Direct: </strong>
                Stuur direct betalingsherinneringen, aanmaningen en
                ingebrekestellingen per e-mail. De knop past automatisch aan als
                een factuur al is verstuurd maar nog niet is betaald.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Standaardteksten:</strong>
                Gebruik eenvoudig aanpasbare standaardteksten voor een correcte
                en professionele benadering bij het versturen van herinneringen.
                Bespaar tijd en zorg voor consistente communicatie.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Direct Betalen:</strong>
                Voeg indien gewenst een betaallink toe aan de herinnering, zodat
                je klant de openstaande factuur direct met iDEAL of andere
                betaalmethoden kan voldoen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Historie van Herinneringen:</strong>
                Houd overzicht met het systeem dat bijhoudt hoeveel
                herinneringen je hebt verstuurd en welke. Hierdoor weet je
                wanneer het tijd is voor vervolgstappen zoals een tweede
                herinnering, aanmaning of ingebrekestelling.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Betaalstatus Overzicht:</strong>
                Bekijk in één oogopslag de betaalstatus van je facturen. Zo zie
                je direct welke facturen openstaan, te laat zijn en of het tijd
                is om een herinnering te sturen.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellReminders;
