import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../Functies/TryFourteenDays";
import { useNavigate } from "react-router-dom";

function Security() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div style={{ padding: "2rem" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Security
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Veiligheid staat voorop bij Dashbook
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Bij Dashbook nemen we de veiligheid van je gegevens uiterst serieus.
          We implementeren uitgebreide maatregelen om ervoor te zorgen dat je
          gegevens beschermd zijn tegen ongeautoriseerde toegang, wijzigingen of
          verwijdering.
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        style={{
          padding: "2rem",
          paddingBottom: "0px",
          paddingLeft: "10pem !important",
        }}
      >
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor relatiebeheer met Dashbook
          </Typography>
        </div>
        <div
          className="buttonBox"
          style={{
            padding: "1rem 3rem",
            paddingTop: "0rem !important",
            overflowX: "scroll",
          }}
        >
          <Button
            variant="contained"
            className={`buttonDesign`}
            onClick={() => scrollToTarget(1)}
          >
            Privacy
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(2)}
          >
            Beschikbaarheid
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(3)}
          >
            Continuïteit
          </Button>
          <Button
            variant="contained"
            className={`buttonDesign `}
            onClick={() => scrollToTarget(4)}
          >
            Veiligheid
          </Button>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-1"
        >
          <Divider textAlign="left" className="divider">
            {"Privacy".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="left-div card">
                <Typography variant="h4" gutterBottom>
                  Privacy
                </Typography>

                <Typography variant="subtitle1" gutterBottom>
                  Je gegevens zijn veilig bij ons
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook neemt de privacy van je gegevens zeer serieus. We
                  verwerken je gegevens alleen voor de doeleinden waarvoor je ze
                  hebt verstrekt. We delen je gegevens nooit met derden zonder
                  je toestemming vooraf.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  We begrijpen dat je je privacy belangrijk vindt. We doen er
                  alles aan om je privacy te beschermen.
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="right-div imageCard">
                <img
                  src={"./Professional/join_the_best.png"}
                  alt="Dashbook Logo"
                  height={"350px"}
                  style={{
                    margin: "auto 0px",
                    display: "inline-block",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-2"
        >
          <Divider textAlign="right" className="divider">
            {"Beschikbaarheid".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="up-div imageCard"></div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="down-div card">
                <Typography variant="h4" gutterBottom>
                  Beschikbaarheid
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Jouw administratie is altijd bereikbaar
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook neemt de beschikbaarheid van onze systemen zeer
                  serieus. We monitoren onze systemen 24/7 en werken direct aan
                  een oplossing als er problemen optreden.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Met Dashbook kun je er dus op vertrouwen dat je administratie
                  altijd bereikbaar is.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-3"
        >
          <Divider textAlign="left" className="divider">
            {"Continuïteit".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="left-div card">
                <Typography variant="h4" gutterBottom>
                  Continuïteit
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Jouw administratie is altijd up-to-date
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook neemt de continuïteit van onze systemen zeer serieus.
                  We hebben twee geografisch gescheiden datacenters in
                  Nederland. Mocht er één datacenter offline gaan, schakelen wij
                  automatisch over naar de ander. Dit wordt dagelijks getest.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Mochten beide datacenters offline gaan, beschikken wij ook nog
                  over beveiligde off-site back-ups. Deze back-ups worden
                  dagelijks gemaakt en zijn altijd beschikbaar.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Al onze leveranciers hebben ISO 27001 certificering en voldoen
                  daarmee aan onze eisen. Onze payment provider is PCI-DSS
                  compliant.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dit betekent dat je altijd zeker kunt zijn dat je
                  administratie up-to-date is, ook als er zich onverwachte
                  problemen voordoen.
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="right-div imageCard"></div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "3rem" }}
          className="section-4"
        >
          <Divider textAlign="right" className="divider">
            {"Veiligheid".toUpperCase()}
          </Divider>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="up-div imageCard"></div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sm={12}
              sx={{ alignSelf: "center" }}
            >
              <div className="down-div card">
                <Typography variant="h4" gutterBottom>
                  Veiligheid
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Jouw gegevens zijn veilig bij ons
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Dashbook neemt de veiligheid van je gegevens zeer serieus. We
                  gebruiken geavanceerde encryptie om je gegevens te beschermen.
                  We bewaren je gegevens in zwaarbeveiligde databunkers die
                  enkel voor specifieke personen toegankelijk zijn.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Verouderde software die niet aan onze strenge veiligheidseisen
                  voldoet, wordt niet ondersteund. Dit betekent dat je altijd
                  zeker kunt zijn dat je gegevens veilig zijn.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default Security;
