import React from "react";
import FunctionCommon from "../Functies/FunctionCommon";

function SupportHome() {
  return (
    <div>
      <FunctionCommon />
    </div>
  );
}

export default SupportHome;
