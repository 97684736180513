import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function LinkApp() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Koppelingen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Boekhouding onderweg met Dashbook
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Als ondernemer of zzp'er wil je natuurlijk altijd bij je boekhouding
          kunnen. Met de Dashbook app heb je je volledige boekhouding altijd bij
          de hand op je iPhone of Android smartphone.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="flex"
        style={{ justifyContent: "space-around", gap: "1rem" }}
      >
        <Typography variant="h4" className="titleUponDivider" gutterBottom>
          De mogelijkheden voor App met Dashbook
        </Typography>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Voordelen App
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Android app
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Eenvoudig je bonnen uploaden
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          iPhone app
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(5)}
        >
          Volledige boekhouding
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Voordelen App".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Voordelen App
              </Typography>
              <Typography variant="h6" gutterBottom>
                <strong>Voordelen:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Volledige boekhouding:</strong> Beheer moeiteloos je
                complete boekhouding, van facturen tot bonnetjes en
                btw-aangiftes, alles binnen handbereik met de Dashbook app.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Onderweg te gebruiken: </strong>
                Jouw boekhouding reist met je mee. Met de Dashbook app op je
                iPhone of Android smartphone heb je altijd en overal toegang tot
                je financiën.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Eenvoudig te gebruiken:</strong> Maak kennis met
                gebruiksgemak. De intuïtieve Dashbook app stelt je in staat om
                snel en gemakkelijk aan de slag te gaan, zodat je meer tijd hebt
                voor wat er echt toe doet
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            lg={5.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="right-div imageCard">
              <img
                src={"./Professional/LinksApp.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Android app".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                ADD
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Eenvoudig je bonnen uploaden".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Eenvoudig je bonnen uploaden
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Scan je bonnetjes en facturen met de Dashbook-app op je
                telefoon. Gemakkelijk te gebruiken en direct toegevoegd aan je
                administratie voor de Belastingdienst. Alles wordt op één
                overzichtelijke plek bewaard zodat je nooit meer je bonnen hoeft
                te zoeken.
              </Typography>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Meer informatie over Bonnen inbox
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"iPhone app".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                ADD
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-5"
      >
        <Divider textAlign="left" className="divider">
          {"Volledige boekhouding".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Volledige boekhouding
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ontdek de Dashbook app en ervaar het verschil:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Complete administratie:</strong> Dashbook gaat verder
                dan alleen bonnetjes. Voer je volledige administratie, van
                offertes tot facturen, schrijf eenvoudig je uren op, registreer
                je ritten, verwerk banktransacties en stuur zelfs moeiteloos je
                btw-aangiftes.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Meer dan de basis:</strong> In tegenstelling tot de
                meeste apps, biedt Dashbook een uitgebreide reeks
                functionaliteiten om aan al je zakelijke behoeften te voldoen.
                We gaan verder dan het standaard aanbod en bieden een
                alles-in-één oplossing.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemakkelijk en veelzijdig:</strong> Ervaar het gemak van
                een app die alles kan. Dashbook is ontworpen voor gebruiksgemak
                en biedt een veelzijdige toolkit waarmee je elke aspect van je
                administratie kunt beheren.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default LinkApp;
