import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Typography,
  Stack,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TablePagination,
  Box,
  IconButton,
  TableSortLabel,
  Grid,
  Collapse,
  Tooltip,
  Chip,
} from "@mui/material";
import AddCircleRounded from "@mui/icons-material/AddCircleRounded";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import DeleteRounded from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { visuallyHidden } from "@mui/utils";
import PaginationActions from "../../components/table/PaginationActions";
import API from "../../config/axios";
import { toast } from "react-hot-toast";
import debounce from "lodash.debounce";
import styles from "../../css/common.module.css";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import dayjs from "dayjs";

import { useForm } from "react-hook-form";
import MobileDatePickerInput from "../../components/form/MobileDatePickerInput";
import AutocompleteInput from "../../components/form/AutocompleteInput";
import { getStatusColor } from "../../utils/Commons";

export default function OffersList() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [search, setSearch] = useState("");
  const abortController = useRef(new AbortController());
  const customerAbortController = useRef(new AbortController());

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteCostInvoiceId, setDeleteCostInvoiceId] = useState(null);
  const [isDeletingCostInvoice, setIsDeletingCostInvoice] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [showFilter, SetShowFilter] = useState(true);

  const defaultValues = {
    startDate: null,
    endDate: null,
    customerId: null,
    subcategoryId: null,
    sent: null,
  };

  const { control, watch, getValues } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    // resolver: yupResolver(validationSchema),
  });

  const headCells = [
    {
      id: "Status",
      numeric: false,
      disablePadding: true,
      label: t("offers.list_tbl.headers.status"),
      sortable: false,
      width: "100px",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("offers.list_tbl.headers.date"),
      sortable: true,
      width: "100px",
    },
    {
      id: "offers No",
      numeric: false,
      disablePadding: true,
      label: t("offers.list_tbl.headers.offers_number"),
      sortable: false,
      width: "100px",
    },
    {
      id: "customer",
      numeric: false,
      disablePadding: true,
      label: t("offers.list_tbl.headers.customer"),
      sortable: false,
      width: "200px",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: t("offers.list_tbl.headers.notes"),
      sortable: false,
      width: "200px",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: true,
      label: t("offers.list_tbl.headers.amount"),
      sortable: false,
      width: "100px",
    },

    {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: t("offers.list_tbl.headers.actions"),
      sortable: false,
      width: "100px",
    },
  ];

  const statusList = [
    {
      name: `${t("offers.filter_options.sent")}`,
      value: "sent",
    },
    {
      name: `${t("offers.filter_options.not_sent")}`,
      value: "not_sent",
    },
    {
      name: `${t("offers.filter_options.expired")}`,
      value: "expired",
    },
    {
      name: `${t("offers.filter_options.offer_accepted")}`,
      value: "offer_accepted",
    },
    {
      name: `${t("offers.filter_options.invoiced")}`,
      value: "invoiced",
    },
  ];

  const showNoData = !isLoading && rows.length === 0 ? true : false;
  const skipRows = page * rowsPerPage;
  const totalColumns = headCells.length + 1;

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getCostInvoiceList = () => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    setIsLoading(true);
    const { startDate, endDate, customerId, status } = getValues();

    API.get("users/offers", {
      params: {
        pagination: true,
        page: page + 1,
        rowsPerPage: rowsPerPage,
        sortBy: orderBy,
        descending: order === "asc" ? false : true,
        filter: search,
        startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
        customerId: customerId?.id || null,
        status: status?.value || null,
      },
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setRows(resData.data.results);
          setTotalRows(resData.data.totalResults || 0);
          setPage(
            resData.data.page > resData.data.totalPages
              ? 0
              : resData.data.page - 1,
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCustomersList = () => {
    customerAbortController.current.abort();
    customerAbortController.current = new AbortController();

    // Replace this with your API call
    API.get(`users/customers?onlyBasicDetails=true`, {
      signal: customerAbortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setCustomers(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {});
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const debounceSearchInput = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    getCostInvoiceList();

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, [page, order, orderBy, rowsPerPage]);

  useEffect(() => {
    getCustomersList();

    return () => {
      customerAbortController.current.abort();
    };
  }, []);

  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const customerId = watch("customerId");
  const status = watch("status");

  useEffect(() => {
    getCostInvoiceList();
    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, [search, startDate, endDate, customerId, status]);

  const showDeleteConfirmationDialog = (projectId) => {
    setDeleteCostInvoiceId(projectId);
    setShowDeleteDialog(true);
  };

  const closeDeleteConfirmationDialog = () => {
    setShowDeleteDialog(false);
    setDeleteCostInvoiceId(null);
  };

  const deleteInvoice = () => {
    setIsDeletingCostInvoice(true);

    const requestStartTime = Date.now();

    API.delete(`users/offers/${deleteCostInvoiceId}`)
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          toast.success(t("offers.invoice_deleted_successfully"));
        } else {
          if (resData?.error?.message) {
            toast.error(resData?.error?.message);
          } else {
            toast.error("Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        closeDeleteConfirmationDialog();
        getCostInvoiceList();

        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsDeletingCostInvoice(false);
        } else {
          setTimeout(() => {
            setIsDeletingCostInvoice(false);
          }, 300);
        }
      });
  };

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ mb: 2 }}
        justifyContent="flex-start"
        alignItems={{ xs: "start", sm: "center" }}
        spacing={1}
      >
        <Typography component="h1" sx={{ fontSize: "20px", flexGrow: 1 }}>
          {t("offers.title")}
        </Typography>

        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Link to="/offers/add">
            <Button
              variant="contained"
              startIcon={<AddCircleRounded />}
              disableElevation
              style={{
                height: "100%",
                backgroundColor: "#00c078",
                color: "#ffffff",
              }}
            >
              {t("offers.add_invoice_btn_lbl")}
            </Button>
          </Link>
        </Stack>
      </Stack>
      <Stack mb={4}>
        <Grid container rowGap={2}>
          <Collapse sx={{ width: "100%", marginTop: 1 }} in={showFilter}>
            <Grid container item xs={12} sm={9} spacing={3} mb={2}>
              <Grid container item xs={12} sm={6} spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MobileDatePickerInput
                    name="startDate"
                    label={t("offers.fields.startDate")}
                    control={control}
                    autoFocus={true}
                    format={"DD/MM/YYYY"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MobileDatePickerInput
                    name="endDate"
                    label={t("offers.fields.endDate")}
                    control={control}
                    autoFocus={true}
                    format={"DD/MM/YYYY"}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={3}>
                <AutocompleteInput
                  name="status"
                  type="text"
                  label={t("offers.fields.status")}
                  control={control}
                  options={statusList}
                  getOptionLabel={(option) => option.name}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={9} spacing={3}>
              <Grid item xs={12} sm={6}>
                <AutocompleteInput
                  name="customerId"
                  type="text"
                  label={t("offers.fields.customerId")}
                  control={control}
                  options={customers}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>

          <Grid container item xs={12} sm={9} spacing={3}>
            <Grid item xs={12} sm={9}>
              <TextField
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ bgcolor: "white" }}
                placeholder={t("search")}
                onChange={debounceSearchInput}
              />
              <Button
                color="info"
                onClick={() => SetShowFilter((prev) => !prev)}
              >
                {showFilter
                  ? `${t("offers.hide_filter")}`
                  : `${t("offers.show_filter")}`}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
          </Grid>
        </Grid>
      </Stack>
      <Paper
        sx={{ backgroundColor: "primaryBg.light" }}
        className={styles.custom_container}
        elevation={2}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ borderRadius: "inherit" }}
        >
          <Table
            aria-label="custom pagination table"
            className={"custom-table br-0"}
            sx={{ backgroundColor: "primaryBg.light", minWidth: 600 }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "80px",
                    minWidth: "80px",
                    textAlign: "center",
                  }}
                >
                  {t("offers.list_tbl.headers.no")}
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ minWidth: headCell.width || "100px" }}
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell style={{ textAlign: "center" }}>
                    {skipRows + (index + 1)}
                  </TableCell>
                  <TableCell scope="row">
                    <Chip
                      sx={{
                        width: 140,
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                      variant="outlined"
                      label={t(`offers.status.${row?.status}`)}
                      color={getStatusColor(row.status)}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    {dayjs(row?.date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell scope="row">{row?.offerNoFormatted}</TableCell>
                  <TableCell scope="row">{row?.customer?.name}</TableCell>
                  <TableCell scope="row">{row?.notes}</TableCell>

                  <TableCell scope="row">
                    {row?.total?.toLocaleString("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </TableCell>

                  <TableCell style={{ width: "100px" }} align="left">
                    <Stack direction="row" spacing={1}>
                      <Link
                        className="link"
                        color="inherit"
                        to={`/offers/edit/${row.id}`}
                      >
                        <Tooltip title="Wijzigen" arrow>
                          <IconButton size="small" aria-label="edit">
                            <EditOutlinedIcon
                              className="edit_icon"
                              fontSize="medium"
                            />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      <Tooltip title="Verwijderen" arrow>
                        <IconButton
                          variant="outlined"
                          color="error"
                          size="small"
                          sx={{
                            border: "2px solid currentColor",
                            width: "30px",
                            height: "30px",
                          }}
                          onClick={() => showDeleteConfirmationDialog(row.id)}
                        >
                          <DeleteRounded fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}

              {showNoData && (
                <TableRow>
                  <TableCell colSpan={totalColumns}>
                    <Typography
                      sx={{ width: "100%", textAlign: "center", color: "grey" }}
                      variant="body2"
                    >
                      {t("noDataAvailable")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={styles.custom_tbl_pagination}>
          <TablePagination
            as="div"
            rowsPerPageOptions={[
              1,
              5,
              10,
              25,
              50,
              { label: "All", value: 99999999 },
            ]}
            labelRowsPerPage={t("rows_per_page")}
            colSpan={totalColumns}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            ActionsComponent={PaginationActions}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          {/* <Box mt={2} mb={2} mr={4}>
            <ExportToExcel
              headerName={{
                name: t("projects.list_tbl.headers.name"),
                "customer.name": t("projects.list_tbl.headers.customerName"),
              }}
              Data={rows}
              fileName={t("projects.title")}
            />
          </Box> */}
        </Stack>
      </Paper>
      <DeleteConfirmationDialog
        open={showDeleteDialog}
        title={t("offers.delete_offers")}
        description={t("offers.delete_selected_offers")}
        onClose={closeDeleteConfirmationDialog}
        onDelete={deleteInvoice}
        isDeletingData={isDeletingCostInvoice}
      />
    </>
  );
}
