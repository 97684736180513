import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Stack,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import API from "../../config/axios";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setIsProcessing } from "../../features/common/commonSlice";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import styles from "./AddVatPercentage.module.css";
import TextInput from "../../components/form/TextInput";
import { useForm } from "react-hook-form";
import AutocompleteInput from "../../components/form/AutocompleteInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomBtn from "../../components/CustomBtn";
import { highlightField } from "../../utils/Commons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  description: "",
  mainCategory: null,
  percentage: "",
  subcategory: null,
};

const validationSchema = yup.object().shape({
  description: yup.string().required("description is required"),
  mainCategory: yup.object().required("category is required"),
  percentage: yup.string().required("percentage is required"),
});

export default function AddVatPercentage({
  showDialog,
  setShowDialog,
  getVarCategoryList,
  urlState,
}) {
  const dispatch = useDispatch();
  const abortController = useRef(new AbortController());
  const { t } = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const questionAbortController = useRef(new AbortController());
  const questions = useRef([]);
  const startQuestionId = useRef(null);
  const [vatCategoriesData, setVatCategoriesData] = useState([]);
  const [vatSubCategoriesData, setVatSubCategoriesData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [changeModel, setChangemodel] = useState(false);

  const [inputErrors, setInputErrors] = useState({});
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const getVatPercentageQuestions = () => {
    // Cancel the previous request
    questionAbortController.current.abort();
    questionAbortController.current = new AbortController();

    setIsLoading(true);

    API.get(`users/vat_percentages/questions`, {
      signal: questionAbortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const data = resData.data;
          startQuestionId.current = data.startQuestionId;
          questions.current = data.questions;

          if (data.questions.length) {
            const firstQuestion = data.questions.find((question) => {
              return question.id === data.startQuestionId;
            });

            if (firstQuestion) {
              setCurrentQuestion(firstQuestion);
            }
          }
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setIsProcessing(false));
      });
  };

  useEffect(() => {
    // display loader till data do not load
    dispatch(setIsProcessing(true));

    getVatPercentageQuestions();

    return () => {
      questionAbortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  const resetAddDialog = () => {
    const firstQuestion = questions.current.find((question) => {
      return question.id === startQuestionId.current;
    });

    if (firstQuestion) {
      setCurrentQuestion(firstQuestion);
    }
  };

  const closeAddDialog = () => {
    setShowDialog(false);
    setChangemodel(false);
  };

  const handleBakeQuestion = (id) => {
    if (id) {
      const question = questions.current.find((question) => {
        return question.id === id;
      });

      if (question) {
        setCurrentQuestion(question);
      }
    }
  };
  const handleOptionClick = (option) => {
    // set next question if provided
    if (option.nextQuestionId) {
      const question = questions.current.find((question) => {
        return question.id === option.nextQuestionId;
      });

      if (question) {
        setCurrentQuestion(question);
      }
    } else {
      getsubCategoryDetails(option?.categoryId);
      setChangemodel(true);
    }
  };

  const getsubCategoryDetails = (id) => {
    setIsLoading(true);

    API.get(`users/vat_categories/vatcategory/${id}`, {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const subcategoryDetails = resData.data;
          setValue("description", subcategoryDetails?.description);
          setValue("mainCategory", subcategoryDetails);
          setValue("percentage", subcategoryDetails?.percentage);
          setVatSubCategoriesData(subcategoryDetails?.subcategories);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setIsProcessing(false));
      });
  };
  const getsubCategoryList = (id) => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    setIsLoading(true);

    API.get(`users/vat_categories`, {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const subcategoryDetails = resData.data;
          setVatCategoriesData(subcategoryDetails);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setIsProcessing(false));
      });
  };
  const onSubmit = (data) => {
    const formData = {};
    const requestStartTime = Date.now();
    for (const key in data) {
      formData[key] = data[key] || "";
    }
    formData.mainCategory = data?.mainCategory?.id;
    formData.type = data?.mainCategory?.type;
    formData.subcategory = data?.subcategory?.id;
    formData.code = data?.subcategory?.code;

    API.post("users/vat_categories", formData)
      .then((res) => {
        const resData = res.data;
        setInputErrors({});

        if (resData.status) {
          getVarCategoryList();
          setShowDialog(false);
          toast.success(
            t("vat_percentages.vat_percentages_added_successfully")
          );
          setChangemodel(false);
          if (urlState) {
            if (urlState?.url) {
              if (resData.data.type === "sales") {
                urlState.vatCategoryId = {
                  description: resData.data?.description,
                  id: resData.data?.id,
                };
              }
              navigate(`${urlState?.url}`, {
                replace: true,
                state: { urlState },
              });
            }
          }
          // navigate("/cars", { replace: true });
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
            try {
              highlightField(Object.keys(resData.error)[0]);
            } catch (error) {
              console.warn("Field cannot be highlighted");
            }
          } else {
            toast.error(resData?.error?.message || "Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };
  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };

  useEffect(() => {
    getsubCategoryList();
    // eslint-disable-next-line
  }, []);

  const mainCategory = watch("mainCategory");
  useEffect(() => {
    setValue("description", mainCategory?.description);
    setValue("percentage", mainCategory?.percentage);
    setValue(
      "subcategory",
      mainCategory?.subcategories ? mainCategory?.subcategories[0] : null
    );
    setVatSubCategoriesData(
      mainCategory?.subcategories ? mainCategory?.subcategories : []
    );
    // eslint-disable-next-line
  }, [mainCategory]);

  return (
    <div>
      <Dialog
        open={showDialog}
        maxWidth={"xs"}
        fullWidth
        sx={{ "& .MuiPaper-root": { borderRadius: "8px" } }}
      >
        {!changeModel ? (
          <>
            <DialogTitle>
              <Stack justifyContent="space-between" direction="row">
                <Typography variant="h5" noWrap>
                  {currentQuestion?.title}
                </Typography>
                <IconButton size="small" onClick={closeAddDialog}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent className={styles.dialog_content}>
              <Typography variant="body1">
                {currentQuestion?.description}
              </Typography>
            </DialogContent>
            <DialogActions
              className={styles.dialog_actions}
              style={{ justifyContent: "space-between" }}
            >
              <Stack justifyContent="flex-start">
                {currentQuestion?.id !== startQuestionId?.current && (
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("vat_percentages.back_btn_lbl")}
                  >
                    <IconButton
                      onClick={() =>
                        handleBakeQuestion(currentQuestion?.perviousQuestionId)
                      }
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
              <Stack direction="row" spacing={2}>
                {currentQuestion?.options.map((option, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    color="info"
                    disableElevation
                    onClick={() => handleOptionClick(option)}
                  >
                    {option?.btnLabel}
                  </Button>
                ))}
                {currentQuestion?.id !== startQuestionId?.current && (
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("vat_percentages.start_over_btn_lbl")}
                  >
                    <IconButton onClick={resetAddDialog}>
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </DialogActions>{" "}
          </>
        ) : (
          <>
            <DialogTitle>
              <Stack justifyContent="space-between" direction="row">
                <Typography variant="h5" noWrap>
                  {t("vat_percentages.add_vat_percentage")}
                </Typography>
                <IconButton size="small" onClick={closeAddDialog}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent className={styles.dialog_content}>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextInput
                      name="description"
                      control={control}
                      label={t("vat_percentages.fields.description")}
                      customError={inputErrors.description || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AutocompleteInput
                      name="mainCategory"
                      control={control}
                      label={t(
                        "vat_percentages.fields.VAT_declaration_category"
                      )}
                      options={vatCategoriesData}
                      customError={inputErrors.mainCategory || null}
                      getOptionLabel={(option) =>
                        option.vatReturnCategoryName || ""
                      }
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="percentage"
                      control={control}
                      label={t("vat_percentages.fields.percentage")}
                      customError={inputErrors.percentage || null}
                    />
                  </Grid>
                  {vatSubCategoriesData.length !== 0 && (
                    <Grid item xs={12}>
                      <AutocompleteInput
                        name="subcategory"
                        control={control}
                        label={t("vat_percentages.fields.Categories")}
                        options={vatSubCategoriesData}
                        customError={inputErrors.subcategory || null}
                        getOptionLabel={(option) => option.name || ""}
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions className={styles.dialog_actions}>
              <Stack direction="row" spacing={2}>
                <CustomBtn
                  variant="contained"
                  color="secondary"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                  disableRipple
                  type="submit"
                  loading={isLoading}
                  onClick={handleSubmit(onSubmit)}
                >
                  save
                </CustomBtn>
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
