import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function AdminRides() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Administratie
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Rittenregistratie: eenvoudig en overzichtelijk
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Voor ondernemers en zelfstandige ondernemers (zzp'ers) is het
          essentieel om een nauwkeurige registratie bij te houden van hun
          gereden ritten en afgelegde kilometers. Deze registratie is nodig voor
          diverse doeleinden, zoals het beheer van de eigen administratie, het
          aanvragen van reiskostenvergoeding, voldoen aan vereisten van de
          Belastingdienst, of om kilometers te kunnen factureren.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden van Ritten met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Ritten registratie
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Privé of zakelijk
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Kilometer vergoeding
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Ritten registratie".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Ritten registratie
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Voor ondernemers en zzp'ers is het essentieel om moeiteloos grip
                te houden op hun gereden ritten en kilometers. Onze
                rittenregistratie biedt hiervoor een gebruiksvriendelijke
                oplossing.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gebruiksvriendelijk: </strong>Dankzij handige functies
                zoals het kopiëren van ritten en directe afstandsberekening,
                registreer je jouw ritten snel en simpel.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Overzichtelijk: </strong>Behoud te allen tijde helder
                inzicht in je ritten, gesorteerd per klant, project en taak.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Flexibel:</strong>Leg diverse vervoersmiddelen vast,
                zodat je ook eenvoudig je privé-kilometers kunt monitoren.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Privé of zakelijk".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Privé of zakelijk
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Of je nu zakelijke kilometers aflegt met je privé-auto, fiets of
                een zakelijke auto, het is essentieel om deze kilometers te
                registreren. Dit is cruciaal voor de verrekening van reiskosten
                of kilometervergoeding en zorgt tevens voor een nauwkeurige
                kilometeradministratie.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Eenvoudige verrekening: </strong>Het registreren van
                zakelijke kilometers maakt een vlotte afhandeling van reiskosten
                of kilometervergoeding mogelijk.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Belastingdienst compliance: </strong> Een gedetailleerde
                kilometeradministratie is van belang voor de Belastingdienst.
                Door je kilometers te registreren, kun je aantonen dat je
                gerechtigd bent om autokosten fiscaal af te trekken.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Kilometer vergoeding".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Kilometer vergoeding
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als ondernemer of zzp'er kun je de kosten voor het zakelijk
                gebruik van je privé-auto vergoeden. De Belastingdienst hanteert
                hiervoor een vast tarief van € 0,21 per kilometer.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiënt: </strong>Onze rittenregistratie maakt het
                eenvoudig om zakelijke kilometers automatisch te berekenen en te
                verwerken in je administratie. Je hoeft slechts de datum, begin-
                en eindstand van de kilometerteller, het begin- en eindadres en
                het doel van de rit in te voeren.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Betrouwbaar: </strong> De door de Belastingdienst
                vastgestelde vergoeding van € 0,21 per kilometer waarborgt de
                juistheid van de toegekende bedragen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Kostenbesparend:</strong>Door zakelijke kilometers te
                vergoeden, kun je fiscaal profiteren door autokosten af te
                trekken. Dit resulteert in aanzienlijke besparingen.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default AdminRides;
