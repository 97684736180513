import { Button, ButtonGroup, Stack } from "@mui/material";
import React from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Switch = ({ name, control, setValue, onChange, onChangeCallback }) => {
  const { t } = useTranslation();
  const selectedMode = useWatch({
    control: control,
    name,
  });

  return (
    <Stack direction="row" alignItems="center" mt={2}>
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          onChange={onChange}
          onClick={() => {
            setValue(name, false);
            onChangeCallback();
          }}
          variant="contained"
          size="small"
          sx={{
            color: selectedMode === true ? "" : "white",
          }}
          color={selectedMode === false ? "success" : "inherit"}
        >
          {t("invoices.exclusive")}
        </Button>
        <Button
          onChange={onChange}
          onClick={() => {
            setValue(name, true);
            onChangeCallback();
          }}
          variant="contained"
          size="small"
          sx={{
            color: selectedMode === true ? "white" : "",
          }}
          color={selectedMode === true ? "success" : "inherit"}
        >
          {t("invoices.inclusive")}
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default Switch;
