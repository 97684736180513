import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { useSelector } from "react-redux";

export default function Loading() {
  const isProcessing = useSelector((state) => state.common.isProcessing);

  return (
    <Backdrop
      sx={{
        bgcolor: "rgba(255, 255, 255, 0.1)",
        zIndex: 1101
      }}
      open={!!isProcessing}
    >
      <CircularProgress color="info" size="30px" sx={{ ml: { xs: 0, sm: 0, md: "260px" } }}/>
    </Backdrop>
  );
}
