import React from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Button, Paper, Stack } from "@mui/material";
import invoiceStyle from "./invoice.module.css";

import { useTranslation } from "react-i18next";
import AutocompleteInput from "../../components/form/AutocompleteInput";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useRef } from "react";
import api from "../../config/axios";
import toast from "react-hot-toast";
import { useEffect } from "react";

const CustomPaper = (props) => {
  return (
    <Paper {...props} classes={{ root: invoiceStyle.MuiAutocomplete_popper }} />
  );
};
const CategoryDialogBox = ({
  isOpen,
  onClose,
  index,
  inputErrors,
  control,
  getValues,
  setValue,
}) => {
  const { t, i18n } = useTranslation();
  const [subcategories, setSubcategories] = useState([]);
  const navigate = useNavigate();
  const subcategoryAbortController = useRef(new AbortController());

  const addNewCategoryOption = () => {
    const {
      date,
      customerId,
      invoiceNo,
      totalIncludingVat,
      notes,
      documents,
      items,
    } = getValues();

    navigate("/subcategories/ownAdd", {
      replace: true,
      state: {
        date: date.toISOString(),
        customerId: customerId,
        invoiceNo: invoiceNo,
        totalIncludingVat: totalIncludingVat,
        items: items,
        notes: notes,
        documents: documents,
        url: window.location.pathname,
      },
    });
  };

  const getAllCategoryList = () => {
    getSubcategories("all");
  };

  const getSubcategories = (type) => {
    // Cancel the previous request
    subcategoryAbortController.current.abort();
    subcategoryAbortController.current = new AbortController();

    api
      .get(`users/subcategories`, {
        signal: subcategoryAbortController.current.signal,
        params: {
          categoryList: type,
        },
      })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          let categoryList = resData.data;
          const result = [];
          categoryList.forEach((item) => {
            const mainCategoryId = item.subcategoryDetails
              ? item.subcategoryDetails?.mainCategoryDetails?._id
              : item.mainCategoryDetails?._id;
            const detail = item.subcategoryDetails
              ? item.subcategoryDetails?.mainCategoryDetails
              : item.mainCategoryDetails;
            const subcategory = {
              main_category: mainCategoryId,
              id: item?.id,
              name: item.subcategoryId
                ? item.subcategoryDetails?.[`name_${i18n.language}`]
                : item?.[`name_${i18n.language}`],
              code: item.subcategoryId
                ? item.subcategoryDetails?.code
                : item?.code,
              type: item.subcategoryId
                ? item.subcategoryDetails?.type
                : item?.type,
            };
            const existingMainCategory = result.find(
              (category) => category.id === mainCategoryId,
            );

            if (existingMainCategory) {
              existingMainCategory.subcategories.push(subcategory);
            } else {
              const mainCategoryDetails = {
                id: mainCategoryId,
                name: detail?.[`name_${i18n.language}`],
                type: detail?.type,
              };
              result.push({
                ...mainCategoryDetails,
                subcategories: [subcategory],
              });
            }
          });

          const categories = result.flatMap((category) => [
            {
              id: category.id,
              name: category?.name,
              type: category?.type,
              isGroupLabel: true,
            },
            ...category.subcategories.map((subcategory) => ({
              id: subcategory?.id,
              name: subcategory?.name,
              code: subcategory?.code,
              main_category: subcategory?.main_category,
            })),
          ]);

          const { items } = getValues();
          if (items[index].subcategoryType === "default") {
            setValue(`items.${index}.subcategoryType`, type);
            setValue(`items.${index}.subcategoryId`, categories[1]);
          }
          setSubcategories(categories);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    getSubcategories("default");

    return () => {
      subcategoryAbortController.current.abort();
    };
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <CustomDialogBox
      title={t("invoices.dialogBox.title.subcategoryId")}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack>
        <AutocompleteInput
          name={`items.${index}.subcategoryId`}
          control={control}
          customError={inputErrors[`items.${index}.subcategoryId`] || null}
          options={[
            ...subcategories,
            {
              id: "extraButton",
              value: "",
              name: "",
            },
          ]}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          getOptionDisabled={(option) => option.isGroupLabel}
          PaperComponent={CustomPaper}
          renderOption={(props, option) => (
            <>
              <li
                {...props}
                className={
                  option.isGroupLabel
                    ? "option_group_label"
                    : "option_group_option MuiAutocomplete-option"
                }
              >
                {option.id === "extraButton" ? (
                  <div
                    className={invoiceStyle.sticky_buttons_container}
                    style={{ backgroundColor: "white" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      style={{ backgroundColor: "white" }}
                    >
                      <Button
                        color="info"
                        variant="outlined"
                        sx={{ whiteSpace: "nowrap" }}
                        fullWidth
                        onClick={(e) => {
                          e.stopPropagation();
                          getAllCategoryList();
                        }}
                      >
                        {t("costs.select_all")}
                      </Button>
                      <Button
                        color="info"
                        variant="outlined"
                        sx={{ whiteSpace: "nowrap" }}
                        fullWidth
                        onClick={(e) => {
                          e.stopPropagation();
                          addNewCategoryOption();
                        }}
                      >
                        <AddOutlinedIcon style={{ marginRight: "8px" }} />
                        {t("costs.add_new")}
                      </Button>
                    </Stack>
                  </div>
                ) : (
                  <>{option.name}</>
                )}
              </li>
            </>
          )}
        />
      </Stack>
    </CustomDialogBox>
  );
};

export default CategoryDialogBox;
