import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/nl"; // Import the Dutch locale data

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.locale("nl"); // Corrected to pass the locale string as "nl"

export default dayjs;
