import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function AdminProductService() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Administratie
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Producten beheren en factureren: eenvoudig en snel
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Als ondernemer of zzp'er krijg je vaak te maken met diverse producten,
          artikelen en diensten. Effectief beheer van deze items is essentieel
          om een snelle en moeiteloze facturatie te waarborgen.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden van Producten en Diensten met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Producten in je boekhouding
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Producten factureren
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Producten voor offertes
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Producten in je boekhouding".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Producten in je boekhouding
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als ondernemer of zzp'er is het regelmatig opstellen van
                facturen een potentieel tijdrovende taak, vooral wanneer je een
                breed scala aan producten en diensten aanbiedt.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiëntie: </strong>Door producten en diensten vooraf
                te registreren in je boekhoudpakket, kun je facturatie snel en
                moeiteloos uitvoeren. Slechts een selectie van het product of de
                dienst en het invoeren van het aantal te factureren eenheden is
                vereist, waarna de omschrijving, prijs, btw-percentage en
                totaalbedrag automatisch worden ingevuld.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemak: </strong>Het vermijdt de noodzaak om producten en
                diensten telkens opnieuw in te voeren. Je hebt constant
                overzicht over je aanbod, gegroepeerd op categorie,
                btw-percentage en prijs.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Betrouwbaarheid:</strong>Met vooraf geregistreerde
                informatie ben je verzekerd van nauwkeurige facturatie met de
                juiste prijzen en btw-percentages.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Producten factureren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Producten factureren
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als ondernemer of zzp'er is het opstellen van facturen een
                terugkerende taak, vooral bij een divers aanbod aan producten en
                diensten. Deze taak kan echter snel en moeiteloos worden
                volbracht.
              </Typography>

              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <strong>Razendsnel:</strong>Factureren wordt een snelle en
                eenvoudige handeling dankzij de mogelijkheid om opgeslagen
                producten te gebruiken. Selecteer simpelweg het product, voer
                het aantal eenheden in, en de omschrijving, prijs,
                btw-percentage en totaalbedrag worden automatisch ingevuld.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemak:</strong> Herhaaldelijk invoeren van
                productgegevens is verleden tijd. Je behoudt altijd overzicht
                over je producten, geordend per categorie, btw-percentage en
                prijs.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Betrouwbaarheid: </strong>Factureer met zekerheid,
                wetende dat de juiste prijzen en btw-percentages worden
                gehanteerd, dankzij de gebruikte opgeslagen productinformatie.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Flexibiliteit: </strong> Pas de gegevens van opgeslagen
                producten moeiteloos aan wanneer nodig, voor optimale
                flexibiliteit.
              </Typography>

              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Meer informatie over Facturatie
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Producten voor offertes".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Producten voor offertes
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als ondernemer of zzp'er is het regelmatig opstellen van
                offertes en facturen een potentieel tijdrovende taak, vooral
                wanneer je een breed scala aan producten en diensten aanbiedt.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Snelheid: </strong> Door gebruik te maken van opgeslagen
                producten en diensten, kun je offertes en facturen snel en
                moeiteloos genereren. Selecteer simpelweg het gewenste product
                of de dienst en voer het aantal te factureren eenheden in,
                waarna de omschrijving, prijs, btw-percentage en totaalbedrag
                automatisch worden ingevuld.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemak:</strong>Het vermijdt de noodzaak om producten en
                diensten herhaaldelijk in te voeren. Je behoudt constant
                overzicht over je aanbod, gegroepeerd op categorie,
                btw-percentage en prijs.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Betrouwbaarheid: </strong> Met vooraf geregistreerde
                informatie ben je verzekerd van nauwkeurige facturatie met de
                juiste prijzen en btw-percentages.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiëntie:</strong>Offertes kunnen snel en naadloos
                worden omgezet naar facturen, waardoor kostbare tijd en moeite
                worden bespaard.
              </Typography>
              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Meer informatie over Facturatie
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default AdminProductService;
