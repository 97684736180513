import React, { useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../Functies/TryFourteenDays";

function OurMission() {
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Hoe is Dashbook ontstaan?
        </Typography>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De boekhoudrevolutie: Van frustratie naar fascinatie
          </Typography>
        </div>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Een zucht van frustratie hing in de lucht. ".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Een zucht van frustratie hing in de lucht.
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Verward door ingewikkelde boekhoudsoftware, voelde je de moed je
                in de schoenen zakken. Betalen voor functies die je nooit zou
                gebruiken, eindeloos navigeren door onbegrijpelijke menu's - het
                leek een onmogelijke opgave. Terug naar verouderde Word- en
                Excel-documenten? Nee, dat kon niet de oplossing zijn. Er moest
                een betere manier zijn.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/hungAir.png"}
                alt="MarioImage"
                width="50%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"En toen ontstond de droom. ".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/dreamHappened.png"}
                alt="MarioImage"
                width="50%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              {" "}
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                En toen ontstond de droom.
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Een droom van een boekhoudprogramma dat wél zou werken.
                Eenvoudig, intuïtief, en ontworpen met de behoeften van de
                moderne ondernemer in gedachten. In samenwerking met jou, de
                gedreven ZZP'er, gingen we de uitdaging aan. We namen de beste
                elementen van bestaande programma's en voegden onze eigen unieke
                twist toe.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Het resultaat? ".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Het resultaat?
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Een boekhoudervaring die ongeëvenaard is. Neem nu het probleem
                van projectbeheer met variabele uren, zoals overuren. In de
                meeste programma's ben je beperkt tot een star budget, terwijl
                de realiteit van ZZP'ers veel complexer is. Facturen met
                verschillende tarieven voor overuren? Onmogelijk! Tot nu. Ons
                programma omarmt de flexibiliteit van jouw werk, waardoor je
                eindelijk grip krijgt op je financiën.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/result.png"}
                alt="MarioImage"
                width="50%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Maar we wilden meer dan alleen een tool voor de techneuten.".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/toolForTechies.png"}
                alt="MarioImage"
                width="50%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Maar we wilden meer dan alleen een tool voor de techneuten.
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                We wilden boekhouden toegankelijk maken voor iedereen, ongeacht
                hun kennisniveau. Inzicht in je financiën zonder ingewikkelde
                termen of dure cursussen. De angst voor belastingaangiften
                wegnemen en je zekerheid geven.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"We begrepen de pijn. ".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                We begrepen de pijn.
              </Typography>

              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                De frustratie van spreadsheets en verloren avonden. De stress
                van de Belastingdienst. De teleurstelling van software die
                belooft, maar faalt. We gooiden het roer om. We daagden de
                status quo uit. En keer op keer bewees onze 'gekke' aanpak zijn
                waarde, zelfs tot verbazing van de financiële experts.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/pain.png"}
                alt="MarioImage"
                width="50%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Maar de echte helden? ".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/realHeros.png"}
                alt="MarioImage"
                width="50%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Maar de echte helden?
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Dat zijn onze trouwe proefkonijnen. De ZZP'ers, fotografen,
                zorgverleners - mensen zoals jij - die ons product elke dag
                testen en verbeteren. Dankzij hun ideeën, kritiek en support
                kunnen we boekhouden heruitvinden. We zijn niet gebonden aan
                investeerders, maar alleen aan onze gebruikers. Aan jou.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <div style={{ padding: "2rem", paddingBottom: "0px" }}>
          <div
            className="flex"
            style={{ justifyContent: "space-around", gap: "1rem" }}
          >
            <Typography variant="h4" className="titleUponDivider" gutterBottom>
              Samen maken we boekhouden niet alleen eenvoudig, maar zelfs
              verslavend leuk.
            </Typography>
          </div>
        </div>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default OurMission;
