import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function AdminTimeKeeper() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Administratie
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Urenregistratie voor zzp'ers: eenvoudig, snel en overzichtelijk
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Als zzp'er is het belangrijk om je uren goed bij te houden. Dit is
          nodig voor je eigen administratie, het urencriterium van de
          Belastingdienst en om je uren te factureren aan je klanten.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden van Urenregistratie met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Uren schrijven
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Urencriterium
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Urenoverzicht
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Uren facturen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(5)}
        >
          Projecten uren
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(6)}
        >
          Uren inzien en controleren
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Uren schrijven".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              {" "}
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Uren schrijven
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als ondernemer is het belangrijk om je uren goed bij te houden.
                Dit is nodig voor je eigen administratie, het urencriterium van
                de Belastingdienst en om je uren te factureren aan je klanten.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Eenvoudig: </strong>Je kunt je uren invoeren in een
                handomdraai. Je hoeft alleen een omschrijving, contact of
                project toe te voegen en aan te geven of je uren declarabel
                zijn.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Overzichtelijk: </strong>Je hebt altijd inzicht in je
                werkuren, per klant, per project en per taak.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Belastingvoordelen:</strong>Je kunt eenvoudig zien of je
                voldoet aan het urencriterium, zodat je de juiste
                belastingvoordelen kunt claimen.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Urencriterium".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              {" "}
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Urencriterium
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als zzp'er / eenmanszaken / V.O.F. heb je recht op verschillende
                belastingvoordelen, zoals de startersaftrek en de
                ondernemersaftrek. Om deze voordelen te claimen, moet je aan het
                urencriterium voldoen. Dit betekent dat je minimaal 1.225 uur
                per jaar aan je onderneming moet besteden.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Urenoverzicht".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              {" "}
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Urenoverzicht
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Er zijn diverse overzichten beschikbaar. Ook zijn de uren te
                exporteren naar Excel-overzichten.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Uren facturen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              {" "}
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Uren facturen
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als ondernemer wil je natuurlijk zo snel mogelijk je facturen
                versturen. Met onze urenregistratie kun je je uren direct
                overzetten naar een factuur.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Makkelijk: </strong>Je hoeft niet handmatig je uren over
                te typen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Snel: </strong>Je kunt je facturen in een handomdraai
                versturen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Flexibel:</strong>Je kunt kiezen of je alle uren in één
                keer of per urenregel wilt factureren.
              </Typography>
              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Meer informatie over factureren
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-5"
      >
        <Divider textAlign="left" className="divider">
          {"Projecten uren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              {" "}
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Projecten uren
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als zzp'er of bedrijf wil je je uren overzichtelijk bijhouden.
                Met onze urenregistratie kun je je uren op maat inrichten, zodat
                je altijd het overzicht hebt.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Project-basis: </strong>Je kunt je uren vastleggen op
                project-basis, zodat je altijd inzicht hebt in je uren per
                project.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Uurtarief: </strong>Per project kun je een uurtarief
                instellen, zodat je eenvoudig je facturen kunt opstellen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>ORT uren:</strong>Onze unieke systeem maakt het mogelijk
                om uren op basis van ORT uren in te richten. Zo kun je eenvoudig
                je overwerkuren berekenen en verrekenen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Urenspecificatie:</strong>De uren worden in de
                urenspecificatie op de factuur automatisch gegroepeerd per
                project.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-6"
      >
        <Divider textAlign="right" className="divider">
          {"Uren inzien en controleren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              {" "}
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Uren inzien en controleren
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als bedrijf heb je meerdere medewerkers die uren registreren.
                Met onze urenregistratie kun je eenvoudig de uren van je team
                bijhouden.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Toegangsbeheer: </strong>Je kunt medewerkers toegang
                geven tot verschillende onderdelen van de urenregistratie. Zo
                kun je ervoor zorgen dat alleen de juiste personen de juiste
                informatie kunnen zien.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Overzicht: </strong> Er zijn diverse overzichten
                beschikbaar, zodat je altijd inzicht hebt in de uren van je
                team. opstellen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Export:</strong>Je kunt de uren exporteren naar
                Excel-overzichten, zodat je ze eenvoudig kunt verwerken.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default AdminTimeKeeper;
