import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Stack,
  Divider,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import style from "../invoice.module.css";

import api from "../../../config/axios";
import { useRef } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const fieldNameTranslations = {
  name: "Naam : ",
  email: "Email : ",
  address: "Adres : ",
  country: "Land : ",
  phoneNo: "Telefoon nr : ",
  chamberOfCommerceNo: "KVK Nr. : ",
  vatNo: "Btw nr : ",
  bankAccountNumber: "Bankrekeningnummer : ",
  bankAccountName: "Bank Account naam : ",
  invoiceDate: "Factuurdatum : ",
  expirationDate: "Vervaldatum : ",
  customerEmail: "Klant email : ",
  customerPhoneNo: "Klant telefoon nr : ",
  space1: "",
  space2: "",
  space3: "",
};

function InvoiceLivePreview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const invoiceState = location?.state;

  const abortController = useRef(new AbortController());
  const companyAbortController = useRef(new AbortController());

  const [companyDetails, setCompanyDetails] = useState({});
  const [logoPreview, setLogoPreview] = useState();
  const [bgPreview, setBgPreview] = useState();

  const [data, setInvoiceData] = useState({
    logoPosition: "center",
    fontFamily: "normal",
    logoSize: 200,
    watermarkImage: null,
    addressWidth: 350,
    AddressPosition: true,
    fontSize: 14,
    textStyles: {
      bold: false,
      italic: false,
      header: false,
    },
  });

  const [invoiceSenderAddress, setInvoiceSenderAddress] = useState({
    showFields: [],
  });
  const [invoiceClientAddress, setInvoiceClientAddress] = useState({
    showFields: [],
  });

  const [invoiceDataFormat, setInvoiceDataFormat] = useState({
    leftColumn: {
      showFields: [],
    },
    rightColumn: {
      showFields: [],
    },
  });

  const invoiceExtraDetails = {
    invoiceDate: dayjs(invoiceState?.date).format("DD-MM-YYYY"),
    expirationDate: dayjs(invoiceState?.date)
      .add(invoiceState.expirationPeriodInDays, "days")
      .format("DD-MM-YYYY"),
    vatNo: companyDetails.vatNo,
    chamberOfCommerceNo: companyDetails?.chamberOfCommerceNo,
    customerEmail: invoiceState?.customerId?.email,
    customerPhoneNo: invoiceState?.customerId?.phoneNo,
    reference: invoiceState?.reference,
    countryCode: invoiceState?.customerId?.countryCode,
  };

  const getInvoiceSettings = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    try {
      const res = await api.get(`users/settings/document_styles`, {
        signal: abortController.current.signal,
      });
      const resData = res.data;

      if (resData.status) {
        if (resData?.data?.documentStyle) {
          let style = resData?.data?.documentStyle;
          setLogoPreview(style?.logo?.image);
          setBgPreview(style?.background?.image);
          setInvoiceData({
            logoPosition: style.logo.position,
            logoSize: style.logo.width,
            fontFamily: style.font.identifier,
            fontSize: style.font.size,
            addressWidth: style.layout.addressWidth,
            AddressPosition:
              style.layout?.customerAddressPosition === "left" ? true : false,
          });

          setInvoiceClientAddress({
            showFields: style.invoiceClientAddress?.showFields || [],
          });
          setInvoiceSenderAddress({
            showFields: style.invoiceSenderAddress?.showFields || [],
          });
          setInvoiceDataFormat((prevState) => {
            return {
              ...prevState,
              leftColumn: {
                ...prevState.leftColumn,
                showFields:
                  style?.invoiceDataFormat?.leftColumn.showFields ||
                  prevState.leftColumn.showFields,
              },
              rightColumn: {
                ...prevState.rightColumn,
                showFields:
                  style?.invoiceDataFormat?.rightColumn.showFields ||
                  prevState.rightColumn.showFields,
              },
            };
          });
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  const getCompanyDetails = async () => {
    companyAbortController.current.abort();
    companyAbortController.current = new AbortController();

    try {
      const res = await api.get(`users/settings/company`, {
        signal: companyAbortController.current.signal,
      });
      const resData = res.data;

      if (resData.status) {
        setCompanyDetails(resData.data.company);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getInvoiceSettings();
    getCompanyDetails();

    return () => {
      companyAbortController.current.abort();
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  const fullURL = process.env.REACT_APP_API_URL;
  const urlObject = new URL(fullURL);
  const baseURL = `${urlObject.protocol}//${urlObject.host}`;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton onClick={() => navigate(invoiceState.url)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography color="black" variant="h5">
              {t("invoices.title")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Box className={style.container_live_layout}>
        <Box
          component="img"
          className={style.invoiceBgImage}
          src={`${baseURL}/user/self/bg_images/${bgPreview?.fileName}`}
        />
        <Paper className={style.invoice_live_layout}>
          <Stack>
            <div style={{ minHeight: "150px", marginBottom: "20px" }}>
              <div
                style={{
                  textAlign: `${data?.logoPosition}`, // You can set the logo position dynamically here
                }}
              >
                <img
                  src={`${baseURL}/user/self/logos/${logoPreview?.fileName}`}
                  alt="company logo"
                  style={{ maxHeight: `${data.logoSize}px` }}
                />
              </div>
            </div>
            <div>
              {" "}
              <div
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "borderBox",
                }}
              >
                <div
                  style={{
                    float: `${data?.AddressPosition ? "right" : "left"}`,
                    textAlign: `${data?.AddressPosition ? "right" : "left"}`,
                  }}
                >
                  {invoiceSenderAddress.showFields.map((field, index) => {
                    if (field.fieldName !== "address") {
                      return (
                        <Typography
                          color="black"
                          key={index}
                          style={{
                            fontStyle: field.italic ? "italic" : "normal",
                            fontWeight: field.bold ? 600 : 400,
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {field.showFieldName &&
                          fieldNameTranslations[field.fieldName] ? (
                            t(`invoices.invoice_pdf_filed.${field?.fieldName}`)
                          ) : (
                            <br />
                          )}
                          {field.fieldName === "phoneNo" &&
                            companyDetails.countryCode &&
                            `+${companyDetails.countryCode} `}
                          {field.fieldName === "country" &&
                            companyDetails.countryId &&
                            `${companyDetails.countryId?.name} `}
                          {companyDetails[field.fieldName] &&
                            companyDetails[field.fieldName]}
                        </Typography>
                      );
                    } else {
                      return (
                        <>
                          <Typography
                            color="black"
                            key={index}
                            style={{
                              fontStyle: field.italic ? "italic" : "normal",
                              fontWeight: field.bold ? 600 : 400,
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                            }}
                          >
                            {field.showFieldName &&
                              fieldNameTranslations["address"]}
                            {companyDetails["address"]}
                          </Typography>

                          <Typography
                            color="black"
                            key={index}
                            style={{
                              fontStyle: field.italic ? "italic" : "normal",
                              fontWeight: field.bold ? 600 : 400,
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                            }}
                          >
                            {companyDetails["postalCode"] || ""}{" "}
                            {companyDetails["city"] &&
                              companyDetails["postalCode"] &&
                              " - "}{" "}
                            {companyDetails["city"] || ""}{" "}
                          </Typography>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "borderBox",
                }}
              >
                <div
                  style={{
                    float: `${!data?.AddressPosition ? "right" : "left"}`,
                    textAlign: `${!data?.AddressPosition ? "right" : "left"}`,
                  }}
                >
                  {invoiceClientAddress.showFields.map((field, index) => {
                    if (field?.fieldName !== "address") {
                      return (
                        <Typography
                          color="black"
                          key={index}
                          style={{
                            fontStyle: field.italic ? "italic" : "normal",
                            fontWeight: field.bold ? 600 : 400,
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {field.showFieldName &&
                          fieldNameTranslations[field.fieldName] ? (
                            t(`invoices.invoice_pdf_filed.${field.fieldName}`)
                          ) : (
                            <br />
                          )}
                          {field.fieldName === "phoneNo" &&
                            invoiceState?.customerId?.countryCode &&
                            `+${invoiceState?.customerId?.countryCode} `}
                          {invoiceState?.customerId[field.fieldName] &&
                            invoiceState?.customerId[field.fieldName]}
                        </Typography>
                      );
                    } else {
                      return (
                        <>
                          <Typography
                            color="black"
                            key={index}
                            style={{
                              fontStyle: field.italic ? "italic" : "normal",
                              fontWeight: field.bold ? 600 : 400,
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                            }}
                          >
                            {field.showFieldName &&
                              fieldNameTranslations["address"]}
                            {
                              invoiceState?.customerId?.billingAddress[
                                "address"
                              ]
                            }
                          </Typography>
                          <Typography
                            color="black"
                            key={index}
                            style={{
                              fontStyle: field.italic ? "italic" : "normal",
                              fontWeight: field.bold ? 600 : 400,
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                            }}
                          >
                            {" "}
                            {invoiceState?.customerId?.billingAddress[
                              "postalCode"
                            ] || ""}{" "}
                            {invoiceState?.customerId?.billingAddress["city"] &&
                              invoiceState?.customerId?.billingAddress[
                                "postalCode"
                              ] &&
                              " - "}{" "}
                            {invoiceState?.customerId?.billingAddress["city"] ||
                              ""}{" "}
                          </Typography>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>

            <Stack mb={5}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "60px",
                }}
              >
                <div>
                  <table>
                    <tr>
                      <td>
                        <Stack>
                          <Typography
                            color="black"
                            sx={{
                              fontFamily: data.fontFamily,
                              // fontSize: data.fontSize,
                            }}
                            variant="h5"
                            fontWeight={600}
                          >
                            {t("invoices.title")} {"   "}
                            {invoiceState?.invoiceNoFormatted}
                          </Typography>
                        </Stack>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <table>
                  {invoiceDataFormat.leftColumn.showFields.map((field, i) => (
                    <tr
                      key={i}
                      style={{
                        fontStyle: field.italic ? "italic" : "normal",
                        fontWeight: field.bold ? 600 : 400,
                        fontFamily: data.fontFamily,
                        fontSize: data.fontSize,
                      }}
                    >
                      <td>
                        {field.showFieldName &&
                          t(`invoices.invoice_pdf_filed.${field.fieldName}`)}
                      </td>
                      <td>
                        {" "}
                        {field.fieldName === "phoneNo" &&
                          invoiceState?.customerId?.countryCode &&
                          `+${invoiceExtraDetails?.countryCode} `}
                        {invoiceExtraDetails[field.fieldName] || "..."}
                      </td>
                    </tr>
                  ))}
                </table>
                <table>
                  {invoiceDataFormat?.rightColumn?.showFields?.map(
                    (field, index) => (
                      <tr
                        key={index}
                        style={{
                          fontStyle: field.italic ? "italic" : "normal",
                          fontWeight: field.bold ? 600 : 400,
                          fontFamily: data.fontFamily,
                          fontSize: data.fontSize,
                        }}
                      >
                        <td>
                          {field.showFieldName &&
                            t(`invoices.invoice_pdf_filed.${field.fieldName}`)}
                        </td>
                        <td>{invoiceExtraDetails[field.fieldName] || "..."}</td>
                      </tr>
                    ),
                  )}
                </table>
              </div>
            </Stack>

            <Stack mt={5}>
              <div className={style.TableContainer}>
                <table className={style.CustomTable}>
                  <thead>
                    <tr>
                      <th align="left">
                        <span
                          className={style.table_header}
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {t("invoices.list_tbl.headers.description")}
                        </span>
                      </th>
                      <th align="right">
                        <span
                          className={style.table_header}
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {t("invoices.list_tbl.headers.quantity")}
                        </span>
                      </th>
                      <th align="right">
                        <span
                          className="table-header"
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {t("products.list_tbl.headers.amount")}
                        </span>
                      </th>
                      <th align="right">
                        <span
                          className="table-header"
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {t("invoices.total")}
                        </span>
                      </th>
                      <th align="right">
                        <span
                          className="table-header"
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          BTW
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceState?.items?.map((item, index) => (
                      <>
                        <tr key={index} style={{ borderTop: `1px solid gray` }}>
                          <td
                            style={{
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                              width: "50%",
                            }}
                          >
                            {item?.name}
                            {item?.comment && (
                              <>
                                <br />
                                {item?.comment}
                              </>
                            )}
                            {item?.periodDate && (
                              <>
                                <br />
                                Periode: {item?.periodDate}
                              </>
                            )}
                          </td>
                          <td
                            style={{
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                              width: "10%",
                            }}
                            align="right"
                          >
                            {Number(item?.quantity).toLocaleString("nl-NL", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </td>
                          <td
                            style={{
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                              width: "10%",
                            }}
                            align="right"
                          >
                            {Number(item?.amount).toLocaleString("nl-NL", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </td>
                          <td
                            style={{
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                              width: "10%",
                            }}
                            align="right"
                          >
                            {(item?.amount * item.quantity).toLocaleString(
                              "nl-NL",
                              {
                                style: "currency",
                                currency: "EUR",
                              },
                            )}
                          </td>
                          <td
                            style={{
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                              width: "10%",
                            }}
                            align="right"
                          >
                            {item?.vatCategories?.percentage}%
                          </td>
                        </tr>
                      </>
                    ))}
                    <tr style={{ borderTop: "2px solid black" }}>
                      <td></td>
                      <td align="right" colSpan="2">
                        <span
                          className={style.table_header}
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          Subtotaal excl. btw
                        </span>
                      </td>
                      <td
                        align="right"
                        className={style.table_header}
                        style={{
                          fontFamily: data.fontFamily,
                          fontSize: data.fontSize,
                        }}
                      >{`${invoiceState?.subTotal?.toLocaleString("nl-NL", {
                        style: "currency",
                        currency: "EUR",
                      })}`}</td>
                      <td></td>
                    </tr>
                    {invoiceState.totalDiscount !== 0 && (
                      <tr>
                        <td></td>
                        <td align="right" colSpan="2">
                          <span
                            className={style.table_header}
                            style={{
                              fontFamily: data.fontFamily,
                              fontSize: data.fontSize,
                            }}
                          >
                            {t("invoices.discount")}
                          </span>
                        </td>
                        <td
                          align="right"
                          className={style.table_header}
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {invoiceState.totalDiscount?.toLocaleString("nl-NL", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </td>
                        <td></td>
                      </tr>
                    )}

                    {invoiceState?.vats.map((vat) => (
                      <tr>
                        <td
                          align="left"
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          Grondslag {vat?.vatPercentage} % -{" "}
                          {vat?.exclusiveVat?.toLocaleString("nl-NL", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </td>

                        <td
                          align="right"
                          colSpan={2}
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {vat.description}
                        </td>
                        <td
                          align="right"
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          {vat?.vatAmount?.toLocaleString("nl-NL", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </td>
                      </tr>
                    ))}
                    <tr style={{ borderTop: "2px solid black" }}>
                      <td></td>
                      <td></td>

                      <td
                        align="right"
                        style={{
                          fontFamily: data.fontFamily,
                          fontSize: data.fontSize,
                        }}
                      >
                        <span
                          className={style.table_header}
                          style={{
                            fontFamily: data.fontFamily,
                            fontSize: data.fontSize,
                          }}
                        >
                          Totaal
                        </span>
                      </td>
                      <td
                        align="right"
                        className={style.table_header}
                        style={{
                          fontFamily: data.fontFamily,
                          fontSize: data.fontSize,
                          borderTop: "2px solid black",
                        }}
                      >{`${invoiceState?.total?.toLocaleString("nl-NL", {
                        style: "currency",
                        currency: "EUR",
                      })}`}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Stack>
          </Stack>

          <Stack mt="20%">
            <Divider sx={{ bgcolor: "black", mb: 1 }} />
            <Typography
              color="black"
              variant="body1"
              align="justify"
              sx={{
                fontFamily: data.fontFamily,
                fontSize: data.fontSize,
              }}
            >
              We verzoeken u vriendelijk het bovenstaande bedrag van{" "}
              {`${invoiceState?.total?.toLocaleString("nl-NL", {
                style: "currency",
                currency: "EUR",
              })}`}{" "}
              voor {invoiceExtraDetails["invoiceDate"]} te voldoen op onze
              bankrekening onder vermelding van de omschrijving{" "}
              {invoiceState.invoiceNoFormatted}. Voor vragen kunt u contact
              opnemen per e-mail.
            </Typography>
          </Stack>
        </Paper>
      </Box>
    </>
  );
}

export default InvoiceLivePreview;
