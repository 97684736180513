import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SellUblInvoices() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          UBL-facturen: de toekomst van factureren
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          " Factureren is een belangrijk onderdeel van het zakendoen. Het is een
          manier om je geleverde diensten of producten te factureren aan je
          klanten."
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor UBL-facturen met Dashbook
          </Typography>
        </div>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          UBL-facturen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"UBL-facturen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h6" gutterBottom>
                UBL-facturen zijn een essentieel aspect van zakendoen, waarbij
                je geleverde diensten of producten worden gefactureerd aan je
                klanten.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Voordelen van UBL-facturen:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong> Sneller betaald met UBL-facturen:</strong> Het
                groeiende gebruik van UBL-facturen maakt automatische verwerking
                mogelijk bij veel opdrachtgevers, wat niet alleen handig is voor
                hen, maar ook de snelheid van betaling aanzienlijk kan verhogen.
                UBL-facturen optimaliseren het betalingsproces en versnellen
                financiële transacties.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>UBL-facturen versturen:</strong>
                In Dashbook verloopt het versturen van UBL-facturen automatisch.
                Bij e-mailverzending wordt de factuur zowel als pdf-bestand als
                in XML-formaat (UBL) automatisch bijgevoegd. Deze
                geautomatiseerde functie vereenvoudigt het proces en maakt
                elektronische verwerking mogelijk.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Professioneel en van deze tijd:</strong>
                Straal professionaliteit uit en blijf modern door elektronische
                UBL-facturen te gebruiken. Hiermee geef je aan efficiënt en
                hedendaags te werken, wat niet alleen een positieve indruk maakt
                op je klanten, maar hen ook aanzienlijke tijd bespaart. Het is
                dan ook niet verwonderlijk dat steeds meer opdrachtgevers de
                voorkeur geven aan deze vorm van elektronische facturatie.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                            variant="contained"
                            style={{ backgroundColor: "#4A88EA" }}
                          >
                            meer info
                          </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellUblInvoices;
