import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import PercentIcon from "@mui/icons-material/Percent";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { DeleteOutline } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Switch from "./Switch";
import { toast } from "react-hot-toast";
import useDialogHooks from "../../components/Hooks/useDialogHooks";
import CustomDialogBox from "../../components/CustomDialogBox";
import dayjs from "dayjs";
import CategoryIcon from "@mui/icons-material/Category";

import { highlightField } from "../../utils/Commons";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../config/axios";
import FileUploadDialogBox from "./FileUploadDialogBox";
import MobileDatePickerInput from "../../components/form/MobileDatePickerInput";
import AutocompleteInput from "../../components/form/AutocompleteInput";
import TextInput from "../../components/form/TextInput";
import TextareaAutoSize from "../../components/form/TextareaAutoSize";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AddCircleRounded from "@mui/icons-material/AddCircleRounded";

import AddInvoiceDrawer from "../../components/Drawer/AddInvoiceDrawer";
import NotGiveDiscount from "../../pages/invoice/normalInvoice/NotGiveDiscount";
import DiscountDialogBox from "./DiscountDialogBox";
import CurrencyInputNumber from "../../components/form/CurrencyInputNumber";
import CategoryDialogBox from "./CategoryDialogBox";
import api from "../../config/axios";

const defaultValues = {
  items: [
    {
      isAmountInclusiveOfVat: false,
      name: "",
      vatCategories: null,
      amount: null,
      quantity: null,
      comment: "",
    },
  ],
  selectProduct: null,
  reference: "",
  expirationDate: null,
  date: dayjs(new Date()),
  endDate: null,
  customerId: null,
  discountType: "percentage",
  discountName: "Korting",
  discount: 0,
  term: null,
  subTotal: 0,
  total: 0,
  totalDiscount: 0,
  hasToGiveDiscount: true,
  documents: [],
  automaticallySendInvoice: false,
  rowSubTotal: [],
  exclusiveVat: 0,
  vats: [],
};

function AddInvoice() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const invoiceState = location?.state;
  const abortController = useRef(new AbortController());
  const productAbortController = useRef(new AbortController());
  const vatCategoryAbortController = useRef(new AbortController());
  const subcategoryAbortController = useRef(new AbortController());
  const subcategoryDefaultAbortController = useRef(new AbortController());

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const isTablet = useMediaQuery("(max-width: 1250px)");
  const [isExtraOptionShow, setIsExtraOptionShow] = useState(true);
  const [vatCategories, setVatCategories] = useState([]);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [showNotes, setShowNotes] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoadingVatCategories, setIsLoadingVatCategories] = useState(false);
  const [subcategoriesDefault, setSubcategoriesDefault] = useState([]);

  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(Array(5).fill(null));
  const [checkedProduct, setCheckedProduct] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("products.list_tbl.headers.name"),
      sortable: true,
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: true,
      label: t("products.list_tbl.headers.amount"),
      sortable: true,
    },
    {
      id: "notes",
      numeric: false,
      disablePadding: true,
      label: t("products.list_tbl.headers.vatCategoryId"),
      sortable: true,
    },
    {
      id: "subcategoryId",
      numeric: false,
      disablePadding: true,
      label: t("products.list_tbl.headers.subcategoryId"),
      sortable: false,
    },
  ];

  const handleOpenMenu = (index, event) => {
    const updatedAnchorEl = [...anchorEl];
    updatedAnchorEl[index] = event.currentTarget;
    setAnchorEl(updatedAnchorEl);
  };

  const handleCloseMenu = (index) => {
    const updatedAnchorEl = [...anchorEl];
    updatedAnchorEl[index] = null;
    setAnchorEl(updatedAnchorEl);
  };

  const handleShowComments = (index) => {
    const newShowNotes = [...showNotes];

    newShowNotes[index] = !newShowNotes[index];
    setShowNotes(newShowNotes);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const showInvoiceConfirmationDialog = (docId) => {
    setShowInvoiceDialog(true);
  };

  const closeInvoiceConfirmationDialog = () => {
    setShowInvoiceDialog(false);
  };

  const handleRowCheckboxChange = (event, row) => {
    const { checked } = event.target;

    setCheckedProduct((prevCheckedRows) =>
      checked
        ? [...prevCheckedRows, row.id]
        : prevCheckedRows.filter((id) => id !== row.id),
    );

    setSelectProduct((prevSelectedRows) =>
      checked
        ? [...prevSelectedRows, row]
        : prevSelectedRows.filter((r) => r.id !== row.id),
    );
  };

  const validationSchema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        name: yup.string().required(t("offers.productNameRequired")),
        amount: yup
          .string()
          .required(t("offers.amountRequired"))
          .min(0, t("offers.amountMustBeGreaterThan0")),
        quantity: yup
          .string()
          .required(t("offers.quantityRequired"))
          .min(0, t("offers.quantityMustBeGreaterThan0")),
        vatCategories: yup.object().required(t("offers.btwRequired")),
      }),
    ),
    customerId: yup.object().required(t("offers.customerRequired")),
    date: yup.date().required(t("offers.dateRequired")),
  });

  const { control, handleSubmit, setValue, watch, getValues, reset } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const addInputField = () => {
    append({
      isAmountInclusiveOfVat: false,
      name: "",
      vatCategories: vatCategories[0],
      amount: null,
      quantity: null,
      comment: "",
      periodStartDate: null,
      periodEndDate: null,
      subcategoryId: subcategoriesDefault[1],
      subcategoryType: "default",
    });
  };

  const findSubCategory = (row) => {
    const category = subcategories?.find((sub) => {
      if (sub?.subcategoryId) {
        return sub.id === row.subcategoryId;
      } else {
        return sub.id === row.subcategoryId;
      }
    });
    if (category?.subcategoryId) {
      return category?.subcategoryDetails?.name;
    } else {
      return category?.name;
    }
  };

  const onSubmit = (data, buttonName) => {
    const {
      customerId,
      date,
      discountType,
      discount,
      discountName,
      items,
      notes,
      expirationDate,
    } = data;

    const itemsArray = items.map((i) => ({
      name: i.name,
      quantity: i.quantity || 0,

      amount: i.amount || 0,
      comment: i.comment,
      vatCategoryId: i.vatCategories.id,
      productId: i?.productId || "",
      isAmountInclusiveOfVat: i.isAmountInclusiveOfVat,
      subcategoryId: i?.subcategoryId?.id || i.subcategoryId._id,
    }));
    const formData = new FormData();
    formData.append("customerId", customerId?.id);
    formData.append("date", dayjs(date).format("YYYY-MM-DD"));
    formData.append(
      "expirationDate",
      expirationDate ? dayjs(expirationDate).format("YYYY-MM-DD") : "",
    );

    formData.append("discountName", discountName);
    formData.append("currencyId", "");
    formData.append("discountType", discountType);
    formData.append("discountPercentage", discount);
    formData.append("discountAmount", discount);
    itemsArray.forEach((item, index) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          formData.append(`items[${index}][${key}]`, item[key]);
        }
      }
    });
    formData.append("notes", notes || "");
    if (documents.length) {
      documents.forEach((doc, index) => {
        formData.append(`documents[${index}]`, doc.file);
      });
    }

    setIsLoading(true);

    const requestStartTime = Date.now();
    API.post("users/offers", formData)
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status) {
          if (buttonName === "copy") {
            toast.success(t("offers.offers_copy_successfully"));
          } else {
            toast.success(t("offers.offers_added_successfully"));
          }

          if (buttonName === "save&new") {
            reset();
          } else {
            navigate(`/offers/edit/${resData?.data?._id}`);
          }
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);

            try {
              highlightField(Object.keys(resData.error)[0]);
            } catch (error) {
              console.warn("Field cannot be highlighted");
            }
          } else {
            toast.error(resData?.error?.message || "Something went wrong");
          }
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };

  const getCustomersList = () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    setIsLoadingCustomers(true);

    // Replace this with your API call
    API.get(`users/customers?onlyBasicDetails=true`, {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setCustomers(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoadingCustomers(false);
      });
  };

  const getProductList = () => {
    // Cancel the previous request
    productAbortController.current.abort();
    productAbortController.current = new AbortController();

    API.get("users/products", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          setProducts(resData?.data ?? []);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {});
  };

  const getVatCategories = () => {
    // Cancel the previous request
    vatCategoryAbortController.current.abort();
    vatCategoryAbortController.current = new AbortController();

    setIsLoadingVatCategories(true);

    API.get(`users/vat_categories/own`, {
      params: {
        type: "sales",
      },
      signal: vatCategoryAbortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setVatCategories(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoadingVatCategories(false);
      });
  };

  const getSubcategories = () => {
    // Cancel the previous request
    subcategoryAbortController.current.abort();
    subcategoryAbortController.current = new AbortController();

    API.get(`users/subcategories`, {
      signal: subcategoryAbortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setSubcategories(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };
  const getSubcategoriesDefault = (type) => {
    // Cancel the previous request
    subcategoryDefaultAbortController.current.abort();
    subcategoryDefaultAbortController.current = new AbortController();

    api
      .get(`users/subcategories`, {
        signal: subcategoryDefaultAbortController.current.signal,
        params: {
          categoryList: type,
        },
      })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          let categoryList = resData.data;
          const result = [];
          categoryList.forEach((item) => {
            const mainCategoryId = item.subcategoryDetails
              ? item.subcategoryDetails?.mainCategoryDetails?._id
              : item.mainCategoryDetails?._id;
            const detail = item.subcategoryDetails
              ? item.subcategoryDetails.mainCategoryDetails
              : item.mainCategoryDetails;
            const subcategory = {
              main_category: mainCategoryId,
              id: item.id,
              name: item.subcategoryId
                ? item.subcategoryDetails.name
                : item.name,
              code: item.subcategoryId
                ? item.subcategoryDetails.code
                : item.code,
              type: item.subcategoryId
                ? item.subcategoryDetails.type
                : item.type,
            };
            const existingMainCategory = result.find(
              (category) => category.id === mainCategoryId,
            );

            if (existingMainCategory) {
              existingMainCategory.subcategories.push(subcategory);
            } else {
              const mainCategoryDetails = {
                id: mainCategoryId,
                name: detail.name,
                type: detail.type,
              };
              result.push({
                ...mainCategoryDetails,
                subcategories: [subcategory],
              });
            }
          });

          const categories = result.flatMap((category) => [
            {
              id: category.id,
              name: category.name,
              type: category.type,
              isGroupLabel: true,
            },
            ...category.subcategories.map((subcategory) => ({
              id: subcategory.id,
              name: subcategory.name,
              code: subcategory.code,
              main_category: subcategory.main_category,
            })),
          ]);

          setValue(`items.${0}.subcategoryId`, categories[1]);

          setSubcategoriesDefault(categories);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    getCustomersList();
    getVatCategories();
    getSubcategories();
    getProductList();
    getSubcategoriesDefault("default");

    return () => {
      abortController.current.abort();
      vatCategoryAbortController.current.abort();
      subcategoryAbortController.current.abort();
      subcategoryDefaultAbortController.current.abort();
      productAbortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTablet) {
      setDrawerOpen(false);
    } else {
      setDrawerOpen(false);
    }
  }, [isTablet]);

  useEffect(() => {
    if (invoiceState) {
      // if (invoiceState?.state) {
      //   const { state } = invoiceState;

      //   const {
      //     customerId,
      //     date,
      //     discountType,
      //     discount,
      //     discountName,
      //     items,
      //     notes,
      //     expirationDate,
      //   } = state;

      //   setValue("date", dayjs(date));
      //   setValue("items", items);
      //   setValue("customerId", customerId);
      //   setValue("discountName", discountName);
      //   setValue("notes", notes);
      //   setValue("expirationDate", expirationDate);
      //   setValue("discount", discount || 0);
      //   setValue("discountType", discountType || null);
      //   calculateTotal();
      // }
      if (invoiceState?.urlState) {
        const { urlState } = invoiceState;

        const {
          customerId,
          date,
          discountType,
          discount,
          discountName,
          items,
          notes,
          expirationDate,
          documents = [],
        } = urlState;

        setValue("date", dayjs(date));
        setValue("items", items);
        setValue("customerId", customerId);
        setValue("discountName", discountName || "");
        setValue("notes", notes || "");
        setValue("expirationDate", dayjs(expirationDate));
        setValue("discount", discount || 0);
        setValue("discountType", discountType || null);
        if (documents?.length) {
          setValue("documents", documents);
        }

        calculateTotal();
      }
    }
    // eslint-disable-next-line
  }, [invoiceState, customers]);

  const calculateTotal = () => {
    let subTotal = 0;
    let totalDiscount = 0;
    let totalExclusiveOfVat = 0;
    let vats = [];
    let totalVatAmount = 0;
    let total = 0;
    let rowSubTotal = [];
    let rowVats = [];

    const { discount, discountType, items } = getValues();

    for (const item of items) {
      let { amount, quantity, isAmountInclusiveOfVat, vatCategories } = item;

      let totalItemAmount = 0;
      let vatAmount = 0;

      let vatPercentage = vatCategories
        ? Number(vatCategories?.percentage)
        : null;

      // Calculate total item amount

      if (isAmountInclusiveOfVat) {
        if (vatPercentage) {
          vatAmount = amount * (vatPercentage / (100 + vatPercentage));
          totalVatAmount += vatAmount * quantity;
          rowVats.push({ inclusiveVat: vatAmount, exclusiveVat: 0 });
        }
        totalItemAmount = (amount - vatAmount) * quantity;
      } else {
        if (vatPercentage) {
          vatAmount = amount * (vatPercentage / 100);
          rowVats.push({ exclusiveVat: vatAmount, inclusiveVat: 0 });
          totalVatAmount += vatAmount * quantity;
        }

        totalItemAmount = amount * quantity;
      }

      subTotal += totalItemAmount;

      // Calculate VAT amounts based on the current totalExclusiveOfVat
      let vatAmountForItem = (totalItemAmount * vatPercentage) / 100;

      let existingVat = vats.find((vat) => vat.vatPercentage === vatPercentage);
      if (existingVat) {
        existingVat.vatAmount += vatAmountForItem;
        existingVat.exclusiveVat += totalItemAmount;
      } else if (vatPercentage === 0 || (vatPercentage && vatCategories)) {
        vats.push({
          vatPercentage: vatPercentage,
          vatAmount: vatAmountForItem,
          description: vatCategories.description,
          exclusiveVat: totalItemAmount,
        });
      }

      rowSubTotal.push(totalItemAmount);
    }

    subTotal = Math.round(subTotal * 100) / 100;

    // Extract the VAT percentages from the objects
    const percentages = items.map((item) => item?.vatCategories?.id);

    // Use the Set data structure to check if all percentages are the same
    const uniquePercentages = new Set(percentages);

    if (uniquePercentages.size === 1) {
      setValue("hasToGiveDiscount", true);
      if (discountType === "percentage") {
        totalDiscount = subTotal * (discount / 100);
        totalDiscount = Math.round(totalDiscount * 100) / 100;
        totalExclusiveOfVat = subTotal - totalDiscount;

        totalVatAmount =
          totalExclusiveOfVat *
          Number(items[0].vatCategories?.percentage / 100);
      } else {
        totalDiscount = Number(discount);
        totalExclusiveOfVat = subTotal - totalDiscount;
        totalVatAmount =
          totalExclusiveOfVat *
          Number(items[0].vatCategories?.percentage / 100);
      }
      if (discount) {
        vats = [];
        vats.push({
          vatPercentage: Number(items[0].vatCategories?.percentage),
          vatAmount: totalVatAmount,
          description: items[0].vatCategories?.description,
          exclusiveVat: totalExclusiveOfVat,
        });
      }
    } else {
      setValue("hasToGiveDiscount", false);
      totalDiscount = 0;
      setValue("discount", 0);
    }

    totalExclusiveOfVat = subTotal - totalDiscount;

    total = totalExclusiveOfVat + totalVatAmount;
    total = Number(total) ? Number(total) : 0;

    setValue("subTotal", subTotal);
    setValue("totalDiscount", totalDiscount);
    setValue("totalExclusiveOfVat", totalExclusiveOfVat);
    setValue("vats", vats);
    setValue("totalVatAmount", totalVatAmount);
    setValue("total", total);
    setValue("rowSubTotal", rowSubTotal);
  };

  const {
    subTotal,
    hasToGiveDiscount,
    vats,
    totalDiscount,
    rowSubTotal,
    documents,
  } = getValues();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Stack
          flex={1}
          width={`${
            isDrawerOpen && !isTablet
              ? "calc(100% - 300px)"
              : isTablet
              ? "98%"
              : "calc(100% - 60px)"
          }`}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                sx={{ fontSize: "20px", marginTop: "5px" }}
              >
                {t("offers.title")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MobileDatePickerInput
                name="date"
                label={t("offers.fields.date")}
                customError={inputErrors.date || null}
                control={control}
                autoFocus={true}
                format={"DD/MM/YYYY"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteInput
                name="customerId"
                type="text"
                label={t("offers.fields.customerId")}
                control={control}
                customError={inputErrors.customerId || null}
                options={[
                  {
                    id: "extraCat",
                    name: `${t("offers.fields.add_new_customer")}`,
                    isButton: true,
                  },
                  ...customers,
                ]}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                loading={isLoadingCustomers}
                onChange={(event, value) => {
                  if (value && value.id === "extraCat") {
                    const {
                      customerId,
                      date,
                      discountType,
                      discount,
                      discountName,
                      items,
                      notes,
                      expirationDate,
                      documents,
                    } = getValues();

                    navigate("/customers/add", {
                      replace: true,
                      state: {
                        customerId,
                        date: date?.toISOString(),
                        discountType,
                        discount,
                        discountName,
                        items,
                        notes,
                        documents,
                        expirationDate: expirationDate?.toISOString(),
                        url: window.location.pathname,
                      },
                    });
                  } else {
                    setValue("customerId", value);
                  }
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    className={
                      option.isGroupLabel
                        ? "option_group_label"
                        : "option_group_option MuiAutocomplete-option"
                    }
                    style={{
                      color: option.id === "extraCat" ? "green" : "", // Set the color to green for "Add New" option
                    }}
                  >
                    {option.id === "extraCat" ? (
                      <>
                        <AddOutlinedIcon style={{ marginRight: "8px" }} />
                        {option.name}
                      </>
                    ) : (
                      <>{option.name}</>
                    )}
                  </li>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="text"
                color="info"
                onClick={() => setIsExtraOptionShow(!isExtraOptionShow)}
                endIcon={
                  isExtraOptionShow ? (
                    <ExpandLessIcon sx={{ color: "#047ccc" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#047ccc" }} />
                  )
                }
              >
                {t("offers.extra_option")}
              </Button>
            </Grid>
            <Collapse
              sx={{ width: "100%", marginLeft: 3, marginTop: 1 }}
              in={isExtraOptionShow}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MobileDatePickerInput
                    name="expirationDate"
                    label={t("offers.fields.payment_term")}
                    control={control}
                    autoFocus={true}
                    customError={inputErrors.expirationDate || null}
                    format={"DD/MM/YYYY"}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextareaAutoSize
                    name="notes"
                    label={t("offers.fields.notes")}
                    control={control}
                    autoFocus={true}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Paper
            sx={{
              backgroundColor: "primaryBg.light",
            }}
          >
            {fields.map((item, index) => (
              <Fragment key={item.id}>
                <Grid container item bgcolor="#f8f8f8">
                  {index > 0 && index < fields.length && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                  <Grid item xs={12} p={2}>
                    <Stack width={"100%"}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                          <TextInput
                            label={t("invoices.fields.name")}
                            name={`items.${index}.name`}
                            control={control}
                            multiline={true}
                            placeholder="XYZ"
                            customError={
                              inputErrors[`items.${index}.name`] || null
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      openDialog("product");
                                    }}
                                  >
                                    <Inventory2OutlinedIcon color="info" />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1.5}>
                          <CurrencyInputNumber
                            label={t("invoices.fields.quantity")}
                            currency={false}
                            name={`items.${index}.quantity`}
                            control={control}
                            placeholder="0"
                            customError={
                              inputErrors[`items.${index}.quantity`] || null
                            }
                            onChangeCallback={calculateTotal}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <CurrencyInputNumber
                            label={t("invoices.fields.amount")}
                            name={`items.${index}.amount`}
                            type="number"
                            control={control}
                            placeholder="0"
                            customError={
                              inputErrors[`items.${index}.amount`] || null
                            }
                            onChangeCallback={calculateTotal}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Switch
                            name={`items.${index}.isAmountInclusiveOfVat`}
                            control={control}
                            setValue={setValue}
                            index={index}
                            onChangeCallback={calculateTotal}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <AutocompleteInput
                            label={t("invoices.fields.vatCategoryId")}
                            name={`items.${index}.vatCategories`}
                            control={control}
                            customError={
                              inputErrors[`items.${index}.vatCategoryId`] ||
                              null
                            }
                            options={vatCategories}
                            getOptionLabel={(option) => option.description}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id;
                            }}
                            onChangeCallback={calculateTotal}
                            loading={isLoadingVatCategories}
                          />
                        </Grid>
                        <Grid item xs={6} md={1}>
                          <Stack>
                            <Typography sx={{ mb: 2 }} variant="body2">
                              {t("invoices.total")}
                            </Typography>
                            {rowSubTotal?.length !== 0 &&
                              rowSubTotal[index]?.toLocaleString("nl-NL", {
                                style: "currency",
                                currency: "EUR",
                              })}
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={1.5}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            mt={3}
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            <Stack>
                              <IconButton
                                onClick={(e) => handleOpenMenu(index, e)}
                                aria-controls={`dropdown-menu-${index}`}
                                aria-haspopup="true"
                              >
                                <MoreHorizIcon sx={{ color: "black" }} />
                              </IconButton>
                              <Menu
                                id={`dropdown-menu-${index}`}
                                anchorEl={anchorEl[index]}
                                open={Boolean(anchorEl[index])}
                                onClose={() => handleCloseMenu(index)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleShowComments(index);
                                    handleCloseMenu(index);
                                  }}
                                >
                                  <ListItemIcon>
                                    <ChatBubbleOutlineIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {showNotes[index]
                                      ? t("invoices.hide_commits")
                                      : t("invoices.add_commits")}
                                  </ListItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    openDialog(`category.${index}`);

                                    handleCloseMenu(index);
                                  }}
                                >
                                  <ListItemIcon>
                                    <CategoryIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {t("invoices.subcategoryId")}
                                  </ListItemText>
                                </MenuItem>
                              </Menu>
                            </Stack>
                            <Stack>
                              {isSmallScreen ? (
                                <Button
                                  variant="outlined"
                                  color="error"
                                  size="small"
                                  onClick={() => {
                                    remove(index);
                                    calculateTotal();
                                  }}
                                >
                                  {t("offers.delete")}
                                </Button>
                              ) : (
                                <IconButton
                                  variant="outlined"
                                  color="error"
                                  size="small"
                                  sx={{
                                    border: "2px solid currentColor",
                                  }}
                                  disabled={fields.length <= 1}
                                  onClick={() => {
                                    remove(index);
                                    calculateTotal();
                                  }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              )}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Collapse in={showNotes[index]}>
                        <Grid item xs={12} md={4}>
                          <TextareaAutoSize
                            name={`items.${index}.comment`}
                            control={control}
                            placeholder={t("offers.fields.notes")}
                          />
                        </Grid>
                      </Collapse>
                    </Stack>
                  </Grid>
                </Grid>
                {isDialogOpen(`category.${index}`) && (
                  <CategoryDialogBox
                    isOpen={() => isDialogOpen(`category.${index}`)}
                    onClose={() => closeDialog(`category.${index}`)}
                    calculateTotal={calculateTotal}
                    setValue={setValue}
                    index={index}
                    getValues={getValues}
                    inputErrors={inputErrors}
                    control={control}
                  />
                )}
              </Fragment>
            ))}
            <Grid item xs={12} mt={3}>
              <Divider />
            </Grid>
            <Grid container mt={2} p={2} spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="info"
                    onClick={addInputField}
                    startIcon={<AddCircleIcon />}
                  >
                    {t("offers.add_items_btn")}
                  </Button>
                  <Tooltip title={t("offers.discount")} arrow placement="top">
                    <IconButton
                      sx={{ borderRadius: "0", border: "1px solid" }}
                      size="small"
                      color="info"
                      onClick={() =>
                        hasToGiveDiscount
                          ? openDialog("discount")
                          : openDialog("notGiveDiscount")
                      }
                    >
                      <PercentIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                {vats.map((vat) => (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={3}
                      mt={1}
                      width="250px"
                      key={vat}
                    >
                      <Typography>
                        Grondslag {vat?.vatPercentage} % -{" "}
                      </Typography>
                      <Typography>
                        {vat?.exclusiveVat?.toLocaleString("nl-NL", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </Typography>
                    </Stack>
                    <Divider sx={{ width: "250px" }} />
                  </>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">{t("offers.subTotal")}</Typography>
                    <Typography>
                      {subTotal?.toLocaleString("nl-NL", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </Typography>
                  </Stack>
                  {totalDiscount !== 0 && (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h6">
                        {t("offers.discount")}
                      </Typography>
                      <Typography>
                        {Number(totalDiscount)?.toLocaleString("nl-NL", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </Typography>
                    </Stack>
                  )}

                  {vats?.map((vat) => (
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                      key={vat}
                    >
                      <Typography variant="body1">
                        {vat?.description}
                      </Typography>
                      <Typography>
                        {vat?.vatAmount?.toLocaleString("nl-NL", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Box p={2}>
              <Divider />
              <Stack direction="row" justifyContent="flex-end" mt={2}>
                <Stack direction="row" alignItems="center" spacing={6}>
                  <Typography variant="h6" fontWeight={700}>
                    {t("offers.total")}
                  </Typography>
                  <Typography variant="body1" fontWeight={700}>
                    {watch("total")?.toLocaleString("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Paper>

          {isDialogOpen("product") && (
            <CustomDialogBox
              title={t("offers.dialogBox.title.products")}
              isOpen={() => isDialogOpen("product")}
              onClose={() => closeDialog("product")}
              maxWidth="lg"
              actions={
                <Stack
                  mr={1}
                  spacing={4}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      const { items } = getValues();
                      const itemsArray = selectProduct.map((i) => {
                        let vatCategory = vatCategories?.find(
                          (vat) => vat.id === i?.vatCategoryId,
                        );
                        let subCategory = subcategories?.find(
                          (sub) => sub.id === i.subcategoryId,
                        );
                        let subCategoryArray = [];
                        if (subCategory) {
                          if (subCategory?.subcategoryId) {
                            subCategoryArray.push({
                              name: subCategory?.subcategoryDetails.name,
                              id: subCategory?.id,
                            });
                          } else {
                            subCategoryArray.push({
                              name: subCategory?.name,
                              id: subCategory?.id,
                            });
                          }
                        } else {
                          if (subcategories?.[0]?.subcategoryId) {
                            subCategoryArray.push({
                              name: subcategories?.[0]?.subcategoryDetails.name,
                              id: subcategories?.[0]?.id,
                            });
                          } else {
                            subCategoryArray.push({
                              name: subcategories?.[0]?.name,
                              id: subcategories?.[0]?.id,
                            });
                          }
                        }

                        return {
                          name: i.name,
                          quantity: i.quantity || 1,
                          amount: i.amount || 0,
                          comment: i.comment || "",
                          vatCategories: vatCategory,
                          productId: i?.productId || "",
                          isAmountInclusiveOfVat: false,
                          subcategoryId: subCategoryArray[0],
                        };
                      });

                      if (items.length !== 1) {
                        const filteredItems = items.filter(
                          (item) => item.name !== "" && item.name !== undefined,
                        );

                        const updatedItems = [...filteredItems, ...itemsArray];

                        setValue("items", updatedItems);
                      } else {
                        setValue("items", itemsArray);
                      }

                      calculateTotal();

                      closeDialog("product");
                      setSelectProduct([]);
                      setCheckedProduct([]);
                    }}
                  >
                    {t("common_btn.save")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => closeDialog("product")}
                  >
                    {t("common_btn.cancel")}
                  </Button>
                </Stack>
              }
            >
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  variant="contained"
                  startIcon={<AddCircleRounded />}
                  disableElevation
                  style={{
                    height: "100%",
                    backgroundColor: "#00c078",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    const {
                      customerId,
                      date,
                      discountType,
                      discount,
                      discountName,
                      items,
                      notes,

                      expirationDate,
                      automaticallySendInvoice,
                    } = getValues();

                    navigate("/products/add", {
                      replace: true,
                      state: {
                        customerId,
                        date: date.toISOString(),
                        discountType,
                        discount,
                        discountName,
                        items,
                        notes,
                        expirationDate: expirationDate?.toISOString(),
                        automaticallySendInvoice,
                        url: window.location.pathname,
                      },
                    });
                  }}
                >
                  {t("products.add_product")}
                </Button>
              </Stack>
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{ borderRadius: "inherit" }}
              >
                <Table
                  aria-label="custom pagination table"
                  className={"custom-table br-0"}
                  sx={{ backgroundColor: "primaryBg.light", minWidth: 600 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: "80px",
                          minWidth: "80px",
                          textAlign: "center",
                        }}
                      >
                        Selecteren
                      </TableCell>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {products.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell scope="row">
                          {
                            <Checkbox
                              checked={checkedProduct.includes(row.id)}
                              onChange={(event) =>
                                handleRowCheckboxChange(event, row)
                              }
                            />
                          }
                        </TableCell>
                        <TableCell scope="row">{row.name}</TableCell>
                        <TableCell scope="row">
                          {row.amount?.toLocaleString("nl-NL", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </TableCell>

                        <TableCell scope="row">
                          {
                            vatCategories?.find(
                              (vat) => vat.id === row.vatCategoryId,
                            )?.percentage
                          }
                          %
                        </TableCell>
                        <TableCell scope="row">
                          {findSubCategory(row)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomDialogBox>
          )}
          {isDialogOpen("discount") && (
            <DiscountDialogBox
              isOpen={() => isDialogOpen("discount")}
              onClose={() => closeDialog("discount")}
              calculateTotal={calculateTotal}
              setValue={setValue}
              control={control}
            />
          )}
          {isDialogOpen("notGiveDiscount") && (
            <NotGiveDiscount
              isOpen={() => isDialogOpen("notGiveDiscount")}
              onClose={() => closeDialog("notGiveDiscount")}
              calculateTotal={calculateTotal}
              setValue={setValue}
              control={control}
              inputErrors={inputErrors}
            />
          )}
          {showInvoiceDialog && (
            <FileUploadDialogBox
              isOpen={showInvoiceDialog}
              onClose={closeInvoiceConfirmationDialog}
              documents={documents}
              setValue={setValue}
              control={control}
            />
          )}
        </Stack>
        <AddInvoiceDrawer
          isTablet={isTablet}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          onError={onError}
          showInvoiceConfirmationDialog={showInvoiceConfirmationDialog}
          isLoading={isLoading}
          documents={watch("documents")}
          backPath="/offers"
          type="normal"
        />
      </form>
    </>
  );
}

export default AddInvoice;
