import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Reports() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Boekhouding en rapportage
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Jaarrekeningn voor ZZP'ers, V.O.F. en eenmanszaken
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Als ZZP'er, V.O.F. of eenmanszaaak ben je niet verplicht om een
          jaarrekening op te stellen. Je moet wel een overzicht van je inkomsten
          en uitgaven bijhouden, maar dat hoeft niet volgens de regels van een
          jaarrekening. Een jaarrekening is een uitgebreid overzicht van de
          financiële situatie van een bedrijf. Het bevat informatie over de
          balans, de winst- en verliesrekening, de toelichting en de kasstroom.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Rapporten met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Waarom een jaarrekening?
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Verschil tussen overzicht en jaarrekening
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Jaarrekening laten opstellen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Waarom een jaarrekening?".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Waarom een jaarrekening?
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Er zijn een aantal redenen waarom het toch handig kan zijn om
                een jaarrekening op te stellen, ook als je ZZP'er bent.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>
                  Om inzicht te krijgen in je financiële situatie:
                </strong>
                Een jaarrekening geeft je een goed overzicht van je inkomsten en
                uitgaven, en van je winst of verlies. Dit kan je helpen om je
                bedrijf beter te managen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Om aan te tonen dat je financieel gezond bent:</strong>
                Een jaarrekening kan je helpen om aan te tonen dat je financieel
                gezond bent. Dit kan belangrijk zijn als je een lening of een
                hypotheek wilt aanvragen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>
                  Om te voldoen aan de eisen van een brancheorganisatie:
                </strong>
                Sommige brancheorganisaties eisen dat je als ZZP'er een
                jaarrekening opstelt.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Verschil tussen overzicht en jaarrekening".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Verschil tussen overzicht en jaarrekening
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Een overzicht biedt een beknopt inzicht in de financiële
                situatie van een bedrijf en omvat doorgaans alleen de balans en
                de winst- en verliesrekening. In tegenstelling hiermee omvat een
                jaarrekening niet alleen deze elementen, maar bevat het ook
                uitgebreidere informatie, zoals de toelichting en de kasstroom.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Jaarrekening laten opstellen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Jaarrekening laten opstellen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Als je een jaarrekening wilt opstellen, kun je dit zelf doen of
                je kunt een boekhouder inschakelen.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                            variant="contained"
                            style={{ backgroundColor: "#4A88EA" }}
                          >
                            meer info
                          </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/AnnualAccountsImage.png"}
                alt="Dashbook Logo"
                width={"50%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        className="flex"
        style={{ padding: "0px 2rem", justifyContent: "center" }}
      >
        <Button variant="contained" style={{ backgroundColor: "#4A88EA" }}>
          Stuur aanvraag
        </Button>
      </div>
    </div>
  );
}

export default Reports;
