import React from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import styles from "./Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { decodeHTMLEntities, truncateText } from "../../utils/Commons";
import PhoneIcon from "@mui/icons-material/Phone";
import LaunchIcon from "@mui/icons-material/Launch";
import EmailIcon from "@mui/icons-material/Email";

const DetailsDialogBox = ({ isOpen, onClose, row, type }) => {
  const { t } = useTranslation();

  const contactMethod = () => {
    switch (row.contactMethod) {
      case "phoneNo":
        return <PhoneIcon />;
      case "email":
        return <EmailIcon />;
      case "link":
        return <LaunchIcon />;
      default:
        // Handle the default case if needed
        return null;
    }
  };

  const contactMethodName = () => {
    switch (row.contactMethod) {
      case "phoneNo":
        return t("apps_and_deals.button.phoneNo");
      case "email":
        return t("apps_and_deals.button.email");

      case "link":
        return t("apps_and_deals.button.link");
      default:
        // Handle the default case if needed
        return null;
    }
  };

  const contactMethodAction = () => {
    switch (row.contactMethod) {
      case "phoneNo":
        return (window.location.href = `tel:${row?.phoneNo}`);
      case "email":
        return (window.location.href = `mailto:${row?.email}`);

      case "link":
        return (window.location.href = row?.linkUrl);
      default:
        // Handle the default case if needed
        return null;
    }
  };

  return (
    <CustomDialogBox
      title={truncateText(row.title, 70)}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="xl"
    >
      <Box position="relative">
        <Box
          component="img"
          className={styles.banner_img_dialog}
          src={`${process.env.REACT_APP_API_BASE_URL}${type}/banners/${row?.banner?.fileName}`}
        ></Box>
        <Paper className={styles.logo_paper_dialog}>
          <Box
            className={styles.logo_img}
            component="img"
            src={`${process.env.REACT_APP_API_BASE_URL}${type}/logos/${row.logo?.fileName}`}
          ></Box>
        </Paper>
      </Box>
      <Stack direction={{ sm: "column", md: "row" }} spacing={3} mt={2}>
        <Paper sx={{ p: 1, width: { sm: "100%", md: "70%" } }} elevation={3}>
          <Stack mt={6} mb={5} ml={1} mr={1}>
            <Typography className={styles.title_text}>{row.title}</Typography>
            <Typography className={styles.location_text}>
              {row.companyName} - {row.companyLocation}
            </Typography>
            <Typography mt={1}>
              {type === "apps"
                ? row?.whyAppIsInteresting
                : row?.whyDealIsInteresting}
            </Typography>
            <Typography
              align="justify"
              dangerouslySetInnerHTML={{
                __html: decodeHTMLEntities(row.description),
              }}
            />
            <Typography
              align="justify"
              dangerouslySetInnerHTML={{
                __html: decodeHTMLEntities(row.companyDescription),
              }}
            />
            <Typography>{row.summary}</Typography>
          </Stack>

          <Stack></Stack>
        </Paper>
        <Stack mt={3} alignItems="center" flex={1} spacing={2}>
          <Button
            // onClick={() => openDialog(`DetailsShow${index}`)}
            variant="contained"
            fullWidth
            color="secondary"
            className={styles.callToActionBtnLbl}
          >
            {truncateText(row.callToActionBtnLbl, 30)}
          </Button>
          <Button
            onClick={() => contactMethodAction()}
            variant="contained"
            fullWidth
            color="success"
            sx={{ color: "white" }}
            startIcon={contactMethod()}
            // className={styles.callToActionBtnLbl}
          >
            {contactMethodName()}
          </Button>
        </Stack>
      </Stack>
    </CustomDialogBox>
  );
};

export default DetailsDialogBox;
