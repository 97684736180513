import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function DeclarationBtw() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Aangiftes
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          BTW aangifte
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          " Als ondernemer dien je verplicht een administratie bij te houden
          voor de btw. Deze administratie wordt bewaard in een boekhouding die
          door de Belastingdienst gecontroleerd kan worden. Hier leggen we uit
          wanneer je de btw-aangifte moet doen en welke mogelijkheden daarvoor
          beschikbaar zijn. "
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor BTW- Aangiftes met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          BTW - aangiftes
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          BTW uit kosten
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          BTW uit verkoopfacturen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          BTW- aangifte indienen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"BTW - aangiftes".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                BTW - aangiftes - Verplicht - wanneer
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Voor bijna elke ondernemer is btw-aangifte verplicht. Zodra je
                ingeschreven bent bij de Kamer van Koophandel, ontvang je
                instructies over hoe en wanneer je btw-aangifte moet doen. Als
                je btw-plichtig bent, moet je btw in rekening brengen voor de
                producten of diensten die je aan klanten levert. Hoewel je btw
                ontvangt, betaal je ook btw voor de goederen of diensten die je
                aankoopt. Als kleine onderneming met een jaaromzet van minder
                dan € 20.000 kun je ervoor kiezen om vrijgesteld te worden van
                btw-plicht.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Wanneer
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Houd moeiteloos overzicht over je btw met Dashbook! Ons
                intuïtieve platform berekent automatisch de btw op je facturen
                en kosten. Geen gedoe, geen zorgen. Bekijk in één oogopslag of
                je geld ontvangt of moet betalen aan de Belastingdienst. En het
                beste van alles? Dashbook zet de benodigde informatie voor je
                klaar, zodat je zonder stress je btw-aangifte kunt doen. Kies
                zelf of je dit per kwartaal, maandelijks of jaarlijks wilt
                regelen. Maak je btw-beheer eenvoudig en efficiënt met Dashbook!
              </Typography>
              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  KOR interessant voor jou?
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"BTW uit kosten".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                BTW uit kosten
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Bij het invoeren van inkoopfacturen, bonnetjes en andere kosten
                berekent Dashbook automatisch de btw. Het platform zorgt ervoor
                dat de btw van kosten op de juiste plaats wordt weergegeven in
                de aangifte en stelt dit automatisch voor je op.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                            variant="contained"
                            style={{ backgroundColor: "#4A88EA" }}
                          >
                            meer info
                          </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"BTW uit verkoopfacturen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                BTW uit verkoopfacturen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Bij het maken en versturen van facturen berekent Dashbook
                automatisch de btw. Het platform zorgt ervoor dat deze btw
                correct wordt weergegeven in de aangifte en stelt dit
                automatisch voor je op.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                            variant="contained"
                            style={{ backgroundColor: "#4A88EA" }}
                          >
                            meer info
                          </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"BTW- aangifte indienen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                BTW- aangifte indienen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Een moeiteloze laatste stap: controleer eenvoudig je overzicht
                en dien vervolgens met slechts één klik je aangifte elektronisch
                in bij de Belastingdienst, direct vanuit Dashbook.
              </Typography>
              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Hoe boek ik investeringen en afschrijvingen?
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default DeclarationBtw;
