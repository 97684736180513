import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function AdminSetup() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Inrichting
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Inrichting administratie in boekhoudsoftware
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          " De boekhoudsoftware biedt uitgebreide functies voor het inrichten
          van de administratie. Dit helpt bedrijven om de software aan te passen
          aan hun specifieke behoeften."
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor inrichten administratie met Dashbook
          </Typography>
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            Artikelen
          </Typography>
        </div>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Overzicht
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Branche gericht
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Project inrichting
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Grootboekrekening
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Overzicht".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Overzicht van actieve subcategorieën
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Met een overzicht van actieve subcategorieën krijg je een
                duidelijk beeld van je inkomsten en uitgaven. Je kunt zien welke
                grootboekrekeningen je gebruikt en hoe deze zijn ingedeeld.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/OverzichtAdminImage.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Branche gericht".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard">
              <img
                src={"./Professional/BrancheAdminImage.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Branche gericht subcategorieënschema ingericht.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Het kan de boekhouding vereenvoudigen: Een branchegericht
                grootboekrekeningschema bevat alleen de grootboekrekeningen die
                relevant zijn voor je branche. Dit kan je helpen om sneller en
                gemakkelijker je boekhouding te voeren. Met Dashbook heb je een
                branchegericht subcategorieën schema binnen handbereik. Kies
                eenvoudig je branche en Dashbook stelt automatisch alle
                relevante subcategorieën in.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Project inrichting".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Project op basis van uurtarief ingericht
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Wil jij sneller en gemakkelijker uren factureren op basis van
                het juiste uurtarief? Laat dan Dashbook je project inrichten op
                uurtarief. Wij bieden ondersteuning voor verschillende
                branche-specifieke uurtarieven, zoals zorg ort uren.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/ProjectAdminImage.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Grootboekrekening".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={5.5}
            lg={5.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="up-div imageCard">
              <img
                src={"./Professional/SubCateImage.png"}
                alt="Dashbook Logo"
                width={"-webkit-fill-available"}
                style={{ maxWidth: "80%" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                SUBCATEGORIEËN
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                "Een grootboekrekening (Subcategorieën) is een account in de
                boekhouding waarin je inkomsten en uitgaven kunt registreren.
                Grootboekrekeningen zijn onderverdeeld in twee types:
                balansrekeningen en resultaatrekeningen (winst & verlies).
                Balansrekeningen geven de activa, passiva en eigen vermogen van
                een bedrijf weer. Resultaatrekeningen geven de opbrengsten en
                kosten van een bedrijf weer."
              </Typography>
              <Typography
                variant="overline"
                display="block"
                align="left"
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "0.3rem 0.6rem",
                  marginBottom: "1rem",
                }}
              >
                Leer meer over grootboekrekening (Subcategorieën)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default AdminSetup;
