import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import { highlightField } from "../../../../utils/Commons";
import API from "../../../../config/axios";
import TextInput from "../../../../components/form/TextInput";
import TextareaAutoSize from "../../../../components/form/TextareaAutoSize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { useSelector } from "react-redux";
import { Paper, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CountriesMenu({
  anchorEl,
  setAnchorEl,
  open,
  setOpen,
  countryCode,
  setCountryCode,
}) {
  const abortController = useRef(new AbortController());

  const [searchCountry, setSearchCountry] = useState("");
  const [filterCountries, setFilterCountries] = useState([]);

  const handleClose = (_, reason) => {
    if (reason === "tabKeyDown") {
      return false;
    }

    setOpen(false);
    setAnchorEl(null);

    setTimeout(() => {
      setFilterCountries(filterCountries);
      setSearchCountry("");
    }, 1000);
  };

  const handleSearchCountry = (e) => {
    const search = e.target.value;

    setSearchCountry(search);

    const searchRegex = new RegExp(`.*${search}.*`, "ig");

    const filtered = filterCountries.filter((c) => !c.name.search(searchRegex));

    setFilterCountries(filtered);
  };

  const getCountries = () => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    API.get("/offer/country/list", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          setFilterCountries(resData.data);
        } else {
          toast.error("Countries cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  const { t } = useTranslation();

  return (
    <Menu
      disableRestoreFocus={true}
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: "250px",
          width: "250px",
          padding: "0px !important",
        },
        sx: { "& .MuiList-root": { paddingTop: "0px" } },
      }}
    >
      <Paper
        sx={{
          position: "sticky",
          top: "0px",
          px: 2,
          py: 2,
          width: "100%",
          zIndex: 5,
        }}
        elevation={0}
        className="search_country_input_container"
      >
        <TextField
          type="text"
          size="small"
          value={searchCountry}
          onChange={handleSearchCountry}
          placeholder={t("search")}
          className="search_country_input"
          fullWidth
        />
      </Paper>
      {filterCountries.map((country) => {
        return (
          <Box
            key={country.identifier}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              py: 1,
              px: 2,
              bgcolor:
                countryCode === country.code ? "rgba(124, 185, 232, 0.2)" : "",
            }}
            className="country_option"
            onClick={() => {
              setCountryCode(country.code);
              handleClose();
            }}
          >
            <img
              loading="lazy"
              width="20"
              height="14"
              src={`https://flagcdn.com/w20/${country.identifier.toLowerCase()}.png`}
              alt=""
            />
            <Typography noWrap>
              {country.name} (+{country.code})
            </Typography>
          </Box>
        );
      })}
      {filterCountries.length === 0 && (
        <Typography variant="body2" textAlign="center" p={1}>
          No Countries Found
        </Typography>
      )}
    </Menu>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const defaultValues = {
  name: null,
  phone: null,
  message: null,
  email: null,
};

function BackofficeService() {
  const [inputErrors, setInputErrors] = useState({});
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  //   const mailController = useRef(new AbortController());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const contactUsValidationSchema = yup.object().shape({
    name: yup.string().required(t("customers.name_required")),
    phone: yup
      .string()
      .required("PhoneNo is required Field")
      .length(10, "Phone number must be exactly 10 digits"),
    message: yup.string(),
    email: yup
      .string()
      .email("Please Enter Valid Email Id")
      .required("Email is required Field"),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(contactUsValidationSchema),
  });

  const onError = (errors) => {
    try {
      if ("message" in errors) {
        console.log({ message: errors.message.message });
        setInputErrors({ message: errors.message.message });
      }
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.log(error, "check");
      console.warn("Field cannot be highlighted");
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("91");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const contactUs = (formData) => {
    console.log(formData, "formData");
    // API.post(`/offer/sendmail/`, formData, {
    //   signal: mailController.current.signal,
    // })
    //   .then((res) => {
    //     const resData = res.data;
    //     if (resData.status) {
    //       toast.success("Contact Us Form Submitted");
    //     } else {
    //       toast.error("Something Went Wrong At Sending contactUs Form Process");
    //     }
    //   })
    //   .catch((error) => {
    //     if (!error.code || error.code !== "ERR_CANCELED") {
    //       toast.error("Something Went Wrong At Sending contactUs Form Process");
    //     }
    //   })
    //   .finally(() => {
    //     reset();
    //     // setIsLoading(false);
    //   });
  };

  useEffect(() => {
    setInputErrors({});
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ backgroundColor: "#F3F3F3" }}>
      <Typography
        variant="h3"
        gutterBottom
        textAlign={"center"}
        sx={{ padding: "2rem", color: "#D0AF88", fontWeight: "300 !important" }}
      >
        Dashbook Backoffice service: Laat je boekhouding uit handen nemen
      </Typography>
      <div style={{ padding: "2rem 6rem", backgroundColor: "#D0AF88" }}>
        <Typography
          variant="h5"
          display="block"
          gutterBottom
          sx={{
            color: "black",
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          Dashbook is een online boekhoudprogramma waarmee je zelf je
          boekhouding kunt doen. Maar als je het toch te druk hebt, er simpelweg
          geen zin in hebt of extra hulp fijn vindt, dan is de Dashbook
          Backoffice service ideaal voor jou.
        </Typography>

        <Typography
          variant="h5"
          display="block"
          gutterBottom
          sx={{ color: "black", fontSize: "1.25rem", marginBottom: "1rem" }}
        >
          Met de Dashbook Backoffice service hoef je je geen zorgen meer te
          maken over je boekhouding. Wij nemen het hele proces voor je uit
          handen, van het inboeken van je facturen tot het opstellen van je
          jaarrekening.
        </Typography>

        <Typography
          variant="h5"
          display="block"
          gutterBottom
          sx={{
            color: "black",
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          Dit zijn de voordelen van de Dashbook Backoffice service:
        </Typography>

        <ul>
          <li style={{ listStyleType: "circle" }}>
            <Typography
              variant="h5"
              display="block"
              gutterBottom
              sx={{ color: "black", fontSize: "1.25rem" }}
            >
              <strong> Gemak: </strong>Laat je boekhouding uit handen nemen door
              professionals
            </Typography>
          </li>
          <li style={{ listStyleType: "circle" }}>
            <Typography
              variant="h5"
              display="block"
              gutterBottom
              sx={{ color: "black", fontSize: "1.25rem" }}
            >
              <strong> Tijdwinst: </strong>Bespaar tijd doordat je je niet meer
              met je boekhouding hoeft bezig te houden
            </Typography>
          </li>
          <li style={{ listStyleType: "circle" }}>
            <Typography
              variant="h5"
              display="block"
              gutterBottom
              sx={{ color: "black", fontSize: "1.25rem" }}
            >
              <strong> Betrouwbaarheid: </strong>Je boekhouding wordt altijd op
              tijd en correct
            </Typography>
          </li>
          <li style={{ listStyleType: "circle" }}>
            <Typography
              variant="h5"
              display="block"
              gutterBottom
              sx={{ color: "black", fontSize: "1.25rem" }}
            >
              <strong> Kwaliteit: </strong>Wij zijn gecertificeerde accountants
              en boekhouders
            </Typography>
          </li>
        </ul>

        <div>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              display: "inline-block",
              color: "black",
              fontSize: "1.25rem",
              marginTop: "1rem",
            }}
          >
            Direct aan de slag?{" "}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "black",
              display: "inline-block",
              fontSize: "1.25rem",
              marginTop: "1rem",
            }}
          >
            Neem contact met ons op voor meer informatie of om een gratis
            offerte aan te vragen.
          </Typography>
        </div>
      </div>
      <div style={{ margin: "0rem 5rem", paddingTop: "3rem" }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                  }}
                >
                  Wat wij doen
                </Typography>

                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Administratie inrichten
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij helpen u om uw administratie in te richten, inclusief
                    het toewijzen van subcategorieën.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Kosten (inkoopfacturen en bonnetjes) inboeken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij boeken uw inkoopfacturen en bonnetjes in, inclusief het
                    toewijzen van grootboekrekeningen, het controleren van
                    gegevens en het opmerken van afwijkingen.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Verkoopfacturen inboeken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij boeken uw verkoopfacturen in, inclusief het toewijzen
                    van grootboekrekeningen, het controleren van gegevens en het
                    opmerken van afwijkingen.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Banktransacties verwerken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij verwerken uw banktransacties, inclusief het controleren
                    van gegevens en het opmerken van afwijkingen.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Maandelijks uw administratie up-to-date houden
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij zorgen ervoor dat uw administratie altijd up-to-date is,
                    zodat u altijd inzicht heeft in uw financiën.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Btw-aangifte indienen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij berekenen en dienen uw btw-aangifte tijdig en correct
                    in.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Aangifte inkomstenbelasting opstellen en indienen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij zorgen ervoor dat u uw aangifte inkomstenbelasting op
                    tijd en correct indient.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Overige administratieve taken ondersteunen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij ondersteunen u bij andere administratieve taken, zoals
                    het opstellen van brieven, het verwerken van post of het
                    bijhouden van een archief.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Opstellen van jaarrekening
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij stellen de jaarrekening van uw onderneming op, inclusief
                    de balans en de winst- en verliesrekening.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Boekhoudkundig advies geven
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij geven u advies over uw boekhouding en helpen u om uw
                    financiële situatie te verbeteren.
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                  }}
                >
                  Extra optie
                </Typography>

                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#1472D0",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Facturen opstellen.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wij kunnen ook je facturen opstellen, maar daarvoor hebben
                    we de relevante gegevens en uren van je nodig. Op basis
                    daarvan kunnen we je factuur juist en volledig opstellen.
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                  }}
                >
                  DB Time Keeper + facturatie Gold
                </Typography>
                <div style={{ display: "flex", gap: "5px" }}>
                  <CloseIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "red",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Verkoopfacturen versturen (optie)
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Gebruik een professioneel facturatieprogramma Dashbook. Dit
                    programma kan je helpen om facturen snel en gemakkelijk te
                    versturen.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Zorg ervoor dat je facturen correct zijn
                    ingevuld. Controleer de gegevens, zoals de datum, het bedrag
                    en de omschrijving.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Voeg alle relevante informatie toe aan je facturen. Dit kan
                    bijvoorbeeld de bankgegevens, de verzendkosten en de
                    betalingsvoorwaarden zijn.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Verstuur je facturen op tijd. Zo zorg je ervoor dat je
                    klanten de facturen op tijd kunnen betalen.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <CloseIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "red",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Rekeningen betalen.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="caption"
                    display="block"
                    style={{ marginLeft: "30px" }}
                    gutterBottom
                  >
                    Tip: Gebruik je zakelijke rekening alleen voor zakelijke
                    betalingen. Dit helpt je om je persoonlijke en zakelijke
                    financiën gescheiden te houden en maakt het voor je
                    boekhouder makkelijker om je administratie te verwerken.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Kies een betalingsmethode die geschikt is voor je
                    bedrijf. Je kunt bijvoorbeeld kiezen voor een
                    bankoverschrijving, een creditcardbetaling of een
                    automatische incasso.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Zorg ervoor dat je betalingen correct zijn. Controleer de
                    gegevens, zoals het bedrag, de tegenrekening, de
                    omschrijving en zorg er altijd voor dat je het factuurnummer
                    erbij vermeld.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Doe je betalingen op tijd. Zo voorkom je boetes of rente.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <CloseIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "red",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Kostenfacturen en bonnetjes doorsturen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Scan of maak foto van je bonnetjes. Dit maakt het
                    gemakkelijker om ze te bewaren en te verwerken. Voeg ze
                    gelijk toe in je administratie.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Doorstuur je inkoopfacturen en bonnetjes naar ons. Zo kunnen
                    we ze verwerken in je administratie.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <CloseIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "red",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                  >
                    Opdrachten binnenhalen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Hier zijn enkele tips voor het binnenhalen van opdrachten:
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Ken je doelgroep. Wat zijn de behoeften en wensen van je
                    klanten?
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Maak een duidelijke boodschap. Wat bied je aan dat je
                    klanten nodig hebben?
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Zorg voor een goede online presence. Zorg ervoor dat je
                    website en sociale media-kanalen goed zijn ingericht en dat
                    ze de juiste informatie bevatten.
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ marginLeft: "30px" }}
                  >
                    Wees proactief. Neem contact op met potentiële klanten en
                    laat ze weten wat je te bieden hebt.
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "2rem 6rem", backgroundColor: "white" }}>
        <Typography variant="h4" gutterBottom textAlign={"center"}>
          Vanaf
        </Typography>
        <Grid
          container
          justifyContent={"space-between"}
          sx={{ paddingTop: "1rem" }}
        >
          <Grid item xs={12} md={3} sm={6} sx={{ padding: "1rem" }}>
            <Box className="borderBox">
              <div className="topText">49,-*</div>
              <div style={{ margin: "15px 0px" }}>
                <Typography
                  variant="body1"
                  display="block"
                  align="center"
                  sx={{
                    color: "black",
                    marginBottom: "1rem",
                  }}
                >
                  Per maand
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sm={6} sx={{ padding: "1rem" }}>
            <Box className="borderBox">
              <div className="topText">89,-*</div>
              <div style={{ margin: "15px 0px" }}>
                <Typography
                  variant="body1"
                  display="block"
                  align="center"
                  sx={{
                    color: "black",
                    marginBottom: "1rem",
                  }}
                >
                  Per maand
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sm={6} sx={{ padding: "1rem" }}>
            <Box className="borderBox">
              <div className="topText">119,-*</div>
              <div style={{ margin: "15px 0px" }}>
                <Typography
                  variant="body1"
                  display="block"
                  align="center"
                  sx={{
                    color: "black",
                    marginBottom: "1rem",
                  }}
                >
                  Per maand
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sm={6} sx={{ padding: "1rem" }}>
            <Box className="borderBox">
              <div className="topText">149,-*</div>
              <div style={{ margin: "15px 0px" }}>
                <Typography
                  variant="body1"
                  display="block"
                  align="center"
                  sx={{
                    color: "black",
                    marginBottom: "1rem",
                  }}
                >
                  Per maand
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign: "center", fontSize: "1.25rem", padding: "1rem 0px" }}
        >
          Nieuwsgierig naar onze diensten? Neem contact met ons op en wij nemen
          binnen 24 uur contact met u op voor een offerte of meer informatie
        </Typography>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Ik wil de Backoffice service" {...a11yProps(0)} />
              <Tab label="Ik wil meer informatie" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(contactUs, onError)}
            >
              <Grid container spacing={2} p={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img
                    alt=""
                    style={{ margin: "1rem auto", display: "block" }}
                    src="./Professional/ContactImage.png"
                    width={"80%"}
                    height={"auto"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid item xs={12}>
                    <TextInput
                      style={{ borderRadius: "5px" }}
                      name="name"
                      type="text"
                      label={"Naam:"}
                      autoFocus={true}
                      control={control}
                      customError={inputErrors.name || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      style={{ borderRadius: "5px" }}
                      name="email"
                      type="email"
                      label={"E-mailadres:"}
                      control={control}
                      customError={inputErrors.email || null}
                      autoFocus={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <TextInput
                        name="phone"
                        label={"Telefoonnummer:"}
                        control={control}
                        customError={inputErrors.phone || null}
                        fullWidth
                        onChangeCallback={(inputValue) => {
                          const newValue = inputValue
                            .replace(/^0+/, "")
                            .replace(/[^0-9]/g, "");
                          setValue("phone", newValue);
                        }}
                        InputProps={{
                          startAdornment: (
                            <Box
                              style={{
                                marginRight: "5px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={handleClick}
                            >
                              +{countryCode}
                              <KeyboardArrowDownIcon
                                sx={{ color: "grey", fontSize: 16 }}
                              />
                            </Box>
                          ),
                        }}
                      />
                      <CountriesMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        open={open}
                        setOpen={setOpen}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <TextareaAutoSize
                      label={"Bericht:"}
                      type="text"
                      name="message"
                      rows={10}
                      control={control}
                      customError={inputErrors.message || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ marginTop: "1rem" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: "green", color: "white" }}
                        sx={{ bgcolor: "green", color: "white" }}
                      >
                        CONTACT NOW
                        <img
                          alt=""
                          src={"./nextWhite.png"}
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(contactUs, onError)}
            >
              <Grid container spacing={2} p={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <img
                    alt=""
                    style={{ margin: "1rem auto", display: "block" }}
                    src="./Professional/ContactImage.png"
                    width={"80%"}
                    height={"auto"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid item xs={12}>
                    <TextInput
                      style={{ borderRadius: "5px" }}
                      name="name"
                      type="text"
                      label={"Naam:"}
                      autoFocus={true}
                      control={control}
                      customError={inputErrors.name || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      style={{ borderRadius: "5px" }}
                      name="email"
                      type="email"
                      label={"E-mailadres:"}
                      control={control}
                      customError={inputErrors.email || null}
                      autoFocus={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <TextInput
                        name="phone"
                        label={"Telefoonnummer:"}
                        control={control}
                        customError={inputErrors.phone || null}
                        fullWidth
                        onChangeCallback={(inputValue) => {
                          const newValue = inputValue
                            .replace(/^0+/, "")
                            .replace(/[^0-9]/g, "");
                          setValue("phone", newValue);
                        }}
                        InputProps={{
                          startAdornment: (
                            <Box
                              style={{
                                marginRight: "5px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={handleClick}
                            >
                              +{countryCode}
                              <KeyboardArrowDownIcon
                                sx={{ color: "grey", fontSize: 16 }}
                              />
                            </Box>
                          ),
                        }}
                      />
                      <CountriesMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        open={open}
                        setOpen={setOpen}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <TextareaAutoSize
                      label={"Bericht:"}
                      type="text"
                      name="message"
                      rows={10}
                      control={control}
                      customError={inputErrors.message || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ marginTop: "1rem" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: "green", color: "white" }}
                        sx={{ bgcolor: "green", color: "white" }}
                      >
                        CONTACT NOW
                        <img
                          alt=""
                          src={"./nextWhite.png"}
                          style={{ marginLeft: "10px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
}

export default BackofficeService;
