import React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";

function DesignPage() {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "2rem", backgroundColor: "#F3F3F3" }}>
      <div style={{ padding: "0px 2rem" }}>
        <div className="prizeBanner">
          <Typography
            variant="h2"
            sx={{ textAlign: "center", color: "white" }}
            gutterBottom
          >
            Functies
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Boekhouden zoals jij dat wilt.
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Alles wat je nodig hebt niet meer en niet minder.
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Dashbook is het online boekhoudprogramma dat met liefde is ontworpen
            door ondernemers, speciaal voor ondernemers zoals jij.
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Met Dashbook wordt je administratie een fluitje van een cent dankzij
            een handige reeks functies.
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            "Ontdek nu de kracht van Dashbook en transformeer je boekhouding en
            bedrijf!"
          </Typography>
          <div style={{ margin: "2rem auto", textAlign: "center" }}>
            <Button variant="contained" sx={{ color: "white" }} onClick={() => navigate("/dashboard")}>
              Nu Gratis Proberen! 00,0 / 14 dagen
            </Button>
          </div>
        </div>
        <div style={{ padding: "2rem" }}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "left",
              color: "#D0AF88",
              fontWeight: "300 !important",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
            gutterBottom
          >
            Urenregistratie
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "left",
              fontWeight: "600 !important",
            }}
            gutterBottom
          >
            "Eenvoudige Urenregistratie met Dashbook: Beschrijf, registreer,
            voeg relatie en project toe, declareer, en bewijs je uren in één
            handige tool."
          </Typography>
          <div style={{ margin: "2rem 0px" }}>
            <Card className="subCard">
              <CardContent>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} md={5} sm={12}>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <DoneIcon
                        style={{
                          height: "15px",
                          margin: "6px 0px",
                          color: "green",
                        }}
                      />
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ color: "black" }}
                      >
                        Registreer je uren op de exacte minuut.
                      </Typography>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <DoneIcon
                        style={{
                          height: "15px",
                          margin: "6px 0px",
                          color: "green",
                        }}
                      />
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ color: "black" }}
                      >
                        Time Keeper: Start een timer terwijl je aan het werk
                        bent.
                      </Typography>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <DoneIcon
                        style={{
                          height: "15px",
                          margin: "6px 0px",
                          color: "green",
                        }}
                      />
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ color: "black" }}
                      >
                        Leg uren vast in het verleden of de toekomst.
                      </Typography>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <DoneIcon
                        style={{
                          height: "15px",
                          margin: "6px 0px",
                          color: "green",
                        }}
                      />
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ color: "black" }}
                      >
                        Voeg een oneindig aantal projecten toe.
                      </Typography>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <DoneIcon
                        style={{
                          height: "15px",
                          margin: "6px 0px",
                          color: "green",
                        }}
                      />
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ color: "black" }}
                      >
                        Maak tijdregistratie leuker en winstgevender voor uw
                        team
                      </Typography>
                    </div>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <DoneIcon
                        style={{
                          height: "15px",
                          margin: "6px 0px",
                          color: "green",
                        }}
                      />
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ color: "black" }}
                      >
                        Uren direct factureren
                      </Typography>
                    </div>
                    <Typography
                      variant="body1"
                      display="block"
                      sx={{
                        color: "#63A7E4",
                        paddingLeft: "30px",
                        marginTop: "1rem",
                      }}
                      gutterBottom
                    >
                      en nog veel meer ….
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} sm={12}>
                    <img
                      src={"./Professional/UrenregistratieIcon.png"}
                      alt="Dashbook Logo"
                      width={"100%"}
                      style={{
                        margin: "auto 0px",
                        display: "block",
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
        <div style={{ padding: "2rem", paddingTop: "0px" }}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "left",
              color: "#D0AF88",
              fontWeight: "300 !important",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
            gutterBottom
          >
            Projecten
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "left",
              fontWeight: "600 !important",
            }}
            gutterBottom
          >
            Met Dashbook kun je moeiteloos projecten aanmaken en aan elke klant
            een eigen uurtarief toewijzen. Je kunt zelfs meerdere projecten per
            klant opzetten.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "left",
              fontWeight: "600 !important",
            }}
            gutterBottom
          >
            "Daarnaast biedt Dashbook de mogelijkheid om interne projecten te
            creëren, zodat je nauwkeurig je uren kunt bijhouden."
          </Typography>
          <div style={{ margin: "2rem 0px" }}>
            <Card className="subCard">
              <CardContent>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} md={5} sm={12}>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Stel aangepaste uurtarieven in voor elke relatie
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Uurtariefsysteem met overwerkuren en weekendtarieven
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Uurtariefsysteem met vaste tarieven en groepdiensten
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Beheer verschillende projecten voor dezelfde klant.
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Houd ook moeiteloos de uren bij voor interne projecten.
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Pas je boekhouding aan zoals jij dat wilt.
                    </Typography>

                    <Typography
                      variant="body1"
                      display="block"
                      sx={{
                        color: "#63A7E4",
                        paddingLeft: "30px",
                        marginTop: "1rem",
                      }}
                      gutterBottom
                    >
                      en nog veel meer ….
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7} sm={12}>
                    <img
                      src={"./Professional/ProjectenIcon.png"}
                      alt="Dashbook Logo"
                      width={"100%"}
                      style={{
                        margin: "auto 0px",
                        display: "block",
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
        <div style={{ padding: "2rem", paddingTop: "0px" }}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "left",
              color: "#D0AF88",
              fontWeight: "300 !important",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
            gutterBottom
          >
            Facturen en offertes
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "left",
              fontWeight: "600 !important",
            }}
            gutterBottom
          >
            "Razendsnelle Facturatie en Verzending Binnen enkele eenvoudige
            stappen kun je moeiteloos facturen creëren en onmiddellijk per
            e-mail verzenden, zowel in PDF- als UBL-formaat. Bovendien biedt
            Dashbook de mogelijkheid om een iDEAL-link toe te voegen! Factureren
            is essentieel voor het innen van betalingen voor je producten of
            diensten. Bij Dashbook begrijpen we dat en maken we het eenvoudig
            voor jou."
          </Typography>

          <div style={{ margin: "2rem 0px" }}>
            <Card className="subCard">
              <CardContent>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} md={5} sm={12}>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Jouw facturen opmaken in je eigen huisstijl
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                        Voeg uren, producten of terugkerende diensten toe
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                        Direct naar je relatie mailen 
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                        Stuur een iDEAL-betaallink mee en krijg sneller betaald
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                        Laat periodieke facturen (abonnementen) automatisch
                      aanmaken
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Snelle Creditering met Slechts één Klik
                    </Typography>

                    <Typography
                      variant="body1"
                      display="block"
                      sx={{
                        color: "#63A7E4",
                        paddingLeft: "30px",
                        marginTop: "1rem",
                      }}
                      gutterBottom
                    >
                      en nog veel meer ….
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
        <div style={{ padding: "2rem", paddingTop: "0px" }}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "left",
              color: "#D0AF88",
              fontWeight: "300 !important",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
            gutterBottom
          >
            Bonnetjes en kosten facturen
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "left",
              fontWeight: "600 !important",
            }}
            gutterBottom
          >
            "In Dashbook hebben we het vereenvoudigd met de 'Inbox'-module, waar
            je moeiteloos al je documenten kunt mailen of uploaden, of het nu
            gaat om facturen, bonnetjes of regelmatige uitgaven. Dit is niet
            alleen praktisch, maar ook van essentieel belang vanwege de
            wettelijke bewaarplicht voor je administratie. Deze verplichting
            houdt in dat je al je facturen, inkoopfacturen en bonnetjes
            gedurende 7 jaar moet bewaren (10 jaar voor onroerende zaken), en nu
            worden deze documenten wettelijk erkend als bewaarde documenten."
          </Typography>

          <div style={{ margin: "2rem 0px" }}>
            <Card className="subCard">
              <CardContent>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} md={5} sm={12}>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Snel bonnetjes scannen met onze mobiele app
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Verwerk je kosten direct vanuit je inbox
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Direct beter zicht op je financiën.
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      style={{ color: "black" }}
                    >
                      Slimme Regels voor Automatische Boekhouding
                    </Typography>

                    <Typography
                      variant="body1"
                      display="block"
                      sx={{
                        color: "#63A7E4",
                        paddingLeft: "30px",
                        marginTop: "1rem",
                      }}
                      gutterBottom
                    >
                      en nog veel meer ….
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesignPage;
