import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

export default function Loading() {
  return (
    <Backdrop
      sx={{
        bgcolor: "rgba(255, 255, 255, 0.1)",
        zIndex: 1101
      }}
      open={true}
    >
      <CircularProgress color="info" size="30px" sx={{ ml: { xs: 0, sm: 0, md: "260px" } }}/>
    </Backdrop>
  );
}
