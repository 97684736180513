import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Card, Grid, Stack, Tab, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import API from "../../../../config/axios";
import styles from "./style.module.css";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";

function Blog() {
  const [value, setValue] = useState("1");
  const abortController = useRef(new AbortController());
  const [rows, setRows] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ** API services for credit list
  const getBlogsList = () => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    // setIsLoading(true);

    let filterValue = ""; // Default filter value

    // Set filterValue based on the value
    if (value === "1") {
      filterValue = "Actueel";
    }
    if (value === "2") {
      filterValue = "Boekhoud Tips";
    }
    if (value === "3") {
      filterValue = "Ondernemen";
    }
    if (value === "4") {
      filterValue = "Boekhouden en factureren";
    }
    if (value === "5") {
      filterValue = "BTW-Aangiftes";
    }
    if (value === "6") {
      filterValue = "Aftrekposten";
    }
    if (value === "7") {
      filterValue = "Inkomstenbelasting";
    }

    API.get("admin/blogs", {
      params: {
        pagination: false,
        filter: filterValue,
      },
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          setRows(resData.data);
          // setTotalRows(resData.data.totalResults || 0);
          // setPage(
          //   resData.data.page > resData.data.totalPages
          //     ? 0
          //     : resData.data.page - 1,
          // );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    getBlogsList();
    // eslint-disable-next-line
  }, [value]);

  return (
    <>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div className="prizeBanner">
          <Typography
            variant="h2"
            sx={{ textAlign: "center", color: "white" }}
            gutterBottom
          >
            Blogs
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Als zzp'er ben je zelf verantwoordelijk voor je boekhouding. Dit kan
            een uitdaging zijn, maar het is ook belangrijk om goed op de hoogte
            te zijn van je financiële situatie. Met onze blogs leer je alles
            over boekhouden voor zzp'ers, van de basis tot de meer geavanceerde
            onderwerpen. Onze blogs zijn geschreven in begrijpelijke taal en
            zijn gericht op zzp'ers die zelf hun boekhouding willen doen.
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Wil je meer weten over boekhouden voor zzp'ers? Lees dan onze blogs!
          </Typography>
          <div style={{ margin: "2rem auto", textAlign: "center" }}>
            <Button variant="contained" sx={{ color: "white" }}>
              Nu Gratis Proberen! 00,0 / 14dagen
            </Button>
          </div>
        </div>
        <div
          className="flex"
          style={{ justifyContent: "flex-start", gap: "1rem" }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "left",
              fontWeight: "300 !important",
              textDecoration: "underline",
              fontStyle: "italic",
            }}
            gutterBottom
          >
            Blogs gemaakt of gepubliceert door Dashbook
          </Typography>
        </div>
        <div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  indicatorColor="secondary"
                >
                  <Tab label="Actueel" value="1" />
                  <Tab label="Boekhoud Tips" value="2" />
                  <Tab label="Ondernemen" value="3" />
                  <Tab label="Boekhouden en factureren" value="4" />
                  <Tab label="BTW- Aangiftes" value="5" />
                  <Tab label="Aftrekposten" value="6" />
                  <Tab label="Inkomstenbelasting" value="7" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
                  {rows?.map((rowData) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={1}>
                        <Card
                          className={styles.mui_card}
                          sx={{
                            ":hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
                            },
                          }}
                        >
                          <Stack
                            justifyContent="space-between"
                            p={1}
                            height="100%"
                          >
                            <Stack>
                              <Box position="relative">
                                <Box
                                  component="img"
                                  className={styles.banner_img}
                                  src={`${process.env.REACT_APP_API_BASE_URL}apps/logos/${rowData?.logo?.fileName}`}
                                ></Box>
                              </Box>
                              <Stack mt={1} mb={5} ml={1} mr={1}>
                                <Typography
                                  className={styles.title_text}
                                  mb={1}
                                >
                                  {dayjs(rowData.date).format("DD MMMM YYYY")} |
                                  &nbsp;
                                  {rowData.blogTypeDetails.name}
                                </Typography>
                                <Typography variant="h4">
                                  {rowData.name}
                                </Typography>
                                <Typography className="h4" mt={1}>
                                  {rowData.description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
                  {rows?.map((rowData) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={1}>
                        <Card
                          className={styles.mui_card}
                          sx={{
                            ":hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
                            },
                          }}
                        >
                          <Stack
                            justifyContent="space-between"
                            p={1}
                            height="100%"
                          >
                            <Stack>
                              <Box position="relative">
                                <Box
                                  component="img"
                                  className={styles.banner_img}
                                  src={`${process.env.REACT_APP_API_BASE_URL}apps/logos/${rowData?.logo?.fileName}`}
                                ></Box>
                              </Box>
                              <Stack mt={1} mb={5} ml={1} mr={1}>
                                <Typography
                                  className={styles.title_text}
                                  mb={1}
                                >
                                  {dayjs(rowData.date).format("DD MMMM YYYY")} |
                                  &nbsp;
                                  {rowData.blogTypeDetails.name}
                                </Typography>
                                <Typography variant="h4">
                                  {rowData.name}
                                </Typography>
                                <Typography className="h4" mt={1}>
                                  {rowData.description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel value="3">
                <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
                  {rows?.map((rowData) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={1}>
                        <Card
                          className={styles.mui_card}
                          sx={{
                            ":hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
                            },
                          }}
                        >
                          <Stack
                            justifyContent="space-between"
                            p={1}
                            height="100%"
                          >
                            <Stack>
                              <Box position="relative">
                                <Box
                                  component="img"
                                  className={styles.banner_img}
                                  src={`${process.env.REACT_APP_API_BASE_URL}apps/logos/${rowData?.logo?.fileName}`}
                                ></Box>
                              </Box>
                              <Stack mt={1} mb={5} ml={1} mr={1}>
                                <Typography
                                  className={styles.title_text}
                                  mb={1}
                                >
                                  {dayjs(rowData.date).format("DD MMMM YYYY")} |
                                  &nbsp;
                                  {rowData.blogTypeDetails.name}
                                </Typography>
                                <Typography variant="h4">
                                  {rowData.name}
                                </Typography>
                                <Typography className="h4" mt={1}>
                                  {rowData.description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel value="4">
                <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
                  {rows?.map((rowData) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={1}>
                        <Card
                          className={styles.mui_card}
                          sx={{
                            ":hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
                            },
                          }}
                        >
                          <Stack
                            justifyContent="space-between"
                            p={1}
                            height="100%"
                          >
                            <Stack>
                              <Box position="relative">
                                <Box
                                  component="img"
                                  className={styles.banner_img}
                                  src={`${process.env.REACT_APP_API_BASE_URL}apps/logos/${rowData?.logo?.fileName}`}
                                ></Box>
                              </Box>
                              <Stack mt={1} mb={5} ml={1} mr={1}>
                                <Typography
                                  className={styles.title_text}
                                  mb={1}
                                >
                                  {dayjs(rowData.date).format("DD MMMM YYYY")} |
                                  &nbsp;
                                  {rowData.blogTypeDetails.name}
                                </Typography>
                                <Typography variant="h4">
                                  {rowData.name}
                                </Typography>
                                <Typography className="h4" mt={1}>
                                  {rowData.description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel value="5">
                <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
                  {rows?.map((rowData) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={1}>
                        <Card
                          className={styles.mui_card}
                          sx={{
                            ":hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
                            },
                          }}
                        >
                          <Stack
                            justifyContent="space-between"
                            p={1}
                            height="100%"
                          >
                            <Stack>
                              <Box position="relative">
                                <Box
                                  component="img"
                                  className={styles.banner_img}
                                  src={`${process.env.REACT_APP_API_BASE_URL}apps/logos/${rowData?.logo?.fileName}`}
                                ></Box>
                              </Box>
                              <Stack mt={1} mb={5} ml={1} mr={1}>
                                <Typography
                                  className={styles.title_text}
                                  mb={1}
                                >
                                  {dayjs(rowData.date).format("DD MMMM YYYY")} |
                                  &nbsp;
                                  {rowData.blogTypeDetails.name}
                                </Typography>
                                <Typography variant="h4">
                                  {rowData.name}
                                </Typography>
                                <Typography className="h4" mt={1}>
                                  {rowData.description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel value="6">
                <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
                  {rows?.map((rowData) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={1}>
                        <Card
                          className={styles.mui_card}
                          sx={{
                            ":hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
                            },
                          }}
                        >
                          <Stack
                            justifyContent="space-between"
                            p={1}
                            height="100%"
                          >
                            <Stack>
                              <Box position="relative">
                                <Box
                                  component="img"
                                  className={styles.banner_img}
                                  src={`${process.env.REACT_APP_API_BASE_URL}apps/logos/${rowData?.logo?.fileName}`}
                                ></Box>
                              </Box>
                              <Stack mt={1} mb={5} ml={1} mr={1}>
                                <Typography
                                  className={styles.title_text}
                                  mb={1}
                                >
                                  {dayjs(rowData.date).format("DD MMMM YYYY")} |
                                  &nbsp;
                                  {rowData.blogTypeDetails.name}
                                </Typography>
                                <Typography variant="h4">
                                  {rowData.name}
                                </Typography>
                                <Typography className="h4" mt={1}>
                                  {rowData.description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
              <TabPanel value="7">
                <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
                  {rows?.map((rowData) => {
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={1}>
                        <Card
                          className={styles.mui_card}
                          sx={{
                            ":hover": {
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
                            },
                          }}
                        >
                          <Stack
                            justifyContent="space-between"
                            p={1}
                            height="100%"
                          >
                            <Stack>
                              <Box position="relative">
                                <Box
                                  component="img"
                                  className={styles.banner_img}
                                  src={`${process.env.REACT_APP_API_BASE_URL}apps/logos/${rowData?.logo?.fileName}`}
                                ></Box>
                              </Box>
                              <Stack mt={1} mb={5} ml={1} mr={1}>
                                <Typography
                                  className={styles.title_text}
                                  mb={1}
                                >
                                  {dayjs(rowData.date).format("DD MMMM YYYY")} |
                                  &nbsp;
                                  {rowData.blogTypeDetails.name}
                                </Typography>
                                <Typography variant="h4">
                                  {rowData.name}
                                </Typography>
                                <Typography className="h4" mt={1}>
                                  {rowData.description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}

export default Blog;
