import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

function OurPackages() {
  return (
    <div style={{ padding: "2rem", backgroundColor: "#F3F3F3" }}>
      <Typography
        variant="h2"
        gutterBottom
        textAlign={"center"}
        sx={{ color: "#D0AF88" }}
      >
        Backoffice pakketen
      </Typography>
      <div style={{ margin: "3rem" }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} md={5.5} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  49,-*
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Membership
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Administratie inrichten
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Kosten (inkoopfacturen en bonnetjes) inboeken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Verkoopfacturen inboeken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Banktransacties verwerken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Max 20 bankmutaties per maand
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Maandelijks uw administratie up-to-date houden
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Incl. Dashbook pakket 'Starters / ZZP'er / MINI'
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Geen Persoonlijk support / ondersteuning
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5.5} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  89,-*
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Membership
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Administratie inrichten
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Kosten (inkoopfacturen en bonnetjes) inboeken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Verkoopfacturen inboeken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Banktransacties verwerken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Max 20 bankmutaties per maand
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Maandelijks uw administratie up-to-date houden
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Btw-aangifte indienen
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Incl. Dashbook pakket "Starters / ZZP'er / MINI"
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Persoonlijk support / ondersteuning
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5.5} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  119,-*
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Membership
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Administratie inrichten
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Kosten (inkoopfacturen en bonnetjes) inboeken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Verkoopfacturen inboeken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Banktransacties verwerken
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Maximaal 600 boekingen per jaar
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Maandelijks uw administratie up-to-date houden
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Btw-aangifte indienen
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Incl. Dashbook pakket "Dashbook Unlimited"
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Persoonlijk support / ondersteuning
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Overige administratieve taken ondersteunen
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Boekhoudkundig advies geven
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5.5} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  149,-*
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Membership
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Administratie inrichten
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Kosten (inkoopfacturen en bonnetjes) inboeken
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Verkoopfacturen inboeken
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Verkoopfacturen opstellen
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Banktransacties verwerken
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Maximaal 900 boekingen per jaar
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Maandelijks uw administratie up-to-date houden
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Btw-aangifte indienen
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Incl. Dashbook pakket "Dashbook Unlimited"
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Persoonlijk support / ondersteuning
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Overige administratieve taken ondersteunen
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Boekhoudkundig advies geven
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Opstellen van jaarrekening
                </Typography>

                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Aangifte inkomstenbelasting opstellen en indienen
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default OurPackages;
