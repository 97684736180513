import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function DeclarationSupplement() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Aangiftes
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Wat is een suppletieaangifte?
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          " Een suppletieaangifte is een belastingaangifte waarmee in Nederland
          een correctie kan worden aangegeven aan de belastingdienst van hetzij
          te veel, hetzij juist te weinig afgedragen btw. "
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor BTW- Aangiftes met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Suppletieaangifte
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Suppletieaangifte door boekhouder
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Zelf Suppletieaangifte indienen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Laat Dashbook uw suppletieaangifte verzorgen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Suppletieaangifte".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Suppletieaangifte
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Wanneer is een suppletieaangifte nodig?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Een suppletieaangifte is nodig als er een fout is gemaakt in een
                eerdere aangifte. Dit kan bijvoorbeeld zijn als:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Je te veel of te weinig btw hebt afgedragen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Je een fout hebt gemaakt in de aangifte.
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Hoe doe je een suppletieaangifte?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Een suppletieaangifte kun je doen via Mijn Belastingdienst. Je
                kunt hier ook het benodigde aangifteformulier vinden.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                De suppletieaangifte moet je binnen vijf jaar na de datum van de
                oorspronkelijke aangifte indienen. Als je de suppletieaangifte
                te laat doet, betaal je boetes.
              </Typography>
              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Lees meer over regels
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Suppletieaangifte door boekhouder".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Suppletieaangifte door boekhouder
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Een boekhouder of accountant kan de suppletieaangifte voor je
                doen. Dit is vaak een goede optie als je niet zelf goed weet hoe
                je de aangifte moet doen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Een boekhouder of accountant heeft de kennis en ervaring om de
                suppletieaangifte correct en tijdig in te dienen. Ook kan een
                boekhouder of accountant je adviseren over de gevolgen van de
                suppletieaangifte voor je belastingaangifte
              </Typography>
              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Lees meer wat heb je nodig ?
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Zelf Suppletieaangifte indienen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Zelf Suppletieaangifte indienen
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Om zelf een suppletieaangifte in te dienen, volg je deze
                stappen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ga naar Mijn Belastingdienst.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Log in met je DigiD.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Klik op "Aangiftes".
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Klik op "Suppletieaangifte".
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Kies het kwartaal of jaar waarvoor je de aangifte wilt doen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Vul de gegevens in het aangifteformulier in.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Controleer de gegevens zorgvuldig.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Klik op "Verzenden".
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Lees meer wat heb je nodig ?
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Inloggen voor ondernemer
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Voor uw belastingzaken logt u in op 'mijn belastingdienst
                zakelijk'. Uw btw-zaken kunt u ook regelen via
                administratiesSoftware of een fiscaal dienstverlener
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ color: "#4A88EA" }}
                gutterBottom
              >
                Zo doet u digitaal btw-aangifte. (EN: How to file digital VAT
                return?)
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  onClick={() => {
                    window.location.href =
                      "https://www.belastingdienst.nl/wps/wcm/connect/nl/ondernemers/content/inloggen-voor-ondernemers";
                  }}
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Naar Mijn Belastingdienst zakelijk
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Laat Dashbook uw suppletieaangifte verzorgen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard">
              <img
                src={"./Professional/SupplimentImage.png"}
                alt="Dashbook Logo"
                width={"70%"}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Laat Dashbook uw suppletieaangifte verzorgen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dashbook neemt de complexiteit uit het proces van
                suppletieaangifte door het voor jou te berekenen. Met ons
                geavanceerde systeem wordt het indienen van een
                suppletieaangifte moeiteloos. Of je nu correcties moet
                aanbrengen in je belastingaangifte of ontbrekende gegevens moet
                toevoegen, Dashbook staat voor je klaar. Vereenvoudig je
                administratieve taken en bespaar tijd met onze intuïtieve
                suppletieberekeningsservice. Ontdek het gemak van nauwkeurige
                berekeningen en soepelere belastingprocessen met Dashbook.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Lees meer wat heb je nodig ?
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        className="flex"
        style={{
          padding: "0px 2rem",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Button variant="contained" style={{ backgroundColor: "#4A88EA" }}>
          Stuur aanvraag
        </Button>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default DeclarationSupplement;
