import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function AdminPurchaseCost() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Administratie
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Kosten en bonnen snel en gemakkelijk verwerken
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Als ondernemer of zzp'er maak je regelmatig kosten. Het is belangrijk
          om deze kosten goed bij te houden, zodat je een goed overzicht hebt
          van je financiën.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden van kosten (bonnen) met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Kosten in je boekhouding
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Kosten in je boekhouding".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Kosten in je boekhouding
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Als ondernemer of zzp'er ben je regelmatig bezig met het maken
                van kosten. Een nauwkeurige registratie van deze kosten is van
                essentieel belang om een duidelijk overzicht te behouden van je
                uitgaven.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Inzicht:</strong>Door kosten te categoriseren en aan
                projecten te koppelen, krijg je gedetailleerd inzicht in je
                belangrijkste kostenposten. Dit stelt je in staat om je uitgaven
                te beheren en te optimaliseren.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Transparantie:</strong> Je kunt je kosten overzichtelijk
                bekijken, zowel per categorie, project als periode. Dit biedt
                een helder beeld van je financiële situatie.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Betrouwbaarheid: </strong>Met de automatische
                registratie van categorisering en projectkoppeling ben je
                verzekerd van een correcte verwerking van al je kosten.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Bonnen Inbox
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default AdminPurchaseCost;
