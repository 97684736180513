import { Typography, Button } from "@mui/material";
import React from "react";

function PrivacyDeclaration() {
  return (
    <div className="pageStart" style={{ backgroundColor: "#F3F4F6" }}>
      <h1 style={{ textAlign: "center", marginBottom: "3rem" }}>
        Privacyverklaring
      </h1>
      <div className="cardPage">
        <p>
          Als jij het boekhoudpakket, de app van Dashbook, een van onze diensten
          gebruikt, of een bezoek brengt aan onze website, leggen wij
          persoonsgegevens van je vast. Wij vinden het belangrijk om jouw
          privacy daarbij zo veel mogelijk te respecteren en te beschermen.
          Daarom gaan we zorgvuldig om met je persoonsgegevens en zijn we
          transparant over het gebruik hiervan. In deze verklaring leggen we uit
          welke gegevens we vastleggen en voor welke doelen we dat doen.
        </p>
        <h3>1. Wie is wie? </h3>
        <p>
          Als je in deze privacyverklaring 'jij', 'je' of 'jou(w)' leest,
          bedoelen we jou als klant van het online boekhoudpakket en/of de apps
          van Dashbook, of als bezoeker van onze website. Lees je 'wij', 'we',
          'ons', of 'onze', dan bedoelen we Dashbook B.V., gevestigd aan de
          Keersopstraat 15D, 3044 EX , in Rotterdam. Dashbook is ingeschreven
          bij de Kamer van Koophandel onder nummer 89981154
        </p>
        <h3>2. Wie is verantwoordelijk voor jouw gegevens? </h3>
        <p>
          Vanuit de privacywetgeving zijn wij de 'verantwoordelijke' voor het
          vastleggen van de persoonsgegevens van onze gebruikers en
          websitebezoekers. Deze privacyverklaring richt zich alleen op die
          persoonsgegevens. Jij bent zelf verantwoordelijk voor het vastleggen
          van de persoonsgegevens van je klanten. Hetzelfde geldt voor de
          gegevens die je in je Dashbook administratie verwerkt via een
          koppeling die door ons wordt aangeboden, bijvoorbeeld met het
          handelsregister van de Kamer van Koophandel. Je kunt deze gegevens
          zelf in je administratie inzien, opslaan, bewerken en verwijderen. Wij
          treden hierbij op als 'verwerker'. Om duidelijk af te spreken wie
          waarvoor verantwoordelijk is, kun je een verwerkersovereenkomst met
          ons afsluiten. Dit kan via het accountbeheer van je administratie in
          Dashbook
        </p>
        <h3>3. Welke gegevens houden we bij en waarom? </h3>
        <p>
          Welke persoonsgegevens we vastleggen en wat de reden hiervan is, hangt
          af van de situatie. Hieronder beschrijven we per situatie wat we
          vastleggen en waarom.
        </p>
        <p>
          a. Als je onze website bezoekt <br />
          We verzamelen gegevens over de bezoekers van onze website,
          bijvoorbeeld hoeveel bezoekers er geweest zijn en vanaf welke pagina
          ze zijn doorverwezen. Welke gegevens we vastleggen, hangt af van welke
          informatie je browser meestuurt. Dit kan bijvoorbeeld informatie zijn
          over je apparaat, je besturingssysteem of het tijdstip waarop je onze
          website hebt bezocht. We gebruiken deze gegevens voor de statistieken
          van onze website en om onze website gebruiksvriendelijker te maken.
          Dit doen we vanuit het zakelijke belang om onze website zo goed
          mogelijk af te stemmen op de bezoekers. Voor deze analyses plaatsen we
          cookies. In onze cookieverklaring kun je lezen welke cookies dit zijn.
        </p>
        <p>
          b. Als je contact met ons opneemt <br />
          Als je contact met ons opneemt via support of als je ons een bericht
          stuurt vanuit je account, leggen we je e-mailadres, voor- en
          achternaam vast. Soms leggen we ook vast welke webbrowser, welk mobiel
          apparaat en/of welke API-client je gebruikt, en vanuit welk bedrijf je
          contact opneemt. We gebruiken deze gegevens om je vragen te
          beantwoorden. Dit hoort bij de uitvoering van onze overeenkomst. Let
          op: Stuur nooit ongevraagd gevoelige persoonsgegevens mee, zoals je
          wachtwoord of volledig creditcardnummer. Als je dit doet, dan brengen
          we je op de hoogte, verwijderen we je bericht en nemen je bericht niet
          in behandeling. Ook kan het zijn dat we het wachtwoord per direct
          resetten en ben je verplicht een nieuw wachtwoord in te stellen.
        </p>
        <p>
          c. Als je je aanmeldt voor Dashbook of als je een betaald abonnement
          afsluit <br />
          Als je je aanmeldt voor Dashbook, moet je je bedrijfsnaam, naam en
          e-mailadres opgeven. Deze gegevens slaan we op in onze administratie.
          Zonder deze gegevens kunnen we de overeenkomst niet aangaan. Daarnaast
          slaan we gegevens op over je bedrijf, zoals contactgegevens,
          KvK-nummer, btw-nummer, SBI code, belanghebbende van je bedrijf
          (UBO’s) en bankrekeningnummer. Deze gegevens zijn verplicht als je een
          betaald abonnement wilt afsluiten of als je aanvullende diensten wilt
          gebruiken waarvoor wij extra verificaties moeten uitvoeren, zoals
          bijvoorbeeld bij Peppol of Dashbook Betaalmethoden.
          <br />
          <br />
          We gebruiken je gegevens om de overeenkomst te kunnen uitvoeren en
          voor de volgende doelen:
          <ul>
            <li>onze dienst te kunnen leveren; </li>
            <li>verificaties uit te voeren voor bepaalde diensten; </li>
            <li> om support te kunnen leveren; </li>{" "}
            <li>facturen te sturen; </li>
            <li>
              te analyseren waar eventuele fouten zitten in de applicatie die je
              gebruikt.{" "}
            </li>
          </ul>
          Daarnaast gebruiken wij je e-mailadres voor het versturen van
          nieuwsbrieven en andere serviceberichten. Hierbij hebben we een
          commercieel belang. Je kunt je hiervoor altijd afmelden via de
          uitschrijflink onderaan de nieuwsbrief of het bericht. Als je geen
          klant bij ons bent dan sturen we je alleen een nieuwsbrief als je ons
          hiervoor toestemming hebt gegeven. Tot slot houden we bij hoe je klant
          bij ons bent geworden. Bijvoorbeeld of je via een advertentie klant
          bent geworden of via een doorverwijzer (referral). We doen dit om de
          effectiviteit te meten van de wervingscampagnes die wij voeren, zoals
          via Google AdWords en doorverwijsacties (referrals). Het is voor ons
          van belang om die informatie bij te houden.
        </p>
        <p>
          d. Als je gebruikmaakt van de diensten van Dashbook <br />
          Wij vinden het belangrijk om onze diensten zo goed mogelijk te laten
          aansluiten op de wensen van onze gebruikers. We zijn dan ook constant
          bezig om onze diensten te verbeteren. Daarvoor analyseren we gegevens
          over onze gebruikers. Die gegevens zijn geanonimiseerd en in zeer
          beperkte mate te herleiden tot individuen <br />
          <br />
          Deze informatie helpt ons om:
          <ul>
            <li>beter te begrijpen hoe onze applicatie wordt gebruikt;</li>
            <li>
              inzicht te krijgen in globale bedrijfstrends en statistieken;
            </li>
            <li>onze diensten gericht te verbeteren.</li>
          </ul>
          Ook gebruiken we deze analyses voor het schrijven van blogberichten of
          andere marketing- of communicatie-uitingen. We presenteren dan geen
          gegevens die tot individuele gebruikers herleidbaar zijn, maar we
          presenteren wel trends en statistieken over grote groepen gebruikers.
          Verder gebruiken we op beperkte schaal gegevens, bijvoorbeeld een
          kleine verzameling van facturen, om onze algoritmes te verbeteren. We
          hebben hier een belang bij en hebben extra waarborgen genomen zodat er
          zorgvuldig met die gegevens omgegaan wordt.
        </p>
        <p>
          e. Als je je bij ons wilt aansluiten als adviseur <br />
          Als je wilt, kun je je op onze website aanmelden als adviseur. Bij je
          aanmelding vul je jouw gegevens in. Dit kunnen je naam, adres,
          contactgegevens of andere persoonsgegevens zijn. Daarbij geef je ons
          toestemming om de gegevens op te slaan en te publiceren op onze
          website. Op die manier kunnen onze klanten jou als adviseur vinden. We
          verzamelen en publiceren ook recensies over jou als adviseur, die
          worden geschreven door onze klanten. Ook hier geef je ons toestemming
          voor bij jouw aanmelding als adviseur.
        </p>
        <p>
          f. Als je een recensie op onze website plaatst <br />
          We zijn heel blij met recensies over Dashbook of over adviseurs. Via
          onze applicatie kun je een recensie achterlaten. Deze slaan wij dan op
          in onze database en publiceren we na jouw toestemming op onze website.
          We leggen alleen je recensie, je geslacht en je naam vast. Geef je ook
          je bedrijfsnaam door, dan slaan we die ook op. Verder sturen we
          recensies over Dashbook door naar partners, die de recensies vindbaar
          maken voor zoekmachines en vergelijkingswebsites.
        </p>
        <p>
          g. Als je onze Facebookpagina bezoekt <br />
          Als je onze Facebookbedrijfspagina bezoekt, dan zijn we daar
          gezamenlijk met Facebook verantwoordelijk voor. We ontvangen van
          Facebook op anonieme basisinformatie over het bezoek van onze pagina.
          Facebook plaatst hiervoor cookies of andersoortige technologieën. Als
          je op onze Facebookpagina reageert op berichten, ons vragen stelt of
          bijvoorbeeld een bericht liket, dan verwerken we die gegevens ook van
          je. Lees de privacyverklaring van Facebook om informatie te krijgen
          hoe Facebook met je privacy omgaat.
        </p>
        <p>
          h. Overige doelen <br /> Tot slot gebruiken we jouw e-mailadres,
          vestigingsadres en/of naam om te factureren, om vorderingen te innen,
          om onderzoek te doen naar je kredietwaardigheid en voor onze
          boekhouding. Ook gebruiken we deze gegevens om eventuele klachten
          en/of geschillen met jou te behandelen. Dit doen we om onze
          overeenkomst goed te kunnen uitvoeren.
        </p>
        <h3>4. Hoe hebben we jouw gegevens beveiligd?</h3>
        <p>
          Om je persoonsgegevens te beveiligen hebben we passende technische en
          organisatorische maatregelen genomen. De keuze van deze maatregelen
          hebben we gebaseerd op de beschikbare technologie, de
          uitvoeringskosten, het type persoonsgegevens dat we van jou verwerken
          en de risico's die daaraan verbonden zijn. Zo heeft onze website
          bijvoorbeeld een TLS-certificaat en maken we gebruik van een firewall
          om misbruik te voorkomen. Ook zijn we ISO 27001 gecertificeerd.
        </p>
        <p>
          Daarnaast hebben we een responsible disclosure beleid. Dat betekent
          dat we - naast het feit dat we zelf actief zoeken naar kwetsbaarheden
          in ons systeem - ook open staan voor meldingen van kwetsbaarheden die
          ontdekt zijn door anderen. We lossen deze gemelde kwetsbaarheden zo
          spoedig mogelijk op. Je leest hier meer over op 
          <a href="https://www.Dashbook.nl/security/" target="blank">
            https://www.Dashbook.nl/security/
          </a>
        </p>
        <h3>
          5. Met wie delen we je gegevens? Van wie ontvangen we gegevens? 
        </h3>
        <p>
          Binnen Dashbook hebben verschillende mensen toegang tot jouw gegevens.
          Dit kunnen alleen medewerkers van Dashbook zijn die de gegevens nodig
          hebben voor de uitoefening van hun functie.
        </p>
        <p>
          We delen zo min mogelijk persoonsgegevens met anderen. We zullen je
          persoonlijke gegevens en die uit je administratie nooit verkopen of
          verhuren. We delen je gegevens alleen:
        </p>
        <ul>
          <li>
            als dit wettelijk verplicht is. Zo kan het zijn dat we vanuit de wet
            moeten meewerken aan een onderzoek van het Openbaar Ministerie,
            bijvoorbeeld bij een verdenking van fraude. In dat geval delen we
            alleen de persoonsgegevens die we volgens de wet voor een dergelijk
            onderzoek moeten verstrekken;
          </li>
          <li>
            als dit past bij de doelen waarvoor we je gegevens vastleggen en als
            we dat mogen van de wet, bijvoorbeeld omdat je ons toestemming hebt
            gegeven om bepaalde gegevens te delen.
          </li>
        </ul>
        <p>
          We kunnen je persoonsgegevens delen binnen de groep van
          Dashbook-bedrijven, die horen bij Dashbook. Daarnaast kunnen we je
          gegevens delen met onze partners, die bepaalde diensten voor ons
          verzorgen (juridisch gezien: verwerkers). Deze partners kunnen in
          opdracht van ons jouw persoonsgegevens vastleggen of gebruiken. Met
          deze partners hebben we goede afspraken gemaakt over de geheimhouding
          en beveiliging van jouw persoonsgegevens. Sommige partners zijn
          gevestigd buiten de Europese Unie of de Europese Economische Ruimte.
          Met deze partijen hebben we goede afspraken gemaakt over de
          beveiliging en geheimhouding van jouw persoonsgegevens, of hebben we
          additionele beveiligingsmaatregelen of Standard Contractual Clauses
          afgesloten.
        </p>
        <p>
          Let op: Op de website van Dashbook staan diverse links naar websites
          van andere bedrijven en instanties. Ook kun je op onze website
          koppelingen maken met applicaties (API’s) van andere bedrijven.
          Dashbook is niet verantwoordelijk voor de naleving van de
          privacywetgeving door deze bedrijven.
        </p>
        <h3>6. Hoe lang bewaren we je gegevens? </h3>
        <p>
          We bewaren je persoonsgegevens niet langer dan noodzakelijk is voor
          het doel waarvoor we ze hebben vastgelegd. Soms verplicht de wet ons
          om gegevens een bepaalde tijd te bewaren. Zo moeten we voor de
          Belastingdienst facturen, met daarin je persoonsgegevens, 10 jaar
          bewaren. We houden ons aan deze wettelijke termijnen. Verder houden we
          ons aan de volgende termijnen:
        </p>
        <ul>
          <li>
            Als je een gebruikersaccount hebt aangemaakt, blijven je gegevens
            bewaard zolang je gebruikmaakt van dit account. Nadat je je account
            hebt opgezegd, verwijderen we de gegevens binnen 1 maand definitief.
            Het gaat dan alleen om de gegevens die we niet meer nodig hebben. Al
            deze gegevens bewaren we 10 jaar: overeenkomsten, waaronder
            verwerkersovereenkomsten en de overeenkomst tot gebruik van
            Dashbook; de data waarop deze overeenkomsten zijn afgesloten en
            opgezegd; het bewijs van verificatie van jouw gegevens.
          </li>
          <li>
            Hebben we een recensie van jou op onze website geplaatst, dan
            verwijderen we deze als jij ons daarom vraagt. We publiceren ook
            recensies via onze partners, bijvoorbeeld op vergelijkingswebsites,
            daar zullen we je review ook verwijderen.
          </li>
          <li>
            Correspondentie die je met onze supportafdeling hebt gevoerd,
            bewaren we 1 jaar. Deze bewaartermijn kan langer zijn als het gaat
            om correspondentie die bijvoorbeeld als bewijs kan dienen als we een
            conflict hebben, of bij een faillissement.
          </li>
          <li>
            Ben je adviseur, dan publiceren we jouw gegevens op onze website.
            Deze gegevens blijven daar gepubliceerd totdat je aangeeft dat je
            deze verwijderd wilt hebben. Dan doen we dit voor je.
          </li>
          <li>
            We maken continu backups van de gegevens die je bij ons opslaat.
            Backups slaan we voor maximaal 45 dagen op. Dit betekent dat
            verwijderde gegevens nog maximaal 45 dagen in zo’n back-up bewaard
            kunnen blijven.
          </li>
          <li>
            Analytische gegevens, waaronder uit Google Analytics, verwijderen we
            na 26 maanden.
          </li>
          <li>
            Staan er andere gegevens van jou op onze website, dan kun je die
            altijd laten verwijderen. Neem hiervoor contact met ons op. We
            zullen je gegevens dan zo spoedig mogelijk offline halen en
            verwijderen.
          </li>
        </ul>
        <h3>7. Welke rechten heb je als het gaat om jouw privacy?</h3>
        <p>
          Volgens de privacywetgeving heb je een aantal rechten. Zo kun je ons
          vragen om:
        </p>
        <ul>
          <li>
            inzage te krijgen in de persoonsgegevens die we van jou verwerken;
          </li>
          <li>
            persoonsgegevens die feitelijk onjuist zijn, te corrigeren, te
            verwijderen of af te schermen;
          </li>
          <li>het gebruik van je persoonsgegevens te beperken;</li>
          <li>
            je persoonsgegevens (digitaal) aan jou over te dragen
            (dataportabiliteit). Dit recht geldt alleen voor persoonsgegevens
            die we hebben vastgelegd om de overeenkomst met jou te kunnen
            uitvoeren en voor persoonsgegevens die we met jouw toestemming
            hebben vastgelegd.
          </li>
        </ul>
        <p>
          Ook kun je bezwaar maken tegen het vastleggen van je persoonsgegevens.
          Heb je ons toestemming gegeven om bepaalde gegevens van jou te
          verwerken, dan kun je die toestemming altijd weer intrekken. Na het
          intrekken van je toestemming verwijderen we je gegevens. We kunnen
          hier mogelijk van afzien als we je gegevens ook nodig hebben voor een
          ander doel, waarvoor geen toestemming vereist is. In dat geval bewaren
          we je gegevens voor dat doel.
        </p>
        <h3>8. Hoe kun je gebruik maken van bovenstaande rechten? </h3>
        <p>
          Om gebruik te maken van bovenstaande rechten, kun je verschillende
          dingen doen: - Via jouw gebruikersaccount of accountbeheer kun je een
          aantal persoonsgegevens inzien, aanpassen of verwijderen. - Als je
          onze nieuwsbrief niet meer wilt ontvangen, kun je je toestemming
          intrekken via de afmeldlink onderaan de nieuwsbrief. Je ontvangt
          daarna geen nieuwsbrieven meer van ons. - Via je accountbeheer kun je
          de persoonsgegevens die we van je vastgelegd hebben en waarvoor we
          verantwoordelijk zijn, downloaden. Het gaat dan bijvoorbeeld om de
          facturen die we je gestuurd hebben voor het gebruik van Dashbook, of
          om de contactgegevens die we van je hebben opgeslagen. We hebben geen
          zeggenschap over de gegevens die je in je Dashbook-administratie hebt
          opgeslagen en waarvoor wij 'verwerker' zijn. Die gegevens vind je
          daarom niet terug in het overzicht, maar deze kun je zelf inzien in
          jouw administratie. Als je van je rechten gebruik wilt maken, kun je
          ons ook mailen via 
          <a
            href="https://support@Dashbook.nl"
            rel="noopener noreferrer"
            target="_blank"
          >
            support@Dashbook.nl.
          </a>
          Of je kunt een brief sturen naar: Dashbook, Keersopstraat 15D, 3044 EX
          , in Rotterdam. Voordat we je verzoek in behandeling nemen, kunnen we
          je vragen om je te identificeren. Daarna ontvang je binnen een maand
          een reactie van ons. In bepaalde gevallen mogen we je verzoek geheel
          of gedeeltelijk weigeren. We leggen je dan duidelijk uit waarom we dat
          doen.
        </p>
        <h3>9. Wat kun je doen als je een privacyklacht hebt? </h3>
        <p>
          Heb je een klacht over hoe we met je persoonsgegevens omgaan, dan
          vragen we je om deze klacht eerst aan ons te melden via  
          <a
            href="https://support@Dashbook.nl"
            rel="noopener noreferrer"
            target="_blank"
          >
            support@Dashbook.nl.
          </a>
          Dit kun je bijvoorbeeld doen als je vermoedt dat er misbruik van je
          gegevens is gemaakt, of dat er een datalek is geweest. We zoeken dan
          samen met jou naar een oplossing. Daarnaast heb je altijd het recht om
          een klacht in te dienen bij de Autoriteit Persoonsgegevens. Dit kun je
          doen op de website 
          <a
            href="https://www.autoriteitpersoonsgegevens.nl/"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.autoriteitpersoonsgegevens.nl.
          </a>
           (opent in nieuw tabblad)
        </p>
        <h3>10. Wat gebeurt er als we deze verklaring wijzigen? </h3>
        <p>
          We kunnen deze privacyverklaring af en toe wijzigen. Dit doen we
          bijvoorbeeld als we meer of andere gegevens van je willen vastleggen.
          Als we de verklaring wijzigen, laten we je dit altijd ruim van tevoren
          per e-mail of via onze website weten. Heb je nog vragen? Stel ze
          gerust via 
          <a href="https://support@Dashbook.nl"> support@Dashbook.nl.</a> We
          streven ernaar om binnen een werkdag antwoord te geven.
        </p>
        <p>Versie 1, maart 2024</p>
        <div className="prizeBanner">
          <Typography
            variant="h3"
            sx={{ textAlign: "center", color: "white" }}
            gutterBottom
          >
            Begin direct en probeer Dashbook vrijblijvend een maand gratis
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Test gratis voor 14 dagen
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
            Meld je nu aan en start je gratis proefperiode
          </Typography>

          <div style={{ textAlign: "center" }}>
            <Button variant="contained" sx={{ color: "white" }}>
              Nu Gratis Proberen! 00,0 / 14dagen
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyDeclaration;
