import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const currentYear = dayjs().year();

const initialState = {
  fromMonth: {
    name: "1",
    value: "0",
  },
  toMonth: {
    name: "12",
    value: "11",
  },
  fromYear: {
    name: currentYear,
    value: currentYear,
  },
  toYear: {
    name: currentYear,
    value: currentYear,
  },
  decimal: true,
};

export const yearsSlice = createSlice({
  name: "years",
  initialState,
  reducers: {
    setFromYears: (state, action) => {
      state.fromYear = action.payload;
    },
    setToYears: (state, action) => {
      state.toYear = action.payload;
    },
    setFromMonth: (state, action) => {
      state.fromMonth = action.payload;
    },
    setToMonth: (state, action) => {
      state.toMonth = action.payload;
    },
  },
});

export const { setFromMonth, setToYears, setToMonth, setFromYears } =
  yearsSlice.actions;

export default yearsSlice.reducer;
