import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Change({ setPage }) {
  const navigate = useNavigate();

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div style={{ padding: "2rem" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Overstappen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Dashbook is zo gebruiksvriendelijk dat iedereen eenvoudig zelf kan
          beginnen. Daarnaast bieden wij een gratis overstapservice aan.
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button variant="contained" sx={{ color: "white" }} onClick={()=>navigate('/dashboard')}>
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="flex"
        style={{ justifyContent: "space-around", gap: "1rem" }}
      >
        <Typography variant="h4" className="titleUponDivider" gutterBottom>
          Gratis overstapservice
        </Typography>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Opstarthulp
        </Button>
      </div>

      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Opstarthulp".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Opstarthulp
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Onze Persoonlijke Ondersteuning
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Wij staan klaar om je op maat te helpen, precies zoals jij dat
                wenst.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Of het nu gaat om een gratis telefonisch consult of
                ondersteuning via e-mail, wij passen ons aan jouw voorkeur aan.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Wij kunnen je assisteren met:
              </Typography>
              <Typography variant="subtitle1">
                * Het koppelen van je bank
              </Typography>
              <Typography variant="subtitle1">
                * Het invoeren van je beginbalans
              </Typography>
              <Typography variant="subtitle1">* En nog veel meer</Typography>
              <Typography variant="subtitle1">
                Ons doel is om het zo eenvoudig mogelijk voor je te maken om met
                Dashbook aan de slag te gaan.
              </Typography>
              <div className="flex" style={{ gap: "1rem", marginTop: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green" }}
                  onClick={() => setPage("contact_us")}
                >
                  Contact ons
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Change;
