import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { highlightField } from "../../../utils/Commons";
import TextInput from "../../../components/form/TextInput";

import CustomBtn from "../../../components/CustomBtn";
import { useState } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import API from "../../../config/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CountriesMenu from "../../../components/CountriesMenu";
import { useSelector } from "react-redux";
import AutocompleteInput from "../../../components/form/AutocompleteInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-hot-toast";
import { useEffect } from "react";

const defaultValues = {
  name: "", // Set default values here
  email: "",
  phoneNo: "",
  countryId: null,
  city: "",
  postalCode: "",
  address: "",
  countryCode: "",
  chamberOfCommerceNo: "",
  vatNo: "",
  bankAccountNumber: "",
  bankAccountName: "",
};

const EditCompanyDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("company.companyNameRequired")),
    email: yup
      .string()
      .email(t("customers.email_invalid"))
      .required(t("customers.email_required")),
    phoneNo: yup.string().nullable(),
    countryId: yup.object().required(t("company.countryRequired")),
    city: yup.string().required(t("company.placeRequired")),
    postalCode: yup.string().required(t("company.postalCodeRequired")),
    address: yup.string().required(t("company.addressRequired")),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const abortController = useRef(new AbortController());
  const [countryCode, setCountryCode] = useState("91");
  const countries = useSelector((state) => state.common.countries);

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onSubmit = (data) => {
    const {
      name, // Set default values here
      email,
      phoneNo,
      countryId,
      city,
      postalCode,
      address,
      chamberOfCommerceNo,
      vatNo,
      bankAccountNumber,
      bankAccountName,
    } = data;
    const formData = {
      name, // Set default values here
      email,
      phoneNo,
      countryId: countryId.id || null,
      city,
      postalCode,
      address,
      countryCode: countryCode,
      chamberOfCommerceNo,
      vatNo,
      bankAccountNumber,
      bankAccountName,
    };

    setIsLoading(true);
    const requestStartTime = Date.now();
    API.put(`users/settings/company`, formData)
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status) {
          toast.success(t("company.company_details_updated_successfully"));

          // navigate("/period", { replace: true });
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
            try {
              highlightField(Object.keys(resData.error)[0]);
            } catch (error) {
              console.warn("Field cannot be highlighted");
            }
          } else {
            toast.error(resData?.error?.message || "Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };

  const getCompanyDetails = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    try {
      const res = await API.get(`users/settings/company`, {
        signal: abortController.current.signal,
      });
      const resData = res.data;

      if (resData.status) {
        const {
          name, // Set default values here
          email,
          phoneNo,
          countryId,
          city,
          postalCode,
          address,
          countryCode,
          chamberOfCommerceNo,
          vatNo,
          bankAccountNumber,
          bankAccountName,
        } = resData.data.company;
        setCountryCode(countryCode);

        reset({
          name: name,
          email,
          phoneNo,
          countryId,
          city,
          postalCode,
          address,
          chamberOfCommerceNo,
          vatNo,
          bankAccountNumber,
          bankAccountName,
        });
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getCompanyDetails();

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Stack
        direction="row"
        sx={{ mb: 2 }}
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <IconButton onClick={() => navigate("/setting/company")}>
          <ArrowBackIcon />
        </IconButton>
        <Typography component="h1" sx={{ fontSize: "20px", flexGrow: 1 }}>
          {t("company.edit_company_information")}
        </Typography>
      </Stack>
      <Paper
        sx={{ backgroundColor: "primaryBg.light", borderRadius: 2, p: 2 }}
        elevation={2}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container spacing={3} columns={12}>
            <Grid item xs={12} md={6}>
              <TextInput
                name="name"
                type="text"
                label={t("company.fields.company_name")}
                autoFocus={true}
                control={control}
                customError={inputErrors.name || null}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="email"
                type="text"
                label={t("company.fields.email")}
                autoFocus={true}
                control={control}
                customError={inputErrors.email || null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="phoneNo"
                label={t("company.fields.phoneNo")}
                control={control}
                customError={inputErrors.phoneNo || null}
                fullWidth
                onChangeCallback={(inputValue) => {
                  const newValue = inputValue
                    .replace(/^0+/, "")
                    .replace(/[^0-9]/g, "");

                  setValue("phoneNo", newValue);
                }}
                InputProps={{
                  startAdornment: (
                    <Box
                      style={{
                        marginRight: "5px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleClick}
                    >
                      +{countryCode}
                      <KeyboardArrowDownIcon
                        sx={{ color: "grey", fontSize: 16 }}
                      />
                    </Box>
                  ),
                }}
              />
              <CountriesMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                setOpen={setOpen}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutocompleteInput
                name="countryId"
                type="number"
                label={t("company.fields.country")}
                control={control}
                customError={inputErrors.countryId || null}
                fullWidth
                options={countries}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    className={
                      option.isGroupLabel
                        ? "option_group_label"
                        : "option_group_option MuiAutocomplete-option"
                    }
                  >
                    <Box
                      key={option.identifier}
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },

                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        height="14"
                        src={`https://flagcdn.com/w20/${option.identifier.toLowerCase()}.png`}
                        alt=""
                      />
                      <Typography noWrap>{option.name}</Typography>
                    </Box>
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="city"
                type="text"
                label={t("company.fields.place")}
                autoFocus={true}
                control={control}
                customError={inputErrors.city || null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="postalCode"
                label={t("company.fields.postalCode")}
                autoFocus={true}
                control={control}
                customError={inputErrors.postalCode || null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="address"
                type="text"
                label={t("company.fields.address")}
                autoFocus={true}
                control={control}
                customError={inputErrors.address || null}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="chamberOfCommerceNo"
                type="text"
                label={t("company.fields.chamberOfCommerceNo")}
                autoFocus={true}
                control={control}
                customError={inputErrors.chamberOfCommerceNo || null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="vatNo"
                type="text"
                label={t("company.fields.vatNo")}
                autoFocus={true}
                control={control}
                customError={inputErrors.vatNo || null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="bankAccountNumber"
                type="text"
                label={t("company.fields.bankAccountNumber")}
                autoFocus={true}
                control={control}
                customError={inputErrors.bankAccountNumber || null}
                placeholder="NL00BANK123456789"
              />
            </Grid>{" "}
            <Grid item xs={12} md={6}>
              <TextInput
                name="bankAccountName"
                type="text"
                label={t("company.fields.bankAccountName")}
                autoFocus={true}
                control={control}
                customError={inputErrors.bankAccountName || null}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={3}>
                <CustomBtn
                  variant="contained"
                  color="secondary"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                  disableRipple
                  type="submit"
                  loading={isLoading}
                >
                  {t("common_btn.submit")}
                </CustomBtn>
                <CustomBtn
                  variant="outlined"
                  color="secondary"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                  onClick={() =>
                    navigate("/setting/company", { replace: true })
                  }
                >
                  {t("common_btn.cancel")}
                </CustomBtn>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default EditCompanyDetails;
