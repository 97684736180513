import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import api from "../../config/axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import useDialogHooks from "../../components/Hooks/useDialogHooks";
import AcceptDialogBox from "./AcceptDialogBox";
import { useLayoutEffect } from "react";

const AcceptOffers = () => {
  const { t } = useTranslation();
  const { offerToken } = useParams();
  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();
  const [offersdata, setOffersData] = useState();
  const [accept, setAccept] = useState(true);
  const [expireOffers, setExpireOffers] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const abortController = useRef(new AbortController());

  const getOffers = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    try {
      const res = await api.post(`public/offers/get_details`, {
        offerToken: offerToken,
      });
      const resData = res.data;

      if (resData.status) {
        setOffersData(resData.data);
      } else {
        if (resData?.type === "NOT_FOUND") {
          setExpireOffers(true);
          toast.error(resData.error.message);
        }
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  useLayoutEffect(() => {
    getOffers();
    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Container maxWidth="lg">
      <Grid item xs={12}>
        <Stack alignItems="center" mt={4}>
          <img src={"/logo.png"} alt="Dashbook Logo" width="30%" />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems="center" mt={4}>
          <Paper sx={{ width: "60%" }} elevation={3}>
            <Stack mb={2} mt={2}>
              <Typography variant="h4" align="center">
                Offerte van
              </Typography>
              <Divider sx={{ mt: 1, mb: 2 }} />
              {!accept && (
                <Box bgcolor="#00ca7e" p={3} mb={1}>
                  <Typography color="white" fontSize="18px">
                    {t("offers.offers_Accept")}
                  </Typography>
                  <Typography color="white" fontSize="18px">
                    00:{seconds}
                  </Typography>
                </Box>
              )}
              <Stack>
                <table>
                  <tr>
                    <td align="right" width="50%">
                      {t("offers.email_send.offers_number")} :
                    </td>
                    <td>{offersdata?.offerNoFormatted}</td>
                  </tr>
                  <tr>
                    <td>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </td>
                    <td>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </td>
                  </tr>
                  <tr>
                    <td align="right">{t("offers.email_send.date")} :</td>

                    <td>{dayjs(offersdata?.date).format("DD-MM-YYYY")}</td>
                  </tr>
                  <tr>
                    <td>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </td>
                    <td>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </td>
                  </tr>
                  <tr>
                    <td align="right">
                      {t("offers.email_send.expiration_date")} :
                    </td>
                    <td>
                      {offersdata?.expirationDate
                        ? dayjs(offersdata?.expirationDate).format("DD-MM-YYYY")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </td>
                    <td>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </td>
                  </tr>
                  <tr>
                    <td align="right">{t("offers.email_send.amount")} :</td>
                    <td>
                      {offersdata?.total?.toLocaleString("nl-NL", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </td>
                  </tr>
                </table>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
        {!accept ||
          (!expireOffers && (
            <Stack direction="row" justifyContent="space-evenly" mt={3}>
              <Button
                variant="contained"
                color="success"
                sx={{ color: "white" }}
                size="medium"
                onClick={() => openDialog("acceptOffer")}
              >
                {t("common_btn.offer_accept")}
              </Button>
              {/* <Button variant="contained" color="inherit">
                Download PDF
              </Button> */}
            </Stack>
          ))}
      </Grid>
      <Grid></Grid>
      <AcceptDialogBox
        isOpen={isDialogOpen("acceptOffer")}
        onClose={() => closeDialog("acceptOffer")}
        offersdata={offersdata}
        accept={accept}
        setAccept={setAccept}
        setSeconds={setSeconds}
      />
    </Container>
  );
};

export default AcceptOffers;
