import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";

import SaveIcon from "@mui/icons-material/Save";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import style from "./purchase.module.css";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const AddInvoiceDrawer = (props) => {
  const {
    isTablet,
    isDrawerOpen,
    toggleDrawer,
    handleSubmit,
    onSubmit,
    onError,
    showInvoiceConfirmationDialog,
    documents,
    backPath,
    type = "purchase",
    difference = 0,
    showToCopy = true,
    showSaveNew = true,
    showFile = true,
  } = props;

  const [showButtons, setShowButtons] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDropdownClick = (event) => {
    setShowButtons(!showButtons);
  };

  return (
    <Stack>
      <Drawer
        variant={!isTablet ? "persistent" : "temporary"}
        anchor="right"
        open={!isTablet ? true : isDrawerOpen}
        onClose={toggleDrawer}
        sx={{ zIndex: 1 }}
      >
        <Divider />
        {isDrawerOpen ? (
          <Stack mt={8} p={4}>
            <Stack spacing={2}>
              <ButtonGroup variant="contained" color="success" size="large">
                <Button
                  color="success"
                  disabled={difference === 0 ? false : true}
                  sx={{
                    color: "white",
                    height: 50,
                  }}
                  startIcon={<SaveIcon style={{ fontSize: 25 }} />}
                  className={style.buttonText}
                  onClick={handleSubmit(
                    (data) => onSubmit(data, "save"),
                    onError,
                  )}
                >
                  {t("common_btn.save")}
                </Button>
                <Button
                  sx={{ height: 50, color: "white" }}
                  color="success"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleDropdownClick}
                >
                  {showButtons ? (
                    <KeyboardArrowUpIcon style={{ fontSize: 25 }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fontSize: 25 }} />
                  )}
                </Button>
              </ButtonGroup>
              <Collapse in={showButtons}>
                <Stack
                  mt={2}
                  p={1}
                  spacing={2}
                  sx={{ transition: "right 0.3s ease" }}
                >
                  <Button
                    startIcon={<AddBoxOutlinedIcon style={{ fontSize: 25 }} />}
                    variant="contained"
                    disabled={difference === 0 ? false : true}
                    color="info"
                    className={style.buttonText}
                    onClick={handleSubmit(
                      (data) => onSubmit(data, "save&new"),
                      onError,
                    )}
                  >
                    <Typography noWrap p={0.5}>
                      {t("common_btn.save+new")}
                    </Typography>
                  </Button>
                  <Button
                    className={style.buttonText}
                    color="purple"
                    disabled={difference === 0 ? false : true}
                    sx={{ color: "white" }}
                    variant="contained"
                    startIcon={
                      <ContentCopyOutlinedIcon style={{ fontSize: 25 }} />
                    }
                    onClick={handleSubmit(
                      (data) => onSubmit(data, "copy"),
                      onError,
                    )}
                  >
                    <Typography noWrap p={0.5}>
                      {t("common_btn.copy")}
                    </Typography>
                  </Button>
                </Stack>
              </Collapse>

              <Button
                className={style.buttonText}
                variant="contained"
                color="inherit"
                startIcon={<ArrowBackOutlinedIcon style={{ fontSize: 25 }} />}
                onClick={() => navigate(backPath)}
              >
                {t("common_btn.back")}
              </Button>
            </Stack>
            {["normal", "periodic"].includes(type) && (
              <Stack mt={6}>
                <Button
                  variant="outlined"
                  onClick={showInvoiceConfirmationDialog}
                  startIcon={<UploadOutlinedIcon style={{ fontSize: 25 }} />}
                  className={style.buttonText}
                  color="error"
                  endIcon={
                    <Chip
                      size="small"
                      label={documents?.length}
                      color="error"
                    />
                  }
                >
                  {t("common_btn.upload_pdf")}
                </Button>
              </Stack>
            )}
          </Stack>
        ) : (
          !isTablet && (
            <Stack width="60px" mt={8} p={1}>
              <Stack spacing={3}>
                <Tooltip placement="left" arrow title={t("common_btn.save")}>
                  <IconButton
                    color="success"
                    disabled={difference === 0 ? false : true}
                    className={style.iconButton}
                    onClick={handleSubmit(
                      (data) => onSubmit(data, "save"),
                      onError,
                    )}
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>

                {showSaveNew && (
                  <Tooltip
                    placement="left"
                    arrow
                    title={t("common_btn.save+new")}
                  >
                    <IconButton
                      className={style.iconButton}
                      color="info"
                      disabled={difference === 0 ? false : true}
                      onClick={handleSubmit(
                        (data) => onSubmit(data, "save&new"),
                        onError,
                      )}
                    >
                      <AddBoxOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {showToCopy && (
                  <Tooltip placement="left" arrow title={t("common_btn.copy")}>
                    <IconButton
                      className={style.iconButton}
                      color="purple"
                      disabled={difference === 0 ? false : true}
                      onClick={handleSubmit(
                        (data) => onSubmit(data, "copy"),
                        onError,
                      )}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip placement="left" arrow title={t("common_btn.back")}>
                  <IconButton
                    className={style.iconButton}
                    color="inherit"
                    name="copy"
                    onClick={() => navigate(backPath)}
                  >
                    <ArrowBackOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {["normal", "periodic"].includes(type) && showFile && (
                  <Tooltip
                    placement="left"
                    arrow
                    title={`${t("common_btn.upload_pdf")} ${documents?.length}`}
                  >
                    <IconButton
                      onClick={showInvoiceConfirmationDialog}
                      color="error"
                      className={style.iconButton}
                    >
                      <UploadOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          )
        )}
      </Drawer>
      <IconButton
        onClick={toggleDrawer}
        size="small"
        style={{
          borderRadius: "4px",
          width: "25px",
          backgroundColor: "#c28948",
          transition: "right 0.3s ease",
          position: "fixed",
          bottom: "20px",
          right: isDrawerOpen ? "267px" : isTablet ? "3px" : "35px",
          zIndex: "1000",
        }}
      >
        {isDrawerOpen ? (
          <KeyboardDoubleArrowRightIcon sx={{ fontSize: 25, color: "white" }} />
        ) : (
          <KeyboardDoubleArrowLeftIcon
            color="secondary"
            sx={{ fontSize: 25, color: "white" }}
          />
        )}
      </IconButton>
    </Stack>
  );
};

export default AddInvoiceDrawer;
