import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function DashbookAndMe({setPage}) {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        section.querySelector(".left-div").style.animation =
          "slideInLeft 1s ease forwards";
        section.querySelector(".right-div").style.animation =
          "slideInRight 1s ease forwards";
      } else {
        // If the section is out of view, revert the animation
        section.querySelector(".left-div").style.animation = "none";
        section.querySelector(".right-div").style.animation = "none";
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Dashbook en ik
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Of Dashbok bij jou past, bepaal jij.
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Dashbook is een boekhoudsoftware die geschikt is voor zzp'ers en
          kleine ondernemers. Het is gebruiksvriendelijk, betaalbaar en biedt
          een breed scala aan functionaliteiten.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Button variant="contained" sx={{ color: "white" }} onClick={()=>navigate('/dashboard')}>
                Nu Gratis Proberen! 00,0 / 14 dagen
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={12}>
              <Button
                variant="contained"
                sx={{ color: "white", backgroundColor: "green" }}
              >
                Gratis Offertes aanvragen
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Factuurprogramma of boekhoudprogramma: wat is het verschil
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Je boekhouding uitbesteden
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Alles wat wij aanbieden
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Overstappen?
        </Button>
      </div>
      <div style={{ textAlign: "center" }} className="section-1">
        <Divider textAlign="left" className="divider">
          {"Factuurprogramma of boekhoudprogramma: wat is het verschil".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Online factuurprogramma's vergemakkelijken het proces van
                facturering door je in staat te stellen facturen online op te
                stellen en automatisch te verzenden. In een online
                boekhoudprogramma kun je ook je financiële transacties verwerken
                en worden rapportages automatisch gegenereerd. Het is belangrijk
                op te merken dat een programma dat zich beperkt tot facturatie
                doorgaans minder uitgebreid is dan een volwaardig
                boekhoudprogramma, maar vaak tegen vergelijkbare kosten wordt
                aangeboden.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8", marginTop: "1rem" }}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/StartInvoice.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-2">
        <Divider textAlign="right" className="divider">
          {"Je boekhouding uitbesteden".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/DashboardStart.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Ondernemen is tijdrovend. Zeker als je je administratie zelf
                moet bijhouden. Met Dashbook heb je alles wat je nodig hebt om
                je administratie eenvoudig en efficiënt te regelen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Met Dashbook kun je:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Je boekhouding beheren: Houd je inkomsten en uitgaven bij, maak
                je belastingaangifte en ontvang je overzichten.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Facturen opstellen: Maak professionele facturen in een
                handomdraai.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>
                  *Uitbesteden: Plaats een oproep op ons offertesysteem en
                  ontvang offertes van boekhouders en accountants.
                </strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dashbook is de ideale oplossing voor:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Zzp'ers: Dashbook is betaalbaar en gebruiksvriendelijk.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Kleine ondernemers: Dashbook biedt alles wat je nodig hebt om
                je administratie op orde te houden.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                *Grote ondernemingen: Dashbook kan worden uitgebreid met extra
                functionaliteiten.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8", marginTop: "1rem" }}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-3">
        <Divider textAlign="left" className="divider">
          {"Alles wat wij aanbieden".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="subtitle1" gutterBottom>
                Dashbook is niet zomaar een boekhoudpakket; het is een
                allesomvattend systeem met diverse functionaliteiten die je
                helpen bij het beheren van je boekhouding.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Maar dat is nog niet alles. Wij gaan verder dan alleen een
                boekhoudpakket; we fungeren als een uitgebreide kennisbank. Dit
                betekent dat zelfs starters toegang hebben tot alle benodigde
                informatie voor het opstarten van een onderneming en het beheren
                van processen na de oprichting. Bovendien bieden we een
                totaalpakket, inclusief de mogelijkheid om je website te laten
                maken.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Als je de voorkeur geeft aan lokale ondernemingen of
                professionals, kun je gebruikmaken van ons offertesysteem.
                Binnen enkele stappen ontvang je diverse offertes in je mailbox.
                Bij Dashbook streven we ernaar om ondernemers een alles-in-één
                oplossing te bieden, van boekhouding tot website-ontwikkeling en
                meer. Ontdek zelf de voordelen van Dashbook voor jouw
                onderneming.
              </Typography>

              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Bekijk onze functies
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8", marginTop: "1rem" }}
                >
                  Gratis proefperiode
                </Button>
              </div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "green",
                  padding: "1rem",
                  marginTop: "1rem",
                }}
              >
                Offerte aanvragen
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div
              className="right-div imageCard"
              style={{ display: "inline-block" }}
            >
              <img
                src={"./Professional/DashboardStart.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ textAlign: "center" }} className="section-4">
        <Divider textAlign="right" className="divider">
          {"Overstappen?".toUpperCase()}
        </Divider>
        <Grid container>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div imageCard">
              <img
                src={"./Professional/DashboardStart.png"}
                alt="MarioImage"
                width="80%"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div card">
              <Typography variant="subtitle1" gutterBottom>
                Dashbook is zo gebruiksvriendelijk dat iedereen eenvoudig zelf
                kan beginnen. Daarnaast bieden wij een gratis overstapservice
                aan.
              </Typography>

              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA", marginTop: "1rem" }}
                >
                  Lees meer
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green", marginTop: "1rem" }}
                  onClick={()=>setPage('contact_us')}
                >
                  Contact ons
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default DashbookAndMe;
