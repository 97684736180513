import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./features/common/commonSlice";
import yearsReduces from "./features/years/yearsSlice";

export const store = configureStore({
  reducer: {
    common: commonReducer,
    years: yearsReduces,
  },
});
