import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { RequireAuth } from "../utils/RequireAuth";
import { PublicRoute } from "../utils/PublicRoute";
import { NotFound } from "../pages/NotFound";
import MainLayout from "../layouts/MainLayout";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import LoadingPage from "../components/LoadingPage";
import AddVatPercentage from "../pages/vat_percentages/AddVatPercentage";
import EditVatPercentage from "../pages/vat_percentages/EditVatPercentage";
import ProductList from "../pages/products/ProductList";
import InvoiceForm from "../pages/invoice/InvoiceFormatter";
import SendInvoice from "../pages/invoice/sendInvoice/SendInvoice";
import EditCompanyDetails from "../pages/invoice/companyDetails/EditCompanyDetails";
import CompanyDetails from "../pages/invoice/companyDetails/CompanyDetails";
import InvoiceLivePreview from "../pages/invoice/invoicePDFLayout/InvoiceLivePreview";
import OffersList from "../pages/offers/OffersList";
import AddOffers from "../pages/offers/AddOffers";
import EditOffers from "../pages/offers/Editoffers";
import AcceptOffers from "../pages/offers/AcceptOffers";
import TransactionHistoryList from "../pages/transaction/TransactionHistoryList";
import ProfessionalApp from "../pages/professional/ProfessionalApp";


// Dashboard
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));

// Customers
const CustomerList = lazy(() => import("../pages/customers/CustomerList"));
const AddCustomer = lazy(() => import("../pages/customers/AddCustomer"));
const EditCustomer = lazy(() => import("../pages/customers/EditCustomer"));

// Projects
const ProjectList = lazy(() => import("../pages/projects/ProjectList"));
const AddProject = lazy(() => import("../pages/projects/AddProject"));
const EditProject = lazy(() => import("../pages/projects/EditProject"));
const CopyProject = lazy(() => import("../pages/projects/CopyProject"));

//Role
const AddRole = lazy(() => import("../pages/rolemanagement/AddRole"));
const GetRole = lazy(() => import("../pages/rolemanagement/RoleList"));
const EditRole = lazy(() => import("../pages/rolemanagement/EditRole"));

// Hours
const AddHours = lazy(() => import("../pages/hours/AddHours"));
const Team = lazy(() => import("../pages/team/Team"));
const Invoice = lazy(() => import("../pages/invoice/Invoice"));

// hours Reports
const HoursReports = lazy(() => import("../pages/hoursReport/Reports"));

// Customers
const UserList = lazy(() => import("../pages/subusers/UserList"));
const AddUser = lazy(() => import("../pages/subusers/AddUser"));
const EditUser = lazy(() => import("../pages/subusers/EditUser"));

// Subcategories
const SubcategoriesList = lazy(() =>
  import("../pages/subcategories/SubcategoriesList")
);
const AddSubcategory = lazy(() =>
  import("../pages/subcategories/AddSubcategory")
);
const EditSubcategory = lazy(() =>
  import("../pages/subcategories/EditSubcategory")
);

// Vat Percentages
const VatPercentageList = lazy(() =>
  import("../pages/vat_percentages/VatPercentageList")
);

const YearEndBankBalance = lazy(() =>
  import("../pages/yearEndClosing/innerTabs/bankBalance")
);

const YearEndCashBalance = lazy(() =>
  import("../pages/yearEndClosing/innerTabs/cashBalance")
);

const YearEndDebiteuren = lazy(() =>
  import("../pages/yearEndClosing/innerTabs/debiteuren")
);

const YearEndCrediteuren = lazy(() =>
  import("../pages/yearEndClosing/innerTabs/crediteuren")
);

const YearEndClosingPeriods = lazy(() =>
  import("../pages/yearEndClosing/innerTabs/periodesSluiten")
);

const ProfessionalList = lazy(() =>
  import("../pages/professional/professionalTabs")
);

const PrivacyPolicy = lazy(() =>
  import("../pages/professional/components/Footer/PrivacyDeclaration")
);

const YearEndOldAgeReserve = lazy(() =>
  import("../pages/yearEndClosing/innerTabs/oldAgeReserve")
);

// Period Module
const PeriodList = lazy(() => import("../pages/period/PeriodList"));
const AddPeriod = lazy(() => import("../pages/period/AddPeriod"));
const EditPeriod = lazy(() => import("../pages/period/EditPeriod"));

// Car
const CarList = lazy(() => import("../pages/car/CarList"));
const AddCar = lazy(() => import("../pages/car/AddCar"));
const EditCar = lazy(() => import("../pages/car/EditCar"));

// Rides
const RidesList = lazy(() => import("../pages/rides/RidesList"));
const AddRides = lazy(() => import("../pages/rides/AddRides"));
const EditRides = lazy(() => import("../pages/rides/EditRides"));

// Products
const AddProduct = lazy(() => import("../pages/products/AddProduct"));
const EditProduct = lazy(() => import("../pages/products/EditProduct"));

// setting
const Setting = lazy(() => import("../pages/setting/Setting"));

// invoice

const NormalInvoiceList = lazy(() =>
  import("../pages/invoice/normalInvoice/InvoiceList")
);
const NormalAddInvoice = lazy(() =>
  import("../pages/invoice/normalInvoice/AddInvoice")
);
const NormalEditInvoice = lazy(() =>
  import("../pages/invoice/normalInvoice/EditInvoice")
);
const PeriodicInvoiceList = lazy(() =>
  import("../pages/invoice/periodicInvoice/InvoiceList")
);
const PeriodicAddInvoice = lazy(() =>
  import("../pages/invoice/periodicInvoice/AddInvoice")
);
const PeriodicEditInvoice = lazy(() =>
  import("../pages/invoice/periodicInvoice/EditInvoice")
);
const InvoicePDFLayout = lazy(() =>
  import("../pages/invoice/invoicePDFLayout/Index")
);

const AddPurchaseInvoice = lazy(() =>
  import("../pages/purchaseInvoice/AddInvoice")
);
const EditPurchaseInvoice = lazy(() =>
  import("../pages/purchaseInvoice/EditInvoice")
);
// const PurchaseInvoice = lazy(() =>
//   import("../pages/purchaseInvoice/InvoiceList")
// );
const PurchaseInvoiceTab = lazy(() =>
  import("../pages/purchaseInvoice/PurchaseInvoiceTab")
);
// Payment methods

const AddPaymentMethod = lazy(() =>
  import("../pages/payment_methods/AddPaymentMethod")
);
const EditPaymentMethod = lazy(() =>
  import("../pages/payment_methods/EditPaymentMethod")
);
const PaymentMethodList = lazy(() =>
  import("../pages/payment_methods/PaymentMethodList")
);

// Transactions
const AddManuallyTransaction = lazy(() =>
  import("../pages/transaction/AddManuallyTransaction")
);
const EditManuallyTransaction = lazy(() =>
  import("../pages/transaction/EditManuallyTransaction")
);
const TransactionList = lazy(() =>
  import("../pages/transaction/TransactionList")
);

const BanKTransactionTab = lazy(() =>
  import("../pages/transaction/BanKTransactionTab")
);

const AutoImportBank = lazy(() =>
  import("../pages/transaction/AutoImportBank")
);

//Inbox
const InboxInkoop = lazy(() => import("../pages/inbox/Inkoop"));
const InboxVerkoop = lazy(() => import("../pages/inbox/Verkoop"));

// Automatic Processing Rule
const AutomaticProcessingRuleList = lazy(() =>
  import("../pages/automatic_processing_rule/AutomaticProcessingRuleList")
);
const AddEditAutomaticProcessingRule = lazy(() =>
  import("../pages/automatic_processing_rule/AddEditAutomaticProcessingRule")
);
const AppsAndDeals = lazy(() => import("../pages/apps_and_deals/AppsAndDeals"));

const OverViews = lazy(() => import("../pages/overviews/OverViews"));

const AddMemorial = lazy(() => import("../pages/memorial/AddMemorial"));

const EditMemorial = lazy(() => import("../pages/memorial/EditMemorial"));

const MemorialList = lazy(() => import("../pages/memorial/MemorialList"));

const VatPrivateCar = lazy(() => import("../pages/memorial/VatPrivateCar"));

const YearEndClosing = lazy(() =>
  import("../pages/yearEndClosing/yearEndClosing")
);

const YearEndClosingOverview = lazy(() =>
  import("../pages/yearEndClosing/overview")
);

const MemorialTemplateList = lazy(() =>
  import("../pages/memorialTemplate/MemorialTemplateList")
);

const AddMemorialTemplate = lazy(() =>
  import("../pages/memorialTemplate/AddMemorialTemplate")
);

const EditMemorialTemplate = lazy(() =>
  import("../pages/memorialTemplate/EditMemorialTemplate")
);

// other booking
const OtherBooking = lazy(() => import("../pages/otherBooking/OtherBooking"));

// opening balance
const OpeningBalance = lazy(() =>
  import("../pages/openingBalance/OpeningBalance")
);

// addition
const Addition = lazy(() => import("../pages/addition/Addition"));

// const pageNames = [
//   "store",
//   "home",
//   "start",
//   "want_to_do_business",
//   "going_into_business",
//   "starting_business",
//   "dashbook_and_me",
//   "our_prices",
//   "our_packages",
//   "back_office_service",
//   "rates_overview",
//   "frequently_asked_questions",
//   "functions",
//   "design",
//   "simple_screens",
//   "unlimited_users",
//   "permissions_and_roles",
//   "relationship_management",
//   "chart_of_accounts",
//   "admin_setup",
//   "administration",
//   "admin_projects",
//   "admin_time_keeper",
//   "admin_rides",
//   "admin_product_service",
//   "admin_purchase_cost",
//   "admin_receipts",
//   "to_sell",
//   "quotes",
//   "invoicing",
//   "recurring_invoices",
//   "branding_layout_editor",
//   "ubl_invoices",
//   "ideal_invoicing",
//   "future_invoicing",
//   "reminders",
//   "digital_inbox_sales",
//   "links",
//   "app",
//   "bank",
//   "mollie_ideal",
//   "accounting_and_reporting",
//   "depreciations",
//   "overviews",
//   "other_bookings",
//   "reports",
//   "documents",
//   "declarations",
//   "declaration_btw",
//   "declaration_icp",
//   "declaration_supplement",
//   "declaration_income_tax",
//   "dashbook_home",
//   "why_we",
//   "from_whome",
//   "security",
//   "blogs",
//   "changes",
//   "vacancies",
//   "contact_us",
//   "support_home",
//   "knowledge_base_faq",
//   "faq_dashbook_update",
//   "faq_support",
//   "find_your_expert",
//   "cookie_declaration",
//   "privacy_declaration",
//   "our_mission",
//   "sitemap",
//   "terms_and_condition",
// ];

const pageNames = [
  "store",
  "home",
  "starten",
  "wil_ik_ondernemen",
  "ik_ga_ondernemen",
  "onderneming_starten",
  "dashbook_en_ik",

  "onze_prijzen",
  "onze_pakketten",
  "backoffice_service",
  "tarieven_overzicht",
  "veel_gestelde_vragen",

  "functies",
  "inrichting",
  "eenvoudige_invoerschermen",
  "onbeperkt_gebruikers_aanmaken",
  "rechten_en_rollen_instellen",
  "relatiebeheer",
  "subcategorien_rekeningschema",
  "inrichting_administratie",

  "administratie",
  "projecten",
  "uren_time_keeper",
  "ritten",
  "producten_en_diensten",
  "inkopen_kosten",
  "bonnen_inbox",

  "verkopen",
  "offertes",
  "factureren",
  "periodieke_facturen",
  "huisstijl_layout_editor",
  "ubl_facturen",
  "facturatie_met_ideal_mollie",
  "facturen_in_toekomst_versturen",
  "herinneringen_en_aanmaningen",
  "digitale_inbox_verkoop",

  "koppelingen",
  "app",
  "bank",
  "mollie_ideal",

  "boekhouding_en_rapportage",
  "afschrijvingen",
  "overzichten",
  "overige_boekingen",
  "rapporten",
  "documenten",

  "aangiftes",
  "btw_aangiftes",
  "icp_aangiftes",
  "suppletieaangifte",
  "inkomstenbelasting",

  "dashbook",
  "waarom",
  "voor_wie",
  "security",
  "blog",
  "overstappen",
  "vacatures",
  "contact",

  "ondersteuning",
  "kennisbank_faq",
  // "faq_dashbook_update",
  "support",
  "vind_je_expert",

  "cookieverklaring",
  "privacyverklaring",
  "onze_missie",
  "sitemap",
  "voorwaarden",
];


const routes = (
  <Routes>
    {pageNames.map((page_name, index) => (
      <Route
        key={index} // Make sure to add a unique key when iterating over components
        path={`/${page_name}`}
        element={<ProfessionalApp name={page_name} />}
      />
    ))}

    <Route
      index
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/register"
      element={
        <PublicRoute>
          <Register />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/forgot-password"
      element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/privacy-policy"
      element={
        <PublicRoute>
          <PrivacyPolicy />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/reset-password"
      element={
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/offer/accept/view/:offerToken"
      element={<AcceptOffers />}
    />
    <Route path="/" element={<ProfessionalApp />} />
    <Route
      path="/"
      element={
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      }
    >
      {/* <Route path="/" element={<Navigate to="dashboard" replace />} /> */}
      <Route
        path="dashboard"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Dashboard />
          </Suspense>
        }
      />
      <Route path="/" element={<Navigate to="customers" replace />} />
      <Route
        path="customers"
        element={
          <Suspense fallback={<LoadingPage />}>
            <CustomerList />
          </Suspense>
        }
      />
      <Route
        path="customers/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddCustomer />
          </Suspense>
        }
      />
      <Route
        path="customers/edit/:customerId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditCustomer />
          </Suspense>
        }
      />
      <Route
        path="projects"
        element={
          <Suspense fallback={<LoadingPage />}>
            <ProjectList />
          </Suspense>
        }
      />
      <Route
        path="projects/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddProject />
          </Suspense>
        }
      />
      <Route
        path="projects/edit/:projectId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditProject />
          </Suspense>
        }
      />
      <Route
        path="projects/copy/:projectId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <CopyProject />
          </Suspense>
        }
      />
      <Route
        path="roles/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddRole />
          </Suspense>
        }
      />
      <Route
        path="roles/edit/:id"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditRole />
          </Suspense>
        }
      />
      <Route
        path="roles"
        element={
          <Suspense fallback={<LoadingPage />}>
            <GetRole />
          </Suspense>
        }
      />
      <Route
        path="hours/overview"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddHours />
          </Suspense>
        }
      />
      <Route
        path="team"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Team />
          </Suspense>
        }
      />
      <Route
        path="invoice"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Invoice />
          </Suspense>
        }
      />
      <Route
        path="hours/reports"
        element={
          <Suspense fallback={<LoadingPage />}>
            <HoursReports />
          </Suspense>
        }
      />
      <Route
        path="subcategories"
        element={
          <Suspense fallback={<LoadingPage />}>
            <SubcategoriesList />
          </Suspense>
        }
      />
      <Route
        path="subcategories/:addId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddSubcategory />
          </Suspense>
        }
      />
      <Route
        path="subcategories/edit/:subcategoryId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditSubcategory />
          </Suspense>
        }
      />
      <Route
        path="users"
        element={
          <Suspense fallback={<LoadingPage />}>
            <UserList />
          </Suspense>
        }
      />
      <Route
        path="users/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddUser />
          </Suspense>
        }
      />
      <Route
        path="users/edit/:userId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditUser />
          </Suspense>
        }
      />
      <Route
        path="vat_percentages"
        element={
          <Suspense fallback={<LoadingPage />}>
            <VatPercentageList />
          </Suspense>
        }
      />
      <Route
        path="vat_percentages/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddVatPercentage />
          </Suspense>
        }
      />
      <Route
        path="vat_percentages/edit/:vatId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditVatPercentage />
          </Suspense>
        }
      />
      <Route
        path="period"
        element={
          <Suspense fallback={<LoadingPage />}>
            <PeriodList />
          </Suspense>
        }
      />
      <Route
        path="period/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddPeriod />
          </Suspense>
        }
      />
      <Route
        path="period/edit/:periodId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditPeriod />
          </Suspense>
        }
      />
      <Route
        path="cars"
        element={
          <Suspense fallback={<LoadingPage />}>
            <CarList />
          </Suspense>
        }
      />
      <Route
        path="cars/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddCar />
          </Suspense>
        }
      />{" "}
      <Route
        path="cars/edit/:carId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditCar />
          </Suspense>
        }
      />
      <Route
        path="rides"
        element={
          <Suspense fallback={<LoadingPage />}>
            <RidesList />
          </Suspense>
        }
      />
      <Route
        path="rides/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddRides />
          </Suspense>
        }
      />
      <Route
        path="rides/edit/:rideId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditRides />
          </Suspense>
        }
      />
      <Route
        path="products"
        element={
          <Suspense fallback={<LoadingPage />}>
            <ProductList />
          </Suspense>
        }
      />
      <Route
        path="products/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddProduct />
          </Suspense>
        }
      />
      <Route
        path="products/edit/:productId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditProduct />
          </Suspense>
        }
      />
      <Route
        path="setting"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Setting />
          </Suspense>
        }
      />
      <Route
        path="periodic_invoice"
        element={
          <Suspense fallback={<LoadingPage />}>
            <PeriodicInvoiceList />
          </Suspense>
        }
      />
      <Route
        path="periodic_invoice/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <PeriodicAddInvoice />
          </Suspense>
        }
      />
      <Route
        path="periodic_invoice/edit/:invoiceId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <PeriodicEditInvoice />
          </Suspense>
        }
      />
      <Route
        path="normal_invoice"
        element={
          <Suspense fallback={<LoadingPage />}>
            <NormalInvoiceList />
          </Suspense>
        }
      />
      <Route
        path="normal_invoice/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <NormalAddInvoice />
          </Suspense>
        }
      />
      <Route
        path="normal_invoice/edit/:invoiceId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <NormalEditInvoice />
          </Suspense>
        }
      />
      <Route
        path="normal_invoice/preview"
        element={
          <Suspense fallback={<LoadingPage />}>
            <InvoiceLivePreview />
          </Suspense>
        }
      />
      <Route
        path="/:invoiceType/send_invoice/:invoiceId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <SendInvoice />
          </Suspense>
        }
      />
      <Route
        path="/invoice/pdf_layout"
        element={
          <Suspense fallback={<LoadingPage />}>
            <InvoicePDFLayout />
          </Suspense>
        }
      />
      <Route
        path="/purchase_invoice/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddPurchaseInvoice />
          </Suspense>
        }
      />
      <Route
        path="/purchase_invoice/edit/:invoiceId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditPurchaseInvoice />
          </Suspense>
        }
      />
      <Route
        path="/purchase_invoice/*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <PurchaseInvoiceTab />
          </Suspense>
        }
      />
      <Route
        path="/offers"
        element={
          <Suspense fallback={<LoadingPage />}>
            <OffersList />
          </Suspense>
        }
      />
      <Route
        path="/offers/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddOffers />
          </Suspense>
        }
      />
      <Route
        path="/offers/edit/:offerId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditOffers />
          </Suspense>
        }
      />
      <Route
        path="/payment_methods/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddPaymentMethod />
          </Suspense>
        }
      />
      <Route
        path="/payment_methods/edit/:payment_methodId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditPaymentMethod />
          </Suspense>
        }
      />
      <Route
        path="/payment_methods"
        element={
          <Suspense fallback={<LoadingPage />}>
            <PaymentMethodList />
          </Suspense>
        }
      />
      <Route
        path="/transactions/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddManuallyTransaction />
          </Suspense>
        }
      />
      <Route
        path="/transactions/edit/:transactionId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditManuallyTransaction />
          </Suspense>
        }
      />
      <Route
        path="/transactions"
        element={
          <Suspense fallback={<LoadingPage />}>
            <TransactionList />
          </Suspense>
        }
      />
      <Route
        path="/bankImport/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <BanKTransactionTab />
          </Suspense>
        }
      />
      <Route
        path="/bankImport"
        element={
          <Suspense fallback={<LoadingPage />}>
            <TransactionHistoryList />
          </Suspense>
        }
      />
      <Route
        path="/bankImport/auto"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AutoImportBank />
          </Suspense>
        }
      />
      <Route
        path="settings/invoices"
        element={
          <Suspense fallback={<LoadingPage />}>
            <InvoiceForm />
          </Suspense>
        }
      />
      <Route
        path="setting/company/edit"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditCompanyDetails />
          </Suspense>
        }
      />
      <Route
        path="setting/company"
        element={
          <Suspense fallback={<LoadingPage />}>
            <CompanyDetails />
          </Suspense>
        }
      />
      <Route
        path="inbox/inkoop"
        element={
          <Suspense fallback={<LoadingPage />}>
            <InboxInkoop />
          </Suspense>
        }
      />
      <Route
        path="inbox/verkoop"
        element={
          <Suspense fallback={<LoadingPage />}>
            <InboxVerkoop />
          </Suspense>
        }
      />
      <Route
        path="automatic_processing_rules"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AutomaticProcessingRuleList />
          </Suspense>
        }
      />
      <Route
        path="automatic_processing_rules/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddEditAutomaticProcessingRule />
          </Suspense>
        }
      />
      <Route
        path="automatic_processing_rules/edit/:ruleId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddEditAutomaticProcessingRule />
          </Suspense>
        }
      />
      <Route
        path="/apps_and_deals/*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AppsAndDeals />
          </Suspense>
        }
      />
      <Route
        path="/overviews/*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <OverViews />
          </Suspense>
        }
      />
      <Route
        path="/memorial/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddMemorial />
          </Suspense>
        }
      />
      <Route
        path="/memorial"
        element={
          <Suspense fallback={<LoadingPage />}>
            <MemorialList />
          </Suspense>
        }
      />
      <Route
        path="/memorial/edit/:memorialId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditMemorial />
          </Suspense>
        }
      />
      <Route
        path="/memorialTemplate"
        element={
          <Suspense fallback={<LoadingPage />}>
            <MemorialTemplateList />
          </Suspense>
        }
      />
      <Route
        path="/memorialTemplate/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddMemorialTemplate />
          </Suspense>
        }
      />
      <Route
        path="/memorialTemplate/edit/:id"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditMemorialTemplate />
          </Suspense>
        }
      />
      <Route
        path="/otherBookings/*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <OtherBooking />
          </Suspense>
        }
      />
      <Route
        path="/memorial/openingBalance"
        element={
          <Suspense fallback={<LoadingPage />}>
            <OpeningBalance />
          </Suspense>
        }
      />
      <Route
        path="/memorial/addition"
        element={
          <Suspense fallback={<LoadingPage />}>
            <Addition />
          </Suspense>
        }
      />
      <Route
        path="/memorial/vatPrivateCar"
        element={
          <Suspense fallback={<LoadingPage />}>
            <VatPrivateCar />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndClosing />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing/:year/overview"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndClosingOverview />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing/:year/bankBalance"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndBankBalance />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing/:year/cashBalance"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndCashBalance />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing/:year/debiteuren"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndDebiteuren />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing/:year/crediteuren"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndCrediteuren />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing/:year/oldAgeReserve"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndOldAgeReserve />
          </Suspense>
        }
      />
      <Route
        path="/memorial/yearEndClosing/:year/periodesSluiten"
        element={
          <Suspense fallback={<LoadingPage />}>
            <YearEndClosingPeriods />
          </Suspense>
        }
      />
      <Route
        path="/professionalList/*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <ProfessionalList />
          </Suspense>
        }
      />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default routes;
