import React from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Button, IconButton, MenuItem, Select, Stack } from "@mui/material";
import TextInput from "../../components/form/TextInput";
import { Controller, useWatch } from "react-hook-form";
import PercentIcon from "@mui/icons-material/Percent";
import EuroIcon from "@mui/icons-material/Euro";
import { useTranslation } from "react-i18next";
import DeleteRounded from "@mui/icons-material/DeleteOutlined";

const DiscountDialogBox = ({
  isOpen,
  onClose,
  control,
  calculateTotal,
  setValue,
}) => {
  const { t } = useTranslation();
  const discount = useWatch({
    name: "discount",
    control: control,
  });

  return (
    <CustomDialogBox
      title={t("invoices.dialogBox.title.discount")}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Stack
          mr={1}
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={() => {
              onClose();
              if (discount === 0 || discount) {
                calculateTotal();
              } else {
                setValue("discount", 0);
                calculateTotal();
              }
            }}
          >
            {t("common_btn.save")}
          </Button>
          <IconButton
            variant="outlined"
            color="error"
            size="small"
            sx={{
              border: "2px solid currentColor",
              width: "30px",
              height: "30px",
            }}
            onClick={() => {
              onClose();
              setValue("discount", 0);
              calculateTotal();
            }}
          >
            <DeleteRounded fontSize="small" />
          </IconButton>
        </Stack>
      }
    >
      <TextInput
        name="discountName"
        control={control}
        label={t("invoices.fields.description")}
      />
      <Stack direction="row" mt={1}>
        <TextInput name="discount" control={control} type="number" />
        <Controller
          name="discountType"
          control={control}
          defaultValue="percentages" // Change the default value to "amount"
          render={({ field }) => (
            <Select {...field} sx={{ width: 100, height: 40 }}>
              <MenuItem value="percentage">
                <PercentIcon />
              </MenuItem>
              <MenuItem value="amount">
                <EuroIcon />
              </MenuItem>
            </Select>
          )}
        />
      </Stack>
    </CustomDialogBox>
  );
};

export default DiscountDialogBox;
