import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SellQuotes() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Efficiënt Offertes Beheren en Factureren in een Oogwenk
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Genereer snel en eenvoudig professionele offertes die de stijl van
          jouw bedrijf weerspiegelen en stuur ze direct naar je klant. Volg de
          voortgang van de offerte handig in het overzicht. Zodra de offerte is
          geaccepteerd, kun je deze moeiteloos omzetten in een factuur.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Offertes met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Gemakkelijk offertes maken
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Online goedkeuren
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Goedgekeurde offerte naar factuur
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Offertes versturen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(5)}
        >
          Offertes met producten of diensten
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Gemakkelijk offertes maken".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Gemakkelijk offertes maken
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Als ondernemer of zzp'er maak je frequent gebruik van offertes.
                Het is cruciaal om deze snel en moeiteloos op te stellen,
                waardoor je kostbare tijd en inspanning bespaart.
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemak:</strong>
                Voer eenvoudig de benodigde informatie in voor producten en
                diensten en genereer met slechts één klik een professionele
                offerte met jouw eigen logo en huisstijl.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiëntie:</strong>
                Bespaar waardevolle tijd en moeite door offertes op een snelle
                en moeiteloze manier te creëren.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Professionaliteit:</strong>
                Maak indruk met offertes die er niet alleen professioneel
                uitzien, maar ook naadloos aansluiten bij je bedrijfshuisstijl.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Online goedkeuren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Online goedkeuren
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                De klant heeft de mogelijkheid om de offerte online te bekijken
                en deze direct online te accepteren. Zowel jij als de klant
                ontvangen vervolgens een bevestiging per e-mail, inclusief een
                kopie van de offerte als pdf-bijlage.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                            variant="contained"
                            style={{ backgroundColor: "#4A88EA" }}
                          >
                            meer info
                          </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Goedgekeurde offerte naar factuur".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Goedgekeurde offerte naar factuur
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Nadat je klant de offerte online heeft geaccepteerd, kun je deze
                moeiteloos omzetten naar een factuur wanneer dat nodig is.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Deze handige functie bespaart niet alleen tijd, maar vermindert
                ook de kans op fouten. Wel zo makkelijk en efficiënt.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Offertes versturen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Offertes versturen
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                Na het aanmaken van de offerte, is het mogelijk om deze direct
                vanuit Dashbook te verzenden. De e-mail, vergezeld van een
                begeleidende tekst, bevat de offerte als een bijgevoegde pdf.
              </Typography>

              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                            variant="contained"
                            style={{ backgroundColor: "#4A88EA" }}
                          >
                            meer info
                          </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-5"
      >
        <Divider textAlign="left" className="divider">
          {"Offertes met producten of diensten".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Offertes met producten of diensten
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Maak je vaak gebruik van dezelfde producten, diensten, of
                artikelen in je offertes?
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Met Dashbook kun je deze gemakkelijk opslaan, zodat je ze bij
                toekomstige offertes snel en eenvoudig kunt gebruiken.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellQuotes;
