import React from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Stack } from "@mui/material";

import { useTranslation } from "react-i18next";

const DocumentsDialogBox = ({ isOpen, onClose, selectedImage }) => {
  const { t } = useTranslation();

  return (
    <CustomDialogBox
      title={t("invoices.dialogBox.title.perview")}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack>
        <img src={selectedImage} alt="Selected" />
      </Stack>
    </CustomDialogBox>
  );
};

export default DocumentsDialogBox;
