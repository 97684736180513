import React, { useState } from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteRounded from "@mui/icons-material/DeleteOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useDialogHooks from "../../components/Hooks/useDialogHooks";
import DocumentsDialogBox from "./DocumentsDialogBox";
import toast from "react-hot-toast";

const FileUploadDialogBox = ({ isOpen, onClose, setValue, documents }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState(documents);

  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileUpload = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const fileSize = newFile.size / (1024 * 1024); // Convert size to MB
      if (fileSize > 2) {
        toast.error("File size must be 2MB or less");
        e.target.value = null; // Reset the input field
        return;
      }

      // Handle the valid file here
    }
    setFiles([...files, { file: newFile, size: newFile.size }]);
    setValue("documents", [...files, { file: newFile, size: newFile.size }]);
  };

  const handleFileDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setValue("documents", updatedFiles);
  };

  const handleImageClick = (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
      openDialog("showPreview");
      // setIframeHeight(image.height);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <CustomDialogBox
        title={t("invoices.dialogBox.title.files")}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <Stack
            mr={1}
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                color="info"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </label>
            <input
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              accept=".jpg, .jpeg, .png, "
              onChange={handleFileUpload}
            />
          </Stack>
        }
      >
        {files.map((file, index) => (
          <Stack key={index} direction={"row"} alignItems="center" mt={2}>
            <Stack flex={1}>
              <Typography
                color="purple"
                sx={{
                  // height: "30px",
                  cursor: "pointer",
                  // ":hover": {
                  //   border: "2px solid black",
                  // },
                }}
                onClick={() => handleImageClick(file.file)}
              >
                {file.file.name} - {Math.round(file.size / 1024)} KB
              </Typography>
            </Stack>
            <IconButton
              variant="outlined"
              color="error"
              size="small"
              sx={{
                border: "2px solid currentColor",
                width: "25px",
                height: "25px",
              }}
              onClick={() => handleFileDelete(index)}
            >
              <DeleteRounded fontSize="small" />
            </IconButton>
          </Stack>
        ))}
        {files.length === 0 && <Typography>No files</Typography>}
      </CustomDialogBox>
      {isDialogOpen("showPreview") && (
        <DocumentsDialogBox
          isOpen={() => isDialogOpen("showPreview")}
          onClose={() => closeDialog("showPreview")}
          documents={documents}
          selectedImage={selectedImage}
        />
      )}
    </>
  );
};

export default FileUploadDialogBox;
