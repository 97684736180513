import React from "react";

import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRef } from "react";
import API from "../../../config/axios";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";

const CompanyDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const abortController = useRef(new AbortController());
  const [companyDetails, setCompanyDetails] = useState({});

  const getCompanyDetails = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    try {
      const res = await API.get(`users/settings/company`, {
        signal: abortController.current.signal,
      });
      const resData = res.data;

      if (resData.status) {
        setCompanyDetails(resData.data.company);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getCompanyDetails();

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton onClick={() => navigate("/setting")}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">{t("company.company_details")}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography align="justify">{t("company.invoice_text")}</Typography>
          <Stack mt={3} direction="row" alignItems={"center"}>
            <Typography fontWeight={500} flexGrow={2}>
              {t("company.sender_info")}
            </Typography>
            <Button
              variant="text"
              color="info"
              onClick={() => {
                navigate("/setting/company/edit");
              }}
            >
              {t("company.to_process")}
            </Button>
          </Stack>
          <Stack mb={3}>
            <Divider />
          </Stack>
          <Grid container spacing={3}>
            <Grid container item xs={7}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={500}>
                  {t("company.fields.address")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="justify">
                  {companyDetails.address}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={7}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={500}>
                  {t("company.fields.email")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>{companyDetails.email}</Typography>
              </Grid>
            </Grid>{" "}
            <Grid container item xs={7}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={500}>
                  {t("company.fields.phoneNo")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>{companyDetails.phoneNo}</Typography>
              </Grid>
            </Grid>{" "}
            <Grid container item xs={7}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={500}>
                  {t("company.fields.chamberOfCommerceNo")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>{companyDetails.chamberOfCommerceNo}</Typography>
              </Grid>
            </Grid>{" "}
            <Grid container item xs={7}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={500}>
                  {t("company.fields.vatCategoryId")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>{companyDetails.vatNo}</Typography>
              </Grid>
            </Grid>{" "}
            <Grid container item xs={7}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={500}>
                  {t("company.fields.bank_account_no")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>{companyDetails.bankAccountNumber}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={7}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={500}>
                  {t("company.fields.account_name")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>{companyDetails.bankAccountName}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyDetails;
