import { IconButton, MenuItem } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
function MobileDropDown({
  menuItem,
  zIndex = 1,
  parent,
  setParent,
  closeMenubar,
  setPage,
  activePage,
}) {
  const [child, setChild] = useState(null);

  const backgroundColor = [
    "rgba(248, 197, 99, 0.11)",
    "rgba(250, 192, 81, 0.3)",
    "rgba(255, 219, 103, 0.52)",
  ];

  const handleOpen = (event) => {
    setParent(menuItem.value);
    setChild(null);
  };

  const handleClose = (event) => {
    setParent(null);
    setChild(null);
  };

  const handleClick = () => {
    // console.log("Setting value:", menuItem.value);
    setPage(menuItem.value);
    closeMenubar();
  };

  const isActive = () => {
    if (activePage === menuItem.value) return true;

    if (menuItem.submenu === true && activePage !== menuItem.value) {
      const isSubActive = menuItem.submenuOptions.some((subOption) => {
        if (subOption.submenu) {
          return subOption.submenuOptions.some(
            (nestedOption) =>
              nestedOption.value === activePage ||
              (nestedOption.submenu &&
                nestedOption.submenuOptions.some(
                  (subSubMenuOption) => subSubMenuOption.value === activePage
                ))
          );
        } else {
          return subOption.value === activePage;
        }
      });

      if (isSubActive) return true;
    }

    return false; // Return false if none of the conditions are met
  };

  return (
    <div>
      {menuItem.submenu ? (
        <div>
          <div className="flex">
            <MenuItem
              sx={{ justifyContent: "space-between" }}
              onClick={handleClick}
              className={`${isActive() ? "activeHeaderBold" : ""}`}
            >
              {menuItem.name}
            </MenuItem>
            {parent === menuItem.value ? (
              <IconButton onClick={handleClose}>
                <RemoveIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleOpen}>
                <AddIcon />
              </IconButton>
            )}
          </div>

          <div
            style={{
              position: "relative",
              zIndex,
              backgroundColor: backgroundColor[zIndex - 1],
              display: parent === menuItem.value ? "block" : "none",
            }}
          >
            {menuItem.submenuOptions.map((subOption, subOptionIndex) => (
              <MobileDropDown
                key={subOptionIndex}
                zIndex={zIndex + 1}
                menuItem={subOption}
                parent={child}
                setParent={setChild}
                setPage={setPage}
                closeMenubar={closeMenubar}
                activePage={activePage}
              />
            ))}
          </div>
        </div>
      ) : (
        <MenuItem
          sx={{ justifyContent: "space-between" }}
          //   onMouseEnter={handleOpen}
          onClick={handleClick}
          className={`${isActive() ? "activeHeaderBold" : ""}`}
        >
          {menuItem.name}
        </MenuItem>
      )}
    </div>
  );
}

export default MobileDropDown;
