import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function Overviews() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Boekhouding en rapportage
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Overzichten: altijd inzicht in je bedrijf
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Als ondernemer of zzp'er wil je natuurlijk altijd inzicht hebben in je
          bedrijf. Met de overzichten van Dashbook heb je dat altijd.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Afschrijvingen met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Overzichten
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Balans
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Winst- en verliesrekening
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Debiteuren en Crediteuren
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Overzichten".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Diverse overzichten
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Met Dashbook behoud je altijd moeiteloos overzicht over je
                administratie. Van het gestroomlijnde dashboard tot uitgebreide
                financiële rapportages zoals de winst- en verliesrekening,
                balans en kolommenbalans, Dashbook biedt krachtige inzichten.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Dashboardoverzicht:</strong>
                Het dashboard van Dashbook geeft snel inzicht in de
                belangrijkste cijfers en ontwikkelingen binnen je administratie.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Financiële Rapportages:</strong>
                Gedetailleerde rapportages, waaronder de winst- en
                verliesrekening en balans, bieden diepgaande inzichten voor
                betere financiële besluitvorming.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Exportmogelijkheden:</strong>
                Alle gegevens zijn eenvoudig te exporteren naar Excel, zodat je
                de flexibiliteit hebt om gegevens verder te analyseren of te
                delen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dashbook zorgt voor helderheid en toegankelijkheid in je
                administratie, waardoor je met vertrouwen belangrijke financiële
                beslissingen kunt nemen. Ontdek het gemak van overzicht met
                Dashbook.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Balans".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard">
              <img
                src={"./Professional/BalanceImage.png"}
                alt="Dashbook Logo"
                width={"60%"}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Balans
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                De balans is een cruciaal overzicht dat de bezittingen, schulden
                en het eigen vermogen van je bedrijf in kaart brengt. Het wordt
                weergegeven in twee kolommen met diverse onderdelen. De
                linkerzijde, ook wel de debetkant, toont je bezittingen, terwijl
                de rechterzijde, de creditkant, je schulden vertegenwoordigt.
                Het essentiële kenmerk van een balans is dat deze altijd in
                evenwicht moet zijn, waarbij het totaal van de debetkant gelijk
                is aan het totaal van de creditkant.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ontdek de kracht van duidelijke balansinformatie om een grondig
                inzicht te krijgen in de financiële gezondheid van je bedrijf.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Winst- en verliesrekening".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Winst- en verliesrekening
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                De resultatenrekening, ook bekend als de winst- of
                verliesrekening, biedt een overzicht van je omzet en kosten. Het
                geeft gedetailleerd inzicht in de belangrijkste inkomsten en
                kostenposten per categorie. De weergave van je winst is direct
                af te leiden uit het verschil tussen je omzet en kosten. Als
                ondernemer is dit essentiële informatie die je graag helder
                gepresenteerd wilt hebben!
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ontdek de kracht van de resultatenrekening om je financiële
                situatie nauwkeurig te analyseren.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                            variant="contained"
                            style={{ backgroundColor: "#4A88EA" }}
                          >
                            Meer info zie overige boekingen
                          </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard">
              <img
                src={"./Professional/AccountImage.png"}
                alt="Dashbook Logo"
                width={"60%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Debiteuren en Crediteuren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard">
              <img
                src={"./Professional/DebImage.png"}
                alt="Dashbook Logo"
                width={"45%"}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Debiteuren en Crediteuren
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Bij het ondernemen is het van belang om grip te houden op zowel
                openstaande verkoopfacturen als inkoopfacturen. Een
                debiteurenoverzicht biedt inzicht in de nog te ontvangen
                betalingen van je klanten, terwijl het crediteurenoverzicht de
                openstaande betalingen aan leveranciers toont. Beide overzichten
                geven per contact helder weer wat er nog betaald moet worden en
                wat je zelf nog moet betalen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ervaren hoe een gestructureerd debiteuren- en crediteurenbeheer
                je financiële overzicht versterkt.
              </Typography>
              <div className="flex" style={{ gap: "0.5rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Meer informatie over debiteuren en crediteuren overzichten
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default Overviews;
