import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";
function Depreciations() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Boekhouding en rapportage
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Afschrijvingen met Dashbook: bespaar tijd en voorkom fouten
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Investeringen in bedrijfsmiddelen zijn belangrijk voor het succes van
          je bedrijf. Maar de afschrijving van deze investeringen kan een
          tijdrovende klus zijn. Met Dashbook kun je dat eenvoudig en snel
          regelen.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Afschrijvingen met Dashbook
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Investeringen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Automatische (memoriaal)boekingen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Afschrijvingstaat
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Afwijkende periodes
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Investeringen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Investeringen
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Dashbook maakt het invoeren van investeringen van € 450 of meer
                wel heel eenvoudig. Met de speciale "Groot Aankoop" knop vergeet
                je nooit meer een afschrijvingsschema aan te maken.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Automatisch Afschrijvingsschema:</strong>
                Met slechts een klik op de "Groot Aankoop" knop kun je direct
                een afschrijvingsschema genereren. Dashbook neemt je mee in het
                proces, zodat je dit belangrijke aspect van je investering niet
                over het hoofd ziet.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Voorkom Vergeten Afschrijvingen: </strong> Dankzij deze
                handige functionaliteit hoef je niet zelf te onthouden om een
                afschrijvingsschema in te stellen. Dashbook doet dit automatisch
                voor je, waardoor je tijd bespaart en zeker weet dat alles
                correct is geregeld.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Efficiënt en Nauwkeurig:</strong> Het is met een druk op
                de knop geregeld, waardoor je efficiënter kunt werken en minder
                kans hebt op fouten in je administratie.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Zorg voor een gestroomlijnde verwerking van je investeringen met
                Dashbook en maak gebruik van de "Groot Aankoop" functionaliteit
                voor moeiteloos beheer van je afschrijvingsschema's.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Automatische (memoriaal)boekingen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Automatische (memoriaal)boekingen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dashbook maakt het bijhouden van je afschrijvingen nog
                eenvoudiger met automatische memoriaalboekingen. Het systeem
                voert de noodzakelijke boekingen automatisch op de achtergrond
                uit. Maandelijks zal Dashbook de vereiste afboekingen in het
                memoriaal verwerken, totdat de afschrijvingsperiode volledig is
                afgerond.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Meer info zie overige boekingen
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Afschrijvingstaat".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography
                variant="h4"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Afschrijvingstaat
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginBottom: "2rem" }}
              >
                Binnen Dashbook wordt het afschrijven van activa een fluitje van
                een cent. Met een paar klikken zet je het afschrijfschema klaar,
                en daarna heb je er geen omkijken meer naar. Het systeem regelt
                het allemaal automatisch voor je op de achtergrond.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Snel en Efficiënt: </strong> In slechts enkele stappen
                heb je het afschrijfschema klaar. Dit bespaart je tijd en maakt
                het proces efficiënt.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Geen Omkijken Meer Naar:</strong> Eenmaal ingesteld hoef
                je je geen zorgen te maken over het afschrijven. Dashbook
                handelt dit volledig automatisch voor je af.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Achtergrondautomatisering:</strong>
                Het systeem regelt alles op de achtergrond, waardoor je je kunt
                concentreren op andere belangrijke zaken voor je onderneming.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Maak gebruik van de eenvoudige afschrijffunctionaliteit binnen
                Dashbook en ervaar de moeiteloze verwerking van
                activa-afschrijvingen.
              </Typography>
              <div className="flex">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  meer info
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Afwijkende periodes".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Afwijkende periodes
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dashbook biedt de flexibiliteit om afschrijvingsschema's toe te
                voegen, zelfs als je met een lopende administratie begint. Geen
                zorgen meer over afwijkende periodes - Dashbook maakt het
                eenvoudig om investeringen te verwerken.
              </Typography>
              <div className="flex" style={{ gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#4A88EA" }}
                >
                  Hoe boek ik investeringen en afschrijvingen?
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default Depreciations;
