import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function FindYourExpert() {
  const navigate = useNavigate();

  return (
    <div
      className="pageStructure"
      style={{ paddingRight: "0px", paddingLeft: "0px", paddingBottom: "0px" }}
    >
      <div className="prizeBanner" style={{ marginBottom: "3rem" }}>
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Expert nodig ?
        </Typography>

        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Ben je op zoek naar extra advies en ondersteuning bij jouw
          boekhouding?
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div className="findYourExpertBanner">
        <div>
          <Typography variant="h3" align="center" style={{ margin: "0px" }}>
            Vraag nu offertes aan.
          </Typography>
          <Typography variant="h3" align="center" style={{ margin: "0px" }}>
            Het is in 2 minuten geregeld.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "550px",
            margin: "1rem auto",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <img
              src="./Professional/RoundCheck.png"
              width={"auto"}
              height={"100%"}
              alt=""
            />
            <Typography variant="h6" align="center">
              Vraag gratis offertes aan
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <img
              src="./Professional/RoundCheck.png"
              width={"auto"}
              height={"100%"}
              alt=""
            />
            <Typography variant="h6" align="center">
              Binnen 24 uur ontvang je meerdere offertes
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <img
              src="./Professional/RoundCheck.png"
              width={"auto"}
              height={"100%"}
              alt=""
            />
            <Typography variant="h6" align="center">
              Vergelijk en kies de beste aanbieder gratis offertes na
            </Typography>
          </div>
        </div>
        <div className="titleTexts">
          <Button
            variant="contained"
            style={{ backgroundColor: "#D0AF88" }}
            onClick={() => {
              window.location.href = "https://offerte.dashbook.nl/";
            }}
          >
            Gratis offertes aanvragen
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "#D0AF88" }}
            onClick={() => navigate("/dashboard")}
          >
            Aanmelden als professional
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FindYourExpert;
