import React, { useState } from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteRounded from "@mui/icons-material/DeleteOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import { toast } from "react-hot-toast";
import API from "../../config/axios";
import { useParams } from "react-router-dom";
import { useWatch } from "react-hook-form";
import DocumentsDialogBox from "./DocumentsDialogBox";
import useDialogHooks from "../../components/Hooks/useDialogHooks";

const EditFileUploadDialogBox = ({
  isOpen,
  onClose,
  setValue,
  control,
  getUploadDocuments,
  uploadOffersDocuments,
  files,
  setFiles,
}) => {
  const { t } = useTranslation();
  const { offerId } = useParams();

  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteOffersDocId, setDeleteOffersDocumentsId] = useState(null);
  const [isDeletingOffers, setIsDeletingOffers] = useState(false);

  const uploadFiles = useWatch({
    control: control,
    name: "uploadDocuments",
  });
  const uploadDoc = useWatch({
    control: control,
    name: "documents",
  });

  const showDeleteConfirmationDialog = (docId) => {
    setDeleteOffersDocumentsId(docId);
    setShowDeleteDialog(true);
  };

  const closeDeleteConfirmationDialog = () => {
    setShowDeleteDialog(false);
    setDeleteOffersDocumentsId(null);
  };

  const handleFileUpload = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const fileSize = newFile?.size / (1024 * 1024); // Convert size to  2MB
      if (fileSize > 2) {
        toast.error("File size must be 2MB or less");
        e.target.value = null; // Reset the input field
        return;
      }
    }
    setFiles([...files, { file: newFile, size: newFile?.size }]);
    setValue("documents", [...files, { file: newFile, size: newFile?.size }]);
  };

  const handleFileDelete = (index, e) => {
    e.stopPropagation(); // Prevent event propagation
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    setValue("documents", updatedFiles);
  };

  const handleImageClick = async (file) => {
    if (!file?._id) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        openDialog("showPreview");
      };
      reader.readAsDataURL(file);
    } else {
      try {
        const response = await API.get(
          `users/resources/offers?documentId=${offerId}&documentField=documents&subDocumentId=${file._id}`,
          {
            responseType: "arraybuffer",
          }
        );
        if (
          response.headers["content-type"] === "application/json; charset=utf-8"
        ) {
          closeDialog("showPreview");
          toast.error(t("something_went_wrong"));
        } else {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          setSelectedImage(URL.createObjectURL(blob));
          openDialog("showPreview");
        }
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const deleteOffersFileUpload = () => {
    setIsDeletingOffers(true);

    const requestStartTime = Date.now();

    API.delete(`users/offers/${offerId}/documents/${deleteOffersDocId}`)
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          toast.success(t("offers.offers_deleted_successfully"));
          closeDeleteConfirmationDialog();
          setFiles([]);
          getUploadDocuments();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsDeletingOffers(false);
        } else {
          setTimeout(() => {
            setIsDeletingOffers(false);
          }, 300);
        }
      });
  };

  return (
    <>
      <CustomDialogBox
        title={t("offers.dialogBox.title.files")}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <Stack
            mr={1}
            spacing={3}
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                color="info"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </label>
            <input
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              accept=".jpg, .jpeg, .png, "
              onChange={handleFileUpload}
            />
            {uploadDoc?.length !== 0 && (
              <Button
                variant="contained"
                disabled={document?.length === 0 ? true : false}
                color="success"
                sx={{ color: "white" }}
                onClick={uploadOffersDocuments}
              >
                {t("common_btn.save")}
              </Button>
            )}
          </Stack>
        }
      >
        {uploadFiles?.map((file, index) => (
          <Stack key={index} direction={"row"} alignItems="center" mt={2}>
            <Stack flex={1}>
              <Typography
                color="purple"
                sx={{
                  // height: "30px",
                  cursor: "pointer",
                  // ":hover": {
                  //   border: "2px solid black",
                  // },
                }}
                onClick={() => handleImageClick(file)}
              >
                {file.clientFileName} -{" "}
                {Math.round(file.fileSizeInBytes / 1024)} KB
              </Typography>
            </Stack>
            <IconButton
              variant="outlined"
              color="error"
              size="small"
              sx={{
                border: "2px solid currentColor",
                width: "25px",
                height: "25px",
              }}
              onClick={() => showDeleteConfirmationDialog(file._id)}
            >
              <DeleteRounded fontSize="small" />
            </IconButton>
          </Stack>
        ))}
        {files.map((file, index) => (
          <Stack key={index} direction={"row"} alignItems="center" mt={2}>
            <Stack flex={1}>
              <Typography
                color="purple"
                sx={{
                  // height: "30px",
                  cursor: "pointer",
                  // ":hover": {
                  //   border: "2px solid black",
                  // },
                }}
                onClick={() => handleImageClick(file.file)}
              >
                {file.file.name} - {Math.round(file.size / 1024)} KB
              </Typography>
            </Stack>
            <IconButton
              variant="outlined"
              color="error"
              size="small"
              sx={{
                border: "2px solid currentColor",
                width: "25px",
                height: "25px",
              }}
              onClick={(e) => handleFileDelete(index, e)}
            >
              <DeleteRounded fontSize="small" />
            </IconButton>
          </Stack>
        ))}
        {files.length === 0 && uploadFiles.length === 0 && (
          <Typography>No files</Typography>
        )}
      </CustomDialogBox>
      {isDialogOpen("showPreview") && (
        <DocumentsDialogBox
          isOpen={() => isDialogOpen("showPreview")}
          onClose={() => closeDialog("showPreview")}
          selectedImage={selectedImage}
        />
      )}
      <DeleteConfirmationDialog
        open={showDeleteDialog}
        title={t("invoices.dialogBox.title.delete_document")}
        description={t("invoices.dialogBox.delete_selected_document")}
        onClose={closeDeleteConfirmationDialog}
        onDelete={deleteOffersFileUpload}
        isDeletingData={isDeletingOffers}
      />
    </>
  );
};

export default EditFileUploadDialogBox;
