import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export const NotFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          component="h1"
          sx={{
            color: "#637c8e",
            py: 5,
            textAlign: "center",
            fontSize: {
              xs: 40,
              md: 60,
            },
          }}
        >
          <b>404, Not Found</b>
        </Typography>
        <Button variant="contained" href="/" color="primary">
          Go Back To Home
        </Button>
      </Box>
    </Box>
  );
};
