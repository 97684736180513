import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import API from "../config/axios";
import { useAuth } from "../hooks/auth";
import { toast } from "react-hot-toast";
import CustomBtn from "../components/CustomBtn";
import TextInput from "../components/form/TextInput";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const defaultValues = {
  email: "",
  password: "",
};

export default function Login() {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const isDarkTheme = useTheme().palette.mode === "dark";
  const logoLink = isDarkTheme ? "/logo_light.svg" : "/logo.svg";

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("nl");

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("loginPage.emailrequired"))
      .email(t("loginPage.emailInvalid")),
    password: yup.string().required(t("loginPage.passwordRequired")),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    const requestStartTime = Date.now();

    API.post("auth/login", {
      email: data.email,
      password: data.password,
    })
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status) {
          resData.user.tokens = resData.tokens;

          auth.login(resData.user);

          API.defaults.headers.common = {
            Authorization: "Bearer " + resData.tokens.access.token,
          };

          toast.success(t("loginPage.loginSuccessfully"));

          navigate("/dashboard", { replace: true });
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
          } else {
            toast.error("Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  const languageList = [
    {
      name: "Dutch",
      value: "nl",
    },
    {
      name: "English",
      value: "en",
    },
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    setSelectedLanguage(lng); // Set control value when language changes
  };

  // const handleLanguageChange = (selectedLanguage) => {
  //   console.log("asddsad", selectedLanguage);

  //   const selectedLanguageValue = selectedLanguage
  //     ? selectedLanguage.value
  //     : "nl";
  //   if (selectedLanguageValue) {
  //     changeLanguage(selectedLanguageValue);
  //   }
  // };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setSelectedLanguage(selectedLanguage);
    changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    let selectedLang = localStorage.getItem(
      "selectedLanguage",
      selectedLanguage,
    );

    const language = selectedLang ? selectedLang : selectedLanguage;
    changeLanguage(language);
    // eslint-disable-next-line
  }, [selectedLanguage]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={11} md={10} lg={10}>
          <img
            src={logoLink}
            width={"150px"}
            alt="logo"
            style={{ position: "relative", top: "30px", left: "30px" }}
          />
          &nbsp;&nbsp;&nbsp;
          <Select
            variant="standard"
            style={{
              position: "relative",
              top: "30px",
              left: "30px",
              border: "none !important",
              color: "white",
            }}
            value={selectedLanguage}
            onChange={(event) => handleLanguageChange(event)}
            displayEmpty
            renderValue={(selected) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={`/${selected}.png`} // Adjust the path as needed
                  alt={selected}
                  style={{ width: 24, marginRight: 8 }}
                />
                {/* {
                    languageList.find((option) => option.value === selected)
                      ?.name
                  } */}
              </div>
            )}
            // MenuProps={{ PaperProps: { sx: { border: "none" } } }} // Remove border from the menu
          >
            {languageList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <img
                  src={`/${option.value}.png`} // Adjust the path as needed
                  alt={option.value}
                  style={{ width: 24, marginRight: 8 }}
                />
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack sx={{ marginX: 2, marginY: 10 }}>
          <Card
            elevation={1}
            sx={{
              p: 2,
              maxWidth: "450px",
              width: "min(90vw, 450px)",
              boxShadow: isDarkTheme
                ? "rgba(0, 0, 0, 0.1) 0px 10px 50px"
                : "rgba(0, 0, 0, 0.1) 0px 10px 50px",
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight={"medium"} gutterBottom>
                      {t("loginPage.login")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="email"
                      type="email"
                      label={t("loginPage.email")}
                      control={control}
                      placeholder="demo@company.com"
                      customError={inputErrors.email || null}
                      autoFocus={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="password"
                      type="password"
                      label={t("loginPage.password")}
                      control={control}
                      customError={inputErrors.password || null}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <CustomBtn
                      variant="contained"
                      color="secondary"
                      fullWidth
                      size="large"
                      disableRipple
                      type="submit"
                      loading={isLoading}
                    >
                      {t("loginPage.login")}
                    </CustomBtn>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                      spacing={1}
                    >
                      <span>
                        <Typography display={"inline"} mr={1} variant="body2">
                          {t("loginPage.noAccountYet")}
                        </Typography>
                        <Link to="/register" className="custom-link">
                          <Typography
                            color="secondary"
                            sx={{ cursor: "pointer" }}
                            display={"inline"}
                            variant="body2"
                          >
                            {t("loginPage.signUp")}
                          </Typography>
                        </Link>
                      </span>
                      <Link to="/forgot-password" className="custom-link">
                        <Typography
                          color="secondary"
                          sx={{ cursor: "pointer" }}
                          display={"inline"}
                          variant="body2"
                        >
                          {t("loginPage.forgotPassword")}
                        </Typography>
                      </Link>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </>
  );
}
