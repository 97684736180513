import React, { useEffect, useRef, useState } from "react";
import {
  Radio,
  FormControlLabel,
  Typography,
  Stack,
  Box,
  Paper,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import CheckBox from "../../../components/form/CheckBox";
import TextInput from "../../../components/form/TextInput";
import TextareaAutoSize from "../../../components/form/TextareaAutoSize";
import { highlightField } from "../../../utils/Commons";
import CustomBtn from "../../../components/CustomBtn";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import API from "../../../config/axios";

const SendInvoice = () => {
  const { invoiceId } = useParams();
  const invoiceData = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const InvoiceAbortController = useRef(new AbortController());
  const [isSendInvoiceByEmail, setIsSendInvoiceByEmail] = useState("true");
  const validationSchema = yup.object().shape({
    email:
      isSendInvoiceByEmail === "true"
        ? yup.string().email().required("E-mailadres mag niet leeg zijn")
        : "",

    emailText:
      isSendInvoiceByEmail === "true"
        ? yup.string().required("emailText is required")
        : "",
  });

  const { control, watch, handleSubmit, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {
      isSendInvoiceByEmail: "true", // Set the default value here
      attachment: true,
      email: "",
      emailText: `Geachte test test,

      In de bijlage kunt u factuur {document.invoice_id} voor onze diensten vinden. Wij verzoeken u vriendelijk de factuur voor 27-09-2023 te voldoen.
      
      Met vriendelijke groet,
      
      RS`,
    },
    resolver: yupResolver(validationSchema),
  });

  const sendEmail = watch("isSendInvoiceByEmail");
  useEffect(() => {
    setIsSendInvoiceByEmail(sendEmail);
  }, [sendEmail]);

  const onSubmit = (data) => {
    const requestStartTime = Date.now();
    const fromData = {
      isSendInvoiceByEmail: data.isSendInvoiceByEmail,
      email: data?.email,
      emailText: data?.emailText,
    };

    setIsLoading(true);

    API.put(`users/invoices/${invoiceId}/send`, fromData)
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          toast.success(t("invoices.invoice_send_successfully"));
          navigate(-1);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };

  const InvoiceSend = () => {
    return (
      <Stack paddingLeft={4} spacing={2}>
        <Stack alignItems="center" direction="row" marginLeft={-1.5}>
          <CheckBox
            name="attachment"
            control={control}
            label={t("invoices.send_invoice.PDF_attachment")}
          />

          <Typography component="span" variant="body2">
            {t("invoices.send_invoice.Add_UBL_invoice")}
          </Typography>
        </Stack>
        <Stack>
          <TextInput
            name="email"
            control={control}
            label={t("invoices.send_invoice.email")}
          />
        </Stack>
        <Stack>
          <TextareaAutoSize
            name="emailText"
            control={control}
            label={t("invoices.send_invoice.email_emailText")}
          />
        </Stack>
      </Stack>
    );
  };

  const getInvoice = () => {
    // Cancel the previous request
    InvoiceAbortController.current.abort();
    InvoiceAbortController.current = new AbortController();

    // setIsLoadingVatCategories(true);

    API.get(`users/invoices/${invoiceId}`, {
      signal: InvoiceAbortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setValue("email", resData.data.customerId.email);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        // setIsLoadingVatCategories(false);
      });
  };

  useEffect(() => {
    getInvoice();
    return () => {
      InvoiceAbortController.current.abort();
    };
    // eslint-disable-next-line
  }, [invoiceId]);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Paper
        sx={{ backgroundColor: "primaryBg.light", borderRadius: 2, p: 2 }}
        elevation={2}
      >
        <Typography variant="h5" fontWeight={500} mb={2}>
          {t("invoices.send_invoice.invoice")}{" "}
          {invoiceData.state?.invoiceNoFormatted}{" "}
          {t("invoices.send_invoice.send")}
        </Typography>
        <Controller
          name="isSendInvoiceByEmail"
          control={control}
          defaultValue="true"
          render={({ field }) => (
            <>
              <Stack spacing={2}>
                <FormControlLabel
                  control={
                    <Radio
                      {...field}
                      color="info"
                      value="true"
                      checked={field.value === "true"}
                    />
                  }
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h6" fontWeight={500}>
                        {t("invoices.send_invoice.send_invoice_by_email")}
                      </Typography>
                      <Typography>
                        {t("invoices.send_invoice.PDF_attachment")}
                      </Typography>
                    </Stack>
                  }
                />
                {sendEmail === "true" && <InvoiceSend />}
              </Stack>
              <Stack mt={3}>
                <FormControlLabel
                  control={
                    <Radio
                      {...field}
                      color="info"
                      value="false"
                      checked={field.value === "false"}
                    />
                  }
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h6" fontWeight={500}>
                        {t("invoices.send_invoice.send_invoice_manually")}
                      </Typography>
                      <Typography>
                        {t("invoices.send_invoice.The_invoice_will_sent")}
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
            </>
          )}
        />
        <Box mt={4}>
          <CustomBtn
            variant="contained"
            color="secondary"
            sx={{ paddingLeft: 2, paddingRight: 2 }}
            disableRipple
            type="submit"
            loading={isLoading}
          >
            Submit
          </CustomBtn>
        </Box>
      </Paper>
    </form>
  );
};

export default SendInvoice;
