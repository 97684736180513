import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
} from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

function RatesOverview() {
  const rows = [
    {
      name: "",
      data: [
        {
          title: "Eenvoudige invoerschermen",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt gebruikers (medewerkers) aanmaken",
          professionals: false,
          timeKeeper: false,
          starter: false,
          unlimited: true,
        },
        {
          title: "Rechten en rollen instellen",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt relaties aanmaken ",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Relatiebeheer",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Categorieën en subcatergorieën (rekeningschema)",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Inrichting administratie",
          professionals: false,
          timeKeeper: false,
          starter: false,
          unlimited: true,
        },
      ],
    },
    {
      name: "Ritten",
      data: [
        {
          title: "Vervoemiddelen aanmaken",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Kilometerregistratie",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Kilimetevergoeding",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Privé of zakelijk",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Projecten",
      data: [
        {
          title: "Onbeperkt projecten aanmaken",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Uurtariefsysteem met overwerkuren en weekendtarieven",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Uurtariefsysteem met vaste tarieven en groepdiensten",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title:
            "uren, medewerkers, taken/activiteiten aan een project gekoppeld",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Projecten dupliceren",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Projecten overzicht",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Exporteren naar PDF of Excel",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Inrichting projecten 'branche gericht'",
          professionals: false,
          timeKeeper: false,
          starter: false,
          unlimited: true,
        },
      ],
    },
    {
      name: "Uren + Time Keeper",
      data: [
        {
          title: "Urenregistratie",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt urenregels factureren",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Projecturen",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Urencriterium",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Urenoverzicht",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Exporteren naar PDF of Excel",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Time Keeper aanwezig",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Producten en diensten",
      data: [
        {
          title: "Onbeperkt producten en diensten aanmaken ",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Producten en diensten beheren",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt producten en diensten factureren",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Offertes",
      data: [
        {
          title: "Onbeperkt offertes maken",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Huisstijl en logo op offerte",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Producten zoekmachine",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Versturen via e-mail",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Downloaden als PDF",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Inhoud bericht mail zelf typen + bijlage toevoegen",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Online Accepteren",
          professionals: true,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Factureren",
      data: [
        {
          title: "Factureren in je eigen huisstijl",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Van offerte tot factuur",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Producten zoekmachine",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt facturen opstellen",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Downloaden als PDF",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Inhoud bericht mail zelf typen + bijlage toevoegen",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Versturen via e-mail",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt Urenregels factureren",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt Producten factureren",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Onbeperkt Credtifacturen maken",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Facturatie met IDEAL (Mollie)",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Facturen in toekomst versturen",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Herinneringen en aanmaningen",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Digitale inbox (verkoop)",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Overzicht facturen",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Bestanden exporteren naar PDF of Excel",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Periodieke facturen",
      data: [
        {
          title: "Onberperkt periodieke facturen aanmaken",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Verstuur automatisch",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Overzicht periodieke facturen",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Bestanden exporteren naar PDF of Excel",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
      ],
    },

    {
      name: "Inkopen (Kosten)",
      data: [
        {
          title: "Onbeperkt inkoopfacturen en bonnetjes verwerken",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Inkoop scannen",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Inkoopfacturen per e-mail (inbox)",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Digitale inbox (inkoop)",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Bank",
      data: [
        {
          title: "Onbeperkt banktransacties verwerken (gratis bankkoppeling)",
          professionals: false,
          timeKeeper: false,
          starter: false,
          unlimited: true,
        },
        {
          title: "Automatische bankkopeling",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Relatime banktransacties",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Alle Nederlandse banken",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Bankimport (MT940)",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Mollie Ideal",
      data: [
        {
          title: "Makkelijk voor jou en je klant",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Direct zichtbaar",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Sneller betaald",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
        {
          title: "Automatisch verwerkt",
          professionals: false,
          timeKeeper: true,
          starter: true,
          unlimited: true,
        },
      ],
    },

    {
      name: "BTW- aangiftes",
      data: [
        {
          title: "BTW uit facturen",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "BTW uit kosten",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Verstuurd naar de belastngdienst",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Opgaaf ICP",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Afschrijvingen",
      data: [
        {
          title: "Investeringen >450",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Afschrijvingschema",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Atomatische (Memoriaalboekingen)",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Afwijkende periodes",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Overzichten",
      data: [
        {
          title: "Beginbalans",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Jaarafsluiting",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Negatieve posten",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Banksaldo en kassaldo",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Debiteuren en crediteuren",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Vraagposten en kruisposten",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
      ],
    },
    {
      name: "Overige boekingen",
      data: [
        {
          title: "Memoriaalboekingen",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
        {
          title: "Loonkosten inboeken",
          professionals: false,
          timeKeeper: false,
          starter: true,
          unlimited: true,
        },
      ],
    },
  ];

  return (
    <div style={{ padding: "2rem 6rem", backgroundColor: "#F3F3F3" }}>
      <Typography
        variant="h3"
        gutterBottom
        textAlign={"center"}
        sx={{ padding: "2rem", color: "#D0AF88", fontWeight: "300 !important" }}
      >
        Dashbook Backoffice service: Laat je boekhouding uit handen nemen
      </Typography>
      <Typography variant="h6" gutterBottom textAlign={"center"}>
        Probeer eerst alle functies behorende bij dashbook unlimted helemaal
        gratis en vrijblijvend uit.
      </Typography>
      <div style={{ padding: "2rem 0px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "white", backgroundColor: "black" }}>
                  Algemeen
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "white",
                    backgroundColor: "#D0AF88",
                  }}
                >
                  Professionals
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", backgroundColor: "#D0AF88" }}
                >
                  Time Keeper + facturatie Gold
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", backgroundColor: "#D0AF88" }}
                >
                  Starters / ZZP'er / MINI
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", backgroundColor: "#D0AF88" }}
                >
                  Dashbook Unlimited
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    {row.name !== "" ? (
                      <TableCell
                        colSpan={5}
                        sx={{
                          color: "black",
                          backgroundColor: "#D0AF88",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {row.name}
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                  {row.data.map((item, itemIndex) => (
                    <TableRow key={itemIndex}>
                      <TableCell>{item.title}</TableCell>
                      <TableCell align="center">
                        {item.professionals ? (
                          <DoneIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "green",
                            }}
                          />
                        ) : (
                          <CloseIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "red",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.timeKeeper ? (
                          <DoneIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "green",
                            }}
                          />
                        ) : (
                          <CloseIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "red",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.starter ? (
                          <DoneIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "green",
                            }}
                          />
                        ) : (
                          <CloseIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "red",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.unlimited ? (
                          <DoneIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "green",
                            }}
                          />
                        ) : (
                          <CloseIcon
                            style={{
                              height: "15px",
                              margin: "6px 0px",
                              color: "red",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default RatesOverview;
// {rows.map((row, index) => (
//     <TableRow key={index}>
//       {row.name !== "" ? (
//         <TableCell colSpan={5}>{row.name}</TableCell>
//       ) : (
//         ""
//       )}
//       {/* {
//         rows.data.map((item,itemIndex)=>(

//       <TableCell component="th" scope="row">
//         {row.title}
//       </TableCell>
//       <TableCell align="center">
//         {row.professionals ? (
//   <DoneIcon
//     style={{
//       height: "15px",
//       margin: "6px 0px",
//       color: "green",
//     }}
//   />
//         ) : (
// <CloseIcon
//   style={{
//     height: "15px",
//     margin: "6px 0px",
//     color: "red",
//   }}
// />
//         )}
//       </TableCell>
//       <TableCell align="center">
//         {row.timeKeeper ? (
// <DoneIcon
//   style={{
//     height: "15px",
//     margin: "6px 0px",
//     color: "green",
//   }}
// />
//         ) : (
//   <CloseIcon
//     style={{
//       height: "15px",
//       margin: "6px 0px",
//       color: "red",
//     }}
//   />
//         )}
//       </TableCell>
//       <TableCell align="center">
//         {row.starter ? (
// <DoneIcon
//   style={{
//     height: "15px",
//     margin: "6px 0px",
//     color: "green",
//   }}
// />
//         ) : (
// <CloseIcon
//   style={{
//     height: "15px",
//     margin: "6px 0px",
//     color: "red",
//   }}
// />
//         )}
//       </TableCell>
//       <TableCell align="center">
//         {row.unlimited ? (
// <DoneIcon
//   style={{
//     height: "15px",
//     margin: "6px 0px",
//     color: "green",
//   }}
// />
//         ) : (
// <CloseIcon
//   style={{
//     height: "15px",
//     margin: "6px 0px",
//     color: "red",
//   }}
// />
//         )}
//       </TableCell>
//         ))
//       } */}
//     </TableRow>
//   ))}
