import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SimpleScreens() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Inrichting
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Eenvoudige invoerschermen voor boekhoudsoftware
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          De boekhoudsoftware heeft eenvoudige invoerschermen die het
          gemakkelijk maken om gegevens in te voeren. Dit helpt gebruikers om
          tijd en moeite te besparen. De schermen zijn gebruiksvriendelijk en
          overzichtelijk, zodat je zonder boekhoudkennis aan de slag kunt.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>

      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <Typography variant="h4" className="titleUponDivider" gutterBottom>
          De mogelijkheden voor eenvoudige invoerschermen met Dashbook
        </Typography>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Invoerschermen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Invoerschermen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={4.5}
            lg={4.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="up-div card">
              <Typography variant="h4" gutterBottom>
                Invoerschermen
              </Typography>
              <Typography variant="h5" gutterBottom>
                Voordelen:
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemak: </strong>De boekhoudsoftware biedt eenvoudige
                invoerschermen waardoor het invoeren van gegevens moeiteloos is.
                Dit bespaart gebruikers tijd en moeite.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <strong>Gebruiksvriendelijkheid: </strong>De schermen zijn
                ontworpen met gebruiksvriendelijkheid in gedachten, zodat zelfs
                gebruikers zonder boekhoudkennis er probleemloos mee kunnen
                werken.
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <strong>Transparantie: </strong>Je behoudt te allen tijde helder
                inzicht in de ingevoerde gegevens.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={7} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div imageCard">
              <img
                src={"./Professional/SimpleScreensImage.png"}
                alt="Dashbook Logo"
                width={"100%"}
                style={{
                  margin: "auto 0px",
                  display: "block",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SimpleScreens;
