import { MenuItem } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const HorizontalMenu = ({
  menuItem,
  zIndex = 1,
  parent,
  setParent,
  closeMenubar,
  setPage,
  activePage,
}) => {
  const handleOpen = (event) => {
    setParent(menuItem.value);
  };

  const handleClose = (event) => {
    closeMenubar();
  };

  const handleClick = (page) => {
    setPage(page);
    closeMenubar();
  };

  const isActive = () => {
    if (activePage === menuItem.value) return true;
  
    if (menuItem.submenu) {
      const isSubActive = menuItem.submenuOptions.some((subOption) => {
        if (subOption.value === activePage) {
          return true;
        }
        if (subOption.submenu) {
          return subOption.submenuOptions.some(
            (nestedOption) => nestedOption.value === activePage
          );
        }
        return false;
      });
  
      if (isSubActive) return true;
    }
  
    return false;
  };
  

  return (
    <div>
      <MenuItem
        sx={{ justifyContent: "space-between" }}
        onMouseEnter={handleOpen}
        onClick={() => handleClick(menuItem.value)}
        className={`activeHeaderEffect ${isActive() ? "activeHeader" : ""}`}
      >
        {menuItem.name}
        {menuItem.submenu ? (
          parent === menuItem.value ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )
        ) : (
          ""
        )}
      </MenuItem>
      <div
        style={{
          position: "absolute",
          zIndex: "1",
          backgroundColor: "#211D1C",
          color: "white",
          width: "100%",
          left: "0px",
          display: parent === menuItem.value ? "flex" : "none",
          justifyContent: "center",
          textAlign: "center",
        }}
        onMouseLeave={handleClose}
      >
        {menuItem.submenuOptions.map((subOption, subOptionIndex) => (
          <div
            key={subOptionIndex}
            sx={{ justifyContent: "space-between" }}
            style={{ marginTop: "1rem" }}
          >
            <MenuItem
              sx={{ justifyContent: "center" }}
              onClick={() => handleClick(subOption.value)}
              className={`activeHeaderEffect ${
                activePage === subOption.value ? "activeHeader" : ""
              }`}
            >
              {subOption.name}
            </MenuItem>
            <ul
              style={{
                listStyle: "none",
                textAlign: "center",
                padding: "0px",
              }}
            >
              {subOption.submenuOptions.map(
                (nestedOption, nestedOptionIndex) => (
                  <li key={nestedOptionIndex}>
                    <MenuItem
                      sx={{ justifyContent: "center" }}
                      onClick={() => handleClick(nestedOption.value)}
                      className={`activeHeaderEffect ${
                        activePage === nestedOption.value ? "activeHeader" : ""
                      }`}
                    >
                      {nestedOption.name}
                    </MenuItem>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
export default HorizontalMenu;
