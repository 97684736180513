import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function TryFourteenDays() {
  const navigate = useNavigate();
  return (
    <div className="tryNow">
      <Typography variant="h6" gutterBottom>
        Test gratis voor 14 dagen
      </Typography>
      <Typography variant="body1" gutterBottom>
        Meld je nu aan en start je gratis proefperiode
      </Typography>
      <Button variant="contained" onClick={()=>navigate('/dashboard')}>Gratis proefperiode</Button>
    </div>
  );
}

export default TryFourteenDays;
