import React from "react";
import CustomDialogBox from "../../components/CustomDialogBox";
import { Button, Divider, Stack, Typography } from "@mui/material";
import TextInput from "../../components/form/TextInput";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { highlightField } from "../../utils/Commons";
import api from "../../config/axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const validationSchema = yup.object().shape({
  fullName: yup.string().required(),
  email: yup.string().email().required(),
});

const AcceptDialogBox = ({
  isOpen,
  onClose,
  offersdata,
  setAccept,
  setSeconds,
}) => {
  const { t } = useTranslation();
  const { offerToken } = useParams();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
    },
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      const res = await api.post(`public/offers/accept`, {
        offerToken: offerToken,
        name: data.fullName,
        email: data.email,
      });
      const resData = res.data;

      if (resData.status) {
        toast.success(t("offers.offers_accept_successfully"));
        onClose();
        setAccept(false);
        setSeconds(10);
        const timer = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        setTimeout(() => {
          clearInterval(timer);
          window.location.href = "/"; // Replace with the path to your home page
        }, 10000);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };
  return (
    <CustomDialogBox
      title={t("offers.dialogBox.title.accept_offer")}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Stack
          mr={1}
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="success"
            size="large"
            sx={{ color: "white" }}
            onClick={handleSubmit(onSubmit, onError)}
          >
            {t("common_btn.save")}
          </Button>
        </Stack>
      }
    >
      <form>
        <TextInput
          name="fullName"
          control={control}
          label={t("offers.fields.full_Name")}
        />
        <Divider sx={{ mt: 2, mb: 2 }} />
        <TextInput
          name="email"
          control={control}
          label={t("offers.fields.email")}
        />
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Stack direction="row" spacing={2} justifyContent="center">
          <Typography variant="body1">
            {t("offers.email_send.date")} :
          </Typography>
          <Typography>
            {" "}
            {dayjs(offersdata?.date).format("DD-MM-YYYY")}
          </Typography>
        </Stack>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack direction="row" spacing={2} justifyContent="center">
          <Typography variant="body1">
            {t("offers.email_send.amount")} :
          </Typography>
          <Typography>
            {offersdata?.total?.toLocaleString("nl-NL", {
              style: "currency",
              currency: "EUR",
            })}
          </Typography>
        </Stack>
      </form>
    </CustomDialogBox>
  );
};

export default AcceptDialogBox;
