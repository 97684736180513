import React, { useState, useEffect } from "react";

import {
  Paper,
  Typography,
  Stack,
  Button,
  Box,
  Grid,
  Card,
} from "@mui/material";

import API from "../../config/axios";
import { toast } from "react-hot-toast";

import styles from "./Dashboard.module.css";

import { truncateText } from "../../utils/Commons";
import DetailsDialogBox from "./DetailsDialogBox";
import useDialogHooks from "../../components/Hooks/useDialogHooks";

export default function AppsAndDeals() {
  const { openDialog, closeDialog, isDialogOpen } = useDialogHooks();

  const [rows, setRows] = useState([]);

  const fetchData = (url) => {
    const abortController = new AbortController();

    return API.get(url, {
      signal: abortController.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          return resData.data;
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        abortController.abort();
      });
  };

  const mergeAndRandomSort = async () => {
    const appList = await fetchData("public/getPublicAppList");
    const dealsList = await fetchData("public/getPublicDealList");

    if (Array.isArray(appList) && Array.isArray(dealsList)) {
      const combinedData = [...appList, ...dealsList];
      const randomlySortedData = combinedData.sort(() => Math.random() - 0.5);

      setRows(randomlySortedData);
    }
  };

  useEffect(() => {
    mergeAndRandomSort();

    // eslint-disable-next-line
  }, []);

  function getDealsAndAppsFromPath(pathString) {
    const segments = pathString.split("/");

    if (segments.length >= 3) {
      return segments[2];
    } else {
      return null; // or handle it as needed (throw an error, return a default value, etc.)
    }
  }

  return (
    <>
      {rows?.map((row, index) => (
        <Grid
          item
          xs={12}
          md={3.5}
          sm={12}
          key={index.toString()}
          style={{ marginBottom: "2rem" }}
        >
          <Card
            className={styles.mui_card}
            sx={{
              ":hover": {
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.28)",
              },
            }}
          >
            <Stack justifyContent="space-between" p={1} height="100%">
              <Stack>
                <Box position="relative">
                  <Box
                    component="img"
                    className={styles.banner_img}
                    src={`${
                      process.env.REACT_APP_API_BASE_URL
                    }${getDealsAndAppsFromPath(row?.logo.filePath)}/banners/${
                      row?.banner?.fileName
                    }`}
                  ></Box>
                  <Paper className={styles.logo_paper}>
                    <Box
                      className={styles.logo_img}
                      component="img"
                      src={`${
                        process.env.REACT_APP_API_BASE_URL
                      }${getDealsAndAppsFromPath(row?.logo.filePath)}/logos/${
                        row.logo?.fileName
                      }`}
                    ></Box>
                  </Paper>
                </Box>
                <Stack mt={8} mb={5} ml={1} mr={1}>
                  <Typography className={styles.title_text}>
                    {truncateText(row.title, 30)}
                  </Typography>
                  <Typography className={styles.location_text}>
                    {truncateText(row.companyName, 30)} -{" "}
                    {truncateText(row.companyLocation, 26)}
                  </Typography>
                  <Typography className={styles.summary_text}>
                    {truncateText(row.summary, 250)}
                  </Typography>
                </Stack>
              </Stack>

              <Stack alignItems="flex-end">
                <Button
                  onClick={() => openDialog(`DetailsShow${index}`)}
                  variant="contained"
                  fullWidth
                  color="secondary"
                  className={styles.callToActionBtnLbl}
                >
                  {truncateText(row.callToActionBtnLbl, 50)}
                </Button>
              </Stack>
            </Stack>
          </Card>
          {isDialogOpen(`DetailsShow${index}`) && (
            <DetailsDialogBox
              type={getDealsAndAppsFromPath(row?.logo.filePath)}
              isOpen={() => isDialogOpen(`DetailsShow${index}`)}
              onClose={() => closeDialog(`DetailsShow${index}`)}
              row={row}
            />
          )}
        </Grid>
      ))}
    </>
  );
}
