import React from "react";
import CustomDialogBox from "../../../components/CustomDialogBox";
import { Stack, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

const NotGiveDiscount = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <CustomDialogBox
      title={t("invoices.dialogBox.title.not_give_discount")}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="lg"
    >
      <Stack mt={1}>
        <Typography>
          {t("invoices.dialogBox.not_give_discount_text_line_1")}
        </Typography>
        <Typography>
          {t("invoices.dialogBox.not_give_discount_text_line_2")}
        </Typography>
        <Typography mt={2}>
          {t("invoices.dialogBox.not_give_discount_text_line_3")}
        </Typography>
      </Stack>
    </CustomDialogBox>
  );
};

export default NotGiveDiscount;
