import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import TryFourteenDays from "../Functies/TryFourteenDays";
import { useNavigate } from "react-router-dom";

function FromWhome() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div style={{ padding: "2rem" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Voor wie is Dahsbook ?
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Vind jij dat de administratieve kant van ondernemen gemakkelijker kan?
          Wij ook!
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Dashbook is er voor zzp'ers en kleine ondernemers, ongeacht de sector
          waarin je werkt of je leeftijd. Dashbook is ontworpen voor iedereen
          die meer vrijheid wil ervaren in zijn of haar professionele leven. Als
          je zelfstandig bent en overweg kunt met een computer, is de kans groot
          dat je moeiteloos met Dashbook aan de slag kunt. Ontdek de eenvoud van
          boekhouden met Dashbook.
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button variant="contained" sx={{ color: "white" }} onClick={()=>navigate('/dashboard')}>
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        style={{ padding: "2rem", paddingBottom: "0px", paddingLeft: "50px" }}
      >
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            Dashbook voor wie ?
          </Typography>
        </div>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Rechtsvormen
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Sectoren
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Adminstratietypes
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Rechtsvormen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="left-div">
              <Grid container justifyContent={"space-between"}>
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  sm={12}
                  sx={{ marginBottom: "2rem" }}
                >
                  <Card className="subCard">
                    <CardContent>
                      <div
                        style={{
                          // height: "100%",
                          // display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            textAlign: "left",
                            color: "#D0AF88",
                            fontWeight: "300 !important",
                            textDecoration: "underline",
                            fontStyle: "italic",
                          }}
                          gutterBottom
                        >
                          Starters, Zzp'ers en eenmanszaken
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook: speciaal ontwikkeld voor starters, zzp'ers
                          en eenmanszaken.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook is vanaf de start ontworpen met de missie om
                          boekhouden begrijpelijk en eenvoudig te maken voor
                          ondernemers.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook onderscheidt zich door:
                        </Typography>
                        <Typography variant="subtitle1">
                          Eenvoudig gebruik
                        </Typography>
                        <Typography variant="subtitle1">
                          Betaalbaarheid
                        </Typography>
                        <Typography variant="subtitle1">
                          Volledige online toegankelijkheid
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Automatische bankkoppeling
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook biedt alle benodigdheden om je boekhouding
                          correct en efficiënt te beheren. Ontdek het gemak van
                          boekhouden met Dashbook.
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  sm={12}
                  sx={{ marginBottom: "2rem" }}
                >
                  <Card className="subCard">
                    <CardContent>
                      <div
                        style={{
                          // height: "100%",
                          // display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            textAlign: "left",
                            color: "#D0AF88",
                            fontWeight: "300 !important",
                            textDecoration: "underline",
                            fontStyle: "italic",
                          }}
                          gutterBottom
                        >
                          Vof's en (kleine) bv's
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook: Geschikt voor Vof's en (kleine) bv's
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Hoewel Dashbook speciaal rekening houdt met zzp'ers,
                          is het een volwaardig boekhoudprogramma dat geschikt
                          is voor elke onderneming.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          De boekhouding van een relatief kleine bv of vof
                          verschilt niet significant van die van een zzp'er of
                          eenmanszaak. We hebben voor VOF de subcategorie al
                          samen gesteld. Kies de juiste template en alle
                          benodigd templates zijn voor u gereed gemaakt.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook is daarom ook uiterst geschikt voor
                          dergelijke ondernemingen. Met Dashbook is er geen
                          behoefte aan een traditioneel, complex en duur
                          boekhoudprogramma. Het biedt de functionaliteiten die
                          nodig zijn, zonder onnodige complexiteit.
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"}>
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  sm={12}
                  sx={{ marginBottom: "2rem" }}
                >
                  <Card className="subCard">
                    <CardContent>
                      <div
                        style={{
                          // height: "100%",
                          // display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            textAlign: "left",
                            color: "#D0AF88",
                            fontWeight: "300 !important",
                            textDecoration: "underline",
                            fontStyle: "italic",
                          }}
                          gutterBottom
                        >
                          Holdings en dga's
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook: Geschikt voor Holdings en DGA's
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Als dga met een holding heb je een uitgebreide
                          boekhouding nodig, en Dashbook is speciaal ontworpen
                          om aan deze behoeften te voldoen.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook biedt alle benodigde functionaliteiten voor
                          een volledige en nauwkeurige administratie, waaronder:
                        </Typography>
                        <Typography variant="subtitle1">Facturatie</Typography>
                        <Typography variant="subtitle1">
                          Bankkoppeling
                        </Typography>
                        <Typography variant="subtitle1">
                          BTW-aangifte
                        </Typography>
                        <Typography variant="subtitle1">
                          Kostenbeheer
                        </Typography>
                        <Typography variant="subtitle1">
                          Financieel overzicht
                        </Typography>
                        <Typography variant="subtitle1">
                          Managementrapportage
                        </Typography>
                        <Typography variant="subtitle1">
                          Dashbook is de complete boekhoudoplossing voor
                          holdings en dga's, waarmee je eenvoudig en efficiënt
                          je financiën kunt beheren.
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5.5}
                  sm={12}
                  sx={{ marginBottom: "2rem" }}
                >
                  <Card className="subCard">
                    <CardContent>
                      <div
                        style={{
                          // height: "100%",
                          // display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            textAlign: "left",
                            color: "#D0AF88",
                            fontWeight: "300 !important",
                            textDecoration: "underline",
                            fontStyle: "italic",
                          }}
                          gutterBottom
                        >
                          Verenigingen en stichtingen
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook: De ideale boekhoudoplossing voor Stichtingen
                          en Verenigingen
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Voor stichtingen en verenigingen is een zorgvuldige
                          administratie van groot belang, en Dashbook is de
                          perfecte oplossing hiervoor.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Dashbook biedt een betaalbare en efficiënte oplossing
                          voor de boekhouding van non-profit organisaties.
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Met Dashbook kun je:
                        </Typography>
                        <Typography variant="subtitle1">
                          Je boekhouding eenvoudig en snel bijhouden
                        </Typography>
                        <Typography variant="subtitle1">
                          Kosten besparen op je boekhoudkosten
                        </Typography>
                        <Typography variant="subtitle1">
                          Voldoen aan alle wettelijke eisen
                        </Typography>
                        <Typography variant="subtitle1">
                          Dashbook is speciaal ontworpen om aan de behoeften van
                          stichtingen en verenigingen te voldoen. Het is
                          gebruiksvriendelijk en biedt alle benodigde
                          functionaliteiten voor een nauwkeurige en volledige
                          administratie. Ontdek de gemakken van boekhouden met
                          Dashbook.
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Sectoren".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="down-div">
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/it_specialist.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      IT Specialist
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/consultant.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Consultant
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src="./Professional/build.png" alt="it_specialist" />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Bouw
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src="./Professional/concern.png" alt="it_specialist" />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Zorg
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/parcel_deliver.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Pakketbezorger
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/yoga_teacher.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Yogadocent
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/sports_trainer.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Sport-Fitness Trainer
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/therapist.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Therapeut
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/interior_stylist.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Interieurstylist
                    </Button>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={3.5}
                  lg={3.5}
                  style={{ margin: "1rem" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="./Professional/young_entrepreneurs.png"
                      alt="it_specialist"
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        display: "block",
                        width: "-webkit-fill-available",
                        marginTop: "1rem",
                      }}
                    >
                      Jonge ondernemers
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Adminstratietypes".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Adminstratietypes
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Onze alles-in-één boekhouding is geschikt voor:
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                • Starters met weinig uitgaven
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                • BV of Holdings met weinig uitgaven
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                • Ervaren ondernemers met veel facturen
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                • Kleine ondernemers die deelnemen aan de nieuwe KOR
                (Kleineondernemersregeling)
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                • Freelancers die willen overstappen vanuit een ander
                boekhoudpakket
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                • Zzp’ers met een boekhouding in Excel Met Dashbook beschik je
                over een compleet boekhoudpakket waarmee je facturen kunt
                versturen, bonnen en inkoopfacturen automatisch kunt verwerken,
                en altijd overzicht hebt van je financiën. Ontdek het gemak van
                boekhouden met Dashbook.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default FromWhome;
