import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import "./MainLayout.css";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Header from "./Header";
import SideBar from "./SideBar";
import { useDispatch } from "react-redux";
import { setCountries } from "../features/common/commonSlice";
import API from "../config/axios";
import { toast } from "react-hot-toast";
import { useState } from "react";

export default function MainLayout() {
  const dispatch = useDispatch();

  const abortController = useRef(new AbortController());
  const [drawerWidth, setDrawerWidth] = useState(260);
  const [showFullDrawer, setShowFullDrawer] = useState(true);

  const getCountries = () => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    API.get("users/countries", {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          dispatch(setCountries(resData.data));
        } else {
          toast.error("Countries cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };

  const isDarkTheme = useTheme().palette.mode === "dark";

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setShowFullDrawer(true);
    setMobileOpen(!mobileOpen);
    setDrawerWidth(260);
  };
  const handleDrawerWidthToggle = () => {
    setShowFullDrawer(!showFullDrawer);
    if (drawerWidth === 260) {
      setDrawerWidth(70);
    } else {
      setDrawerWidth(260);
    }
  };

  useEffect(() => {
    getCountries();

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        isDarkTheme={isDarkTheme}
        setDrawerWidth={setDrawerWidth}
        handleDrawerWidthToggle={handleDrawerWidthToggle}
        showFullDrawer={showFullDrawer}
      />
      <SideBar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        isDarkTheme={isDarkTheme}
        mobileOpen={mobileOpen}
        showFullDrawer={showFullDrawer}
      />
      <Paper
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          minHeight: "100vh",
          width: "100%",
          bgcolor: "primaryBg.dark",
        }}
      >
        <Toolbar />
        <Outlet />
      </Paper>
    </Box>
  );
}
