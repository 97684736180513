import { createContext, useContext, useEffect, useState } from "react";
import API from "../config/axios";

const AuthContext = createContext(null);
const checkMembershipStatus = async () => {
  API.put("/users/update_status")
    .then(() => {
      console.warn("Status Updated successfully");
    })
    .catch(() => {
      console.warn("Something went wrong while Status Update");
    });
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem("dashbook_user");

    if (user) {
      try {
        setUser(JSON.parse(user));
      } catch (error) {
        console.warn("user cannot be set");
      }
    }
  }, []);

  const login = (user) => {
    try {
      localStorage.setItem("dashbook_user", JSON.stringify(user));
      localStorage.removeItem("beginBalancePopup");
      setUser(user);
      checkMembershipStatus();
    } catch (error) {
      console.warn("user cannot be set");
    }
  };

  const logout = () => {
    const refreshToken = user?.tokens?.refresh?.token;

    if (refreshToken) {
      API.post("/auth/logout", {
        refreshToken: refreshToken,
      })
        .then(() => {
          console.warn("Logged out successfully");
        })
        .catch(() => {
          console.warn("Something went wrong while logging out user");
        });
    }

    localStorage.removeItem("dashbook_user");
    localStorage.removeItem("beginBalancePopup");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
