import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";

function OurPrices() {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "2rem", backgroundColor: "#F3F3F3" }}>
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Onze Prijzen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Simpel en Betaalbaar: Alle Essentiële Functies, Geen Overbodige
          Toeters en Bellen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Met onze boekhoudpakketten ben je verzekerd van succes, ongeacht je
          bedrijfsomvang.
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Alle benodigde functies en modules zijn inbegrepen, samen met
          transparante prijzen.
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Vraag vandaag nog een gratis proefperiode aan en laat je bedrijf
          groeien
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div className="prizeContent">
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} md={8} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card
              style={{
                padding: "1rem !important",
                // width: "100%",
                height: "100%",
                borderRadius: "15px",
                marginRight: "2rem",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  marginTop: "0.5rem",
                  paddingLeft: "3rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Ben je nog geen member van Dashbook? Word het snel en
                  profiteer van leuke en simpele kortingen, zoals:
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  * Krijg korting op onze boekhoudpakketten tussen 20 en 70%
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  * Korttingen op verschillende relevante producten en diensten
                  van onze partners
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  * Gratis consult van 30 minuten met een van onze
                  boekhoudspecialisten
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <img
              alt=""
              src="./Professional/WordLid.png"
              width={"100%"}
              height={"auto"}
            />
          </Grid>
        </Grid>
      </div>
      <Typography
        variant="h2"
        gutterBottom
        textAlign={"center"}
        sx={{ color: "#D0AF88" }}
      >
        Online factureren en Boekhouden
      </Typography>
      <div style={{ margin: "3rem" }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} md={3.75} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                  }}
                >
                  DB Time Keeper + facturatie Gold
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  Onbeperkt vlotte urenregistratie en facturatie voor teams en
                  freelancers.
                </Typography>

                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  5,00*
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Alle functies die u nodig heeft:
                </Typography>

                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    Dashboard
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkte projecten, klanten en taken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Rollen en machtigingen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Inzicht in jouw projecten en team
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt offertes
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    TIME KEEPER aanwezig
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt urenregels factureren
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt factureren & online betalingen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt uren factureren
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Terugkerende facturen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Eigen logo & huisstijl
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Herinneringen en aanmaningen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Rapportage en gegevensexport
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Per extra gebruiker / maand 5,-
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#1472D0", marginLeft: "30px" }}
                  >
                    Geen jaarcontract, maandelijks opzegbaar
                  </Typography>
                </div>
              </CardContent>

              <CardActions
                sx={{ flexDirection: "column", justifyContent: "center" }}
              >
                <div
                  style={{
                    width: "100%",
                    margin: "1rem",
                    padding: "1rem",
                    backgroundColor: "rgba(208,175,136,0.3)",
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="caption" display="block" gutterBottom>
                    Deze prijzen gelden alleen voor Dashbook-leden.
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Niet-leden betalen de volgende prijzen. € 12,50
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    De prijzen zijn exclusief btw
                  </Typography>
                </div>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    padding: "0px 1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/dashboard")}
                >
                  Abonneer nu
                </Typography>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={3.75} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                  }}
                >
                  DB Starters / ZZP'er / MINI
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  Boekhouden, factureren met een maximum aantal bankmutaties
                </Typography>

                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  9,00*
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Alle functies die u nodig heeft:
                </Typography>

                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    Dashboard
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkte projecten, klanten en taken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Rollen en machtigingen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Inzicht in jouw projecten en team
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt offertes
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt producten toevoegen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Ritten en km's
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    TIME KEEPER aanwezig
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt urenregels factureren
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt factureren & online betalingen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt uren factureren
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Terugkerende facturen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Eigen logo & huisstijl
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Herinneringen en aanmaningen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Rapportage en gegevensexport
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Per extra gebruiker / maand 5,-
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Digitale inbox
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt inkoopfacturen en bonnetjes verwerken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Bonnetjes inscannen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Inzicht in je kosten
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    Gratis bankkoppeling
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    20 banktransacties p/m verwerken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Contant geld bijhouden
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Btw aangiftehulp
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Investeringen afschrijven
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Balans en Winst- en Verliesrekening
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Aanvraag inrichting administratie
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Aanvraag Inrichting projecten "branche gericht"
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#1472D0", marginLeft: "30px" }}
                  >
                    Geen jaarcontract, maandelijks opzegbaar
                  </Typography>
                </div>
              </CardContent>
              <CardActions
                sx={{ flexDirection: "column", justifyContent: "center" }}
              >
                <div
                  style={{
                    width: "100%",
                    margin: "1rem",
                    padding: "1rem",
                    backgroundColor: "rgba(208,175,136,0.3)",
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="caption" display="block" gutterBottom>
                    Deze prijzen gelden alleen voor Dashbook-leden.
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Niet-leden betalen de volgende prijzen. € 14,00
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    De prijzen zijn exclusief btw
                  </Typography>
                </div>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    padding: "0px 1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/dashboard")}
                >
                  Abonneer nu
                </Typography>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={3.75} sm={12} sx={{ marginBottom: "2rem" }}>
            <Card className="subCard">
              <CardContent>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                  }}
                >
                  Dashbook Unlimited
                </Typography>

                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  Compleet onbeperkt
                </Typography>

                <Typography variant="h3" sx={{ textAlign: "center" }}>
                  12,5*
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ textAlign: "center" }}
                >
                  per maand
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Alle functies die u nodig heeft:
                </Typography>

                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    Dashboard
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkte projecten, klanten en taken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Rollen en machtigingen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Inzicht in jouw projecten en team
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt offertes
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt producten toevoegen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Ritten en km's
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    TIME KEEPER aanwezig
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt urenregels factureren
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt factureren & online betalingen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt uren factureren
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Terugkerende facturen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Eigen logo & huisstijl
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Herinneringen en aanmaningen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Rapportage en gegevensexport
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Per extra gebruiker / maand 5,-
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Digitale inbox
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Onbeperkt inkoopfacturen en bonnetjes verwerken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Bonnetjes inscannen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Inzicht in je kosten
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    Gratis bankkoppeling
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    Onbeperkt banktransacties verwerken
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Contant geld bijhouden
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Btw aangiftehulp
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Investeringen afschrijven
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Balans en Winst- en Verliesrekening
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#d0af88" }}
                  >
                    Onbeperkt gebruikers toevoegen
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Gratis inrichting administratie
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Gratis Inrichting projecten "branche gericht"
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    Jaarafsluiting
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Typography
                    variant="overline"
                    display="block"
                    style={{ color: "#1472D0", marginLeft: "30px" }}
                  >
                    Geen jaarcontract, maandelijks opzegbaar
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <DoneIcon
                    style={{
                      height: "15px",
                      margin: "6px 0px",
                      color: "#d0af88",
                    }}
                  />
                  <Typography variant="overline" display="block">
                    EN NOG VEEL MEER
                  </Typography>
                </div>
              </CardContent>
              <CardActions
                sx={{ flexDirection: "column", justifyContent: "center" }}
              >
                <div
                  style={{
                    width: "100%",
                    margin: "1rem",
                    padding: "1rem",
                    backgroundColor: "rgba(208,175,136,0.3)",
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="caption" display="block" gutterBottom>
                    Deze prijzen gelden alleen voor Dashbook-leden.
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Niet-leden betalen de volgende prijzen. € 19,99
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    De prijzen zijn exclusief btw
                  </Typography>
                </div>
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    padding: "0px 1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/dashboard")}
                >
                  Abonneer nu
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default OurPrices;
