import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import CustomBtn from "../components/CustomBtn";
import { useTranslation } from "react-i18next";

export default function DeleteConfirmationDialog({
  open,
  title,
  description,
  onClose = () => {},
  onDelete = () => {},
  isDeletingData = false,
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      fullWidth
      sx={{ "& .MuiPaper-root": { borderRadius: "8px" } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          minHeight="100px"
          mt={3}
          sx={{ border: "3px dotted #0080ff", p: 2, borderRadius: "10px" }}
        >
          <Typography
            align="justify"
            fontSize={18}
            dangerouslySetInnerHTML={{ __html: description }}
          ></Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: 1, px: 2 }}>
        <Button
          onClick={onClose}
          color="success"
          sx={{ color: "white" }}
          variant="contained"
          disabled={isDeletingData}
        >
          {t("common_btn.cancel")}
        </Button>
        <CustomBtn
          onClick={onDelete}
          color="error"
          variant="contained"
          // disableRipple
          // disableElevation
        >
          {t("common_btn.delete")}
        </CustomBtn>
      </DialogActions>
    </Dialog>
  );
}
