import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Paper, Menu, TextField, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./CountriesMenu.css";

export default function CountriesMenu({
  anchorEl,
  setAnchorEl,
  open,
  setOpen,
  countryCode,
  setCountryCode,
}) {
  const { t } = useTranslation();

  const countries = useSelector((state) => state.common.countries);

  const [searchCountry, setSearchCountry] = useState("");
  const [filterCountries, setFilterCountries] = useState([]);

  useEffect(() => {
    setFilterCountries(countries);
  }, [countries]);

  const handleClose = (_, reason) => {
    if (reason === "tabKeyDown") {
      return false;
    }

    setOpen(false);
    setAnchorEl(null);
    
    setTimeout(() => {
      setFilterCountries(countries);
      setSearchCountry("");
    }, 1000);
  };

  const handleSearchCountry = (e) => {
    const search = e.target.value;

    setSearchCountry(search);

    const searchRegex = new RegExp(`.*${search}.*`, "ig");

    const filtered = countries.filter((c) => !c.name.search(searchRegex));

    setFilterCountries(filtered);
  };

  return (
    <Menu
      disableRestoreFocus={true}
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: "250px",
          width: "250px",
          padding: "0px !important",
        },
        sx: { "& .MuiList-root": { paddingTop: "0px" } },
      }}
    >
      <Paper
        sx={{
          position: "sticky",
          top: "0px",
          px: 2,
          py: 2,
          width: "100%",
          zIndex: 5,
        }}
        elevation={0}
        className="search_country_input_container"
      >
        <TextField
          type="text"
          size="small"
          value={searchCountry}
          onChange={handleSearchCountry}
          placeholder={t("search")}
          className="search_country_input"
          fullWidth
        />
      </Paper>
      {filterCountries.map((country) => {
        return (
          <Box
            key={country.identifier}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              py: 1,
              px: 2,
              bgcolor:
                countryCode === country.code ? "rgba(124, 185, 232, 0.2)" : "",
            }}
            className="country_option"
            onClick={() => {
              setCountryCode(country.code);
              handleClose();
            }}
          >
            <img
              loading="lazy"
              width="20"
              height="14"
              src={`https://flagcdn.com/w20/${country.identifier.toLowerCase()}.png`}
              alt=""
            />
            <Typography noWrap>
              {country.name} (+{country.code})
            </Typography>
          </Box>
        );
      })}
      {filterCountries.length === 0 && (
        <Typography variant="body2" textAlign="center" p={1}>
          No Countries Found
        </Typography>
      )}
    </Menu>
  );
}
