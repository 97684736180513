import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function SellIdealInvoicing() {
  const navigate = useNavigate();
  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Verkopen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          iDEAL facturatie met Mollie: snel en gemakkelijk betaald
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          " Bied je klanten de mogelijkheid om de factuur eenvoudig online te
          betalen. De integratie met Mollie maakt het mogelijk om een directe
          betaallink aan de factuur toe te voegen. Hierdoor kunnen je klanten de
          factuur moeiteloos betalen met iDEAL of andere beschikbare
          betaalmethoden. Deze handige functie bevordert een vlotte en
          gemakkelijke betalingservaring voor zowel jou als je klanten."
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-around", gap: "1rem" }}
        >
          <Typography variant="h4" className="titleUponDivider" gutterBottom>
            De mogelijkheden voor Mollie iDEAL betalingen met Dashbook
          </Typography>
        </div>
      </div>

      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          Mollie iDEAL betalingen
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"Mollie iDEAL betalingen".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Mollie iDEAL betalingen
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                }}
                gutterBottom
              >
                <strong>
                  Vereenvoudig het betalingsproces voor zowel jou als je
                  klanten:
                </strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Gemakkelijk Betalen:</strong> Klanten ontvangen de
                factuur per e-mail en kunnen direct betalen. De bedragen en
                referenties zijn vooraf ingevuld, waardoor fouten worden
                geminimaliseerd.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Snellere Betalingen:</strong>
                Door de directe betaalmogelijkheid met weinig handelingen,
                worden facturen sneller betaald. Het is laagdrempeliger en
                verkleint de kans op vergeten betalingen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Realtime Zichtbaarheid:</strong>
                De realtime koppeling met Mollie zorgt ervoor dat Dasbook direct
                weet of een factuur is betaald. Dit elimineert de wachttijd van
                bankverwerkingen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong> Automatische Verwerking:</strong>
                Transacties en betalingen worden automatisch geïmporteerd en
                verwerkt in je boekhouding dankzij de naadloze integratie met
                Mollie. Dit bespaart tijd en minimaliseert handmatig werk.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            lg={5.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="right-div imageCard">
              <img
                src={"./Professional/LinksMollie.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default SellIdealInvoicing;
