import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import TryFourteenDays from "../TryFourteenDays";
import { useNavigate } from "react-router-dom";

function LinkBank() {
  const navigate = useNavigate();

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };
  const scrollToTarget = (id) => {
    const targetElement = document.getElementsByClassName(`section-${id}`)[0];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);
  return (
    <div className="pageStructure">
      <div className="prizeBanner">
        <Typography
          variant="h2"
          sx={{ textAlign: "center", color: "white" }}
          gutterBottom
        >
          Koppelingen
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Bankkoppeling: je boekhouding altijd up-to-date
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }} gutterBottom>
          Houd je boekhouding automatisch up-to-date met onze handige
          bankkoppeling. Bespaar tijd en minimaliseer fouten door transacties
          moeiteloos te synchroniseren met je boekhouding. Zo blijf je altijd in
          controle en kun je je focussen op wat echt belangrijk is: ondernemen.
        </Typography>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{ color: "white" }}
            onClick={() => navigate("/dashboard")}
          >
            Nu Gratis Proberen! 00,0 / 14 dagen
          </Button>
        </div>
      </div>
      <div
        className="flex"
        style={{ justifyContent: "space-around", gap: "1rem" }}
      >
        <Typography variant="h4" className="titleUponDivider" gutterBottom>
          De mogelijkheden voor automatische bankkoppeling met Dashbook
        </Typography>
      </div>
      <div
        className="buttonBox"
        style={{
          padding: "1rem 3rem",
          paddingTop: "0rem !important",
          overflowX: "scroll",
        }}
      >
        <Button
          variant="contained"
          className={`buttonDesign`}
          onClick={() => scrollToTarget(1)}
        >
          GRATIS Automatische bankkoppeling
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(2)}
        >
          Realtime banktransacties
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(3)}
        >
          Bankimport
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(4)}
        >
          Alle Nederlandse banken
        </Button>
        <Button
          variant="contained"
          className={`buttonDesign `}
          onClick={() => scrollToTarget(5)}
        >
          Automatische verwerkregels
        </Button>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-1"
      >
        <Divider textAlign="left" className="divider">
          {"GRATIS Automatische bankkoppeling".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                GRATIS Automatische bankkoppeling
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Als ondernemer of zzp'er wil je natuurlijk dat je boekhouding
                altijd up-to-date is. Met de automatische bankkoppeling is dat
                geen probleem.
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "#d0af88" }}
                gutterBottom
              >
                Geniet van onze GRATIS bankkoppeling in Dashbook! In
                tegenstelling tot andere platforms brengen wij geen extra kosten
                in rekening voor deze handige functie.
              </Typography>
              <Typography variant="h6" gutterBottom>
                <strong>Voordelen:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Up-to-date boekhouding:</strong> Je boekhouding is
                altijd up-to-date, waardoor je altijd inzicht hebt in je
                financiële situatie.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Automatisch: </strong>De koppeling gebeurt automatisch,
                waardoor je tijd bespaart.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Flexibel:</strong> Je kunt de koppeling ook tussendoor
                laten doen, als je dat wilt.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-2"
      >
        <Divider textAlign="right" className="divider">
          {"Realtime banktransacties".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="up-div imageCard"></div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Realtime banktransacties
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Geniet van realtime banktransacties met onze koppeling! Met de
                Dashbook-bankkoppeling worden transacties direct opgehaald en in
                je administratie bijgewerkt. Zo is je bank altijd up-to-date in
                Dashbook. Let wel op dat je na 90 dagen opnieuw toestemming moet
                geven voor het ophalen van gegevens.. Dit zorgt ervoor dat je
                veilig en betrouwbaar gebruik kunt blijven maken van deze
                functionaliteit.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-3"
      >
        <Divider textAlign="left" className="divider">
          {"Bankimport".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Bankimport
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Met Dashbook heb je niet alleen de mogelijkheid van een
                automatische bankkoppeling, maar ook de vrijheid om transacties
                handmatig te importeren. Dit biedt flexibiliteit, zodat je jouw
                bankgegevens op verschillende manieren kunt integreren in je
                boekhouding.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Naast de automatische bankkoppeling kun je transacties
                importeren via CAMT bestanden. Deze formaat wordt ondersteund
                door vrijwel alle banken, waardoor je eenvoudig je gegevens kunt
                integreren.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-4"
      >
        <Divider textAlign="right" className="divider">
          {"Alle Nederlandse banken".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={5.5}
            lg={5.5}
            sm={12}
            sx={{ alignSelf: "center" }}
          >
            <div className="up-div imageCard">
              <img
                src={"./Professional/LinksBank.png"}
                alt="Dashbook Logo"
                width={"100%"}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="down-div card">
              <Typography variant="h4" gutterBottom>
                Alle Nederlandse banken
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Geniet van onze GRATIS bankkoppeling in Dashbook! In
                tegenstelling tot andere platforms brengen wij geen extra kosten
                in rekening voor deze handige functie. Je banktransacties worden
                naadloos gesynchroniseerd met Dashbook via onze geïntegreerde
                bankkoppeling met GoCardless. GoCardless, met een
                PSD2-vergunning, is geautoriseerd om banktransacties uit te
                lezen in heel Europa. Zo ben je verzekerd van een soepele en
                veilige integratie van je bankgegevens in Dashbook.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "3rem" }}
        className="section-5"
      >
        <Divider textAlign="left" className="divider">
          {"Automatische verwerkregels".toUpperCase()}
        </Divider>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="left-div card">
              <Typography variant="h4" gutterBottom>
                Automatische verwerkregels
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Dashbook biedt je niet alleen gratis bankkoppelingen, maar ook
                een slimme functie genaamd "Automatische Verwerkregels". Met
                deze functionaliteit kun je zelf regels instellen om bepaalde
                banktransacties volledig automatisch te verwerken. Dit bespaart
                niet alleen tijd, maar zorgt ook voor een efficiënte en foutloze
                boekhouding.
              </Typography>
              <Typography variant="h6" gutterBottom>
                <strong>Voordelen:</strong>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Volledig Automatische Verwerking:</strong> Stel
                eenvoudig regels in voor specifieke transacties, en Dashbook zal
                deze volautomatisch voor je verwerken. Hierdoor wordt een groot
                deel van je boekhouding geautomatiseerd.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Onbeperkt Aantal Regels:</strong>Onbeperkt Aantal
                Regels: Je hebt de vrijheid om zoveel regels in te stellen als
                nodig is. Dashbook biedt flexibiliteit, zodat je de automatische
                verwerkregels kunt aanpassen aan de unieke behoeften van jouw
                onderneming.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Tijdbesparing:</strong>
                Dankzij de automatische verwerkregels hoef je niet handmatig
                transacties te categoriseren. Dashbook doet dit automatisch
                volgens de door jou ingestelde regels, wat resulteert in
                aanzienlijke tijdbesparingen.
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Maak gebruik van Dashbook's Automatische Verwerkregels om je
                boekhouding moeiteloos en nauwkeurig te beheren.
              </Typography>
              <div className="flex" style={{ justifyContent: "end" }}>
                {/* <Button
                          variant="contained"
                          style={{ backgroundColor: "#4A88EA" }}
                        >
                          meer info
                        </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#6ED1D8" }}
                  onClick={() => navigate("/dashboard")}
                >
                  Gratis proefperiode
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignSelf: "center" }}>
            <div className="right-div imageCard"></div>
          </Grid>
        </Grid>
      </div>
      <TryFourteenDays />
    </div>
  );
}

export default LinkBank;
