import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
  IconButton,
  Grid,
} from "@mui/material";
import API from "../../config/axios";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setIsProcessing } from "../../features/common/commonSlice";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./AddVatPercentage.module.css";
import TextInput from "../../components/form/TextInput";
import { useForm } from "react-hook-form";
import AutocompleteInput from "../../components/form/AutocompleteInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomBtn from "../../components/CustomBtn";
import { highlightField } from "../../utils/Commons";

const defaultValues = {
  description: "",
  mainCategory: null,
  percentage: "",
  subcategory: null,
};

const validationSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
  mainCategory: yup
    .object()
    .test(
      "is-filled",
      "VAT declaration category is required",
      function (value) {
        return Object.keys(value).length > 0;
      },
    )
    .required("Select Category is required"),
  percentage: yup.string().required("% is required"),
});

export default function EditVatPercentage({
  showDialog,
  setShowDialog,
  getVarCategoryList,
  id,
}) {
  const dispatch = useDispatch();
  const abortController = useRef(new AbortController());
  const categoryAbortController = useRef(new AbortController());
  const { t } = useTranslation();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [vatCategoriesData, setVatCategoriesData] = useState([]);
  const [vatSubCategoriesData, setVatSubCategoriesData] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const closeAddDialog = () => {
    setShowDialog(false);
  };

  const getsubCategoryDetails = () => {
    // Cancel the previous request
    categoryAbortController.current.abort();
    categoryAbortController.current = new AbortController();

    setIsLoading(true);

    API.get(`users/vat_categories/uservatcategory/${id}`, {
      signal: categoryAbortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const subcategoryDetails = resData.data;
          setValue("description", subcategoryDetails?.description);
          setValue("mainCategory", subcategoryDetails?.mainCategory);
          setValue("percentage", subcategoryDetails?.percentage);
          setValue("subcategory", subcategoryDetails?.subcategory);
          setVatSubCategoriesData([subcategoryDetails?.subcategory]);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setIsProcessing(false));
      });
  };

  const getsubCategoryList = () => {
    // Cancel the previous request
    abortController.current.abort();
    abortController.current = new AbortController();

    setIsLoading(true);

    API.get(`users/vat_categories`, {
      signal: abortController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const subcategoryDetails = resData.data;
          setVatCategoriesData(subcategoryDetails);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setIsProcessing(false));
      });
  };

  const onSubmit = (data) => {
    const formData = {};
    const requestStartTime = Date.now();

    for (const key in data) {
      formData[key] = data[key] || "";
    }

    formData.mainCategory = data?.mainCategory?.id;
    formData.type = data?.mainCategory?.type;
    formData.subcategory = data?.subcategory?.id;
    formData.code = data?.subcategory?.code;

    API.put(`users/vat_categories/uservatcategory/${id}`, formData)
      .then((res) => {
        const resData = res.data;
        setInputErrors({});

        if (resData.status) {
          getVarCategoryList();
          setShowDialog(false);
          toast.success(
            t("vat_percentages.vat_percentages_details_updated_successfully"),
          );
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
            try {
              highlightField(Object.keys(resData.error)[0]);
            } catch (error) {
              console.warn("Field cannot be highlighted");
            }
          } else {
            toast.error(resData?.error?.message || "Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };

  useEffect(() => {
    getsubCategoryDetails(id);
    getsubCategoryList();
    // eslint-disable-next-line
  }, []);

  const mainCategory = watch("mainCategory");
  useEffect(() => {
    setValue("description", mainCategory?.description);
    if (mainCategory?.percentage) {
      setValue("percentage", mainCategory?.percentage);
    }

    if (
      Array.isArray(mainCategory?.subcategories) &&
      mainCategory?.subcategories.length
    ) {
      setValue("subcategory", mainCategory?.subcategories[0]);
      setVatSubCategoriesData(mainCategory?.subcategories);
    }
    // eslint-disable-next-line
  }, [mainCategory]);

  return (
    <div>
      <Dialog
        open={showDialog}
        maxWidth={"xs"}
        fullWidth
        sx={{ "& .MuiPaper-root": { borderRadius: "8px" } }}
      >
        <>
          <DialogTitle>
            <Stack justifyContent="space-between" direction="row">
              <Typography variant="h5" noWrap>
                {t("vat_percentages.edit_vat_percentage")}
              </Typography>
              <IconButton size="small" onClick={closeAddDialog}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent className={styles.dialog_content}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    name="description"
                    control={control}
                    label={t("vat_percentages.fields.description")}
                    customError={inputErrors.description || null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteInput
                    name="mainCategory"
                    control={control}
                    label={t("vat_percentages.fields.VAT_declaration_category")}
                    options={vatCategoriesData}
                    customError={inputErrors.mainCategory || null}
                    getOptionLabel={(option) =>
                      option.vatReturnCategoryName || ""
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="percentage"
                    control={control}
                    label={t("vat_percentages.fields.percentage")}
                    customError={inputErrors.percentage || null}
                  />
                </Grid>
                {vatSubCategoriesData.length !== 0 && (
                  <Grid item xs={12}>
                    <AutocompleteInput
                      name="subcategory"
                      control={control}
                      label={t("vat_percentages.fields.Categories")}
                      options={vatSubCategoriesData}
                      customError={inputErrors.subcategory || null}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions className={styles.dialog_actions}>
            <Stack direction="row" spacing={2}>
              <CustomBtn
                variant="contained"
                color="secondary"
                sx={{ paddingLeft: 2, paddingRight: 2 }}
                disableRipple
                type="submit"
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                save
              </CustomBtn>
            </Stack>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
}
