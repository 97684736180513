import React, { useRef } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import api from "../../config/axios";
import { Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import TextInput from "../../components/form/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import API from "../../config/axios";
import InfoIcon from "@mui/icons-material/Info";
import CustomBtn from "../../components/CustomBtn";
import { useTranslation } from "react-i18next";
import { highlightField } from "../../utils/Commons";
import TextareaAutoSize from "../../components/form/TextareaAutoSize";

const schema = yup.object().shape({
  invoiceNoFormat: yup.string().required(),
  // invoiceEmailText: yup.string().required(),
});

const InvoiceForm = () => {
  const { t } = useTranslation();

  const [preview, setPreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  const abortController = useRef(new AbortController());

  const defaultValues = {
    invoiceNoFormat: "",
    id: 0,
  };

  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const getInvoiceSettings = async () => {
    abortController.current.abort();
    abortController.current = new AbortController();

    try {
      const res = await api.get(`users/settings/numbering_and_text`, {
        signal: abortController.current.signal,
      });
      const resData = res.data;

      if (resData.status) {
        setValue("invoiceNoFormat", resData?.data?.invoiceNoFormat);
        setValue("id", resData?.data?.invoiceNoSequence);
        setValue("invoiceEmailText", resData?.data?.invoiceEmailText);
        setValue("offerEmailText", resData?.data?.offerEmailText);
        setValue("invoiceReminderEmailText", resData?.data?.invoiceEmailText);

        setPreview(generatePreview(resData?.data?.invoiceNoFormat));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (!error.code || error.code !== "ERR_CANCELED") {
        toast.error("Something went wrong");
      }
    }
  };

  const handleFormatChange = (newFormat) => {
    setValue("invoiceNoFormat", newFormat);
    let customer_id;
    const matches = newFormat.match(/\{customer_id\}/);
    customer_id = matches ? customer_id : "1234";

    setPreview(
      generatePreview(newFormat.replace(/\{customer_id\}/, customer_id))
    );
  };

  const handleFormatChangeForEmail = (newFormat) => {
    setValue("invoiceEmailText", newFormat);
  };

  const handleFormatChangeForReminderEmail = (newFormat) => {
    setValue("invoiceReminderEmailText", newFormat);
  };

  const handleTagClick = (tag) => {
    const { invoiceNoFormat } = getValues();
    if (invoiceNoFormat) {
      setValue("invoiceNoFormat", invoiceNoFormat + `-{${tag}}`);
      setPreview(generatePreview(invoiceNoFormat + `-{${tag}}`));
    } else {
      setValue("invoiceNoFormat", `{${tag}}`);
    }
  };

  const handleTagClickForEmail = (tag) => {
    const { invoiceEmailText } = getValues();
    if (invoiceEmailText) {
      setValue("invoiceEmailText", invoiceEmailText + `{${tag}}`);
      setPreview(generatePreview(invoiceEmailText + `{${tag}}`));
    } else {
      setValue("invoiceEmailText", `{${tag}}`);
    }
  };

  const handleTagClickForReminderEmail = (tag) => {
    const { invoiceReminderEmailText } = getValues();
    if (invoiceReminderEmailText) {
      setValue(
        "invoiceReminderEmailText",
        invoiceReminderEmailText + `{${tag}}`
      );
      setPreview(generatePreview(invoiceReminderEmailText + `{${tag}}`));
    } else {
      setValue("invoiceReminderEmailText", `{${tag}}`);
    }
  };

  const generatePreview = (format) => {
    const { id } = getValues();
    let previewString = "";
    const tags = format.match(/{[^}]+}/g);

    if (tags) {
      for (const tag of tags) {
        const tagName = tag.slice(1, -1); // Remove curly braces
        switch (tagName) {
          case "id":
            previewString += `${id}`;
            break;
          case "year":
            previewString += new Date().getFullYear();
            break;
          case "year_two_digits":
            previewString += new Date().getFullYear().toString().slice(-2);
            break;
          case "quarter":
            previewString += "Q3";
            break;
          case "month":
            previewString += (new Date().getMonth() + 1)
              .toString()
              .padStart(2, "0");
            break;
          case "month_name":
            const monthNames = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            const currentMonth = new Date().getMonth();
            previewString += monthNames[currentMonth];
            break;
          // case "week_number":
          //   previewString += getWeekNumber();
          //   break;
          case "day_number":
            previewString += new Date().getDate().toString().padStart(2, "0");
            break;
          default:
            break;
        }
        // Add a hyphen after each tag (except for the last one)
        if (tags[tags.length - 1] !== tag) {
          previewString += "-";
        }
      }
    }
    return previewString;
  };

  const onSubmit = (data) => {
    const formData = {
      invoiceNoFormat: data.invoiceNoFormat,
      invoiceNoSequence: data.id,
      invoiceEmailText: data.invoiceEmailText,
      invoiceReminderEmailText: data.invoiceReminderEmailText,
    };

    setIsLoading(true);
    const requestStartTime = Date.now();
    API.put(`users/settings/numbering_and_text`, formData)
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status) {
          toast.success(
            t("invoices.setting.number_details_updated_successfully")
          );

          // navigate("/period", { replace: true });
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
            try {
              highlightField(Object.keys(resData.error)[0]);
            } catch (error) {
              console.warn("Field cannot be highlighted");
            }
          } else {
            toast.error(resData?.error?.message || "Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };

  useEffect(() => {
    getInvoiceSettings();

    return () => {
      abortController.current.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5">{t("invoices.setting.number")}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography align="justify">
                  {t("invoices.setting.paragraph")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextInput
                  label={t("invoices.setting.Invoice_number")}
                  type="text"
                  name="invoiceNoFormat"
                  control={control}
                  customError={inputErrors.invoiceNoFormat || null}
                  onChangeCallback={(value) => handleFormatChange(value)}
                  placeholder="Enter custom format"
                />
                <div>
                  <label>{t("invoices.setting.example")}:</label>
                  <span>{preview}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mt={-0.6}
                >
                  <Typography variant="body2">
                    {t("invoices.setting.Next_issue")}
                  </Typography>

                  <Tooltip title={t("invoices.setting.tooltip_text")}>
                    <InfoIcon />
                  </Tooltip>
                </Stack>
                <TextInput
                  type="text"
                  control={control}
                  name="id"
                  placeholder="Enter custom format"
                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  flexWrap="wrap"
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography>{t("invoices.setting.tags")} :</Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClick("id")}
                  >
                    {t("invoices.setting.id")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClick("year")}
                  >
                    {t("invoices.setting.year_four_digits")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClick("year_two_digits")}
                  >
                    {t("invoices.setting.year_two_digits")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClick("quarter")}
                  >
                    {t("invoices.setting.quarter")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClick("month")}
                  >
                    {t("invoices.setting.month_number")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClick("month_name")}
                  >
                    {t("invoices.setting.month_name")}
                  </Typography>
                  {/* <Typography
                    style={{  cursor: "pointer" }}
                    onClick={() => handleTagClick("week_number")}
                  >
                    {t("invoices.setting.week_number")}
                  </Typography>
                  <Typography
                    style={{  cursor: "pointer" }}
                    onClick={() => handleTagClick("day_number")}
                  >
                    {t("invoices.setting.day_number")}
                  </Typography> */}
                </Stack>
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextareaAutoSize
                  label={t("invoices.setting.send_email_text")}
                  type="text"
                  name="invoiceEmailText"
                  control={control}
                  customError={inputErrors.invoiceEmailText || null}
                  onChangeCallback={(value) =>
                    handleFormatChangeForEmail(value)
                  }
                  placeholder="Enter custom format"
                />
                {inputErrors?.invoiceEmailText && (
                  <Typography color="red">
                    Invoice Email Text required
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Stack
                  flexWrap="wrap"
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography>{t("invoices.setting.tags")} :</Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClickForEmail("invoice_id")}
                  >
                    {t("invoices.setting.invoice_number")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClickForEmail("customer_name")}
                  >
                    {t("invoices.setting.customer")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClickForEmail("name")}
                  >
                    {t("invoices.setting.name")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClickForEmail("expiration_date")}
                  >
                    {t("invoices.setting.expiration_date")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextareaAutoSize
                  label={t("invoices.setting.reminder_email_text")}
                  type="text"
                  name="invoiceReminderEmailText"
                  control={control}
                  customError={inputErrors.invoiceReminderEmailText || null}
                  onChangeCallback={(value) =>
                    handleFormatChangeForReminderEmail(value)
                  }
                  placeholder="Enter custom format"
                />
                {inputErrors?.invoiceReminderEmailText && (
                  <Typography color="red">
                    Invoice reminder Email Text required
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack
                  flexWrap="wrap"
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography>{t("invoices.setting.tags")} :</Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() =>
                      handleTagClickForReminderEmail("invoice_details_table")
                    }
                  >
                    {t("invoices.setting.table")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() =>
                      handleTagClickForReminderEmail("customer_name")
                    }
                  >
                    {t("invoices.setting.customer")}
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="blue"
                    onClick={() => handleTagClickForReminderEmail("name")}
                  >
                    {t("invoices.setting.name")}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={3}>
                  <CustomBtn
                    variant="contained"
                    color="secondary"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                    disableRipple
                    type="submit"
                    loading={isLoading}
                  >
                    {t("common_btn.save")}
                  </CustomBtn>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InvoiceForm;
